import axios from "axios";
import {IGetPostsRequestBody, IGetSinglePost} from "../interfaces/post";

export const makePost = (data: FormData) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/post`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const getAllPost = (data: IGetPostsRequestBody) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/post/user/${data.id}`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const getSinglePost = (data: IGetSinglePost) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/post/${data.id}`,
        data: {
            asset_size: data?.asset_size
        }
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const updateSinglePost = (data: FormData, id: string) => {
    return axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_API_URL}/post/${id}`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const deleteSinglePost = (id: string | undefined) => {
    return axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/post/${id}`
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
