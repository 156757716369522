import axios from "axios";
import {ICreateTicket} from "../interfaces/ticket";

export const createTicket = (data: ICreateTicket) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/ticket`,
        data
    })
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
