import React, {useState, useEffect} from 'react'
import {useHistory} from 'react-router-dom';
import StyledButtonComponent from '../buttons/AnimatedButton/animated-button';
import HeaderWithMandarine from '../header/header-with-mandarine';
import {ROUTES} from '../../enum/routes';
import star from '../../assets/img/star.svg'


interface IProps {
    header: string,
    options: any,
    buttonName: {first: string, second: string},
    buttonHref: {first: string, second: string},
    img: string
}

const DetaisPageDesctop: React.FC<IProps> = ({header, options, buttonName, buttonHref, img}) => {
    const history = useHistory()
    const [imageLoaded, setImageLoaded] = useState(false)

    useEffect(() => {
        const image = new Image()
        image.src = img
        image.onload = () => setImageLoaded(true)
    }, [img])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='details-page-desctop-container'>
            <div className='picture-container'>
                <div className={`${imageLoaded && 'picture-loaded'} picture`} style={{
                    background: `url(${img})`,
                }}/>
            </div>
            <div className='description-container'>
                <HeaderWithMandarine/>
                <div className='description'>
                    <h1>{header}</h1>
                    {options.map((el: any, index: number) => (
                        <div key={index}>
                            {el.description}
                            <ul>
                                {el?.options?.map((option: any, optionIndex: number) => (
                                    <li key={optionIndex}><img alt='Chusp bullet points' src={star}/>{option}</li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <div className='button-container'>
                <StyledButtonComponent width={'40%'} text={buttonName.first} onClickHandler={() => history.push(buttonHref.first)}/>
                <StyledButtonComponent width={'40%'} text={buttonName.second} onClickHandler={() => history.push(buttonHref.second)}/>
                </div>
                <div className='go-back' onClick={() => history.push(ROUTES.HOME)}>
                    Sign In
                </div>
                <div className='privacy'>
                    By continuing, you agree with our 
                    <a 
                      className='styled-link'
                      href={`${process.env.REACT_APP_UI_URL}/p/terms`}
                      target="_blank"
                      rel="noopener noreferrer">
                        Terms
                      </a> 
                      and 
                    <a
                      className='styled-link'
                      href={`${process.env.REACT_APP_UI_URL}/p/policy`} 
                      target="_blank" 
                      rel="noopener noreferrer">
                        Privacy Policy
                    </a>.
                </div>
            </div>
        </div>
    )
}

export default DetaisPageDesctop;