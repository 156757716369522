import {useAuth} from "./auth";
import {useEffect, useState} from "react";

export const useHasToken = () => {
    const [jwtLoading, setJwtLoading] = useState<boolean>(true);
    const [hasJwt, setHasJwt] = useState<boolean | null | undefined>(null);
    const {hasToken} = useAuth();


    useEffect(() => {
        (async () => {
            let response = await hasToken();
            setHasJwt(response);
            setJwtLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        hasJwt,
        jwtLoading
    }
}