import moment from "moment-timezone";
import lookup from "country-code-lookup";
import ct from "countries-and-timezones";
import { getTimeZones } from "@vvo/tzdb";

export const getTimeZoneListOfValuesWithLabels = () => {
    return moment.tz.names().map((timezone) => {
        const timezoneGMTString = moment().tz(timezone).format('Z')
        const cityWithoutContinentString = timezone.split('/')[1]
        const label = `${cityWithoutContinentString} (GMT${timezoneGMTString})`

        return {value: timezone, label}
    })
}

export const getTimeZoneLabelsList = () => {
    return moment.tz.names().map((timezone) => {
        const timezoneGMTString = moment().tz(timezone).format('Z')
        const cityWithoutContinentString = timezone.split('/')[1]

        return `${cityWithoutContinentString} (GMT${timezoneGMTString})`
    })
};

export const getTimezoneLabelInAlpha3ByValueWithGMT = (value: string) => {
    const timeZonesWithLabelList = getTimeZoneListOfValuesWithLabels()
    const timezoneObj = timeZonesWithLabelList.find((t) => t.value === value)
    const timezoneMomentValue = timezoneObj?.value ? timezoneObj?.value : moment.tz.guess()
    const countryCodeFromTimezoneValue = ct.getTimezone(timezoneMomentValue)?.countries[0]
    const timezoneGMTString = moment().tz(timezoneMomentValue).format('Z')
    const searchParam = countryCodeFromTimezoneValue ? countryCodeFromTimezoneValue : ""
    const countryInIso3 = lookup.byIso(searchParam)?.iso3

    return !!countryInIso3?.length ? `${countryInIso3} (GMT${timezoneGMTString})` : getTimeZoneLabelByValue(value)
}

export const getTimezoneLabelInAlpha3ByValue = (value: string) => {
    const timeZonesWithLabelList = getTimeZoneListOfValuesWithLabels()
    const timezoneObj = timeZonesWithLabelList.find((t) => t.value === value)
    const timezoneMomentValue = timezoneObj?.value ? timezoneObj?.value : moment.tz.guess()
    const countryCodeFromTimezoneValue = ct.getTimezone(timezoneMomentValue)?.countries[0]
    const searchParam = countryCodeFromTimezoneValue ? countryCodeFromTimezoneValue : "SG"
    const countryInIso3 = lookup.byIso(searchParam)?.iso3

    return !!countryInIso3?.length ? `${countryInIso3} Standart time` : getTimeZoneLabelByValue(value)

}

export const getTimezoneValueByLabel = (label: string) => {
    const timeZonesWithLabelList = getTimeZoneListOfValuesWithLabels()
    const timezoneObj = timeZonesWithLabelList.find((t) => t.label === label)

    return timezoneObj ? timezoneObj.value : ''
}


export const getTimeZoneLabelByValue = (value: string) => {
    const timeZonesWithLabelList = getTimeZoneListOfValuesWithLabels()
    const timezoneObj = timeZonesWithLabelList.find((t) => t.value === value)

    return timezoneObj ? timezoneObj.label : ''
}

export const getStandardTimeLabelAbbrByValue = (value: string) => {
    const timeZonesWithLabelList = getTimeZoneListOfValuesWithLabels()
    const timezoneObj = timeZonesWithLabelList.find((t) => t.value === value)
    const timeZones = getTimeZones();
    const timeZoneAbbr = timezoneObj && timeZones.filter(item => item.group.includes(timezoneObj.value))
    return timeZoneAbbr?.length ? timeZoneAbbr[0].abbreviation : ''
}