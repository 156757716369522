import {useEffect, useState} from "react";
import {getIP, sendIP} from "../../api/get-ip";
import {useCookie} from "react-use";
import {AUTH_KEY} from "../../enum/auth";
import moment from "moment";

export const useDetectUserLocation = () => {
    const [ip, setIp] = useState<string>("")
    const [values] = useCookie(AUTH_KEY)

    useEffect(() => {
        (async () => {
            const countryIp = sessionStorage.getItem("location")
            if (!countryIp) {
                const response = await getIP();
                if (response) {
                    const countryIp = response?.country_name
                    countryIp && sessionStorage.setItem("location", countryIp)
                    await setIp(response?.ip)
                }
            }
        })();
    }, [])

    useEffect(() => {
        (async () => {
            if (values && ip) {
                await sendIP({
                    ip,
                    timezone: moment.tz.guess()
                })
            }
        })()
    }, [values, ip])

}
