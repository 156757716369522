import {useEffect, useRef, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {loginAction, logoutAction} from '../../redux/reducers/auth';
import {logoutFromVisitorMode} from '../../redux/reducers/visitorMode';
import {
    signinRequest,
    signupRequest,
    authRequest,
    confirmRegistration
} from '../../api/auth';
import {useCookies} from './cookies';
import {deleteToken, setToken} from './token';
import {USER_ROLE} from '../../enum/user';
import {AUTH_KEY} from "../../enum/auth";
import {ROUTES} from "../../enum/routes";
import {useHistory} from "react-router";
import {IAuthSocialsRequestBody, IGoogleAuthRequestBody, ILoginWithSocialsRequestBody, IWeChatAuthRequestBody} from "../../interfaces/auth";
import {getVatRate} from "../../api/vat";
import {setVatRate} from "../../redux/reducers/vat";
import { saveToMemory } from '../../redux/reducers/searchUsers';


export const useAuth = () => {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [socialLoading, setSocialsLoading] = useState<boolean>(false)
    const [refreshLoader, setRefreshLoader] = useState<boolean>(false)
    const {getItem, removeItem, setItemWithExpiration, setItem} = useCookies();
    const dispatch = useDispatch();
    const history = useHistory();
    const [confirmEmailLoading, setConfirmEmailLoading] = useState<boolean>(false)
    const mounted = useRef<boolean>(false)


    useEffect(() => {
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    return {
        error,
        setError,
        loading,
        refreshLoader,
        socialLoading,
        confirmEmailLoading,
        login: async (credentials: { email: string, password: string }, remember: boolean | undefined) => {
            setLoading(true);
           const meetingId = sessionStorage.getItem('meetingId')
            const response = await signinRequest(credentials);
            if (response && response.message) {
                setError(response.message);
            } else if (response && response.data) {
                const {
                    token,
                    user,
                } = response.data;
                const authToken = token.type + " " + token.accessToken;
                if (remember) {
                    await setItemWithExpiration({
                        key: AUTH_KEY,
                        value: authToken,
                        expiresIn: token.expiresIn,
                    })
                }
                const tokenExist = await getItem(AUTH_KEY)
                if (!tokenExist) {
                    await setItem({
                        key: AUTH_KEY,
                        value: authToken,
                        expiresIn: token.expiresIn
                    })
                }
                dispatch(logoutFromVisitorMode())
                setError("")
                setToken(authToken);
                dispatch(loginAction({user}));
                const vatRate = await getVatRate()
                vatRate && dispatch(setVatRate(vatRate.rate))
                meetingId ? history.push(ROUTES.MY_PAGE.MEETING_MODE + '/' + meetingId) :  history.push(ROUTES.USER_LIST)
            } else {
                setError("User doesn't exist.");
            }
            setLoading(false);
        },

        register: async (credentials: { first_name: string, email: string, password: string, residence: string }) => {
            setError("")
            setLoading(true);
            const response: any = await signupRequest(credentials);
            if (response && response.message) {
                setError(response.message);
            }
            if (response && response.data) {
                setError("")
            }
            setLoading(false);
            return response
        },
        confirmSignUp: async (body: ILoginWithSocialsRequestBody) => {
            setConfirmEmailLoading(true)
            const response = await confirmRegistration(body)
            response && setConfirmEmailLoading(false)
            if (response && response.message) {
                setError(response.message);
            }
            if (response?.data) {
                const {
                    token,
                    user,
                } = response.data;
                if (token && user) {
                    const authToken = token.type + " " + token.accessToken;
                    const tokenExist = await getItem(AUTH_KEY)
                    if (!tokenExist) {
                        await setItemWithExpiration({
                            key: AUTH_KEY,
                            value: authToken,
                            expiresIn: token.expiresIn
                        });
                    }
                    dispatch(logoutFromVisitorMode())
                    setError("")
                    setToken(authToken);
                    dispatch(loginAction({user}));
                    const vatRate = await getVatRate()
                    vatRate && dispatch(setVatRate(vatRate.rate))
                    history.push(ROUTES.USER_LIST)
                }
            }
        },
        doAuthWithSocials: async (asyncFunction: any, body: IAuthSocialsRequestBody | IWeChatAuthRequestBody | IGoogleAuthRequestBody) => {
            setSocialsLoading(true)
            const meetingId = sessionStorage.getItem('meetingId')
            const response = await asyncFunction(body)
            response && setSocialsLoading(false)
            if (response && response.message) {
                setError(response.message);
            } else if (response && response.data) {
                const {
                    token,
                    user,
                } = response.data;
                if (token && user) {
                    const authToken = token.type + " " + token.accessToken;
                    const tokenExists = await getItem(AUTH_KEY)
                    if (!tokenExists) {
                        await setItemWithExpiration({
                            key: AUTH_KEY,
                            value: authToken,
                            expiresIn: token.expiresIn
                        });
                    }
                    dispatch(logoutFromVisitorMode())
                    setError("")
                    setToken(authToken);
                    dispatch(loginAction({user}));
                    const vatRate = await getVatRate()
                    vatRate && dispatch(setVatRate(vatRate.rate))
                    meetingId ? history.push(ROUTES.MY_PAGE.MEETING_MODE + '/' + meetingId) :  history.push(ROUTES.USER_LIST)
                } else {
                    setError("User doesn't exist.");
                }
            }
        },
        authCheck: async (callback: () => void) => {
            setLoading(true);
            const authToken = await getItem(AUTH_KEY);
            if (authToken) {
                setToken(authToken)
                const response = await authRequest();
                if (!response) {
                    history.push(ROUTES.OOPS)
                }
                if (response && response.message) {
                    if (response?.code === 401) {
                        await removeItem(AUTH_KEY)
                        deleteToken()
                        history.push(ROUTES.ROOT_AUTH)
                    }
                    setError(response.message);
                } else if (response && response.data) {
                    dispatch(logoutFromVisitorMode())
                    const user = response.data;
                    dispatch(loginAction({user}));
                    const vatRate = await getVatRate()
                    vatRate && dispatch(setVatRate(vatRate))
                    callback()
                }
            }
            setLoading(false);
        },

        refreshUser: async () => {
            setRefreshLoader(true)
            const authToken = await getItem(AUTH_KEY);
            if (authToken) {
                const response = await authRequest();
                if (response && response.message) {
                    // history.push(ROUTES.OOPS)
                    setError(response.message);
                } else if (response && response?.data) {
                    dispatch(logoutFromVisitorMode())
                    const user = response.data;
                    dispatch(loginAction({user}));
                    const vatRate = await getVatRate()
                    vatRate && dispatch(setVatRate(vatRate.rate))
                }
            }
            setRefreshLoader(false);
        },

        logout: async () => {
            setLoading(true);
            await removeItem(AUTH_KEY);
            await dispatch(logoutAction());
            await deleteToken();
            const authToken = await getItem(AUTH_KEY);
            if (authToken) {
                await history.push(ROUTES.USER_LIST)
            } else {
                dispatch(saveToMemory({id: null, page: 0, usersInfo: []}))
                await history.push(ROUTES.LOGIN)
            }
            setLoading(false);
        },
        hasToken: async () => {
            setLoading(true);
            const authToken = await getItem(AUTH_KEY);
            setLoading(false);
            return !!authToken;
        },
    };
};

export const useUserRole = () => {
    const role = useSelector((state: RootStateOrAny) => state.auth?.user?.user?.role);
    return {
        isVisitor: role === USER_ROLE.VU,
        isAdmin: role === USER_ROLE.ADU,
        isHostingUser: role === USER_ROLE.HU
    };
};
