import {useEffect, useState} from "react";
import {getSuccessRateByUser} from "../../api/score";


export const useUsersSuccessRate = (userId: number | undefined, shouldFetch: boolean = true) => {
    const [successRate, setSuccessRate] = useState<number>(0)


    useEffect(() => {
        (async () => {
            if (shouldFetch) {
                const response = userId && await getSuccessRateByUser(userId)
                if (response?.data) {
                    setSuccessRate(response?.data?.count)
                }
            }
        })()
    }, [userId, shouldFetch])

    return {
        successRate
    }
}
