import React, {useEffect} from "react";
import "cropperjs/dist/cropper.css";
import Modal from "../main-modal/modal";
import {ICommonModalProps} from "../../../interfaces/modal";
import Cropper from "react-cropper";

interface IProps extends ICommonModalProps {
    image: string | ArrayBuffer | null,
    setImage: React.Dispatch<React.SetStateAction<string | ArrayBuffer | null>>,
    onComplete: () => Promise<void>,
    onCancel: () => Promise<void>,
    setCropper: any
}

const CropperModal: React.FC<IProps> = (
    {
        open,
        setOpen,
        image,
        setImage,
        onComplete,
        onCancel,
        setCropper
    }) => {


    useEffect(() => {
        return () => setImage(null)
        // eslint-disable-next-line
    }, [])


    return (
        <Modal
            rightButton={"OK"}
            leftButton={'Cancel'}
            open={open}
            setOpen={setOpen}
            onComplete={onComplete}
            onCancel={onCancel}
            children={
                <Cropper
                    className={"cropper-styled"}
                    zoomTo={0}
                    src={typeof image === "string" ? image : undefined}
                    viewMode={1}
                    minContainerHeight={300}
                    minContainerWidth={300}
                    aspectRatio={1}
                    autoCropArea={0.9}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                />
            }
        />
    );
};

export default CropperModal;
