import React, {useContext, useRef, useState} from 'react'
import Modal from "../../../../components/modal/main-modal/modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {AutoComplete} from "material-ui";
import {TextField} from "@material-ui/core";
import {PrivateContainerStateContext} from "../../../../pages/App/Private";
import {
    getTimeZoneLabelByValue,
    getTimeZoneLabelsList,
    getTimezoneValueByLabel
} from "../../../../utils/getTimeZoneLabels";
import {ICommonModalProps} from "../../../../interfaces/modal";

const TimezoneModal: React.FC<ICommonModalProps> = ({open,setOpen}) => {
    const {currentTimeZone, selectCurrentTimezone, selectTimeZoneLabel} = useContext(PrivateContainerStateContext)
    const ref = useRef<AutoComplete>(null)

    const [selectedTimeZoneLabel, setSelectedTimeZoneLabel] = useState<string>(getTimeZoneLabelByValue(currentTimeZone))

    const listOfLabels = getTimeZoneLabelsList()

    const localeSort = Array.from(listOfLabels).sort((a, b) => {
        return a.localeCompare(b, 'en', {sensitivity: 'base'});
    });

    const onComplete = async () => {
        const getValueByLabel = getTimezoneValueByLabel(selectedTimeZoneLabel)
        selectTimeZoneLabel(selectedTimeZoneLabel)
        selectCurrentTimezone(getValueByLabel)
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            refToIgnore={ref}
            title={'Choose your timezone'}
            rightButton={'OK'}
            leftButton={'Cancel'}
            onComplete={onComplete}
            children={
                <Autocomplete
                    ref={ref}
                    options={localeSort}
                    value={selectedTimeZoneLabel}
                    onChange={(event, value) => value && setSelectedTimeZoneLabel(value)}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Timezone"
                            variant="outlined"
                            InputLabelProps={{shrink: true}}
                        />}
                />
            }
        />
    )
}

export default TimezoneModal;
