import React from 'react'
import TwitterIcon from "@material-ui/icons/Twitter";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TelegramIcon from "@material-ui/icons/Telegram";
import FacebookIcon from "@material-ui/icons/Facebook";
import MailIcon from "@material-ui/icons/Mail";
import {IUser} from "../../../../interfaces/user";
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share'
import AppleStyleModal from '../../../../components/modal/apple-style/apple-style-modal';

interface IProps {
    user?: IUser;
    postId?: number;
    contentId?: string;
    openShareModal: boolean,
    setOpenShareModal: React.Dispatch<React.SetStateAction<boolean>>
}

const ShareIconsDesctop: React.FC<IProps> = ({user, postId,  contentId, openShareModal, setOpenShareModal}) => {
    const user_name = user?.user_name?.replace(/\s/g, '_');
    const share_link = `${process.env.REACT_APP_NEXT_PUBLIC_ENV_BASE_CLIENT_UI}/u/${user_name}`
    const share_post_link = `${process.env.REACT_APP_NEXT_PUBLIC_ENV_BASE_CLIENT_UI}/users/${user?.id}/post/${postId}`
    const share_content_link = `${process.env.REACT_APP_NEXT_PUBLIC_ENV_BASE_CLIENT_UI}/pc/${contentId}`

    return (
        <AppleStyleModal
            open={openShareModal}
            setOpen={setOpenShareModal}
            items={[
                <TwitterShareButton title={'Twitter'} url={postId ? share_post_link : contentId ? share_content_link : share_link}>
                        <div className="refer-icon">
                            <div className="refer-icon-circle twitter">
                                <TwitterIcon/>
                            </div>
                            <span>Twitter</span>
                        </div>
                    </TwitterShareButton>,
                    <WhatsappShareButton title={'WhatsApp'} url={postId ? share_post_link : contentId ? share_content_link : share_link}>
                        <div className="refer-icon">
                            <div className="refer-icon-circle whatsapp">
                                <WhatsAppIcon/>
                            </div>
                            <span>WhatsApp</span>
                        </div>
                    </WhatsappShareButton>,
                    <TelegramShareButton title={'Telegram'} url={postId ? share_post_link : contentId ? share_content_link : share_link}>
                        <div className="refer-icon">
                            <div className="refer-icon-circle telegram">
                                <TelegramIcon/>
                            </div>
                            <span>Telegram</span>
                        </div>
                    </TelegramShareButton>,
                    <FacebookShareButton title={'Facebook'} url={postId ? share_post_link : contentId ? share_content_link : share_link}>
                        <div className="refer-icon">
                            <div className="refer-icon-circle facebook">
                                <FacebookIcon/>
                            </div>
                            <span>Facebook</span>
                        </div>
                    </FacebookShareButton>,
                    <EmailShareButton title={'Mail'} url={postId ? share_post_link : contentId ? share_content_link : share_link}>
                        <div className="refer-icon">
                            <div className="refer-icon-circle mail">
                                <MailIcon/>
                            </div>
                            <span>Mail</span>
                        </div>
                    </EmailShareButton>
                ]}
            />
    )
};

export default ShareIconsDesctop
