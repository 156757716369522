import {IUserMeta} from "../interfaces/user";
import {updateUser} from "../api/user";
import {userMetaTypes} from "../enum/user";

interface IUpdateUserMeta {
    data: any
}


export const getMetaGlobal = (metadata: IUserMeta[], type: string) => {
    const metaObj = metadata?.find((item: IUserMeta) => item?.type === type);
    if (type === userMetaTypes.LANGUAGE ||
        type === userMetaTypes.WORK ||
        type === userMetaTypes.WEBSITES ||
        type === userMetaTypes.BASIC_INFO ||
        type === userMetaTypes.COLLEGE
    ) {
        try {
            return metaObj && metadata !== undefined ? JSON.parse(metaObj?.value) : ''
        } catch (err) {
            console.log(err)
        }
    } else
        return metaObj?.value
}

export const updateMetaData = (user: any, type: string, data: IUpdateUserMeta) => {

    const deepUserCopy = JSON.parse(JSON.stringify(user));
    const metaExist = deepUserCopy?.metadata?.find((item: IUserMeta) => item?.type === type);
    const meta = data?.data;

    if (metaExist) {
        deepUserCopy?.metadata?.forEach((item: IUserMeta) => {
            if (item?.type === type) {
                item.value = meta
            }
        })
    } else {
        const newMeta = {
            type: type,
            value: meta
        }
        deepUserCopy?.metadata?.push(newMeta)
    }
    return updateUser(deepUserCopy);
}



