import React from 'react'
import DetaisPageDesctop from '../../../components/details-page/details-page-desctop'
import boy from "../../../assets/img/male-face.jpg";
import {options, header, buttonName} from '../../../enum/be-client'
import {ROUTES} from '../../../enum/routes';
import DetaisPageMobile from '../../../components/details-page/details-page-mobile';

const BeClient = () => {
    return (
        <div>
            <DetaisPageDesctop
                header={header}
                options={options}
                buttonName={buttonName}
                buttonHref={{first: ROUTES.BE_HOST, second: ROUTES.BE_CREATOR}}
                img={boy}
            />
            <DetaisPageMobile
                header={header}
                options={options}
                buttonName={buttonName}
                buttonHref={{first: ROUTES.BE_HOST, second: ROUTES.BE_CREATOR}}
                img={boy}
            />
        </div>
    )
}

export default BeClient;
