export const TICKET_PROFILE_REASON = {
    INAPPROPRIATE_CONTENT: {
        type: 'INAPPROPRIATE_CONTENT',
        text: 'Inappropriate Content'
    },
    SUSPICIOUS_USER_PROFILE: {
        type: 'SUSPICIOUS_USER_PROFILE',
        text: 'Suspicious user profile'
    },
    SPAM_PROFILE: {
        type: 'SPAM_PROFILE',
        text: 'Spam'
    },
};

export const TICKET_POST_REASON = {
    INAPPROPRIATE_CONTENT: {
        type: 'INAPPROPRIATE_CONTENT',
        text: 'Inappropriate Content'
    },
    SPAM_POST: {
        type: 'SPAM_POST',
        text: 'Spam'
    },
    SUSPICIOUS_USER_PROFILE: {
        type: 'SUSPICIOUS_USER_PROFILE',
        text: 'Suspicious user profile'
    },
};

export const TICKET_PAID_CONTENT_REASON = {
    INAPPROPRIATE_CONTENT: {
        type: 'INAPPROPRIATE_CONTENT',
        text: 'Inappropriate Content'
    },
    SPAM_POST: {
        type: 'SPAM_POST',
        text: 'Spam'
    },
    SUSPICIOUS_USER_PROFILE: {
        type: 'SUSPICIOUS_USER_PROFILE',
        text: 'Suspicious user profile'
    },
    COPYRIGHT_INFRINGEMENT: {
        type: 'COPYRIGHT_INFRINGEMENT',
        text: 'Copyright infringement'
    }
};