import {RootStateOrAny, useSelector} from "react-redux";

export const useVariableValues = (variableKey: string) => {
    const variable = useSelector((state: RootStateOrAny) => state.variable?.variables.find((v: { key: string, value: string }) => v?.key === variableKey))
    const variableValue = variable?.value

    return {
        variableValue
    }
}
