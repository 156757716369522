export const SERVICE_VARIABLE = {
    MU:"MU",
    HBR:"HBR",
    HRF1:"HRF1",
    HRF2:"HRF2",
    HRF3:"HRF3",
    HRF4:"HRF4",
    MIN_HOSTING_RATE: "MIN_HOSTING_RATE",
    N1: "N1",
    N2: "N2",
    N3: "N3",
    CPM0: "CPM0",
    CPM1: "CPM1",
    CPM2: "CPM2",
    CPM3: "CPM3",
    BDF0: "BDF0",
    BDF1: "BDF1",
    BDF2: "BDF2",
    BDF3: "BDF3",
    REPEAT_SLOT_WEEK_COUNTER: "REPEAT_SLOT_WEEK_COUNTER",
    CHUSP_FEE: "CHUSP_FEE",
    COMPLAINT_WINDOW_PERIOD: "COMPLAINT_WINDOW_PERIOD",
    HOSTING_USER_GRACE_PERIOD: "HOSTING_USER_GRACE_PERIOD",
    FAN_USER_GRACE_PERIOD: "FAN_USER_GRACE_PERIOD",
    PROFILE_BIO_CHARACTERS_COUNT: "PROFILE_BIO_CHARACTERS_COUNT",
    CONTENT_DESCRIPTION_CHARACTERS_COUNT: "CONTENT_DESCRIPTION_CHARACTERS_COUNT",
    TEXT_POSTS_CHARACTERS_COUNT: "TEXT_POSTS_CHARACTERS_COUNT",
    FU_MESSAGE_TO_HU_CHARACTERS_COUNT: "FU_MESSAGE_TO_HU_CHARACTERS_COUNT",
    COMPLAINT_FORM_CHARACTERS_COUNT: "COMPLAINT_FORM_CHARACTERS_COUNT",
    FEEDBACK_FORM_CHARACTERS_COUNT: "FEEDBACK_FORM_CHARACTERS_COUNT",
    SUPPORT_FORM_CHARACTERS_COUNT: "SUPPORT_FORM_CHARACTERS_COUNT",
    HOSTING_ESCALATION_CHARACTERS_COUNT: "HOSTING_ESCALATION_CHARACTERS_COUNT",
    MAX_QUALITY_RESOLUTION_FOR_MEETING_MODE: "MAX_QUALITY_RESOLUTION_FOR_MEETING_MODE",
    PAID_CONTENT_MAX_ALLOWED_ITEM_SIZE: "PAID_CONTENT_MAX_ALLOWED_ITEM_SIZE"
};
