import React from 'react'
import girl from "../../../assets/img/girl-des.jpg";
import DetaisPageDesctop from '../../../components/details-page/details-page-desctop'
import {options, header, buttonName} from '../../../enum/be-host'
import {ROUTES} from '../../../enum/routes';
import DetaisPageMobile from '../../../components/details-page/details-page-mobile'

const BeHost = () => {
    return (
        <div>
            <DetaisPageDesctop
                header={header}
                options={options}
                buttonName={buttonName}
                buttonHref={{first:ROUTES.BE_CREATOR, second: ROUTES.BE_CLIENT}}
                img={girl}
            />
            <DetaisPageMobile
                header={header}
                options={options}
                buttonName={buttonName}
                buttonHref={{first:ROUTES.BE_CREATOR, second: ROUTES.BE_CLIENT}}
                img={girl}
            />
        </div>
    )
}

export default BeHost;
