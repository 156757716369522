import { createSlice } from "@reduxjs/toolkit";
import { VARIABLE } from "../types";

interface Variable {
  key: string;
  value: string;
}

interface State {
  variables: Variable[];
}
const defaultSlice = createSlice({
  name: VARIABLE,
  initialState: {
    variables: [] as Variable[],
  },
  reducers: {
    setVariablesAction(state: State, action) {
      return { ...state, variables: action.payload };
    },
  },
});

export const { setVariablesAction } = defaultSlice.actions;

export default defaultSlice.reducer;
