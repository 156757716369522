import React, {useEffect, useState} from 'react'
import Card from './card';
import {getFollowers} from "../../../api/follow";
import {FOLLOW_TYPE} from "../../../enum/follow";
import {IUser} from "../../../interfaces/user";
import PullToRefresh from "react-simple-pull-to-refresh";
import {useWindowSize} from "../../../service/common/window";
import {useHistory} from "react-router";
import {usePagination} from "../../../service/pagination/pagination";
import ChuspSpinner from "../../../components/loader/ChuspSpinner";
import {ASSETS_SIZE} from "../../../enum/asset-size";
import { ROUTES } from '../../../enum/routes';
import TextWithDecoration from '../../../components/text-with-decoration/TextWithDecoration';

interface IProps {
    type: typeof FOLLOW_TYPE.ADMIRORS | typeof FOLLOW_TYPE.FOLLOWERS,
}

const FollowersContainer: React.FC<IProps> = ({type}) => {
    const history = useHistory()
    const {isDesktop} = useWindowSize()
    const [currentPage, setCurrentPage] = useState<number>(0)
    const [loader, setLoader] = useState<boolean>(false)
    const [listOfFollower, setListOfFollower] = useState<any>()
    const [newData] = usePagination(getFollowers, {
        variables: {
            type,
            pagination: {page: currentPage + 1, pageSize: 7},
            asset_size: ASSETS_SIZE.SMALL,
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        (async () => {
            setLoader(true)
            const response = await getFollowers({
                    type,
                    pagination: {page: 0, pageSize: 7},
                    asset_size: ASSETS_SIZE.SMALL,
                }
            )
            if (response) {
                setListOfFollower(response?.items)
                setCurrentPage(response?.currentPage)
                setLoader(false)
            }
        })()
    }, [type])

    useEffect(() => {
        if (newData?.items && newData?.items?.length > 0) {
            setListOfFollower([...listOfFollower, ...newData?.items])
            setCurrentPage(newData?.currentPage)
        }
        // eslint-disable-next-line
    }, [newData])

    useEffect(() => {
        if (listOfFollower?.length === 0) {
            history.push(ROUTES.MY_PAGE.PROFILE)
        }
        // eslint-disable-next-line
    }, [listOfFollower, history])

    const onRefresh = async () => {
        await getFollowers({
            type,
            pagination: {page: 0, pageSize: 7},
            asset_size: ASSETS_SIZE.SMALL,
        })
    }

    return (
        <div className='index-folow-mark-container'>
            {loader ? <ChuspSpinner isGlobal={false}/> : (
                <>
                    {isDesktop ? (
                        <div>
                            {isDesktop && <TextWithDecoration className='title' content={type === FOLLOW_TYPE.ADMIRORS ? 'Admirors' : 'Followers'} />}
                            {listOfFollower?.map((user: IUser, index: number) => (
                                <Card
                                    key={index}
                                    user={user}
                                    type={type}
                                    setListOfFollower={setListOfFollower}
                                />
                            ))}
                        </div>
                    ) : (
                        <PullToRefresh onRefresh={onRefresh}>
                            <div>
                                {isDesktop && <TextWithDecoration className='title' content={type === FOLLOW_TYPE.ADMIRORS ? 'Admirors' : 'Followers'}  />}
                                {listOfFollower?.map((user: IUser, index: number) => (
                                    <Card
                                        key={index}
                                        user={user}
                                        type={type}
                                        setListOfFollower={setListOfFollower}
                                    />
                                ))}
                            </div>
                        </PullToRefresh>
                    )}
                </>
            )}
        </div>
    )
}

export default FollowersContainer
