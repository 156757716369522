import React from 'react'

const InlineLoader = () => {
    return (
        <div className='inline-loader-container'>
            <div/>
            <div/>
            <div/>
        </div>
    )
}

export default InlineLoader;
