export const POST_TYPE = {
    IMAGE: "IMAGE",
    TEXT: "TEXT"
}

export const UPLOAD_IMAGE_POSTS_LIMIT = 3

export const SUPPORTED_FILE_TYPES_REGEX = /image-*/

export const POSTS_SUCCESS_MESSAGE = (postId: string) => `Successfully ${postId ? "updated" : "saved"}`

export const POSTS_ERROR_MESSAGES = {
    IMAGE_UPLOAD_LIMITATION_EXCEED: `You can only upload ${UPLOAD_IMAGE_POSTS_LIMIT} images`,
    UPLOAD_ERROR: (postId: string) => `Couldn't ${postId ? "update" : "save"}`,
    SOMETHING_WENT_WRONG: `Something went wrong..`
}

