import React, {CSSProperties} from 'react'

interface IHeaderProps {
    children?: JSX.Element | null | string,
    leftElement?: JSX.Element | null,
    rightElement?: JSX.Element | null
}

// QUICK STYLES WITHOUT LOGIC AND MEDIA
const STYLES_LEFT_CONTAINER: CSSProperties = {marginRight: "auto"}
const STYLES_RIGHT_CONTAINER: CSSProperties = {marginLeft: "auto"}

const InfoBar: React.FC<IHeaderProps> = ({children, leftElement, rightElement}) => {
    return (
        <div className='infobar-container'>
            <div style={STYLES_LEFT_CONTAINER}>
                {leftElement}
            </div>
            <div className='children'>
                {children}
            </div>
            <div style={STYLES_RIGHT_CONTAINER}>
                {rightElement}
            </div>
        </div>
    )
}

export default InfoBar;
