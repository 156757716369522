import axios from 'axios'
import {IGetPreSignedURL, IBaseS3} from "../interfaces/system";


export const getPreSignedUrl = (data: IGetPreSignedURL) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/system/signed/url`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const uploadFileToS3 = async (url: string, file: string) => {
    const buffer = Buffer.from(file?.replace(/^data:image\/\w+;base64,/, ""),'base64');
    const token = axios.defaults.headers.common['Authorization']
    delete axios.defaults.headers.common["Authorization"];
    return await axios({
        method: "PUT",
        url: `${url}`,
        data: buffer
    })
        .then(response => {
            axios.defaults.headers.common['Authorization'] = token
            return {message: response && response.statusText, status: response.status}
        })
        .catch(error => {
            axios.defaults.headers.common['Authorization'] = token
            return {message: error.response.statusText, status: error.response.status}
        })
}

export const resizedImage = async (s3Object: IBaseS3) => {
    return await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/system/image/resize`,
        data: s3Object
    })
        .then(response => response && response.data)
        .catch(error => error && error.message)
}

export const checkingEmail = (data: {email: string}) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/user/exist`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
