import React from "react";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

interface IProps {
    handleChange: (value: string) => void,
    resetSearch: () => void,
    value: string,
    placeholder: string,
    handleCut: (value: any) => void
}

const SearchField: React.FC<IProps> = ({handleChange, resetSearch, value, placeholder, handleCut}) => {
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    return (
        <form className="search-field-container" onSubmit={onSubmit}>
            <input type="text"
                   placeholder={placeholder}
                   onChange={e => handleChange(e.target.value)}
                   value={value}
                   autoFocus={false}
                   onCut={handleCut}
            />
            {value ? <HighlightOffIcon onClick={resetSearch}/> : null}
        </form>
    )
}

export default SearchField
