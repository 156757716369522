import axios from "axios";
import {IAddPreEventNote, IBookingCancel, IBookMeetingWithUser, IMeetingDetails, IUserAvailableDates} from "../interfaces/booking";

export const bookMeetingWithUser = (data: IBookMeetingWithUser) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/booking`,
        data
    })
        .then((response) =>  {
            if(response && response.data) {
                // localStorage.setItem("eventId", response.data.data?.eventId)
                localStorage.setItem("eventId", response.data?.invoiceNumber)
                // localStorage.setItem("checkoutSessionId", response.data.data?.checkoutSessionId)
                return response.data;
            }
        })
        .catch((error) =>
            error &&
            error?.response?.data
                ? {error: error?.response?.data}
                : {error: 'unknown error'},
        );
}
export const CheckStripeWalletPaymentStatus = (SessionId: string, externalTransactionId: string) => {
    const data = {
        SessionId: SessionId,
        transactionId: externalTransactionId,
        eventId:  localStorage.getItem("eventId") 
    }
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/booking/get/eWallet/paymentStatus`,
        data
    })
        .then((response) => {
            if(response) {
                localStorage.removeItem("eventId");
                localStorage.removeItem("transactionId");
                return response.data;
            }})
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const getUserAvailableDate = (data: IUserAvailableDates, source?: {token : any}) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/slot/date/available/user/${data.id}`,
        data,
        cancelToken: source?.token
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const getUserAvailableDateNew = (data: IUserAvailableDates, source?: {token : any}) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/slot/date/available/user/new/${data.id}`,
        data,
        cancelToken: source?.token
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const bookingCancel = (data: IBookingCancel) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/booking/cancel`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const getMeetingDetails = (data: IMeetingDetails) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/booking/public/get`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const addPreEventNote = (id: string, data: IAddPreEventNote) => {
    return axios({
        method: 'PATCH',
        url: `${process.env.REACT_APP_API_URL}/booking/${id}`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const getTransactionId = (id: string) => {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/booking/get/${id}`
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
