import {createSlice} from "@reduxjs/toolkit";
import {VAT} from "../types";


interface State {
    rate: number;
    active: boolean;
}

const defaultSlice = createSlice({
    name: VAT,
    initialState: {
        rate: 0,
        active: false
    },
    reducers: {
        setVatRate(state: State, action) {
            return {...state, rate: action.payload.rate, active: action.payload.active};
        },
    },
});

export const {setVatRate} = defaultSlice.actions;

export default defaultSlice.reducer;
