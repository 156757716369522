const container_search = '?container='

export const CONTAINERS = {
    HOSTING: {
        CONTAINER_SEARCH: `${container_search}hosting`
    },
    WALLET: {
        CONTAINER_SEARCH: `${container_search}wallet`
    },
    SHOW_MORE: {
        CONTAINER_SEARCH: `${container_search}show-more`
    },
    ADMIRORS: {
        CONTAINER_SEARCH: `${container_search}admirors`
    },
    FOLLOWERS: {
        CONTAINER_SEARCH: `${container_search}followers`
    },
    POST: {
        CONTAINER_SEARCH: `${container_search}post`
    },
    POST_SHOW_MORE: {
        CONTAINER_SEARCH: `${container_search}post-show-more`
    },
    BLACKLIST: {
        CONTAINER_SEARCH: `${container_search}blacklist`
    },

    // PAYMENT CARDS
    CARDS: 'CARDS',
    EDTI_CARD: 'EDIT_CARD',
    ENTER_CARD: 'ENTER_CARD',
    BILLING_ADDRESS: 'BILLING ADDRESS',
    EDIT_BILLING_ADDRESS: 'EDIT_BILLING_ADDRESS',

    // CALENDAR
    // MODAL CONTAINERS
    CREATE_ON_SELECT: 'CREATE_ON_SELECT',
    EDIT_DATES: 'EDIT_DATES',
}

