import React, { useRef, useState, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useWindowSize } from "../../../service/common/window";
import { useLocation, useHistory } from "react-router";
import { ROUTES } from "../../../enum/routes";
import { THEME } from "../../../ui/theme";
import SearchIcon from '@material-ui/icons/Search';
import Avatar from "@material-ui/core/Avatar";
import Header from "../../../components/header/index";
import InfoBar from "../../../components/infobar/index";
import SearchField from "../../../components/field/SearchField";
import Footer from "../../../components/footer/index";
import { PROFILE_MODES } from "../../../enum/profile-mods";
import { IconButton } from "@material-ui/core";
import { useClickAway } from "react-use";
import BackButton from "../../../components/buttons/back-button";
import ShareOrReport from "../../../containers/Profile/Modals/ShareOrReportModal";
import { DOCUMENTS_REQUEST } from "../../../enum/documents";
import { IUser } from "../../../interfaces/user";
import { RootStateOrAny, useSelector } from "react-redux";
import ChuspLoader from "../../../components/loader/ChuspLoader";
import Btn from "../../../components/buttons/MainButton/Button";
import FAQ from "./FAQ";
import { useOpenUserProfile } from "../../../service/user/user";
// import { setCurrentPageData, setUsersInfo } from "../../../redux/reducers/searchUsers";


const AboutUs = React.lazy(() => import("./AboutUs"));
const UserList = React.lazy(() => import("./UserList"));
const Document = React.lazy(() => import("./Document"));
const SinglePostContainer = React.lazy(() => import("./SinglePost"));
const Profile = React.lazy(() => import("../../../containers/Profile"));

const PublicContainer = () => {
    const location: any = useLocation()
    const { pathname, state } = location;

    const { isTable, width } = useWindowSize()
    const history = useHistory()
    const { openUserProfile } = useOpenUserProfile()

    const [searchValue, setSearchTerm] = useState("")
    const [toggleSearch, setToggleSearch] = useState<boolean>(false)

    const myData: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const isUserPage = pathname.includes(ROUTES.USER_PAGE) && !pathname.includes(ROUTES.USER_POST)

    const openUserFromSessionStorage = JSON.parse(window.sessionStorage.getItem('openUserData')!)

    const ref = useRef<HTMLDivElement>(null)
    // const dispatch = useDispatch();
    const [searchResult, setSearchResult] = useState<any>([]);

    const responsiveWidth = () => {
        if (width && width === 768) {
            return 250
        } else if (width && width <= 375) {
            return 230
        } else {
            return 320
        }
    }

    const onHandleChange = useCallback((value: string) => {
        if (value !== '') {
            //   dispatch(setUsersInfo([]));
            //   dispatch(setCurrentPageData(0));
            setSearchResult([]);
        }
        setSearchTerm(value)
    }, [setSearchTerm])

    const resetSearch = () => {
        setSearchResult([]);
        setSearchTerm('');
    }
    const handleCut = (event: any) => {
        // dispatch(setUsersInfo([]));
        // dispatch(setCurrentPageData(0));
        setSearchTerm('');
        setSearchResult([]);
    };
    useClickAway(ref, () => setToggleSearch(false))
    const renderMobileHeader = () => {
        if (isTable && pathname === ROUTES.USER_LIST) {
            return (
                <div className="mobile-header" ref={ref}>
                    <div style={{
                        width: toggleSearch ? responsiveWidth() : 0,
                        overflow: 'hidden',
                        transition: "0.3s linear"
                    }}>
                        <SearchField
                            handleChange={onHandleChange}
                            resetSearch={() => { setSearchResult([]); setSearchTerm("") }}
                            value={searchValue}
                            placeholder="Enter search criteria..."
                            handleCut={handleCut}
                        />
                    </div>
                    <div className={"public-right-icon"}>
                        {toggleSearch ?
                            <Btn onClick={() => {
                                setSearchTerm('')
                                setToggleSearch(!toggleSearch)
                            }
                            }
                                clr={THEME.colors.white}
                                content={'Cancel'}
                            /> :
                            <IconButton className={"public-icon-btn"} onClick={() => { setToggleSearch(!toggleSearch) }}>
                                <SearchIcon />
                            </IconButton>}
                    </div>
                </div>
            )
        } else if (pathname === `${ROUTES.USER_PAGE}/${openUserFromSessionStorage?.id}${ROUTES.USER_POST}`) {
            return null
        } else if (pathname === `${ROUTES.USER_PAGE}/${openUserFromSessionStorage?.id}`) {
            return <ShareOrReport user={openUserFromSessionStorage} />
        }
    };

    const showUserAvatarWithNameIfNoAvatarExist = () => {
        if (state?.userProfile) {
            return <Avatar
                className={"public-user-avatar"}
                src={state?.userProfile} />
        } else if (openUserFromSessionStorage?.public_avatar_url) {
            return <Avatar
                className={"public-user-avatar"}
                src={openUserFromSessionStorage?.public_avatar_url} />
        } else {
            return <Avatar
                className={"public-user-avatar"}
            >
                {openUserFromSessionStorage?.first_name[0]}
            </Avatar>
        }
    }

    const renderInfoBar = () => {
        switch (pathname) {
            case ROUTES.USER_LIST:
                return (
                    <>
                        {!isTable &&
                            <InfoBar>
                                <SearchField
                                    handleChange={onHandleChange}
                                    resetSearch={() => { setSearchTerm(""); setSearchResult([]); }}
                                    value={searchValue}
                                    placeholder="Enter search criteria..."
                                    handleCut={handleCut}
                                />
                            </InfoBar>}
                    </>
                )
            case ROUTES.POLICY:
                return (
                    <InfoBar leftElement={<BackButton goBack={() => myData ? history.goBack() : history.push(ROUTES.ROOT_AUTH)} />}>
                        PRIVACY POLICY
                    </InfoBar>
                );
            case ROUTES.FAQ:
                return (
                    <InfoBar leftElement={<BackButton goBack={() => myData ? history.goBack() : history.push(ROUTES.ROOT_AUTH)} />}>
                        FAQ
                    </InfoBar>
                );
            case ROUTES.TERMS:
                const historyRoute = localStorage.getItem("refund_terms_history_route")
                return (
                    <InfoBar leftElement={<BackButton
                        goBack={() => historyRoute ? history.push(historyRoute) :
                            myData ? history.goBack() : history.push(ROUTES.ROOT_AUTH)
                        } />}>
                        TERMS
                    </InfoBar>
                );
            case `${ROUTES.USER_PAGE}/${state?.userId}${ROUTES.USER_POST}`:
                return (
                    <InfoBar leftElement={<BackButton goBack={() => myData ? history.goBack() : history.push(ROUTES.ROOT_AUTH)} />}
                        children={(
                            <div
                                className={"public-posts-header"}
                                onClick={() => {
                                    state?.userId === myData?.id ?
                                        history.push(ROUTES.MY_PAGE.PROFILE) :
                                        openUserProfile(state?.userId)
                                }}>
                                {showUserAvatarWithNameIfNoAvatarExist()}
                                {/* <span>{openUserFromSessionStorage?.first_name} {openUserFromSessionStorage?.last_name}</span> */}
                                <span>{state?.userfullName}</span>
                            </div>
                        )}
                    />
                );
        }
    }

    return (
        <div className={"public-container"}>
            <div className={"public-sticky"}>
                <Header rightIcon={renderMobileHeader()} />
                {renderInfoBar()}
            </div>
            <div className={"public-content"}>
                <div className={"public-wrapper"} style={{ minHeight: isUserPage ? '90vh' : '82vh' }}>
                    <React.Suspense fallback={<ChuspLoader />}>
                        <Switch>
                            <Route exact path={ROUTES.USER_LIST}
                                render={() =>
                                    <UserList searchValue={searchValue} searchResult={searchResult} setSearchResult={setSearchResult} resetSearch={resetSearch} />}
                            />
                            <Route exact path={`${ROUTES.USER_PAGE}/:id`}
                                render={() => <Profile mode={PROFILE_MODES.VIEW} />} />
                            <Route exact path={`${ROUTES.USER_PAGE}/:userId${ROUTES.USER_POST}`}
                                render={() => <SinglePostContainer />} />
                            <Route exact path={ROUTES.POLICY}
                                render={() => <Document type={DOCUMENTS_REQUEST.POLICY} />}
                            />
                            <Route exact path={ROUTES.TERMS}
                                render={() => <Document type={DOCUMENTS_REQUEST.TERMS} />}
                            />
                            <Route path={ROUTES.FAQ} component={FAQ} exact />
                            <Route exact path={ROUTES.ABOUT_US} render={() => <AboutUs />} />
                            <Redirect to={ROUTES.OOPS} />
                        </Switch>
                    </React.Suspense>
                </div>
            </div>
            {isTable && <Footer />}
        </div>
    );
};

export default PublicContainer;
