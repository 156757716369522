import React, { useEffect, useState } from "react";
import googleIcon from "../../../../assets/img/icons/gmail.svg";
import wechatIcon from "../../../../assets/img/icons/wechat-logo.svg";
// import fbIcon from "../../../../assets/img/icons/facebookLogo.png";
import { useGoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login";
import { THEME } from "../../../../ui/theme";
import { ROUTES } from "../../../../enum/routes";
import MobileLoginForm from "../../components/form/mobile/signin";
import { useAuth } from "../../../../service/authentication/auth";
// import { useHistory } from "react-router";
import {
  // facebookCheckIfAccountExist,
  // facebookLoginRequest,
  // facebookSignUpWithEmailRequest,
  googleLoginRequest,
} from "../../../../api/auth";
import { Link } from "react-router-dom";
import { useUsersIPLocation } from "../../../../service/IP";
import { popUpWindow } from "../../../../utils/window-popup";
import SuccessOrErrorModal from "../../../../components/modal/success-or-error/success-or-error-modal";
import { SUCCESS_OR_ERROR_MODAL } from "../../../../enum/modal";
import Btn from "../../../../components/buttons/MainButton/Button";
import YoutubeSlideInButton from "../../../../components/youtube-slide-in-button";
import Modal from "../../../../components/modal/main-modal/modal";
import InstallPWA from "../../../../components/install-pwa-button";
import FiberManualRecordSharpIcon from "@material-ui/icons/FiberManualRecordSharp";
import CopyrightIcon from '@material-ui/icons/Copyright';

interface IProps {
  toggleForm: boolean;
  setToggleForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignInContainer: React.FC<IProps> = ({ toggleForm, setToggleForm }) => {
  const { doAuthWithSocials, error } = useAuth();
  // const history = useHistory();
  const { countryIp } = useUsersIPLocation();
  const [openUserBlockedModalWindow, setOpenUserBlockedModalWindow] =
    useState<boolean>(false);
  // eslint-disable-next-line
  const [authCheckErrorMessage, setAuthCheckErrorMessage] =
    useState<string>("");
  const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setOpenUserBlockedModalWindow(true);
    }
  }, [error]);

  const googleRequest = useGoogleLogin({
    onSuccess: async (codeResponse) =>
      codeResponse &&
      codeResponse.code &&
      (await doAuthWithSocials(googleLoginRequest, {
        code: codeResponse.code,
        residence_country: countryIp,
      })),
    flow: "auth-code",
  });

  // const loginWithFacebook = async (response: any) => {
  //   if (response.accessToken) {
  //     const data = await facebookCheckIfAccountExist(response?.accessToken);
  //     if (data && data?.message) {
  //       setOpenUserBlockedModalWindow(true);
  //       setAuthCheckErrorMessage(data?.message);
  //     }
  //     const alreadyExist = data?.data?.existInDB;
  //     const facebookWithEmail = data?.data?.hasEmail;
  //     alreadyExist
  //       ? await doAuthWithSocials(facebookLoginRequest, {
  //           token: response?.accessToken,
  //         })
  //       : facebookWithEmail
  //       ? await doAuthWithSocials(facebookSignUpWithEmailRequest, {
  //           token: response?.accessToken,
  //           residence_country: countryIp,
  //         })
  //       : history.push({
  //           pathname: ROUTES.AUTH_EMAIL_ADD,
  //           state: { token: response.accessToken },
  //         });
  //   }
  // };

  const weChatUrl = process.env.REACT_APP_WE_CHAT_LOGIN_URL || "";
  const loginWechatRes = () => {
    popUpWindow(weChatUrl, "WeChat", 500, 500);
  };

  return (
    <div
      className={`${toggleForm ? "signin-container-toggle" : "signin-container"
        }`}
    >
      {!toggleForm && (
        <YoutubeSlideInButton onClick={() => setOpenVideoModal(true)} />
      )}
      {!toggleForm && <InstallPWA />}
      <Btn
        className="signin-button"
        display={toggleForm}
        background={THEME.colors.white}
        clr={THEME.colors.main}
        onClick={() => setToggleForm(true)}
        content={"Sign In"}
      />
      {!toggleForm && (
        <div className="wrapper">
          <p className="cuntinue-text">
            <Link to={ROUTES.REGISTER}>Sign up</Link> or continue with
          </p>
          <div className="socials">
            {/* <FacebookLogin
              appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
              autoLoad={false}
              textButton={""}
              fields="name,email,picture"
              callback={loginWithFacebook}
              isMobile={true}
              redirectUri={`${process.env.REACT_APP_REDIRECT_FACEBOOK_URI}`}
              disableMobileRedirect={true}
              icon={<img src={fbIcon} alt={"Chusp Facebook"} />}
            /> */}
            <img
              className="google-icon"
              src={googleIcon}
              onClick={googleRequest}
              alt="Chusp Gmail"
            />
            <button className="weChat-login-button" onClick={loginWechatRes}>
              <img
                className="weChat-logo"
                src={wechatIcon}
                alt="Chusp WeChat"
              />
            </button>
          </div>
          {/* <p className='learn-more link'>
                    <Link to={ROUTES.BE_HOST}>
                        Learn more
                    </Link>
                    about Chusp or read our
                    {" "}<Link to={ROUTES.FAQ}>
                    FAQs
                </Link>
                </p> */}
          <div className="privacy-policy-mobile">
            <p className="s">
              By proceeding, you agree with our
              {" "}
              <a href={ROUTES.TERMS} target={"_blank"} rel={"noreferrer"}>
                Terms
              </a>
              {" "}
              and{" "}
              <a href={ROUTES.POLICY} target={"_blank"} rel={"noreferrer"}>
                Privacy Policy
              </a>
            </p>
          </div>
          <div className="footer-menu-mobile">
            {" "}
            <p className="learn-more learn-more-mobile">
              <Link to={ROUTES.BE_HOST}>About</Link>
              <FiberManualRecordSharpIcon />
              <Link to={ROUTES.FAQ}>FAQs</Link>
              <FiberManualRecordSharpIcon />
              <a href="mailto:support@chusp.com">Contact</a>
            </p>
            <div className="copy-right-container">
              <span className="copyRightText copyRightText-mobile ml-4"><CopyrightIcon className="copyRightText-mobile" />&nbsp;Chusp</span>
            </div>
          </div>
        </div>
      )}
      {toggleForm && (
        <div
          style={{
            backgroundColor: THEME.colors.white,
            transition: "all linear",
            display: "block",
          }}
        >
          <MobileLoginForm />
        </div>
      )}
      {openUserBlockedModalWindow && (
        <SuccessOrErrorModal
          open={openUserBlockedModalWindow}
          setOpen={setOpenUserBlockedModalWindow}
          type={SUCCESS_OR_ERROR_MODAL.ERROR}
          textError={error ? error : authCheckErrorMessage}
          onActionClicked={() => setOpenUserBlockedModalWindow(false)}
        />
      )}
      {openVideoModal && (
        <Modal
          open={openVideoModal}
          setOpen={setOpenVideoModal}
          leftButton={"Close"}
          children={
            <video
              className="youtube-video video-tag"
              autoPlay
              playsInline
              controls
            >
              <source
                src="https://s3.ap-southeast-1.amazonaws.com/assets.prod-chusp.com/public/CHUSP+Hear+We+Come.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          }
        />
      )}
    </div>
  );
};

export default SignInContainer;
