import React from 'react'
import Modal from "../main-modal/modal";
import { THEME } from '../../../ui/theme';
import {ICommonModalProps} from "../../../interfaces/modal";

interface IProps extends ICommonModalProps{
    title?: JSX.Element | JSX.Element[] | null | string,
    leftButton?: JSX.Element | JSX.Element[] | null | string,
    rightButton?: JSX.Element | JSX.Element[] | null | string,
    onComplete?: any,
    items: any
}

const AppleStyleModal: React.FC<IProps> = ({open, setOpen, title, leftButton, rightButton, onComplete, items}) => {
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={title}
            leftButton={leftButton}
            rightButton={rightButton}
            onComplete={onComplete}
            color={THEME.colors.white}
            children={
                <div className='apple-style-modal-container'>
                    {items.map((el: any, index: number) => (
                        <div className='styled-text' key={index}>{el}</div>
                    ))}
                </div>
            }
        />)
}

export default AppleStyleModal;