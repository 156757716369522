import axios from 'axios';

export const setToken = (authToken?: string) => {
  axios.defaults.headers.common.Authorization =
      authToken ? authToken : null;
};

export const deleteToken = () => {
  delete axios.defaults.headers.common.Authorization;
};
