import React, {useEffect} from 'react'
import {useHistory} from "react-router"
import SuccessOrErrorModal from "../../../../components/modal/success-or-error/success-or-error-modal";
import {SUCCESS_OR_ERROR_MODAL} from "../../../../enum/modal";
import {CALENDAR_ERRORS} from "../../../../enum/errors";
import {ROUTES} from "../../../../enum/routes";
import { SEARCH_STEPS } from '../../../../enum/steps';
import {ICommonModalProps} from "../../../../interfaces/modal";


interface IProps extends ICommonModalProps{
    errorMessage: string | undefined,
    removeTempSlot: () => void,
    setErrorMessage: any
}

const CannotCreateSlotErrorModal: React.FC<IProps> = ({open, setOpen, errorMessage, removeTempSlot, setErrorMessage}) => {
    const history = useHistory()
    useEffect(()=>{
        removeTempSlot()
        // eslint-disable-next-line
    },[open])

    const actionButton = errorMessage === CALENDAR_ERRORS.CREATE_SLOT_NOT_ALLOWED ? "Start hosting" : "Try again"
    const actionClicked = errorMessage === CALENDAR_ERRORS.CREATE_SLOT_NOT_ALLOWED 
    ? () => history.push({
        pathname: ROUTES.MY_PAGE.HOSTING,
        search: SEARCH_STEPS.FIRST_STEP
    })
    : () => {
        setOpen(false)
        setErrorMessage('')
    }
    return (
        <SuccessOrErrorModal
            open={open}
            setOpen={setOpen}
            type={SUCCESS_OR_ERROR_MODAL.ERROR}
            textError={errorMessage}
            actionButton={actionButton}
            onActionClicked={actionClicked}
            disableClose={true}
        />
    )
}



export default CannotCreateSlotErrorModal
