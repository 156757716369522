import React, {useEffect, useState} from "react";
import ReactQuillPreviewCard from "../../../../components/reqct-quill/preview";

interface IProps {
    text: any
    openSinglePost: any
}

const TextPost: React.FC<IProps> = ({text, openSinglePost}) => {
    const [loadMoreText, setLoadMoreText] = useState<any>({})
    const [container, setContainer] = useState<any>()
    const [bottom, setBottom] = useState<any>()
    const [containerHeight, setContainerHeight] = useState<any>()

    const paddingTopFromMainContainer = 5
    const heightOfTextContainer = 45

    const toggleText = (id: any) => {
        setLoadMoreText((prev: any) => Boolean(!prev[id]) ? {...prev, [id]: true} : {...prev, [id]: false});
    };

    // eslint-disable-next-line
    useEffect(() => {
        setContainer(document.getElementById(`postContainer${text.id}`))
        setBottom(container && (container.children[0].offsetTop + container.children[0].offsetHeight - paddingTopFromMainContainer))
        setContainerHeight(container && container.offsetHeight)
    })

    return (
        <div
            className={"text-post-container"}
            style={{
                height:
                    (!loadMoreText[text?.id] && !(bottom > heightOfTextContainer)) || (loadMoreText[text?.id]) ? 'auto' : '70px'
            }}
        >
            <div
                className={"text-container"}
                style={{height: (!loadMoreText[text?.id] && !(bottom > heightOfTextContainer)) || (loadMoreText[text?.id]) ? 'auto' : '45px'}}
                id={`postContainer${text.id}`}
            >
                <ReactQuillPreviewCard
                    text={text?.text}
                    onClick={() => {
                        openSinglePost(text?.id)
                    }}
                />
            </div>
            {bottom > containerHeight &&
            <button
                className={"read-more-btn"}
                style={{boxShadow: !loadMoreText[text?.id] ? `10px -18px 20px 10px #F7F7F7` : 'none'}}
                onClick={() => toggleText(text?.id)}
            >
                Click for more
            </button>}
            {loadMoreText[text?.id] &&
            <button
                className={"read-more-btn"}
                style={{boxShadow: !loadMoreText[text?.id] ? `10px -18px 20px 10px #F7F7F7` : 'none'}}
                onClick={() => toggleText(text?.id)}
            >
                Click for less
            </button>}
        </div>
    );
};

export default TextPost;
