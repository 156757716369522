import {useState, useEffect} from 'react';
import {
    IPostBody, IPostBodyPagination, IListResponse, IObjectResponse,
} from '../../interfaces/request';
// const defaultPagination = {page: 0, pageSize: 10};
export const useInitialRequest = (request: (...args: any) => any,
                                  args: IPostBody | IPostBodyPagination = {}): any[] => {
    const {variables, pagination} = {...args};

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState<IObjectResponse | IListResponse | null>(null);
    const [page, setPage] = useState(pagination && pagination.page ? pagination.page : 0);
    const [paginationLoading, setPaginationLoading] = useState(false);

    useEffect(() => {
        getData().then((data) => data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = async () => {
        setLoading(true);
        const data = await request(
            pagination ? {pagination, ...variables} : variables,
        );
        if (data && data.error) {
            setError(data.error);
        } else {
            setResponse(data);
            pagination && setPage(pagination.page);
        }
        setLoading(false);
    };
    const getDataLazy = async (args: IPostBody | IPostBodyPagination = {}) => {
        const {variables, pagination} = {...args};
        setLoading(true);
        const data = await request(
            pagination ? {pagination, ...variables} : variables,
        );

        if (data && data.error) {
            setError(data.error);
        } else {
            setResponse(data);
            pagination && setPage(pagination.page);
        }
        setLoading(false);
    };
    const loadMore = async () => {
        setPaginationLoading(true);
        const data = await request({
            pagination: {page: page + 1, pageSize: pagination ? pagination.pageSize : 10},
            ...variables,
        });
        if (data && data.error) {
            setError(data.error);
        } else {
            setPage(page + 1);
            setResponse((prevState: IListResponse): IListResponse | null => {
                if (prevState) {
                    return prevState && prevState.items ? {items: prevState.items.concat(data.items)} : {items: data.items}
                } else return null;
            });
        }
        setPaginationLoading(false);
    };
    return [
        getDataLazy,
        {
            pagination: pagination
                ? {
                    posible: response &&
                        response.items &&
                        response.totalCount &&
                        response.totalCount > response.items.length,
                    loadMore,
                    paginationLoading,
                }
                : {posible: false, loadMore: () => console.log('Pagination is not define')},
            loading,
            page,
            data: response,
            error: error,
        }
    ];
};

export const useLazyRequest = (request: (...args: any) => any,
                                  args: IPostBody): any[] => {
    const {variables} = {...args};
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [response, setResponse] = useState<IObjectResponse | IListResponse | null>(null);

    const getData = async () => {
        setError(null)
        setResponse(null)
        setLoading(true);
        const data = await request(
            variables,
        );
        if (data && data.error) {
            setError(data);
        } else {
            setResponse(data);
        }
        setLoading(false);
    };
    return [
        getData,
        {
            loading,
            data: response,
            error: error
        }
    ];
};

// export const useFileRequest = (request: (...args : any) => any) => {
//   const getData = (base64Source, fileName, variables) => {
//     return request({
//       ...variables,
//       formData: createFormData(base64Source, fileURI),
//     }).then((data) => {
//       if (data && data.error) {
//         return data.error;
//       } else {
//         return data;
//       }
//     });
//   };
//
//   return [getData];
// };
