import React, {useContext, useRef} from 'react'
import badge from "../../assets/img/check_circle_outline_24px.png"
import defaultChuspAvatat from "../../assets/img/chusp-avatar.png"
import {useClickAway} from "react-use";
import {Avatar} from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Btn from "../../components/buttons/MainButton/Button";
import {THEME} from "../../ui/theme";
import {RootStateOrAny, useSelector} from "react-redux";
import {useAuth} from "../../service/authentication/auth";
import {useHistory} from 'react-router'
import {ROUTES} from "../../enum/routes";
import {SocketsContext} from "../../pages/App";
import {IUser} from "../../interfaces/user";
import LEFT_SIDEBAR_DATA from "../../enum/left-sidebar";

type Props = {
    open: boolean,
    setOpenLeftBar: React.Dispatch<React.SetStateAction<boolean>>
}
const LeftSideBar: React.FC<Props> = ({open, setOpenLeftBar}) => {
    const history = useHistory()
    const {socket} = useContext(SocketsContext)
    const myData: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const {logout} = useAuth()
    // const firstName = myData?.first_name ? myData?.first_name : ""
    // const lastName = myData?.last_name ? myData?.last_name : ""
    const userAvatar = myData && myData?.public_avatar_url
    const ref = useRef<HTMLDivElement>(null)
    useClickAway(ref, () => setOpenLeftBar && setOpenLeftBar(false));

    const logoutFromSystem = async () => {
        socket && socket.close()
        history.push(ROUTES.LOGIN);
        localStorage.clear()
        sessionStorage.clear()
        await logout()
    }

    const openPage = (route: string, search?: string) => {
        switch (route) {
            case ROUTES.MY_PAGE.VERIFICATION:
                if (!myData?.id_verified) {
                    setOpenLeftBar(false)
                    history.push({
                        pathname: route,
                        search
                    })
                }
                break;
            default:
                setOpenLeftBar(false)
                history.push({
                    pathname: route,
                    search
                })
        }
    }

    const disableButtonIfStateIsFalse = (route: string): boolean => {
        switch (route) {
            case ROUTES.MY_PAGE.VERIFICATION:
                return !myData?.id_verified
            default:
                return true
        }
    }

    return (
        <div className={`left-sidebar-container ${open ? "left-sidebar-opened" : ""}`}>
            <div ref={ref} className={`left-sidebar-menu ${open ? "sidebar-menu-opened" : ""}`}>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div className={"sidebar-picture"} onClick={() => openPage(ROUTES.MY_PAGE.PROFILE)}>
                        <Avatar
                            src={userAvatar ? userAvatar : defaultChuspAvatat}
                            alt={"Chusp avatar"}
                            className={"sidebar-avatar"}
                        />
                        {myData?.id_verified && <div className={"verification-badge"}>
                            <img src={badge} alt={"Chusp verification badge"}/>
                        </div>}
                    </div>
                </div>
                {LEFT_SIDEBAR_DATA.map((data) => (
                    <div className={"sidebar-section"}>
                        <h3>{data.section}</h3>
                        {data.menuItems.map((item) => (
                            <Btn
                                className={"menu-item"}
                                clr={disableButtonIfStateIsFalse(item.route) ? THEME.colors.white : THEME.colors.menugrey}
                                onClick={() => openPage(item.route, item.search)}
                                content={
                                    <>
                                        {item.icon}
                                        <span>{item.label}</span>
                                    </>
                                }
                            />
                        ))}
                    </div>
                ))}
                <div className={"sidebar-section"}>
                    <Btn
                        className={"logout-btn"}
                        clr={THEME.colors.white}
                        onClick={logoutFromSystem}
                        content={
                            <>
                                <ExitToAppIcon/>
                                Log out
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default LeftSideBar;
