import React, {useEffect, useState} from 'react'
import {Avatar} from "@material-ui/core";
import SkeletonLoader from "../../../assets/img/chusp-skeleton.jpg"
import {deleteFromBlacklist, getBlockedUser} from "../../../api/blacklist";
import {REQUEST_TYPES} from "../../../enum/request";
import {useWindowSize} from "../../../service/common/window";
import {IUser} from "../../../interfaces/user";
import {THEME} from "../../../ui/theme";
import Btn from "../../../components/buttons/MainButton/Button";
import ChuspSpinner from "../../../components/loader/ChuspSpinner";
import { useHistory } from 'react-router-dom';

const BlockedUserContainer: React.FC = () => {
    const history: any = useHistory()
    const {isTable, isDesktop} = useWindowSize()
    const [avatarLoaded, setAvatarLoaded] = useState<boolean>(false)
    const [onDeleteLoading, setOnDeleteLoading] = useState<boolean>(false)
    const [listOfBlokedUser, setListOfBlokedUser] = useState<any>()
    const [blackListLoading, setBlackListLoading] = useState<boolean>(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        (async () => {
            setBlackListLoading(true)
            const response = await getBlockedUser({type: REQUEST_TYPES.BLOCKED, pagination: {page: 0, pageSize: 10}})
            if (response) {
                setBlackListLoading(false)
                setListOfBlokedUser(response?.items)
            }
        })()
    }, [onDeleteLoading])

    useEffect(() => {
        if (listOfBlokedUser?.length < 1) {
            history.goBack()
        }
        // eslint-disable-next-line
    }, [listOfBlokedUser])

    const removeUserFromBlacklist = async (id: number) => {
        setOnDeleteLoading(true)
        const response = await deleteFromBlacklist(id)
        if (response) {
            setOnDeleteLoading(false)
        }
    }

    return (
        <>
            {blackListLoading || onDeleteLoading ? <ChuspSpinner isGlobal={false}/> : (
                <>
                    {isDesktop && <div className={"blacklist-title"}>Blocked User</div>}
                    {listOfBlokedUser?.map((user: IUser) => {
                        return (
                            <div className={"blacklist-container"}>
                                <div className={"blacklist-user-picture"}>
                                    <div>
                                        <Avatar
                                            onLoad={() => setAvatarLoaded(true)}
                                            src={avatarLoaded ? user.public_avatar_url : SkeletonLoader}
                                            style={isTable ? {width: "40px", height: "40px"} : {
                                                width: "70px",
                                                height: "70px"
                                            }}
                                        />
                                        <div className={"blacklist-info-container"}>
                                            <h2>
                                                {user?.first_name} {user?.last_name}
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                {
                                    <Btn
                                        className={"remove-btn"}
                                        background={THEME.colors.secondaryblue}
                                        clr={THEME.colors.white}
                                        onClick={() => removeUserFromBlacklist(user?.id)}
                                        content={'Remove'}
                                    />
                                }
                            </div>
                        )
                    })}
                </>
            )}
        </>
    )
}


export default BlockedUserContainer





