import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {
    SEARCH_USERS
} from '../types'
import { IUser } from '../../interfaces/user';

interface SearchMemory {
    memory: {
        id: string | null,
        page: number,
        usersInfo: IUser[];
    },

}

const defaultSlice = createSlice({
    name: SEARCH_USERS,
    initialState: {
        memory: {
            id: null,
            page: 0,
            usersInfo: [],
        }
    },
    reducers: {
        saveToMemory(state: SearchMemory, action) {
            state.memory = action.payload;
        },
        setUsersInfo(state: SearchMemory, action: PayloadAction<IUser[]>) {
            const uniqueUserIds = Array.from(new Set(action.payload.map(user => user.id)));
            const uniqueUsers = uniqueUserIds.map(id => action.payload.find(user => user.id === id) as IUser);
            state.memory.usersInfo = uniqueUsers;
        },
        addUsersInfo(state: SearchMemory, action: PayloadAction<IUser[]>) {
            const combinedUsers = [...state.memory.usersInfo, ...action.payload];
            const uniqueUserIds = Array.from(new Set(combinedUsers.map(user => user.id)));
            const uniqueUsers = uniqueUserIds.map(id => combinedUsers.find(user => user.id === id) as IUser);
            state.memory.usersInfo = uniqueUsers;
        },
          setCurrentPageData(state: SearchMemory, action) {
            state.memory.page = action.payload;
        },
        updateUserUrl(state: SearchMemory, action: PayloadAction<{ userId: number, newUrl: string }>) {
            const { userId, newUrl } = action.payload;
            console.log('inside updateUserUrl',userId, newUrl)
            const user = state.memory.usersInfo.find(user => user.id === userId);
            if (user) {
              user.public_avatar_url = newUrl;
            }
          },
    },
});

export const {
    saveToMemory,
    setUsersInfo, addUsersInfo, setCurrentPageData,updateUserUrl
} = defaultSlice.actions;
export default defaultSlice.reducer;
