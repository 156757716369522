import {IDevicesType} from '../../interfaces'

export const devices: IDevicesType = {
    mobileS: 320,
    mobile: 481,
    tablet: 768,
    laptop: 1025,
    pc: 1500,
}

