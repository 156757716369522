/* eslint-disable */
import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import Header from "../../../components/header";
import {Switch, Route, useParams} from 'react-router-dom'
import {ROUTES} from "../../../enum/routes";
import {useWindowSize} from "../../../service/common/window";
import SettingsIcon from '@material-ui/icons/Settings';
import Footer from '../../../components/footer'
import LeftSideBar from "../../../containers/LeftSideBar";
import {Redirect} from "react-router";
import {useLocation, useHistory} from "react-router";
import {PROFILE_MODES} from "../../../enum/profile-mods";
import Profile from "../../../containers/Profile";
import InfoBar from "../../../components/infobar";
import BackButton from "../../../components/buttons/back-button";
// import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
// import {IconButton} from "@material-ui/core";
import Modal from "../../../components/modal/main-modal/modal";
import HostingModal from "./StartHosting/Modal";
import {VIEWS} from "../../../config/calendar/views";
import BigCalendarHeader from "../../../containers/Calendar/Header";
import moment from 'moment-timezone'
import ShareIconsMobile from "../../../containers/Profile/Modals/ShareSocialsMobile";
import Avatar from "@material-ui/core/Avatar";
import {BookingUser} from "../index";
import {
    getTimeZoneLabelByValue,
    getTimezoneValueByLabel
} from "../../../utils/getTimeZoneLabels";
import {ICalendarViews} from "../../../interfaces/calendar";
import ChuspLoader from "../../../components/loader/ChuspLoader";
import {useSearchParam} from "react-use";
import EnterCardDetails from "./PaymentCards/AvailableCards/EnterCCDetails";
import AddBillingAddress from "./PaymentCards/AvailableCards/AddBillingAddress";
import {SelectBillingAddress} from "./PaymentCards/AvailableCards/SelectBillilngAddress";
import { getPaidContentListById } from '../../../api/paid-content';
import { IUser } from '../../../interfaces/user';
import { Base64 } from 'js-base64';
import { getUserById } from '../../../api/user';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { getVatRate } from '../../../api/vat';
import { setVatRate } from '../../../redux/reducers/vat';

const Feedback = React.lazy(() => import("./Feedback"));
const BuyCredits = React.lazy(() => import("./BuyCredits"));
const CreatePost = React.lazy(() => import("./CreatePost"));
const HelpCenter = React.lazy(() => import("./HelpCenter"));
const UserBooking = React.lazy(() => import("./UserBooking"));
const MeetingMode = React.lazy(() => import("./MeetingMode"));
const SystemMeetingMode = React.lazy(() => import("./SystemMeetingMode"));
const ReferAndEarn = React.lazy(() => import("./ReferAndEarn"));
const StartHosting = React.lazy(() => import("./StartHosting"));
const Verification = React.lazy(() => import("./Verification"));
const PaymentCards = React.lazy(() => import("./PaymentCards"));
const HostingRates = React.lazy(() => import("./HostingRates"));
const CreditAccount = React.lazy(() => import("./CreditAccount"));
const ComplaintForm = React.lazy(() => import("./ComplaintForm"));
const PayoutHistory = React.lazy(() => import("./PayoutHistory"));
const PayoutSettings = React.lazy(() => import("./PayoutSettings"));
const LoginAndSecurity = React.lazy(() => import("./LoginSecurity"));
const EarningsAccount = React.lazy(() => import("./EarningsAccount"));
const NotificationsPage = React.lazy(() => import("./Notifications"));
const ComplaintRespond = React.lazy(() => import("./ComplaintRespond"));
const PaidContentPage = React.lazy(() => import("./PaidContent"));
const MyContentPage = React.lazy(() => import("./MyContent"));
const VideoPlaylistPage = React.lazy(() => import("./VideoPlaylist"));
const CreatePaidContentPage = React.lazy(() => import("./CreatePaidContent"));
const BuyContentPage = React.lazy(() => import("./BuyContent"));
const Edit = React.lazy(() => import("../../../containers/Profile/Edit"));
const Calendar = React.lazy(() => import("../../../containers/Calendar"));

export const PrivateContainerStateContext = createContext<any>(null)

const PrivateContainer = () => {
    const profileContainer = useSearchParam("container")
    const user: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const {bookingUser} = useContext(BookingUser)
    const bookingUserFromSessionStorage = JSON.parse(window.sessionStorage.getItem('bookingUserData')!)
    const [loading, setLoading] = useState<boolean>(false)
    const {isTable, isDesktop} = useWindowSize()
    const history: any = useHistory()
    const {pathname, state} : any = useLocation()
    const [openLeftBar, setOpenLeftBar] = useState<boolean>(false)
    // CALENDAR
    const [currentMonth, setCurrentMonth] = useState('')
    const [currentYear, setCurrentYear] = useState('')
    const [view, setView] = useState<ICalendarViews>(VIEWS.SEVEN_DAYS)
    const [customViewMode, setCustomViewMode] = useState<boolean>(false)
    const [viewStartDay, setViewStartDay] = useState<Date | string>('')
    const [viewEndDay, setViewEndDay] = useState<Date | string>('')
    const defaultTimezone = moment.tz.guess()
    const [currentTimeZone, setCurrentTimeZone] = useState<string>(defaultTimezone)
    const [monthDaySelected, setMonthDaySelected] = useState<boolean>(false)
    const [timezoneLabel, setTimezoneLabel] = useState<string>('')
    const param : any = useParams()
    const [storeName, setStoreName] = useState<string>('Chusp Store')
    const paidContentOwnerId = parseInt(Base64.decode(`${localStorage.getItem('paidContentOwnerId' ?? '')}`))

    const updateStoreName = (user: IUser) =>{
        const storeOwnerName : string = `${user ? `${user?.first_name ?? ''} ${user?.last_name ?? ''}` : ''}`.trim()
        setStoreName(`${storeOwnerName}'s Chusp Store`)
    }

    const buyContentStoreName = async () =>{
        let paidContentId = param?.id ?? pathname.split('/')[4]
        const decodedPaidContentId = paidContentId && Base64.decode(`${paidContentId}`)
        if(decodedPaidContentId === '0') return;
        const {data} =  await getPaidContentListById(decodedPaidContentId)
        data && data?.user && updateStoreName(data.user)
    }

    useEffect(() =>{user && localStorage.setItem('accountOwnerId', Base64.encode(`${user.id}`))},[])

    useEffect(() =>{
        (async () =>{
            if(history.location.pathname === ROUTES.MY_PAGE.PAID_CONTENT){
                let buyMode = history.location.state?.buyMode ?? (localStorage.getItem('buyMode') === 'true')
                if(buyMode){
                    let userData: IUser = history?.location?.state?.user ?? await getUserById(paidContentOwnerId,{asset_size: ''}).then((res:any) => res.data)
                    userData && updateStoreName(userData) 
                }else{
                    let accountOwnerId = +Base64.decode(localStorage.getItem('accountOwnerId') ?? '')
                    let userData: IUser = user ?? (accountOwnerId && await getUserById(accountOwnerId,{asset_size: ''}).then((res: any) => res.data))
                    userData && updateStoreName(userData)
                }
            }
        })()
    },[pathname])

        
    const dispatch = useDispatch();
    const vatRate = useSelector((state: RootStateOrAny) => state?.vat?.rate)

    const resetVatRate = async () =>{
        const vatRate = await getVatRate()
        vatRate && dispatch(setVatRate(vatRate))
    }

    useEffect(()=>{
        if(!vatRate){
            resetVatRate()
        }
    },[])

    useEffect(() => isDesktop ? setView(VIEWS.SEVEN_DAYS) : setView(VIEWS.THREE_DAYS), [isDesktop])

    useEffect(() => {
        bookingUser && window.sessionStorage.setItem("bookingUserData", JSON.stringify(bookingUser));
    }, [bookingUser])

    useEffect(() => {
        const currentTimeZoneLabel = getTimeZoneLabelByValue(currentTimeZone)
        setTimezoneLabel(currentTimeZoneLabel)
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        const timezoneByLabel = getTimezoneValueByLabel(timezoneLabel)
        setCurrentTimeZone(timezoneByLabel)
    }, [timezoneLabel])

    // PAGES
    const isMyProfilePage = pathname === ROUTES.MY_PAGE.PROFILE
    const isCalendarPage = pathname === ROUTES.MY_PAGE.SCHEDULER
    const isMeetingMode = pathname.includes(ROUTES.MY_PAGE.MEETING_MODE)
    const isSystemMeetingMode = pathname.includes(ROUTES.MY_PAGE.SYSTEM_MEETING_MODE)

    // FILTER STATE
    const [meetingsCheckbox, setMeetingsCheckbox] = useState<boolean>(true)
    const [availableCheckbox, setAvailableCheckbox] = useState<boolean>(true)

    const [hostingModal, setHostingModal] = useState<boolean>(false)

    const renderInfoBar = () => {
        const rightPathname = pathname.slice(-1) === "/" ? pathname.slice(0, -1) : pathname
        const paidContentId = rightPathname.split('/')[4]
        const buyPaidContentPath = ROUTES.MY_PAGE.BUY_PAID_CONTENT.split('/').map((s: string,i: number) => i === 4 ? paidContentId : s ).join('/')
        switch (rightPathname) {
            case ROUTES.MY_PAGE.CREATE_POST:
                return (
                    <InfoBar
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        children={<span>Create post</span>}
                    />
                )
            case ROUTES.MY_PAGE.EDIT_POST:
                return (
                    <InfoBar
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        children={<span>Edit post</span>}
                    />
                )
            case ROUTES.MY_PAGE.REFER_EARN:
                return (
                    <InfoBar
                        children={<span>Refer</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.HOSTING:
                return (
                    <>
                        <InfoBar
                            children={<span>Create Host Profile</span>}
                        />
                    </>
                )
            case ROUTES.MY_PAGE.EDIT:
                return (
                    <InfoBar
                        children={<span>{isDesktop ? 'Edit Profile' : 'Edit My Profile'}</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.VERIFICATION:
                return (
                    <InfoBar
                        children={<span>{isDesktop ? 'Host Account Verification' : 'Host Profile Verification '}</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.CARDS:
                return (
                    <InfoBar
                        children={<span>Payment cards</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.SECURITY:
                return (
                    <InfoBar
                        children={<span>Login and Security</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.PAYOUT_SETTINGS:
                return (
                    <InfoBar
                        children={<span>Payout Settings</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.EARNINGS_ACCOUNT:
                return (
                    <InfoBar
                        children={<span>Your Earnings Account</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.CREDIT_ACCOUNT:
                return (
                    <InfoBar
                        children={<span>Your Credit Account</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.ADD_CREDITS:
                return (
                    <InfoBar
                        children={<span>Buy Credits</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.HELP_CENTER:
                return (
                    <InfoBar
                        children={<span>Help Center</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.FAQ:
                return (
                    <InfoBar
                        children={<span>FAQ</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                    />
                )
            case ROUTES.FEEDBACK:
                return (
                    <InfoBar
                        children={<span>Feedback</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.COMPLAINT_FORM:
                return (
                    <InfoBar
                        children={<span>Complaint Form</span>}
                    />
                )
            case ROUTES.MY_PAGE.COMPLAINT_RESPOND:
                return (
                    <InfoBar
                        children={<span>Complaint response</span>}
                    />
                )
            case ROUTES.MY_PAGE.RATES:
                return (
                    <InfoBar
                        children={<span>Hosting Rates</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.NOTIFICATIONS:
                return (
                    <InfoBar
                        children={<span>Notifications</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.USER_BOOKING:
                return (
                    <InfoBar
                        children={(
                            <div className='private-container-booking-header'>
                                <Avatar className='private-container-user-avatar' src={bookingUserFromSessionStorage?.public_avatar_url}>
                                    {!bookingUserFromSessionStorage?.public_avatar_url && bookingUserFromSessionStorage?.first_name[0]}
                                </Avatar>
                                <span>Your meeting with {bookingUserFromSessionStorage?.first_name} {bookingUserFromSessionStorage?.last_name}</span>
                            </div>
                        )}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.PAYOUT_HISTORY:
                return (
                    <InfoBar
                        children={<span>Your payout History</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.PAID_CONTENT:
                return (
                    <InfoBar
                        children={<span>{storeName}</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.MY_CONTENT:
                return (
                    <InfoBar
                        children={<span>My Chusp Store</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.CREATE_PAID_CONTENT:
                let handleGoBackCreatePaidContent = () =>{
                    state && state?.isAutomaticRedirection ? history.go(-2) : history.goBack()
                }
                return (
                    <InfoBar
                        children={<span>Create Paid Content</span>}
                        leftElement={<BackButton goBack={handleGoBackCreatePaidContent}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case ROUTES.MY_PAGE.EDIT_PAID_CONTENT:
                return (
                    <InfoBar
                        children={<span>Edit Paid Content</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                        // rightElement={
                        //     <IconButton onClick={() => history.push(ROUTES.FAQ)}>
                        //         <HelpOutlineOutlinedIcon/>
                        //     </IconButton>
                        // }
                    />
                )
            case buyPaidContentPath:
                buyContentStoreName()
                return (
                    <InfoBar
                        children={<span>{storeName}</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                    />
                )
            case ROUTES.MY_PAGE.ENTER_CARD_DETAILS:
                const handleGoBackCardDetails = () =>{
                    state && state?.isAutomaticRedirection ? history.go(-2) : history.goBack()
                }
                return (
                    <InfoBar
                        children={<span>Enter Card Details</span>}
                        leftElement={<BackButton goBack={handleGoBackCardDetails}/>}
                    />
                )
            case ROUTES.MY_PAGE.ADD_BILLING_ADDRESS:
                return (
                    <InfoBar
                        children={<span>Add Billing Address</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                    />
                )
            case ROUTES.MY_PAGE.SELECT_BILLING_ADDRESS:
                return (
                    <InfoBar
                        children={<span>Billing Address</span>}
                        leftElement={<BackButton goBack={() => history.goBack()}/>}
                    />
                )
        }
    }

    const triggerLoader = useCallback((boolean: boolean) => setLoading(boolean), [setLoading])
    const selectCurrentMonth = useCallback((month: string) => setCurrentMonth(month), [setCurrentMonth])
    const selectCurrentYear = useCallback((year: string) => setCurrentYear(year), [setCurrentYear])
    const changeCalendarView = useCallback((view: string) => setView(view), [setView])
    const selectIsMonthDaySelected = useCallback((boolean: boolean) => setMonthDaySelected(boolean), [setMonthDaySelected])
    const selectCustomDayGridView = useCallback((boolean: boolean) => setCustomViewMode(boolean), [setCustomViewMode])
    const selectCurrentTimezone = useCallback((timezone: string) => setCurrentTimeZone(timezone), [setCurrentTimeZone])
    const selectTimeZoneLabel = useCallback((label: string) => setTimezoneLabel(label), [setTimezoneLabel])
    const selectActiveViewStartDate = useCallback((viewStartDate: string) => setViewStartDay(viewStartDate), [setViewStartDay])
    const selectActiveViewEndDate = useCallback((viewEndDate: string) => setViewEndDay(viewEndDate), [setViewEndDay])
    const showBookedMeetings = useCallback((boolean: boolean) => setMeetingsCheckbox(boolean), [setMeetingsCheckbox])
    const showAvailableDates = useCallback((boolean: boolean) => setAvailableCheckbox(boolean), [setAvailableCheckbox])


    const contextObject = {
        loading: useMemo(() => loading, [loading]),
        triggerLoader,
        currentMonth: useMemo(() => currentMonth, [currentMonth]),
        selectCurrentMonth,
        currentYear: useMemo(() => currentYear, [currentYear]),
        selectCurrentYear,
        view: useMemo(() => view, [view]),
        customViewMode: useMemo(() => customViewMode, [customViewMode]),
        selectCustomDayGridView,
        changeCalendarView,
        currentTimeZone: useMemo(() => currentTimeZone, [currentTimeZone]),
        selectCurrentTimezone,
        monthDaySelected: useMemo(() => monthDaySelected, [monthDaySelected]),
        selectIsMonthDaySelected,
        viewStartDay: useMemo(() => viewStartDay, [viewStartDay]),
        selectActiveViewStartDate,
        viewEndDay: useMemo(() => viewEndDay, [viewEndDay]),
        selectActiveViewEndDate,
        meetingsCheckbox: useMemo(() => meetingsCheckbox, [meetingsCheckbox]),
        showBookedMeetings,
        availableCheckbox: useMemo(() => availableCheckbox, [availableCheckbox]),
        showAvailableDates,
        timezoneLabel: useMemo(() => timezoneLabel, [timezoneLabel]),
        selectTimeZoneLabel
    }

    const bigCalendarProps = {
        changeCalendarView,
        selectIsMonthDaySelected,
        currentMonth,
        selectCustomDayGridView,
        availableCheckbox,
        showAvailableDates,
        meetingsCheckbox,
        showBookedMeetings,
        timezoneLabel,
        currentYear
    }

    const isFollowersAdmirersProfileContainer = profileContainer === "admirors" || profileContainer === "followers"

    return (
        <PrivateContainerStateContext.Provider value={contextObject}>
            <div className='private-container'>
                <div className='private-container-sticky'>
                    <Header
                        setOpenLeftBar={setOpenLeftBar}
                        leftIcon={(!isMeetingMode || !isSystemMeetingMode) ? <SettingsIcon/> : null}
                        rightIcon={isMyProfilePage && !isFollowersAdmirersProfileContainer ? <ShareIconsMobile/> : null}
                    />
                    {renderInfoBar()}
                    {isCalendarPage && <BigCalendarHeader {...bigCalendarProps}/>}
                </div>
                {openLeftBar && <LeftSideBar open={openLeftBar} setOpenLeftBar={setOpenLeftBar}/>}
                <div className='private-container-content'>
                    <div className='private-container-wrapper' style={{minHeight: isMyProfilePage ? '90vh' : '82vh'}}>
                        <React.Suspense fallback={<ChuspLoader/>}>
                            <Switch>
                                <Route path={ROUTES.MY_PAGE.PROFILE}
                                       render={() => <Profile mode={PROFILE_MODES.EDIT}/>} exact/>
                                <Route path={ROUTES.MY_PAGE.CREATE_POST} component={CreatePost} exact/>
                                <Route path={ROUTES.MY_PAGE.EDIT_POST} component={CreatePost} exact/>
                                <Route path={`${ROUTES.MY_PAGE.MEETING_MODE}/:roomID`} component={MeetingMode} exact/>
                                <Route path={`${ROUTES.MY_PAGE.SYSTEM_MEETING_MODE}/:roomID`} component={SystemMeetingMode} exact/>
                                <Route path={ROUTES.MY_PAGE.REFER_EARN} component={ReferAndEarn} exact/>
                                <Route path={ROUTES.MY_PAGE.HOSTING} component={StartHosting} exact/>
                                <Route path={ROUTES.MY_PAGE.VERIFICATION} component={Verification} exact/>
                                <Route path={ROUTES.MY_PAGE.CARDS} component={PaymentCards} exact/>
                                <Route path={ROUTES.MY_PAGE.ENTER_CARD_DETAILS} component={EnterCardDetails} exact/>
                                <Route path={ROUTES.MY_PAGE.ADD_BILLING_ADDRESS} component={AddBillingAddress} exact/>
                                <Route path={ROUTES.MY_PAGE.SELECT_BILLING_ADDRESS} component={SelectBillingAddress} exact/>
                                <Route path={ROUTES.MY_PAGE.EDIT} component={Edit} exact/>
                                <Route path={ROUTES.MY_PAGE.SECURITY} component={LoginAndSecurity} exact/>
                                <Route path={ROUTES.MY_PAGE.SCHEDULER}   render={() => <Calendar currentMonth={currentMonth} currentYear={currentYear} />}  exact/>
                                <Route path={ROUTES.MY_PAGE.COMPLAINT_RESPOND} component={ComplaintRespond} exact/>
                                <Route path={ROUTES.MY_PAGE.COMPLAINT_FORM} component={ComplaintForm} exact/>
                                <Route path={ROUTES.MY_PAGE.PAYOUT_SETTINGS} component={PayoutSettings} exact/>
                                <Route path={ROUTES.MY_PAGE.ADD_CREDITS} component={BuyCredits} exact/>
                                <Route path={ROUTES.MY_PAGE.EARNINGS_ACCOUNT} component={EarningsAccount} exact/>
                                <Route path={ROUTES.MY_PAGE.CREDIT_ACCOUNT} component={CreditAccount} exact/>
                                <Route path={ROUTES.MY_PAGE.RATES} component={HostingRates} exact/>
                                <Route path={ROUTES.MY_PAGE.NOTIFICATIONS} component={NotificationsPage} exact/>
                                <Route path={ROUTES.USER_BOOKING} component={UserBooking} exact/>
                                <Route path={ROUTES.MY_PAGE.PAYOUT_HISTORY} component={PayoutHistory} exact/>
                                <Route path={ROUTES.HELP_CENTER} component={HelpCenter} exact/>
                                <Route path={ROUTES.FEEDBACK} component={Feedback} exact/>
                                <Route path={ROUTES.MY_PAGE.PAID_CONTENT} component={PaidContentPage} exact/>
                                <Route path={ROUTES.MY_PAGE.MY_CONTENT} component={MyContentPage} exact/>
                                <Route path={ROUTES.MY_PAGE.VIDEO_PLAYLIST} component={VideoPlaylistPage} exact/>
                                <Route path={ROUTES.MY_PAGE.CREATE_PAID_CONTENT} component={CreatePaidContentPage} exact/>
                                <Route path={ROUTES.MY_PAGE.EDIT_PAID_CONTENT} component={CreatePaidContentPage} exact/>
                                <Route path={ROUTES.MY_PAGE.BUY_PAID_CONTENT} component={BuyContentPage} exact={true}/>
                                <Redirect to={ROUTES.OOPS}/>
                            </Switch>
                        </React.Suspense>
                    </div>
                    {hostingModal && <Modal
                        open={hostingModal}
                        setOpen={setHostingModal}
                        leftButton={'Yes, stop'}
                        rightButton={'No, continue'}
                        children={<HostingModal/>}
                        onCancel={() => history.push(ROUTES.MY_PAGE.PROFILE)}
                    />}
                </div>
                {(!isMeetingMode || !isSystemMeetingMode) && isTable && <Footer/>}
            </div>
        </PrivateContainerStateContext.Provider>
    )
};

export default PrivateContainer;
