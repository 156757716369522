import React, {useEffect} from 'react'
import Btn from '../../../components/buttons/MainButton/Button'
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import WorkIcon from '@material-ui/icons/Work';
import SchoolIcon from '@material-ui/icons/School';
import {useWindowSize} from "../../../service/common/window";
import {getEducationInfoString, getWorkplaceInfoString} from "../../../utils/formatters";
import {IUser} from "../../../interfaces/user";
import { useUserBio } from '../../../service/user/user';
import { useHistory } from 'react-router-dom';

interface IProps {
    user: IUser | undefined,
}

const MoreInfo: React.FC<IProps> = ({user}) => {
    const history: any = useHistory()
    const {isDesktop} = useWindowSize()
    const {languageInfo, locationInfo, workInfo, educationInfo, websiteInfo} = useUserBio(user)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const goBack = () => {
        history.goBack()
    }

    const websiteHrefLink = (website: string) => {
        if (!website.includes("https" || "http")) {
            return `https://${website}`
        } else {
            return website
        }
    }

    return (
        <div className={"more-info-container"}>
            {locationInfo && (
                <div className={"more-info"}>
                    <LocationOnOutlinedIcon/>
                    <p>Lives in {locationInfo?.replace(/"/g, '')}</p>
                </div>
            )}
            {languageInfo && languageInfo?.items?.length > 0 && (
                <div className={"more-info"}>
                    <LanguageOutlinedIcon/>
                    <p>Knows {languageInfo && languageInfo?.items?.map((item,index) => (index ? ', ' : '') + item )} </p>
                </div>
            )}
            {!!websiteInfo?.items?.length && websiteInfo?.items?.map((item: string, index) => (
                <a href={websiteHrefLink(item)} className={"more-info"} key={index}>
                    <LinkOutlinedIcon/>
                    <p>{item}</p>
                </a>
            ))}
            {!!workInfo?.length && !!workInfo && (
                <div className={"title"}>
                    <span>Work</span>
                </div>
            )}
            {!!workInfo?.length && workInfo?.map((work: any, index: number) => (
                <div className={"more-info"} key={index}>
                    <WorkIcon/>
                    <p>{getWorkplaceInfoString(work)}</p>
                </div>
            ))}
            {!!educationInfo?.length && !!educationInfo && (
                <div className={"title"}>
                    <span>Education</span>
                </div>
            )}
            {!!educationInfo?.length && educationInfo?.map((item, index) => (
                <div className={"more-info"} key={index}>
                    <SchoolIcon/>
                    <p>{getEducationInfoString(item)}</p>
                </div>
            ))}
            {isDesktop && (
                <div className={"more-info-btns"}>
                    <Btn onClick={goBack} content={'Back'} />
                </div>
            )}
        </div>
    )
}

export default MoreInfo
