import React from 'react'
import {Button} from "@material-ui/core";
import {THEME} from "../../../ui/theme";

interface PropsType {
    clr?: string,
    background?: string,
    size?: string,
    border?: string,
    display?: boolean | undefined,
    disabled?: any,
    content: any,
    name?: any,
    type?: any,
    onClick?: any,
    style?: any,
    className?: any
}


const Btn: React.FC<PropsType> = ({...props}) => {
    return <Button
        className={`main-button ${props.className ? props.className : ''}`}
        style={{
            display: props.display ?  'none' : 'flex',
            color: props?.clr,
            backgroundColor: props.disabled ? THEME.colors.grey : props.background,
            ...props.style
        }}
        name={props.name}
        type={props.type}
        onClick={!props.disabled ? props.onClick : () => {}}
    >
        {props.content}
    </Button>
}

export default Btn;
