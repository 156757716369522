import React, {useRef} from 'react'
import {useClickAway} from "react-use";
import {THEME} from "../../../ui/theme";
import Btn from '../../buttons/MainButton/Button';

interface IProps {
    children?: JSX.Element | JSX.Element[] | null | string,
    rightButton?: JSX.Element | JSX.Element[] | null | string,
    rightButtonOptions?: JSX.Element | JSX.Element[] | null | string,
    leftButton?: JSX.Element | JSX.Element[] | null | string,
    title?: JSX.Element | JSX.Element[] | null | string,
    subtitle?: JSX.Element | JSX.Element[] | null | string,
    modalName?:string | null,
    defaultValue?: string | string[],
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onComplete?: any,
    onCancel?: any,
    disabled?: boolean | undefined,
    refToIgnore?: any,
    titleAlign?: 'left' | 'center' | 'right',
    width?: number | string,
    minWidth?: number | string,
    maxHeight?: number | string,
    overflowY?: 'visible' | 'hidden' | 'scroll' | 'auto' | 'inherit' | 'initial';
    backgroundColor?: string,
    color?: string,
    disableClose?: boolean,
    scroll?:boolean,
    disableCloseOnComplete?: boolean
    zIndex?: number,
    footerOnTop?: boolean
    customClass?: string
    footerCustomClassName?: string
}

const Modal: React.FC<IProps> = (
    {
        children,
        rightButton,
        rightButtonOptions,
        leftButton,
        title,
        subtitle,
        modalName,
        open,
        setOpen,
        onComplete,
        onCancel,
        disabled,
        refToIgnore,
        color,
        backgroundColor,
        width,
        minWidth,
        maxHeight,
        overflowY,
        disableClose,
        scroll,
        disableCloseOnComplete,
        zIndex,
        footerOnTop = false,
        customClass = '',
        footerCustomClassName
    }) => {
    const ref = useRef<HTMLDivElement>(null)

    const modalScroll = scroll || false;

    const closeModal = () => {
        setOpen(false)
        onCancel && onCancel()
    }

    const confirmModal = () => {
        onComplete && onComplete()
        !disableCloseOnComplete && setOpen(false)
    }

    useClickAway(ref, () => {
        !refToIgnore && !disableClose && setOpen(false)
    });
    
    return (
        <div className={`main-modal-container ${open && 'main-modal-container--open'} ${modalScroll ? 'modal-scroll' : ''} ${customClass}`}
        style={{zIndex: zIndex || 10000 }}
        >
            <div className='modal-wrapper'>
                <div
                    className='modal-content'
                    ref={ref}
                    style={{
                        width: width,
                        maxWidth: width,
                        minWidth: minWidth,
                        maxHeight:maxHeight,
                        overflowY:overflowY,
                        color: color,
                        backgroundColor: backgroundColor,
                    }}>
                    <div className='modal-title' style={{padding: title ? '20px 0' : '10px 0'}}>
                        <span className='title'>{title}</span>
                    </div>
                    <div
                        className='modal-subtitle'
                        style={{
                            paddingBottom: subtitle ? '20px' : '0',
                            paddingLeft: subtitle ? '20px' : '0'
                        }}>
                        {subtitle}
                    </div>
                    <div className={`modal-body ${modalName}`}>
                        {children}
                    </div>
                    <div className={`modal-footer ${footerOnTop ? 'modal-footer-on-top' : ''} ${footerCustomClassName}`}>
                        <div>
                            <div>
                                <Btn
                                    className='modal-button'
                                    clr={THEME.colors.menugrey}
                                    background={'transparent'}
                                    onClick={closeModal}
                                    content={leftButton}
                                    style={{visibility: leftButton ? "visible" : "hidden"}}
                                />
                            </div>
                            <div>
                                {rightButtonOptions && <Btn
                                    className='modal-button'
                                    clr={THEME.colors.menugrey}
                                    background={'transparent'}
                                    onClick={closeModal}
                                    content={rightButtonOptions}
                                />}
                                {rightButton && <Btn
                                    className='modal-button'
                                    style={{backgroundColor: disabled ? 'white' : ''}}
                                    clr={disabled ? THEME.colors.menugrey : THEME.colors.lightblue}
                                    background={'transparent'}
                                    onClick={!disabled ? confirmModal : 
                                        // () => {
                                            // alert('from modal component')}
                                        confirmModal
                                }
                                    // onClick={alert('from modal component')}
                                    content={rightButton}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal;
