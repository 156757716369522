import React from 'react'
import Button from "@material-ui/core/Button";
import {FieldArrayRenderProps} from "formik";

interface IMailOptions {
    id: number,
    option: string
}

type Props = {
    arrayHelpers: FieldArrayRenderProps,
    setFieldValue: any
}

const hardcodedMailOptions: IMailOptions[] = [
    {
        id: 1,
        option: '@gmail.com'
    },
    {
        id: 2,
        option: '@yahoo.com'
    },
    {
        id: 3,
        option: '@hotmail.com'
    }
]

const MailOptions: React.FC<Props> = ({arrayHelpers, setFieldValue}) => {
    return (
        <div className='mailpoints-container'>
            {hardcodedMailOptions.map((item) => (
                <div key={item.id}>
                    <Button
                        onClick={() => {
                            const exist = hardcodedMailOptions?.some((i) => arrayHelpers.form.values.email?.includes(i.option))
                            if (!exist) {
                                setFieldValue('email', arrayHelpers.form.values.email + item.option)
                            }
                        }}>{item.option}</Button>
                </div>
            ))}
        </div>
    )
}

export default MailOptions;
