import React, {useState} from "react"
import {getDateInCorrectTimeZoneByConvertingUtcDate} from "../../utils/getDateInCorrectTimeZoneByConvertingUtcDate";
import moment from "moment";

export const useRealTime = () => {
    const currentTimeZone = moment.tz.guess()
    const [currentDateWithTime, setCurrentDateWithTime] = useState<string>(getDateInCorrectTimeZoneByConvertingUtcDate(moment().format(), currentTimeZone))
    const [currentTimeInUnix, setCurrentTimeInUnix] = useState<number>(moment().unix())

    React.useEffect(() => {
        const timer = setInterval(() => {
            const currentTime = getDateInCorrectTimeZoneByConvertingUtcDate(moment().format(), currentTimeZone)
            setCurrentDateWithTime(currentTime);
            setCurrentTimeInUnix(moment().unix())
        }, 1000);
        return () => {
            clearInterval(timer);
        }
        // eslint-disable-next-line
    }, []);


    return {
        currentDateWithTime,
        currentTimeInUnix
    };
};