import {useEffect, useState} from "react";
import { getNotifications } from "../../api/notifications";
import { IGetNotifications } from "../../interfaces/notifications";


export const useGetNotifications = (body: IGetNotifications, reFetch: boolean = false, shouldFetch: boolean = true) => {
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            if (shouldFetch) {
                setLoading(true)
                const response = await getNotifications(body)
                if (response) {
                    setData(response)
                    setLoading(false)
                }
            }
        })()

        return () => setData(undefined)
        // eslint-disable-next-line
    }, [reFetch, shouldFetch])

    return {
        data,
        loading
    }
}