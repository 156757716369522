/* eslint-disable */
import React, { useState } from 'react'
import { MenuItem, TextField } from "@material-ui/core";
import Btn from "../../../../../../components/buttons/MainButton/Button";
import { THEME } from "../../../../../../ui/theme";
import { LIST_OF_COUNTRIES } from "../../../../../../enum/list-of-countries";
import { addBillingAddress, addBillingAddressForStripe } from "../../../../../../api/payment";
import { ROUTES } from "../../../../../../enum/routes";
import { useHistory } from "react-router";

const AddBillingAddress = () => {
    const history: any = useHistory()
    const [billingAddress, setBillingAddress] = useState<any>();
  

    const onChange = (e: any) => {
        if (e.target.name === 'postal') {
            setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value.slice(0, 10) })
        } else {
            setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value })
        }
    }
    const disableSave = !billingAddress?.address || !billingAddress?.postal || !billingAddress?.country


    const onSubmit = async () => {
        // console.log(history.location.state, 'history.location.state')
        const cardId = history.location.state?.cardId
        const customerId = history.location.state?.customerId
        const stripeCardId = history.location.state?.stripeCardId
        const data = {
            "customer_id": customerId,
            "card_id": stripeCardId,
            "address_line1": billingAddress.address,
            "postal_code": billingAddress.postal,
            "address_country": billingAddress.country
        }
        // const res = await addBillingAddressForStripe(data);
        const response = await addBillingAddress(billingAddress)
        if (response) history.push({ pathname: ROUTES.MY_PAGE.SELECT_BILLING_ADDRESS, state: { cardId } })
    }

    return (
        <div className={`add-billing-container`}>
            <div className={`content`}>
                <TextField
                    style={{ width: '100%', marginTop: '20px' }}
                    label="Street"
                    size={'medium'}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    name='address'
                    onChange={onChange}
                    inputProps={{ maxLength: 40 }}
                />
                <TextField
                    style={{ width: '100%', marginTop: '20px' }}
                    label="Address Line 2 (Optional) "
                    size={'medium'}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    name='address2'
                    onChange={onChange}
                    inputProps={{ maxLength: 40 }}
                />
                <TextField
                    style={{ width: '100%', marginTop: '20px' }}
                    label="Postal or Zip Code"
                    size={'medium'}
                    type={'number'}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    name='postal'
                    value={billingAddress?.postal}
                    onChange={onChange}
                />
                <TextField select variant={"outlined"} style={{ width: "100%", marginTop: '20px' }} label="Country" name='country'
                    value={billingAddress?.country} onChange={onChange}>
                    {LIST_OF_COUNTRIES?.map((country: any) => (
                        <MenuItem value={country?.code}>{country?.name}</MenuItem>
                    ))}
                </TextField>
                <Btn
                    type="submit"
                    className='saveButton'
                    clr={THEME.colors.white}
                    background={THEME.colors.secondaryblue}
                    content={'Save Billing Address'}
                    disabled={disableSave}
                    onClick={onSubmit}
                />
            </div>
        </div>
    )
}

export default AddBillingAddress
