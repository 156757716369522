import React, {useContext, useEffect, useState} from "react";
import chuspSkeleton from "../../../../assets/img/chusp-skeleton.jpg"
import {Avatar} from "@material-ui/core";
import {IUser} from "../../../../interfaces/user";
import {FOLLOW_TYPE} from "../../../../enum/follow";
import {useWindowSize} from "../../../../service/common/window";
import {useLazyRequest} from "../../../../service/common/request";
import {getFollowers, unfollow} from "../../../../api/follow";
import {THEME} from "../../../../ui/theme";
import Btn from "../../../../components/buttons/MainButton/Button";
import {ProfileContext} from "../../index";
import {ASSETS_SIZE} from "../../../../enum/asset-size";
import {useOpenUserProfile} from "../../../../service/user/user";


interface IFollowerProps {
    user: IUser,
    type: string,
    setListOfFollower: React.Dispatch<[]>
}

const Card: React.FC<IFollowerProps> = ({user, type, setListOfFollower}) => {
    const {openUserProfile} = useOpenUserProfile()
    const isFollowers = type === FOLLOW_TYPE.FOLLOWERS;
    const {isMobile} = useWindowSize()
    const {setFollowersCountStateChange} = useContext(ProfileContext)
    const [avatarLoader, setAvatarLoaded] = useState<boolean>(false)
    const [stopFollowing, {data: hasStopFollow}] = useLazyRequest(unfollow, {variables: user.id});

    useEffect(() => {
        if (hasStopFollow) {
            getFollowers({
                type: FOLLOW_TYPE.FOLLOWERS,
                pagination: {page: 0, pageSize: 10},
                asset_size: ASSETS_SIZE.SMALL
            }).then((response) => {
                setFollowersCountStateChange(true)
                if (response.items) {
                    setFollowersCountStateChange(false)
                }

                setListOfFollower(response.items);
            })
        }
        // eslint-disable-next-line
    }, [hasStopFollow])

    return (
        <>
            <div
                className={"follower-card-container"}
                onClick={() => openUserProfile(user.id)}
            >
                <div className={"user-picture"}>
                    <Avatar
                        onLoad={() => setAvatarLoaded(true)}
                        src={avatarLoader ? user?.public_avatar_url : chuspSkeleton}
                        style={isMobile ? {width: "55px", height: "55px"} : {width: "75px", height: "75px"}}
                    >
                        {!user?.public_avatar_url && user?.first_name[0]}
                    </Avatar>
                    <div className={"info-container"}>
                        <h2>
                            {user?.first_name} {user?.last_name}
                        </h2>
                    </div>
                </div>
                {
                    isFollowers &&
                    <Btn
                        className={"remove-btn"}
                        background={THEME.colors.secondaryblue}
                        clr={THEME.colors.white}
                        onClick={(e: any) => {
                            e.stopPropagation()
                            stopFollowing()
                        }}
                        content={'Remove'}
                    />
                }
            </div>
            <hr style={{opacity: 0.1}}/>
        </>
    );
};

export default Card;
