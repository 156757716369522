import React from "react";
import { useWindowSize } from "../../service/common/window";

interface IProps {
  onClick: () => void;
}

const YoutubeSlideInButton: React.FC<IProps> = ({ onClick }) => {
  const { isDesktop } = useWindowSize();
  return (
    <>
      {isDesktop ? (
        <button
          style={{ color: "white" }}
          className="youtube-button"
          onClick={onClick}
        >
          <div className="button-content">
            <div className="watch">
              <p>Watch</p>
            </div>
            <div className="the-video">
              <p className="the">the</p>
              <p className="video pl-4">Video</p>
            </div>
          </div>
        </button>
      ) : (
        <button
          style={{ color: "white" }}
          className="mobile-youtube-button"
          onClick={onClick}
        >
          <div className="mobile-youtube">
            <div className="watch-mobile">Watch</div>
            <div className="video-mobile">
              <div className="video-the-mobile">the</div>
              <div className="video-vid-mobile">Video</div>
            </div>
          </div>
        </button>
      )}
    </>
  );
};

export default YoutubeSlideInButton;
