import {useCallback, useEffect, useState} from "react";

export const useWrapperHeight = () => {
    const [vh, setVh] = useState<any>(window.innerHeight * 0.01)

    useEffect(() => {
        const globalVh = window.innerHeight * 0.01
        setVh(globalVh)
        document.documentElement.style.setProperty('--vh', `${globalVh}px`);
        // eslint-disable-next-line
    }, [window.innerHeight])

    const resizeBlock = useCallback(() => () => {
        let ListenerVh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${ListenerVh}px`);
        // eslint-disable-next-line
    }, [vh])

    window.addEventListener('resize', resizeBlock)
}
