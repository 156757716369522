import React, {useEffect, useState} from 'react'
import chuspLogo from "../../../assets/img/chusp.svg";
import Btn from "../../../components/buttons/MainButton/Button";
import {THEME} from "../../../ui/theme";
import {ROUTES} from "../../../enum/routes";
import {Link} from "react-router-dom";
import {useHistory} from "react-router";
import {useAuth} from "../../../service/authentication/auth";
import {facebookSignUpWithEmailRequest, weChatSignUp} from "../../../api/auth";
import SuccessOrErrorModal from "../../../components/modal/success-or-error/success-or-error-modal";
import {SUCCESS_OR_ERROR_MODAL} from "../../../enum/modal";
import {patternEmail} from "../../../config/form-validation/validation";
import ChuspLoader from "../../../components/loader/ChuspLoader";
import {useUsersIPLocation} from "../../../service/IP";

const AddEmailContainer: React.FC = () => {
    const [emailInput, setEmailInput] = useState<string>("")
    const history: any = useHistory()
    const facebookToken = history?.location?.state?.token
    const openid = history?.location?.state?.openid
    const weChatAccessToken = history?.location?.state?.access_token
    const {doAuthWithSocials, error, socialLoading} = useAuth()
    const {countryIp} = useUsersIPLocation()
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>("")

    useEffect(() => {
        error && setOpenErrorModal(true)
        error && setErrorMessage(error)
    }, [error])

    const signUpAction = async () => {
        if (openid && weChatAccessToken) {
            await doAuthWithSocials(weChatSignUp, {
                access_token: weChatAccessToken,
                openid,
                email: emailInput,
                residence_country: countryIp
            })

        } else {
            facebookToken && await doAuthWithSocials(facebookSignUpWithEmailRequest, {
                token: facebookToken,
                email: emailInput,
                residence_country: countryIp
            })
        }
    }

    const validEmail = patternEmail.test(emailInput)

    return (
        <div className='confirm-email-container'>
            {socialLoading && <ChuspLoader/>}
            <img className='email-logo' src={chuspLogo} alt='Chusp' onClick={() => history.goBack()}/>
            <div className='email-container'>
                <label>Please enter your email address</label>
                <input 
                    className='styled-input'
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    type="email"
                    placeholder="Email"
                />
                <Btn  disabled={emailInput === '' || !validEmail}
                      background={emailInput === '' ? THEME.colors.menugrey : THEME.colors.main}
                      clr={THEME.colors.white}
                      onClick={signUpAction}
                      className="auth-button"
                      content={'Complete sign up'}
                />
                <p className='have-acc link'>Already have an account?<Link to={ROUTES.LOGIN}>Sign
                    in</Link></p>
            </div>
            {openErrorModal && <SuccessOrErrorModal
                open={openErrorModal}
                setOpen={setOpenErrorModal}
                type={SUCCESS_OR_ERROR_MODAL.ERROR}
                textError={errorMessage}
            />}
        </div>
    )
}

export default AddEmailContainer
