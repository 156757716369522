import React, { useEffect, useState } from "react";
import SignUpForm from "../../components/form/signup";
import chuspLogo from "../../../../assets/img/chusp.svg";
import mandarine from "../../../../assets/img/chinese-char.png";
import googleIcon from "../../../../assets/img/icons/gmail.svg";
import wechatIcon from "../../../../assets/img/icons/wechat-logo.svg";
// import fbIcon from "../../../../assets/img/icons/facebookLogo.png";
import { useGoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login";
import { ROUTES } from "../../../../enum/routes";
import { useAuth } from "../../../../service/authentication/auth";
import { useHistory } from "react-router";
import {
  // facebookCheckIfAccountExist,
  // facebookLoginRequest,
  // facebookSignUpWithEmailRequest,
  googleLoginRequest,
} from "../../../../api/auth";
import ChuspLoader from "../../../../components/loader/ChuspLoader";
import { useUsersIPLocation } from "../../../../service/IP";
import SuccessOrErrorModal from "../../../../components/modal/success-or-error/success-or-error-modal";
import { SUCCESS_OR_ERROR_MODAL } from "../../../../enum/modal";
import YoutubeSlideInButton from "../../../../components/youtube-slide-in-button";
import Modal from "../../../../components/modal/main-modal/modal";

const SignUpContainer: React.FC = () => {
  const history = useHistory();
  const { countryIp } = useUsersIPLocation();
  const { doAuthWithSocials, socialLoading, error } = useAuth();
  // eslint-disable-next-line
  const [weChatCheckLoading, setWeChatCheckLoading] = useState<boolean>(false);
  // eslint-disable-next-line
  const [authCheckErrorMessage, setAuthCheckErrorMessage] =
    useState<string>("");
  const [openUserBlockedModalWindow, setOpenUserBlockedModalWindow] =
    useState<boolean>(false);
  const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (error) {
      setOpenUserBlockedModalWindow(true);
    }
  }, [error]);

  const googleRequest = useGoogleLogin({
    onSuccess: async (codeResponse) =>
      codeResponse &&
      codeResponse.code &&
      (await doAuthWithSocials(googleLoginRequest, {
        code: codeResponse.code,
        residence_country: countryIp,
      })),
    flow: "auth-code",
  });

  // const responseFacebook = async (response: any) => {
  //   if (response.accessToken) {
  //     setWeChatCheckLoading(true);
  //     const data = await facebookCheckIfAccountExist(response?.accessToken);
  //     data && setWeChatCheckLoading(false);
  //     if (data && data?.message) {
  //       setOpenUserBlockedModalWindow(true);
  //       setAuthCheckErrorMessage(data?.message);
  //     }
  //     const alreadyExist = data?.data?.existInDB;
  //     const facebookWithEmail = data?.data?.hasEmail;
  //     alreadyExist
  //       ? await doAuthWithSocials(facebookLoginRequest, {
  //           token: response?.accessToken,
  //         })
  //       : facebookWithEmail
  //       ? await doAuthWithSocials(facebookSignUpWithEmailRequest, {
  //           token: response?.accessToken,
  //           residence_country: countryIp,
  //         })
  //       : history.push({
  //           pathname: ROUTES.AUTH_EMAIL_ADD,
  //           state: { token: response.accessToken },
  //         });
  //   }
  // };
  const weChatUrl = process.env.REACT_APP_WE_CHAT_LOGIN_URL || "";
  const signUpWithWeChat = () => {
    window.open(weChatUrl, "_self");
  };

  return (
    <div className="signup-container">
      {(weChatCheckLoading || socialLoading) && <ChuspLoader />}
      <img
        className="signin-logo"
        onClick={() => history.push(ROUTES.LOGIN)}
        src={chuspLogo}
        alt="Chusp"
      />
      <img className={"sign-up-mandarine"} src={mandarine} alt="chinese" />
      <div className="form-container">
        <SignUpForm />
        <div className="socials">
          {/* <FacebookLogin
            appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
            autoLoad={false}
            textButton={""}
            fields="name,email,picture,FacebookLogin"
            callback={responseFacebook}
            icon={
              <img className="face-book-icon" src={fbIcon} alt={"Facebook"} />
            }
          /> */}
          <img
            className="google-icon"
            src={googleIcon}
            onClick={googleRequest}
            alt="Chusp Gmail"
          />
          <button className="weChat-login-button" onClick={signUpWithWeChat}>
            <img alt="weChat-logo" className="weChat-logo" src={wechatIcon} />
          </button>
        </div>
        <div className="privacy">
          By proceeding, you accept our{" "}
          <a
            href={`${process.env.REACT_APP_UI_URL}/p/terms`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms
          </a>{" "}
          and{" "}
          <a
            href={`${process.env.REACT_APP_UI_URL}/p/policy`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
          .
        </div>
      </div>
      {/* <div className='privacy'>
                By continuing, you agree with our
                {" "}<a href={`${process.env.REACT_APP_UI_URL}/p/terms`} target="_blank"
                   rel="noopener noreferrer">Terms</a> and <a
                href={`${process.env.REACT_APP_UI_URL}/p/policy`} target="_blank" rel="noopener noreferrer">Privacy
                Policy</a>.
            </div> */}
      <YoutubeSlideInButton onClick={() => setOpenVideoModal(true)} />
      {openVideoModal && (
        <Modal
          open={openVideoModal}
          width={900}
          setOpen={setOpenVideoModal}
          leftButton={"Close"}
          children={
            <iframe
              style={{
                width: "100%",
                filter: iframeLoaded ? "none" : "blur(20px)",
                transition: iframeLoaded ? "filter 0.3s ease-out" : "none",
              }}
              onLoad={() => setIframeLoaded(true)}
              className="youtube-video"
              src="https://s3.ap-southeast-1.amazonaws.com/assets.prod-chusp.com/public/CHUSP+Hear+We+Come.mp4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          }
        />
      )}
      {openUserBlockedModalWindow && (
        <SuccessOrErrorModal
          open={openUserBlockedModalWindow}
          setOpen={setOpenUserBlockedModalWindow}
          type={SUCCESS_OR_ERROR_MODAL.ERROR}
          textError={error ? error : authCheckErrorMessage}
          onActionClicked={() => setOpenUserBlockedModalWindow(false)}
        />
      )}
    </div>
  );
};

export default SignUpContainer;
