import React from "react";
import male from "../../../../assets/img/male-without-background(1).png";
import maleWebp from "../../../../assets/img/male-without-background.webp";
import female from "../../../../assets/img/woman-without-background(1).png";
import femaleWebp from "../../../../assets/img/woman-without-background.webp";
import CopyrightIcon from "@material-ui/icons/Copyright";

const DesktopBanner: React.FC = () => {
  return (
    <div className="picture-container">
      <div className="pictures">
        <span>
          <picture>
            <source srcSet={maleWebp} type="image/webp" />
            <source srcSet={male} type="image/png" />
            <img src={male} alt="Chusp male model" />
          </picture>
        </span>
        <span>
          <picture>
            <source srcSet={femaleWebp} type="image/webp" />
            <source srcSet={female} type="image/png" />
            <img src={female} alt="Chusp female model" />
          </picture>
        </span>
      </div>
      {/* <span className="bannerText">Earn by hosting personal video meetings &#8226; On your terms</span> */}
      <div className="bannerText-container">
        <span className="bannerText">
          Earn by hosting exclusive video meetings and content
        </span>
      </div>
      <span className="copyRightText ml-4">
        <CopyrightIcon className="copyright-icon" />&nbsp;<span>Chusp</span>
      </span>

      {/* <span className='copyRightText ml-4'>@Nika Private Limited 2024</span> */}
    </div>
  );
};

export default DesktopBanner;
