export const THEME = {
    colors: {
        main: '#000000',
        primary: '#FC0101',
        deletered: '#D83A18',
        orange: "#FFA500",
        white: '#FFFFFF',
        milk: '#FDFDFD',
        light: '#F2F2F2',
        secondarylight: '#E5E5E5',
        lightblue: '#476AC2',
        blue: '#1554F6',
        secondaryblue: '#405DE6',
        secondarybluehover: '#5C75EA',
        videobtnhover: '#515151',
        success: '#4CAF50',
        grey: '#777777',
        lightgrey: '#BDBDBD',
        secondarylightgrey: '#AAAAAA',
        darkgrey: '#333333',
        secondarydarkgray: '#444444',
        gainsboro: '#E0E0E0',
        menugrey: '#828282',
        backgroundgrey: '#EEEEEE',
        lighterackgroundgrey: "#EAEAEA36",
        red: '#FF0000',
        lightBlack: 'rgba(0, 0, 0, 0.15)',
        social: {
            twitter: '#55ACEE',
            whatsapp: '#25D366',
            telegram: '#37AEE2',
            facebook: '#1877F2'
        },
        calendar: {
            available_slot: '#4592B4',
            initiator_meeting: '#15195A',
            header_today: '#17077D',
            header_text: '#4F4F4F',
            header_border: 'rgba(0, 0, 0, 0.25)',
            calendar_border: '#CCCCCC',
            create_modal: '#F7F7F7'
        },
    },
    fonts: {
        martel: 'Martel Sans',
        source: 'Source Sans Pro',
    },
    size: {
        xsmall: 10,
        smaller: 12,
        small: 14,
        normal: 16,
        large: 18,
        middle: 20,
        larger: 24,
        huge: 36,
        xhuge: 64,
    },
    borderRadius: 6,
    weight: 600,
    footerHeightTable: 50,
    headerHeightTable: 60,
    infoHeaderHeightTable: 53, 
}
