import React, {CSSProperties} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store} from './redux'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {ROUTES} from "./enum/routes";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { PersistGate } from 'redux-persist/integration/react';

const STYLES_TOP_CONTAINER: CSSProperties = {
    height: window.location.pathname.includes(ROUTES.ROOT_AUTH) ? "100vh" : "0vh",
    overflow: "hidden",
    width: "100vw",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "black",
    zIndex: -2
}

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_APP_ID}`}>
                    <div style={STYLES_TOP_CONTAINER} />
                    <App />
                </GoogleOAuthProvider>
            </MuiPickersUtilsProvider>
            {/* </PersistGate> */}
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);

serviceWorkerRegistration.register();
