import { useEffect } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { IUser } from "../../interfaces/user";
import { ROUTES } from "../../enum/routes";
import { useLocation } from "react-router";

const SESSION_STORAGE_KEY_NAME = "isFirstTime";

export const useFirstTimeVisit = () => {
  const { pathname } = useLocation();
  const myData: IUser = useSelector(
    (state: RootStateOrAny) => state.auth?.user?.user
  );
  useEffect(() => {
    let obj: any = {};
    if (myData?.is_first_time) {
      Object.entries(ROUTES.MY_PAGE).forEach(([key, value]) => {
        obj[key] = {
          route: value,
          isFirstTime: true,
        };
      });
      sessionStorage.setItem(SESSION_STORAGE_KEY_NAME, JSON.stringify(obj));
    }
  }, [myData]);

  useEffect(() => {
    return () => {getFirstTimeStatusOfTheCurrentRoute();}
    // eslint-disable-next-line
  }, [pathname]);

  function getFirstTimeStatusOfTheCurrentRoute() {
    const object: any = sessionStorage.getItem(SESSION_STORAGE_KEY_NAME);
    const parsedData = JSON.parse(object);
    if (parsedData) {
      const key = Object.keys(parsedData).find((key: string) => parsedData[key]["route"] === pathname);
      if (key) {
          parsedData[key]["isFirstTime"] = false;
          sessionStorage.setItem(SESSION_STORAGE_KEY_NAME, JSON.stringify(parsedData));
      }
    }
  }
};



export const useFirstTimePageStatus = () => {
  const { pathname } = useLocation();

  function getRoute() {
    const object: any = sessionStorage.getItem(SESSION_STORAGE_KEY_NAME);
    if (object) {
      const parsedData = JSON.parse(object);
      const key = Object.keys(parsedData).find(
          (key: string) => parsedData[key]["route"] === pathname
      );
      if (key) return parsedData[key]["isFirstTime"];
    }
  }
  return { isFirstTime: getRoute() };
};