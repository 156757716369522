import axios from "axios";
import { IGetBlockedUser } from "../interfaces/blacklist";

export const addToBlacklist = (id: string) => {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/blacklist/${id}`
    })
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const getBlockedUser = (data: IGetBlockedUser) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/blacklist/get`,
        data
    })
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const deleteFromBlacklist = (id: number) => {
    return axios({
        method: 'DELETE',
        url: `${process.env.REACT_APP_API_URL}/blacklist/unblocked/${id}`,
    })
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}