import React from 'react'
import Button from "@material-ui/core/Button";

type Props = {
    text: string,
    onClickHandler?: any,
    type?: "button" | "submit" | "reset" | undefined,
    disabled?: boolean,
    width?: string,
    className?: string

}

const StyledButtonComponent: React.FC<Props> = ({text, onClickHandler, type, disabled, width, className}) => {
  return (
    <Button 
      className={`styled-button ${className}`}
      style={{"--dynamic-width": width, height: '50px'} as React.CSSProperties}
      type={type} 
      onClick={onClickHandler} 
      disabled={disabled}
      size="large"
    >
      <svg>
        <rect x="1" y="1" rx="5px" ry="5px" fill="none" width="99%" height="90%" />
      </svg>
      {text}
    </Button>
  )
}
  
export default StyledButtonComponent;
