import moment from "moment";

type DataType = {
    id?: number,
    position: string,
    workplace: string,
    currentlyWork: string,
    startDate: string,
    endDate: string
}

// TODO calculating by overflow-x, not countOfSymbolsInOneLine
const countOfSymbolsInOneLine = 65

export const getWorkplaceInfoString = (data: DataType, isMobile?: boolean | null) => {
    if (data) {
        let info = ''
        const {position, workplace, currentlyWork, startDate, endDate} = data

        const startDateYear = moment(startDate).get('years')
        const endDateYear = moment(endDate).get('years')

        const startDateMonth = moment(startDate).get('months')
        const endDateMonth = moment(endDate).get('months')

        if (startDateYear === endDateYear && startDateMonth !== endDateMonth && !currentlyWork) {
            info = `${position} at ${workplace}, ${moment(startDate).format('MMMM')} - ${moment(endDate).format('MMMM')} ${moment(endDate).get('years')}`
        } else if (currentlyWork && startDateMonth === endDateMonth) {
            info = `${position} at ${workplace}, Present`
        } else if (currentlyWork) {
            info = `${position} at ${workplace}, ${moment(startDate).format('MMMM')} ${moment(startDate).get('years')} - Present`
        } else {
            info = `${position} at ${workplace}, ${moment(startDate).format('MMMM')} ${moment(startDate).get('years')} - ${moment(endDate).format('MMMM')} ${moment(endDate).get('years')}`
        }

        if (isMobile) {
            if (position.length + 4 + workplace.length <= countOfSymbolsInOneLine) {
                info = `${position} at ${workplace}`
            } else if (position.length <= countOfSymbolsInOneLine) {
                info = `${position}`
            }
        }

        return info
    }
}

export const getEducationInfoString = (data: any, isMobile?: boolean | null) => {
    if (data) {
        let info = ''

        const {school, studyField, degree, graduationDate} = data
        const futureYear = moment(graduationDate).get('years') > moment().get('years')
        const graduationYear = moment(graduationDate).get('years')

        // ONLY SCHOOL
        if (school && studyField && !degree && !graduationYear) {
            info = `Studied ${studyField} at ${school}`
        }

        // SCHOOL, STUDY FIELD AND DEGREE
        else if (school && studyField && degree && !graduationYear) {
            info = `${degree} in ${studyField} from ${school}`
        }

        // SCHOOL, STUDY FIELD AND GRADUATION YEAR
        else if (school && studyField && graduationYear && !degree) {
            if (futureYear) {
                info = `Studies ${studyField} at ${school}. Class of ${graduationYear}`
            } else {
                info = `Studied ${studyField} at ${school}. Class of ${graduationYear}`
            }
        } else {
            info = `${degree} in ${studyField} from ${school}. Class of ${graduationYear}`
        }


        if (degree && isMobile) {
            if (degree.length + 4 + studyField.length + 6 + school.length <= countOfSymbolsInOneLine) {
                info = `${degree} in ${studyField} from ${school}`
            } else if (degree.length + 4 + studyField.length <= countOfSymbolsInOneLine) {
                info = `${degree} in ${studyField}`
            } else {
                info = `${studyField}`
            }
        } else if (isMobile) {
            if (8 + studyField.length + 4 + school.length <= countOfSymbolsInOneLine) {
                info = futureYear ? `Studies ${studyField} at ${school}` : `Studied ${studyField} at ${school}`
            }  else if (8 + studyField.length <= countOfSymbolsInOneLine) {
                info = futureYear ? `Studies ${studyField}` : `Studied ${studyField}`
            } 
        }
        

        return info
    }
}
