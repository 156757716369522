import {useEffect, useState} from 'react'
import {devices} from "../../ui/queries/devices";

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState<{ width: number | undefined | null, height: number | undefined | null }>({
        width: null,
        height: null,
    });
    const [pc, setPc] = useState<boolean | null>(null)
    const [desktop, setDesktop] = useState<boolean | null>(null);
    const [table, setTable] = useState<boolean | null>(null);
    const [mobile, setMobile] = useState<boolean | null>(null)
    const [smallMobile, setSmallMobile] = useState<boolean | null>(null);

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
            setPc(window && window.innerWidth >= devices.pc)
            setDesktop(window && window.innerWidth >= devices.laptop);
            setTable(window && window.innerWidth < devices.laptop);
            setMobile(window && window.innerWidth < devices.tablet)
            setSmallMobile(window && window.innerWidth <= devices.mobileS);
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return {
        ...windowSize,
        isPC: pc,
        isDesktop: desktop,
        isTable: table,
        isMobile: mobile,
        isMobileS: smallMobile
    };
};

