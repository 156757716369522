import React, {useContext, useState} from 'react'
import HomeIcon from "@material-ui/icons/Home";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import Badge from "@material-ui/core/Badge";
import {useHistory} from 'react-router'
import {ROUTES} from "../../enum/routes";
import {useLocation} from 'react-router'
import {IUser} from "../../interfaces/user";
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {saveToMemory} from "../../redux/reducers/searchUsers";
import LogInModal from '../modal/login-modal/login-modal';
import {useCommonNotifications} from "../../service/notifications/common-notifications";
import {SocketsContext} from "../../pages/App";
import Btn from '../buttons/MainButton/Button';


const Footer = () => {
    const {socket} = useContext(SocketsContext)
    const {pathname} = useLocation()
    const user: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const {commonNotifications, resetNotifications} = useCommonNotifications(socket, false)
    const dispatch = useDispatch()
    const history = useHistory()
    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false)
    const searchMemory = useSelector(
        (state: RootStateOrAny) => state?.searchMemory?.memory
      );
    const goToPage = (historyParams: { pathname: string, state?: any }, setStateAction?: () => void) => {
        if (user) {
            dispatch(saveToMemory({id: searchMemory?.id, page: searchMemory?.page, usersInfo: searchMemory?.usersInfo}))
            setStateAction && setStateAction()
            return history.push(historyParams)
        } else {
            setOpenLoginModal(true)
            // history.push(ROUTES.ROOT_AUTH)
        }
    }

    return (
        <div className={"mobile-footer-container"}>
            {openLoginModal && <LogInModal open={openLoginModal} setOpen={setOpenLoginModal}/>}
            <Btn
                className={`mobile-icon ${pathname === ROUTES.USER_LIST ? "activated" : ""}`}
                onClick={() => history.push(ROUTES.USER_LIST)}
                content={pathname === ROUTES.USER_LIST ? <HomeIcon/> : <HomeOutlinedIcon/>} />
            <Btn
                className={`mobile-icon ${pathname === ROUTES.MY_PAGE.SCHEDULER ? "activated" : ""}`}
                onClick={() =>
                    goToPage({pathname: ROUTES.MY_PAGE.SCHEDULER, state: {isRedirectedFromHeader: true}})}
                    content={pathname === ROUTES.MY_PAGE.SCHEDULER ? <PermContactCalendarIcon/> : <PermContactCalendarOutlinedIcon/>} />
            <Btn
                className={`mobile-icon ${pathname === ROUTES.MY_PAGE.CREATE_POST? "activated" : ""}`}
                onClick={() =>
                    goToPage({pathname: ROUTES.MY_PAGE.CREATE_POST})}
                content={pathname === ROUTES.MY_PAGE.CREATE_POST ? <AddBoxIcon/> : <AddBoxOutlinedIcon/>} />
            <Btn
                className={`mobile-icon ${pathname === ROUTES.MY_PAGE.PROFILE ? "activated" : ""}`}
                onClick={() => {
                    console.log('hey this is from mobile view')
                    goToPage({pathname: ROUTES.MY_PAGE.PROFILE}, resetNotifications)
                }}
                content={
                    <Badge className={"profile-badge"} 
                            badgeContent={commonNotifications ? commonNotifications?.length : 0}
                            color="error">
                        {pathname === ROUTES.MY_PAGE.PROFILE ? <PersonIcon/> : <PersonOutlineOutlinedIcon/>}
                    </Badge>} />
        </div>
    )
}

export default React.memo(Footer);
