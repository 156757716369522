import React, {useState} from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import {useHistory} from 'react-router'
import "cropperjs/dist/cropper.css";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import {IUser} from "../../../../interfaces/user";
import AppleStyleModal from "../../../../components/modal/apple-style/apple-style-modal";
import ShareIconsDesctop from "../ShareSocialsDesctop";
import ReportModal from "../ReportModal";
import {deleteSinglePost} from "../../../../api/post";
import {useLocation} from "react-router-dom";
import {ROUTES} from "../../../../enum/routes";
import {useCopyToClipboard} from "react-use";
import LogInModal from "../../../../components/modal/login-modal/login-modal";
import Modal from "../../../../components/modal/main-modal/modal";
import { useBase64 } from "../../../../service/common/base64";

interface IProps {
    user?: IUser;
    postId?: number;
    paidContentId?: number;
    setDeletePostId?: any;
    alreadyClickedShareOrReport?: boolean;
}

const ShareOrReport: React.FC<IProps> = ({user, postId, paidContentId, setDeletePostId, alreadyClickedShareOrReport = false}) => {
    const history = useHistory()
    const {pathname} = useLocation();
    const [openShareOrReportModal, setOpenShareOrReportModal] = useState<boolean>(alreadyClickedShareOrReport)
    const [openReportModal, setOpenReportModal] = useState<boolean>(false)
    const [openShareModal, setOpenShareModal] = useState<boolean>(false)
    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false)
    const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false)
    // eslint-disable-next-line
    const [state, copyToClipboard] = useCopyToClipboard();
    const {encryptToBase64} = useBase64()
    const user_name = user?.user_name?.replace(/\s/g, '_');
    const share_user_link = `${process.env.REACT_APP_NEXT_PUBLIC_ENV_BASE_CLIENT_UI}/u/${user_name}`
    const share_post_link = `${process.env.REACT_APP_NEXT_PUBLIC_ENV_BASE_CLIENT_UI}/users/${user?.id}/post/${postId}`
    const share_content_link = `${process.env.REACT_APP_NEXT_PUBLIC_ENV_BASE_CLIENT_UI}/pc/${encryptToBase64(`${paidContentId}`)}`

    const postIdToDeleteOrEdit = postId?.toString()

    const onPostDelete = async () => {
        const response = await deleteSinglePost(postIdToDeleteOrEdit)
        response && setDeletePostId(postIdToDeleteOrEdit)
    }

    const onPostEdit = async () => {
        return history.push({
            pathname: user && ROUTES.MY_PAGE.EDIT_POST,
            state: {id: postIdToDeleteOrEdit}
        })
    }
    const isUserLogined = useSelector((state: RootStateOrAny) => state.auth?.user?.user.id)
    const isMyProfile = isUserLogined === user?.id
    const isPostsPage = pathname.includes(ROUTES.USER_POST)

    const renderShareOrReportModal = () => {
        if (isPostsPage && isMyProfile) {
            return [
                <span onClick={() => {
                    setOpenShareModal(true)
                    setOpenShareOrReportModal(false)
                }} className="share">
                    Share to ...
                </span>,
                <span onClick={() => {
                    setOpenShareOrReportModal(false)
                    copyToClipboard(share_post_link)
                }}>
                    Copy link
                </span>,
                <span
                    onClick={async () => {
                       setOpenConfirmationModal(true)
                        setOpenShareOrReportModal(false)
                    }}>
                    Delete
                </span>,
                <span
                    onClick={async () => {
                        await onPostEdit()
                        setOpenShareOrReportModal(false)
                    }}>
                    Edit
                </span>,
                <span
                    onClick={() => {
                        setOpenShareOrReportModal(false)
                    }}>
                    Cancel
                </span>
            ]
        }else if(alreadyClickedShareOrReport){
            return [
                <span onClick={() => {
                    setOpenShareOrReportModal(false)
                    copyToClipboard(share_content_link)
                }}>
                    Copy link
                </span>,
                <span
                    onClick={() => {
                        setOpenShareOrReportModal(false)
                        if (isUserLogined) {
                            setOpenReportModal(true)
                        } else {
                            setOpenLoginModal(true)
                        }
                    }}>
                    Report
                </span>,
                <span
                    onClick={() => {
                        setOpenShareOrReportModal(false)
                    }}>
                    Cancel
                </span>
            ]
        }else {
            return [
                <span onClick={() => {
                    setOpenShareModal(true)
                    setOpenShareOrReportModal(false)
                }} className="share">
                    Share to ...
                </span>,
                <span onClick={() => {
                    setOpenShareOrReportModal(false)
                    isPostsPage ? copyToClipboard(share_post_link) : copyToClipboard(share_user_link)
                }}>
                    Copy link
                </span>,
                <span
                    onClick={() => {
                        setOpenShareOrReportModal(false)
                        if (isUserLogined) {
                            setOpenReportModal(true)
                        } else {
                            setOpenLoginModal(true)
                        }
                    }}>
                    Report
                </span>,
                <span
                    onClick={() => {
                        setOpenShareOrReportModal(false)
                    }}>
                    Cancel
                </span>
            ]
        }
    }

    return (
        <>
            {openLoginModal && <LogInModal open={openLoginModal} setOpen={setOpenLoginModal}/>}
            <MoreHorizOutlinedIcon
                className={"share-three-dots-icon"}
                style={{color: isPostsPage ? "#AAAAAA" : "#ffffff"}}
                onClick={() => setOpenShareOrReportModal(true)}
            />
            {openShareOrReportModal && <AppleStyleModal
                open={openShareOrReportModal}
                setOpen={setOpenShareOrReportModal}
                items={renderShareOrReportModal()}
            />}
            {openShareModal && (
                <ShareIconsDesctop
                    user={user}
                    setOpenShareModal={setOpenShareModal}
                    openShareModal={openShareModal}
                    postId={postId}
                />
            )}
            {openReportModal && (
                <ReportModal
                    isRedirectedFromPaidContent={alreadyClickedShareOrReport}
                    user={user}
                    openReportModal={openReportModal}
                    setOpenReportModal={setOpenReportModal}
                    postId={postId}
                    paidContentId={paidContentId}
                />
            )}
            <Modal open={openConfirmationModal}
                   setOpen={setOpenConfirmationModal}
                   children={<div className='delete-confirmation'>Do you really want to remove post?</div>}
                   leftButton={'No'}
                   rightButton={'Yes'}
                   onComplete={async() =>  await onPostDelete()}
                   onCancel={() => setOpenConfirmationModal(false)}
            />
        </>
    );
};

export default ShareOrReport;
