export const useBase64 = (data?: any) => {
    const encryptToBase64 = (data: any) => {
        return new Buffer(data).toString("base64")
    }

    const decryptBase64 = (data: any) => {
        return Buffer.from(data, "base64").toString("ascii")
    }

    return {
        encryptToBase64,
        decryptBase64,
        data: data ? Buffer.from(data, "base64").toString("ascii") : ""
    }
}