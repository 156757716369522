import axios, {AxiosResponse} from 'axios'

export const getVatRate = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/vat/user`
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
};

export const getListOfVatCountries = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/vat/countries`
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
};

