import React, {useState} from "react"

interface IProps {
    children: JSX.Element | HTMLElement | HTMLElement[],
    onClick?: () => void,
    id?: string
}


const TouchableOpacity: React.FC<IProps> = ({children, id, onClick}) => {
    const [touched, setTouched] = useState<{ id: string | null }>({id: null})
    return (
        <div
            onClick={onClick}
            onTouchStart={() => setTouched({id: id ? id : "1"})}
            onTouchEnd={() => setTouched({id: null})}
            style={{opacity: touched.id === id ? 0.3 : 1, transition: "ease-in-out"}}
        >
            {children}
        </div>
    )
}

export default TouchableOpacity;
