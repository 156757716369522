import React, {useEffect} from 'react';
import {useTitle} from "react-use";
import { useFaq } from '../../../../service/faq/faq';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ChuspLoader from "../../../../components/loader/ChuspLoader";

const FAQ = () => {
    const [expanded, setExpanded] = React.useState<number | false>();

    useTitle("FAQ")
    const {faq, loading} = useFaq()
        // const reversedArray = faq?.length>0 ? [...faq]?.reverse() : []

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    if (loading) {
        return <ChuspLoader/>
    }

    return (
        <div className="container-faq">
            <div className='faq-wrapper'>
                {faq?.map((faqItem: any) => (
                    <div className='faq'>
                        <h2>{faqItem.topic}</h2>
                        {JSON.parse(faqItem.value).map((QAvalue: any, index: number) => (
                            <MuiAccordion expanded={expanded === faqItem.id + index} onChange={() => setExpanded(expanded === faqItem.id + index ? false : faqItem.id + index)}>
                            <MuiAccordionSummary className='question' aria-controls="panel1d-content" id="panel1d-header">
                                <Typography>{QAvalue.question}</Typography>
                                {expanded === faqItem.id + index ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                            </MuiAccordionSummary>
                            <MuiAccordionDetails>
                                <Typography>
                                    <div dangerouslySetInnerHTML={{__html: QAvalue.answer}}/>
                                </Typography>
                            </MuiAccordionDetails>
                        </MuiAccordion>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
};

export default FAQ;
