import {useEffect, useState} from "react";
import {checkFollow, follow, unfollow} from "../../api/follow";
import {PROFILE_MODES} from "../../enum/profile-mods";
import {ROUTES} from "../../enum/routes";
import {useLocation} from "react-use";

type IProfileMode = typeof PROFILE_MODES.VIEW | typeof PROFILE_MODES.EDIT

export const useFollowUser = (userId: number, mode?: IProfileMode) => {
    const {pathname} = useLocation()
    const [followLoader, setFollowLoader] = useState<boolean>(false)
    const [followingStatus, setFollowingStatus] = useState<boolean>(false)
    const [followError, setFollowError] = useState<string>('')
    const [unfollowError, setUnfollowError] = useState<string>('')
    const isUserList = pathname === ROUTES.USER_LIST

    const followUser = async () => {
        const response = await follow(userId)
        if (response.data) {
            setFollowingStatus(!followingStatus)
        } else if(response?.error){
            setFollowError(response?.error)
        }
    }
    const stopFollowingUser = async () => {
        const response = await unfollow(userId)
        if (response.isExpected) {
            setFollowingStatus(!followingStatus)
        } else if(response?.error){
            setUnfollowError(response?.error)
        }
    }
    
    useEffect(() => {
        (async () => {
            if (mode === PROFILE_MODES.VIEW || isUserList) {
                setFollowLoader(true)
                if (!Number.isNaN(+userId)) {
                    const response = await checkFollow(userId)
                    if (response) {
                        setFollowLoader(false)
                        setFollowingStatus(response.isExpected)
                    }
                }
            }
        })()
        // eslint-disable-next-line
    }, [userId])

    return {
        followUser,
        stopFollowingUser,
        followingStatus,
        followLoader,
        followError,
        unfollowError
    }
}