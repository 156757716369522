export const NOTIFICATION_TEXT = {
    SYSTEM_MEETING_BOOKED: "booked a meeting with you!",
    USER_SUBSCRIBED: "started following!",
    CANCELLED_MEETING: "just cancelled meeting with you!"
};

export const NOTIFICATION_TYPE = {
    SYSTEM_MEETING_BOOKED: 'SYSTEM_MEETING_BOOKED',
    SYSTEM_MEETING_CANCELED: 'SYSTEM_MEETING_CANCELED',
    USER_SUBSCRIBED: 'USER_SUBSCRIBED',
    SYSTEM_COMPLAINT_STATUS: 'SYSTEM_COMPLAINT_STATUS',
    VERIFICATION_USER: 'VERIFICATION_USER'
};

