import React, {InputHTMLAttributes} from 'react'
import {Field, FormikTouched} from "formik";
import {ReactComponent as VisibilityIcon} from '../../assets/icons/visibility.svg';
import {ReactComponent as VisibilityOffIcon} from '../../assets/icons/visibility_off.svg';
import KeyboardCapslockIcon from '@material-ui/icons/KeyboardCapslock';
import {THEME} from "../../ui/theme";

interface FieldType extends InputHTMLAttributes<HTMLInputElement> {
    name?: string,
    title: string,
    type: string,
    placeholder?: string,
    value: string,
    error?: boolean | "" | FormikTouched<any> | FormikTouched<any>[] | undefined
    classname?:string
}

const InputField: React.FC<FieldType> =
    ({
         name,
         title,
         type,
         placeholder,
         value,
         onChange,
         onBlur,
         error,
         classname
     }) => {
        const [visible, setVisible] = React.useState<boolean>(false)
        const [isCapsLockOn, setIsCapsLockOn] = React.useState<boolean>(false)

        const handleCapsLockOn = (event: any) => {
            setIsCapsLockOn(event.getModifierState("CapsLock"))
        }

        return (
            <div className={`${error ? 'error-field-input-wrapper' : ""} field-input-wrapper`}>
                <label>{title}</label>
                <Field
                    name={name}
                    type={type === 'password' && visible ? 'text' : type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    autoComplete={'off'}
                    border={THEME.colors.secondaryblue}
                    onKeyUp={handleCapsLockOn}
                    className={classname}
                />
                {isCapsLockOn && <KeyboardCapslockIcon/>}
                <div className='password-icon'>
                    {type === 'password' && (visible ?
                             <VisibilityIcon onClick={() => setVisible(false)}/> :
                             <VisibilityOffIcon onClick={() => setVisible(true)}/>
                    )}

                </div>
            </div>
        )
    }

export default React.memo(InputField);
