import React from 'react'
import StarsIcon from "@material-ui/icons/Stars";
import {SCORE_VALUES_IN_STARS} from "../../enum/score";

interface IProps {
    totalScore: number,
    getStarsCounterByEnumValue: (amountOfStars: number) => number
}

const StarsStatistics: React.FC<IProps> = ({totalScore, getStarsCounterByEnumValue}) => {
    return (
        <div className='stars-statistics-container'>
            <div className='statistics-flex'>
                <h3>Host Meetings</h3>
                <span>{totalScore}</span>
            </div>
            <div className='statistics-info'>
                <div className='statistics-icons zero'>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                </div>
                <span>{getStarsCounterByEnumValue(SCORE_VALUES_IN_STARS.FIVE_STARS.count)}</span>
            </div>
            <div className='statistics-info'>
                <div className='statistics-icons one'>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                </div>
                <span>{getStarsCounterByEnumValue(SCORE_VALUES_IN_STARS.FOUR_STARS.count)}</span>
            </div>
            <div className='statistics-info'>
                <div className='statistics-icons two'>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                </div>
                <span>{getStarsCounterByEnumValue(SCORE_VALUES_IN_STARS.THREE_STARS.count)}</span>
            </div>
            <div className='statistics-info'>
                <div className='statistics-icons three'>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                </div>
                <span>{getStarsCounterByEnumValue(SCORE_VALUES_IN_STARS.TWO_STARS.count)}</span>
            </div>
            <div className='statistics-info'>
                <div className='statistics-icons four'>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                    <StarsIcon/>
                </div>
                <span>{getStarsCounterByEnumValue(SCORE_VALUES_IN_STARS.ONE_STAR.count)}</span>
            </div>
        </div>
    )
}


export default StarsStatistics
