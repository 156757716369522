import React from "react"
import Modal from "../main-modal/modal";
import {useWindowSize} from "../../../service/common/window";
import {ICommonModalProps} from "../../../interfaces/modal";


interface IProps extends ICommonModalProps {
    videoUrl: string,
    onComplete?: () => void
}

const VideoPreviewModal: React.FC<IProps> = ({open, setOpen, videoUrl, onComplete}) => {
    const {isDesktop, isTable} = useWindowSize()

    const renderModalWidth = () => {
        return isDesktop ? 700 : isTable ? window.innerWidth - 50 : undefined
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            leftButton={"Close"}
            rightButton={!!onComplete ? <div style={{marginRight: 20}}>Upload new</div> : ""}
            onComplete={onComplete}
            width={renderModalWidth()}
            children={<video
                className="video-preview"
                preload={"auto"}
                autoPlay
                controls={true}
                src={videoUrl}
                muted
            />}
        />
    )
}

export default VideoPreviewModal;