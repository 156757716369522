import {useEffect, useState} from "react";
import {getCurrentBalance} from "../../api/finance";

export const useEarnings = (shouldFetch: boolean = true) => {
    const [earnings, setEarnings] = useState<number>(0)

    useEffect(() => {
        (async () => {
            if (shouldFetch) {
                const response = await getCurrentBalance()
                if (response) {
                    const currentBalance = response.data
                    const unremittedEarnings = currentBalance?.unremitted_earnings
                    Number.isNaN(unremittedEarnings) ? setEarnings(0) : setEarnings(Math.round(unremittedEarnings * 100) / 100)
                }
            }
        })()
    }, [shouldFetch])


    return {
        earnings
    }
}
