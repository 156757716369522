/* eslint-disable */
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import {PROFILE_MODES} from "../../enum/profile-mods";
import Navigation from "./Navigation";
import ProfileHeader from "./Header";
import {useHistory, useParams} from "react-router";
import {useWindowSize} from "../../service/common/window";
import {RootStateOrAny, useSelector} from "react-redux";
import BioContainer from "./UserInfo/Bio";
import AvailableDates from "./UserInfo/AvailableDates";
import PostsContainer from "./UserInfo/Posts";
import MoreInfo from "./MoreInfo";
import InfoBar from "../../components/infobar";
import Hosting from "./Hosting";
import Wallet from "./Wallet";
import {FOLLOW_TYPE} from "../../enum/follow";
import {CONTAINERS} from "../../enum/containers";
import {Avatar} from "@material-ui/core";
import BackButton from "../../components/buttons/back-button";
import BlockedUserContainer from "./Blacklist";
import FollowersContainer from "./Followers";
import {ASSETS_SIZE, MAX_IMAGE_SIZE_BYTES} from "../../enum/asset-size";
import LogInModal from "../../components/modal/login-modal/login-modal";
import {IUser} from "../../interfaces/user";
import {OpenUser} from "../../pages/App";
import {useTitle} from "react-use";
import {useLocation} from "react-router-dom";
import Btn from "../../components/buttons/MainButton/Button";
import {THEME} from "../../ui/theme";
import {SUCCESS_OR_ERROR_MODAL} from "../../enum/modal";
import SuccessOrErrorModal from "../../components/modal/success-or-error/success-or-error-modal";
import CropperModal from "../../components/modal/cropper/cropper-modal";
import {stringFormat} from "../../utils/string";
import {S3_PREFIX_NAME, S3_PRESIGNED_VALUES} from "../../enum/s3";
import {IGetPreSignedURL} from "../../interfaces/system";
import AppleStyleModal from "../../components/modal/apple-style/apple-style-modal";
import {checkFileSizeLimit} from "../../utils/media-files-size-limit";
import {FILE_ERRORS} from "../../enum/errors";
import {useUserProfileInfo} from "../../service/user/user";
import {
    getPreSignedUrl,
    resizedImage,
    uploadFileToS3,
} from "../../api/system";
import {updateUser} from "../../api/user";
import {useAuth} from "../../service/authentication/auth";
import {useUsersSuccessRate} from "../../service/user/successRate";
import {useUsersPopScore} from "../../service/user/popScore";
import {useEarnings} from "../../service/user/earnings";
import {useInitialRequest, useLazyRequest} from "../../service/common/request";
import {getAllPost} from "../../api/post";
import {POST_TYPE} from "../../enum/post";
import {countFollowers} from "../../api/follow";
import {useBase64} from "../../service/common/base64";
import {getPaidContentList} from "../../api/paid-content";
import ChuspLoader from "../../components/loader/ChuspLoader";
import { ROUTES } from "../../enum/routes";
import { useHasToken } from "../../service/authentication/has-token";

interface IProps {
    mode: typeof PROFILE_MODES.EDIT | typeof PROFILE_MODES.VIEW;
}

type IContext = {
    followersCountStateChange: boolean;
    setFollowersCountStateChange: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ProfileContext = createContext<IContext>({
    followersCountStateChange: false,
    setFollowersCountStateChange: () => {
    },
});

const Profile: React.FC<IProps> = ({mode}) => {
    const {setOpenUser} = useContext(OpenUser);
    const {isDesktop, isMobile, isTable} = useWindowSize()
    const {refreshUser} = useAuth();
    const params: { id: string } = useParams();
    const {data: decryptedUserId} = useBase64(params?.id)
    const inputRef = useRef<HTMLInputElement>(null);
    const location = useLocation();
    const history: any = useHistory();
    const myData: IUser = useSelector(
        (state: RootStateOrAny) => state.auth?.user?.user
    );
    const { jwtLoading, hasJwt } = useHasToken();

    const [followersCountStateChange, setFollowersCountStateChange] = useState<boolean>(false);
    const [
        assetsSizeDependingOnScreenSize,
        setAssetSizeDependingOnScreenSize,
    ] = useState<string>(ASSETS_SIZE.MEDIUM);
    const [image, setImage] = useState<string | ArrayBuffer | null>(null);
    const [imageName, setImageName] = useState<string>("");
    const [tempImageWhileS3Upload, setTempImageWhileS3Upload] = useState<string | null>(null);
    const [errorModalText, setErrorModalText] = useState<string>("");

    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
    const [openCropperModal, setOpenCropperModal] = useState<boolean>(false);
    const [openChoosePhotoModal, setOpenChoosePhotoModal] = useState<boolean>(
        false
    );
    const [cropper, setCropper] = useState<any>();

    const openLoginModalCallback = useCallback(
        (boolean: boolean) => setOpenLoginModal(boolean),
        [setOpenLoginModal]
    );
    const openErrorModalCallback = useCallback(
        (boolean: boolean) => setOpenErrorModal(boolean),
        [setOpenErrorModal]
    );
    const setErrorModalTextCallback = useCallback(
        (error: string) => setErrorModalText(error),
        [setErrorModalText]
    );

    const isMyProfile = mode === PROFILE_MODES.EDIT;

    const {data, loading} = useUserProfileInfo(
        +decryptedUserId || +params?.id,
        {asset_size: assetsSizeDependingOnScreenSize},
        !isMyProfile
    );

    const isMobileOrTablet = !!isMobile || isTable

    const {successRate} = useUsersSuccessRate(myData?.id, !!isMyProfile && !!isMobileOrTablet)
    const {totalScore, getStarsCounterByEnumValue} = useUsersPopScore(myData?.id, isMyProfile && !!isMobileOrTablet)
    const {earnings} = useEarnings(!!isMyProfile && !!isMobileOrTablet)


    // TODO replace that with hook
    // eslint-disable-next-line
    const [getPostData, {data: postsWithImages, loading: imagePostsLoading}] = useInitialRequest(getAllPost, {
        variables: {
            id: isMyProfile ? myData?.id : +decryptedUserId, asset_size: isMobile ? ASSETS_SIZE.SMALL : ASSETS_SIZE.MEDIUM, post_type: POST_TYPE.IMAGE
        },
        pagination: {page: 0, pageSize: 3}
    });
    // eslint-disable-next-line
    const [getTextPostData, {data: textPosts, loading: textPostsLoading}] = useInitialRequest(getAllPost, {
        variables: {
            id: isMyProfile ? myData?.id : +decryptedUserId, asset_size: ASSETS_SIZE.MEDIUM, post_type: POST_TYPE.TEXT
        },
        pagination: {page: 0, pageSize: 3}
    });

    // TODO replace this, rewrite !!!
    const [countOfFollowers, {
        data: hasCountOfFollowers,
        loading: loadingOfFollowers
    }] = useLazyRequest(countFollowers, {variables: FOLLOW_TYPE.FOLLOWERS});

    const [countOfAdmirors, {
        data: hasCountOfAdmirors,
        loading: loadingOfAdmirors
    }] = useLazyRequest(countFollowers, {variables: FOLLOW_TYPE.ADMIRORS});

    useEffect(() => {
        if (isMyProfile && !!isMobile && !!isTable) {
            countOfFollowers()
            countOfAdmirors()
        }
        // eslint-disable-next-line
    }, [isMyProfile, isMobile, isTable])

    const bioProps = {
        loadingOfAdmirors,
        loadingOfFollowers,
        hasCountOfFollowers,
        hasCountOfAdmirors
    }

    const samePropsForMobileHostingAndBioContainers = {
        successRate,
        totalScore,
        getStarsCounterByEnumValue,
        earnings
    }

    const isShowMore = location.search === CONTAINERS.SHOW_MORE.CONTAINER_SEARCH;
    const isAdmirors = location.search === CONTAINERS.ADMIRORS.CONTAINER_SEARCH;
    const isFollowers = location.search === CONTAINERS.FOLLOWERS.CONTAINER_SEARCH;
    const isBlacklist = location.search === CONTAINERS.BLACKLIST.CONTAINER_SEARCH;
    const isPost = location.search === CONTAINERS.POST.CONTAINER_SEARCH;

    const firstName = !!data?.first_name ? data?.first_name : "";
    const lastName = !!data?.last_name ? data?.last_name : "";
    const fullName = `${firstName} ${lastName}`;
    const userProfileTitle =
        firstName || lastName ? `Profile • ${fullName}` : "Profile";

    const [contentLength, setContentLength] = useState(null)
    const [contentLoading, setContentLoading] = useState<boolean>(false)

    useEffect(()=>{
        (async()=>{
           if(data?.id && !contentLength) {
                await setContentLoading(true)
                const resData = await getPaidContentList({
                    pagination: {
                        page: 0,
                        pageSize: 10
                    },
                    host_user_id: +data?.id
                })
                if (resData) {
                    setContentLength(resData?.items?.length)
                    await setContentLoading(false)
                }
            }
        })()
    },[data])

    useEffect(() => {
        return () => {
            setContentLength(null)
        };
    }, []);

    const showNavigationAndHeader =
        (isShowMore || isAdmirors || isFollowers || isPost || isBlacklist) &&
        !isDesktop;
    const showInfoBar =
        (isShowMore || isAdmirors || isFollowers || isBlacklist ) && !isDesktop;

    const prefixName = stringFormat([S3_PREFIX_NAME.USER_AVATAR], myData?.id);
    const s3UserAvatar: IGetPreSignedURL = {
        bucket_name: `${process.env.REACT_APP_PRIVATE_S3_BUCKET}`,
        prefix_name: prefixName[0],
        source: imageName,
        permission_type: S3_PRESIGNED_VALUES.PERMISSION_TYPE.PUT,
        content_type: "application/x-www-form-urlencoded",
    };

    const onChange = useCallback((e: any) => {
        e.preventDefault();
        const file = e.target.files && e.target.files[0];
        if (file) {
            if (file?.type?.includes("image")) {
                const isAllowedSize = checkFileSizeLimit(
                    file?.size,
                    MAX_IMAGE_SIZE_BYTES
                );
                if (!isAllowedSize) {
                    setErrorModalText(FILE_ERRORS.MAX_IMAGE_SIZE_ERROR_MESSAGE);
                    setOpenErrorModal(true);
                } else {
                    setImageName(file?.name);
                    let reader = new FileReader();
                    reader.onload = function () {
                        setImage(reader?.result);
                        setOpenCropperModal(true);
                    };
                    reader?.readAsDataURL(file);
                }
            } else {
                setErrorModalText(FILE_ERRORS.WRONG_FORMAT_ERROR_MESSAGE);
                setOpenErrorModal(true);
            }
        }
    }, []);

    const onComplete = async () => {
        const data = cropper.getCroppedCanvas().toDataURL();
        data && setTempImageWhileS3Upload(data);
        const response = await getPreSignedUrl(s3UserAvatar);
        if (response) {
            const s3BucketResponse = await uploadFileToS3(response.data, data);
            if(s3BucketResponse.status === false){
                setTempImageWhileS3Upload(null);
                setErrorModalText(s3BucketResponse.message)
                setOpenErrorModal(true)
            }else{
                const {bucket_name, prefix_name, source} = s3UserAvatar;
                const isResized = await resizedImage({
                    bucket_name,
                    prefix_name,
                    source,
                });
                if (isResized) {
                    const resizedImageUrl = `${process.env.REACT_APP_FULL_PUBLIC_S3_BUCKET_URL}/${prefix_name}/${source}`;
                    const copy = JSON.parse(JSON.stringify(myData));
                    copy.public_avatar_url = resizedImageUrl;
                    const updatedUserAvatarResponse = await updateUser(copy);
                    if (updatedUserAvatarResponse) {
                        await refreshUser();
                        setTempImageWhileS3Upload(null);
                    }
                }
            }
        }
    };

    const onCancel = async () => {
        setImage(null);
        setTempImageWhileS3Upload(null);
    };

    const myProfileTitle = () => {
        switch (location.search) {
            case CONTAINERS.HOSTING.CONTAINER_SEARCH:
                return "My profile • Hosting";
            case CONTAINERS.WALLET.CONTAINER_SEARCH:
                return "My profile • Wallet";
            case CONTAINERS.ADMIRORS.CONTAINER_SEARCH:
                return "My profile • Admirers";
            case CONTAINERS.FOLLOWERS.CONTAINER_SEARCH:
                return "My profile • Favourites";
            case CONTAINERS.SHOW_MORE.CONTAINER_SEARCH:
                return "My profile • Bio";
            case CONTAINERS.BLACKLIST.CONTAINER_SEARCH:
                return "My profile • BlackList";
            default:
                return "My profile";
        }
    };

    useTitle(isMyProfile ? myProfileTitle() : userProfileTitle);

    useEffect(() => {
        isDesktop
            ? setAssetSizeDependingOnScreenSize(ASSETS_SIZE.MEDIUM)
            : setAssetSizeDependingOnScreenSize(ASSETS_SIZE.SMALL);
    }, [isDesktop]);

    useEffect(() => {
        setOpenUser(isMyProfile ? myData : data);
        // eslint-disable-next-line
    }, [data, isMyProfile]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    const renderContainer = () => {
        switch (location.search) {
            case CONTAINERS.SHOW_MORE.CONTAINER_SEARCH:
                return <MoreInfo user={isMyProfile ? myData : data}/>;
            case CONTAINERS.HOSTING.CONTAINER_SEARCH:
                return <Hosting {...samePropsForMobileHostingAndBioContainers}/>;
            case CONTAINERS.WALLET.CONTAINER_SEARCH:
                return <Wallet earnings={earnings}/>;
            case CONTAINERS.FOLLOWERS.CONTAINER_SEARCH:
                return <FollowersContainer type={FOLLOW_TYPE.FOLLOWERS}/>;
            case CONTAINERS.ADMIRORS.CONTAINER_SEARCH:
                return <FollowersContainer type={FOLLOW_TYPE.ADMIRORS}/>;
            case CONTAINERS.BLACKLIST.CONTAINER_SEARCH:
                return <BlockedUserContainer/>;
            default:
                return (
                    <div className={"main-info"}>
                        <BioContainer
                            {...samePropsForMobileHostingAndBioContainers}
                            {...bioProps}
                            user={isMyProfile ? myData : data}
                            mode={mode}
                        />
                        {!isMyProfile && <AvailableDates user={data}/>}
                        <PostsContainer
                            imagePostsLoading={imagePostsLoading}
                            textPostsLoading={textPostsLoading}
                            postsWithImages={postsWithImages}
                            textPosts={textPosts}
                            userId={isMyProfile ? myData?.id : +decryptedUserId}
                            userfullName={renderName()}
                            userProfile={renderAvatar()}
                        />
                    </div>
                );
        }
    };

    const renderName = () => {
        const user: IUser = isMyProfile ? myData : data;
        return `${user?.first_name ? user?.first_name : ""} ${
            user?.last_name ? user?.last_name : ""
        }`;
    };

    const renderAvatar = () => {
        const user: IUser = isMyProfile ? myData : data;
        return user?.public_avatar_url;
    };

    const renderHeader = () => {
        switch (location.search) {
            case CONTAINERS.SHOW_MORE.CONTAINER_SEARCH:
                return (
                    <div className={"picture"}>
                        <Avatar style={{width: 32, height: 32}} src={renderAvatar()}/>
                        <p>{renderName()}</p>
                    </div>
                );
            case CONTAINERS.ADMIRORS.CONTAINER_SEARCH:
                return <span>Admirers</span>;
            case CONTAINERS.FOLLOWERS.CONTAINER_SEARCH:
                return <span>Followers</span>;
            case CONTAINERS.BLACKLIST.CONTAINER_SEARCH:
                return <span>Blacklist</span>;
        }
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <>
            <div className={"profile-container"}>
                <ProfileContext.Provider
                    value={{followersCountStateChange, setFollowersCountStateChange}}
                >
                    {hasJwt && !isMyProfile && (isDesktop || isShowMore) && (
                        <Btn
                            className={"go-back-button"}
                            clr={THEME.colors.secondaryblue}
                            onClick={() => history.goBack()}
                            content={"Back"}
                        />
                    )} 
                    <div className="sticky">
                        {hasJwt && showInfoBar && (
                            <InfoBar
                                leftElement={<BackButton goBack={goBack}/>}
                                children={renderHeader()}
                            />
                        )}
                        {!showNavigationAndHeader && (
                            <ProfileHeader
                                user={isMyProfile ? myData : data}
                                mode={mode}
                                loading={loading}
                                inputRef={inputRef}
                                tempImageWhileS3Upload={tempImageWhileS3Upload}
                                image={image}
                                setErrorModalTextCallback={setErrorModalTextCallback}
                                openErrorModalCallback={openErrorModalCallback}
                                openLoginModalCallback={openLoginModalCallback}
                                setOpenChoosePhotoModal={setOpenChoosePhotoModal}
                                onChange={onChange}
                            />
                        )}
                        {!showNavigationAndHeader && (
                            <div className="profile-navigation-container">
                                <Navigation
                                    mode={mode}
                                    contentLength={contentLength}
                                    user={isMyProfile ? myData : data}
                                    openLoginModal={openLoginModal}
                                    setOpenLoginModal={setOpenLoginModal}
                                />
                            </div>
                        )}
                    </div>
                    <div className={"container-wrapper"}>
                        <div className={"content-container"}>
                            <div className={"wrapper"}>
                                {renderContainer()}
                                {/*<SkeletonImage src={ChuspSkeleton}/>*/}
                            </div>
                        </div>
                    </div>
                </ProfileContext.Provider>
            </div>
            {openLoginModal && (
                <LogInModal open={openLoginModal} setOpen={setOpenLoginModal}/>
            )}
            {openErrorModal && (
                <SuccessOrErrorModal
                    type={SUCCESS_OR_ERROR_MODAL.ERROR}
                    textError={errorModalText}
                    open={openErrorModal}
                    setOpen={setOpenErrorModal}
                    onActionClicked={() => setOpenErrorModal(false)}
                />
            )}
            {openCropperModal && (
                <CropperModal
                    image={image}
                    setImage={setImage}
                    open={openCropperModal}
                    setOpen={setOpenCropperModal}
                    onComplete={onComplete}
                    onCancel={onCancel}
                    setCropper={setCropper}
                />
            )}
            <AppleStyleModal
                open={openChoosePhotoModal}
                setOpen={setOpenChoosePhotoModal}
                items={[
                    <span
                        className={"mobile-select"}
                        onClick={() => {
                            setOpenChoosePhotoModal(false);
                        }}
                    >
            <label htmlFor="file">Select from device</label>
            <input
                ref={inputRef}
                id="file"
                type="file"
                accept="image/png, image/jpg, image/jpeg, image/svg"
                onChange={onChange}
            />
          </span>,
                    <span
                        className={"mobile-select"}
                        onClick={() => {
                            setOpenChoosePhotoModal(false);
                        }}
                    >
            <label htmlFor="camera">Camera</label>
            <input
                ref={inputRef}
                id="camera"
                type="file"
                accept="image/png, image/jpg, image/jpeg, image/svg"
                capture={"camera"}
                onChange={onChange}
            />
          </span>,
                    <span
                        onClick={() => {
                            setOpenChoosePhotoModal(false);
                        }}
                    >
            Cancel
          </span>,
                ]}
            />
            {contentLoading && <ChuspLoader/>}
        </>
    );
};

export default Profile;
