import React from 'react'
import DetaisPageDesctop from '../../../components/details-page/details-page-desctop'
import creator_girl from "../../../assets/img/creator-girl.png";
import {options, header, buttonName} from '../../../enum/be-creator'
import {ROUTES} from '../../../enum/routes';
import DetaisPageMobile from '../../../components/details-page/details-page-mobile';

const BeCreator = () => {
    return (
        <div>
            <DetaisPageDesctop
                header={header}
                options={options}
                buttonName={buttonName}
                buttonHref={{first:ROUTES.BE_CLIENT, second: ROUTES.BE_HOST}}
                img={creator_girl}
            />
            <DetaisPageMobile
                header={header}
                options={options}
                buttonName={buttonName}
                buttonHref={{first:ROUTES.BE_CLIENT, second: ROUTES.BE_HOST}}
                img={creator_girl}
            />
        </div>
    )
}

export default BeCreator;
