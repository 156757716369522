import React, {useContext, useEffect, useState} from 'react'
import SuccessRateIcon from "../../../assets/icons/success-rate.svg"
import AdmirersIcons from "../../../assets/icons/admirers.svg"
import StarsIcon from '@material-ui/icons/Stars';
import {useLocation} from "react-use";
import {useWindowSize} from "../../../service/common/window";
import {FOLLOW_TYPE} from "../../../enum/follow";
import {countFollowers} from "../../../api/follow";
import {useLazyRequest} from "../../../service/common/request";
import {ProfileContext} from "../index";
import {CONTAINERS} from "../../../enum/containers";
import HoveredPopup from "../../../components/hovered-popup";
import StarsStatistics from "../../../components/stars-statistics";
import {IUser} from "../../../interfaces/user";
import {useUsersPopScore} from "../../../service/user/popScore";
import {useUsersSuccessRate} from "../../../service/user/successRate";
import {useEarnings} from "../../../service/user/earnings";
import {useHistory} from 'react-router-dom';


interface IProps {
    user: IUser,
    isMyProfile: boolean
}

const StatsContainer: React.FC<IProps> = ({user, isMyProfile}) => {
    const {followersCountStateChange} = useContext(ProfileContext)
    const history: any = useHistory()
    const {pathname} = useLocation()
    const {isDesktop} = useWindowSize()

    const [followerCount, setFollowerCount] = useState();
    const [admirorsCount, setAdmirorsCount] = useState();

    const renderAllowFetchDependingOnProfileType = () => {
        if (isMyProfile) {
            return !!isDesktop
        } else {
            return true
        }
    }

    const {getStarsCounterByEnumValue, totalScore} = useUsersPopScore(user?.id, renderAllowFetchDependingOnProfileType())
    const {successRate} = useUsersSuccessRate(user?.id, renderAllowFetchDependingOnProfileType())
    const {earnings} = useEarnings(isMyProfile && !!isDesktop)

    const [countOfFollowers, {
        data: hasCountOfFollowers,
        loading: loadingOfFollowers
    }] = useLazyRequest(countFollowers, {variables: FOLLOW_TYPE.FOLLOWERS});

    const [countOfAdmirors, {
        data: hasCountOfAdmirors,
        loading: loadingOfAdmirors
    }] = useLazyRequest(countFollowers, {variables: FOLLOW_TYPE.ADMIRORS});

    useEffect(() => {
        if (isMyProfile && !!isDesktop) {
            countOfFollowers()
            countOfAdmirors()
        }
        // eslint-disable-next-line
    }, [pathname, followersCountStateChange, isDesktop])

    useEffect(() => {
        if (!loadingOfFollowers) {
            setFollowerCount(hasCountOfFollowers?.data?.count)
        }
        // eslint-disable-next-line
    }, [loadingOfFollowers, followersCountStateChange])

    useEffect(() => {
        if (!loadingOfAdmirors) {
            setAdmirorsCount(hasCountOfAdmirors?.data?.count)
        }
        // eslint-disable-next-line
    }, [loadingOfAdmirors])


    const openAdmirors = () => {
        admirorsCount && history.push({
            search: CONTAINERS.ADMIRORS.CONTAINER_SEARCH
        })
    }

    const openFollowers = () => {
        followerCount && history.push({
            search: CONTAINERS.FOLLOWERS.CONTAINER_SEARCH
        })
    }

    return (
        <div className={"stats-container"}>
            {isMyProfile ? (
                //MY PROFILE
                <div className={"my-profile"}>
                    <div className={"my-earnings"}>
                        {Number.isNaN(earnings) ? <span>$0 Earnings</span> :
                            <span>${earnings} Earnings</span>}
                    </div>
                    <div className={"stats"}>
                        <span>{totalScore}</span>
                        <StarsIcon/>
                        <HoveredPopup style={{top: '50px', right: '-85px'}}
                                      children={
                                          <StarsStatistics
                                              getStarsCounterByEnumValue={getStarsCounterByEnumValue}
                                              totalScore={totalScore}
                                          />
                                      }
                        />
                    </div>
                    <div className={"success-rate"}>
                        <span>{successRate}% Success</span>
                    </div>
                    <div className={"followers-admirers"}>
                            <span onClick={openAdmirors}>
                                {admirorsCount} Admirers
                            </span>
                    </div>
                    <div className={"followers-admirers"}>
                            <span onClick={openFollowers}>
                                {followerCount} Favourites
                            </span>
                    </div>
                </div>
            ) : (
                //USER PROFILE
                <div className={"user-profile"}>
                    <div className={"stats"}>
                        <span>{totalScore}</span>
                        <StarsIcon className={"star-icon"}/>
                        <HoveredPopup
                            style={isDesktop ? {top: '50px', right: '-85px'} : {top: '40px', left: '-53px'}}
                            children={<StarsStatistics
                                getStarsCounterByEnumValue={getStarsCounterByEnumValue}
                                totalScore={totalScore}
                            />}
                        />
                    </div>
                    <div className={"success-rate"}>
                        <span>{successRate}%</span>
                        <img className={"success-rate-icon"} src={SuccessRateIcon} alt={"Chusp success rate"}/>
                    </div>
                    {/*TEMP WHEN DECIDE ON DESIGN*/}
                    <div className={"followers-admirers"} style={{marginLeft: 15}}>
                            <span>
                                {user?.admirorsCount}
                            </span>
                        <img className={"followers-admirers-icon"} src={AdmirersIcons} alt={"Chusp admirers icon"}/>
                    </div>
                </div>
            )}
        </div>
    )
}


export default StatsContainer
