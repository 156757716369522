import {createSlice} from '@reduxjs/toolkit';

import {
    VISITOR_MODE
} from '../types'

interface State {
    isVisitor: boolean
}
const defaultSlice = createSlice({
    name: VISITOR_MODE,
    initialState: {
        isVisitor: false
    },
    reducers: {
        enterAsAVisitor(state: State, action) {
            state.isVisitor = action.payload;
        },
        logoutFromVisitorMode(state: State) {
            state.isVisitor = false;
        }
    },
});

export const {
    enterAsAVisitor,
    logoutFromVisitorMode,
} = defaultSlice.actions;
export default defaultSlice.reducer;
