import {useCallback, useEffect, useMemo, useState} from "react";
import {ICommonNotifications} from "../../interfaces/notifications";
import {SOCKET_LISTENERS} from "../../enum/meeting";
import {Socket} from "socket.io-client";

export const useCommonNotifications = (socket: Socket | null, autoClose: boolean = true) => {
    const [commonNotifications, setCommonNotifications] = useState<ICommonNotifications[]>([])

    socket &&
    socket?.on(SOCKET_LISTENERS.NOTIFICATIONS, (data) => {
        const mySet: any = new Set([...commonNotifications, data])
        setCommonNotifications([...mySet]);
    });

    useEffect(() => {
        if (autoClose) {
            if (commonNotifications.length > 0) {
                setTimeout(() => {
                    setCommonNotifications([]);
                }, 8000);
            }
        }
        // eslint-disable-next-line
    }, [commonNotifications]);

    const closeNotification = useCallback((id: number) => {
        setCommonNotifications(commonNotifications.slice(0, 3).filter((item: any) => item.id !== id));
    }, [commonNotifications, setCommonNotifications])

    const resetNotifications = useCallback(() => {
        setCommonNotifications([])
    }, [setCommonNotifications])

    return {
        commonNotifications: useMemo(() => commonNotifications, [commonNotifications]),
        closeNotification,
        resetNotifications
    }
}