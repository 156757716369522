import * as Yup from "yup";
import {ERRORS} from "../../enum/errors";
import {patternEmail, patternPassword} from './validation'

export const SignupSchema = Yup.object().shape({
    name: Yup.string()
        .min(6, ERRORS.NAME.MIN_CHAR)
        .max(50, ERRORS.NAME.MAX_CHAR)
        .required(ERRORS.NAME.REQUIRED),
    email: Yup.string().required(ERRORS.EMAIL.REQUIRED).matches(patternEmail, ERRORS.EMAIL.INVALID),
    residence: Yup.string().required(ERRORS.RESIDENCE.REQUIRED),
    password: Yup.string().required(ERRORS.PASSWORD.REQUIRED).matches(patternPassword, ERRORS.PASSWORD.FORMAT),
    acceptTerms: Yup.bool().oneOf([true], ERRORS.ACCEPT_TERMS.REQUIRED)
});

export const SigninSchema = Yup.object().shape({
    email: Yup.string().required(ERRORS.EMAIL.REQUIRED).matches(patternEmail, ERRORS.EMAIL.INVALID),
    password: Yup.string().required(ERRORS.PASSWORD.REQUIRED).matches(patternPassword, ERRORS.PASSWORD.FORMAT),
});
