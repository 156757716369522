import React from 'react'

const particlesArray = Array.apply(null, Array(15));
const ChuspLoader = () => {
    return (
        <div className="fire-container">
            <div className="chusp-female"/>
            <div className="fire">
                <div className="fire-center">
                    <div className="main-fire"/>
                    <div className='chusp-logo'>C</div>
                    {particlesArray.map((e, i) =>
                         <div key={i} className={`particle particle-fire${i}`}/>)}
                </div>
            </div>
        </div>
    )
}

export default ChuspLoader
