/* eslint-disable */
import axios, {AxiosResponse} from "axios";

export const getCreditCard = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/finance/cc/get`,
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const getCreditCardById = (cardId: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/finance/cc/get/${cardId}`,
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const deleteCreditCard = (id: number) => {
    return axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/finance/cc/${id}`
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const updateDefaultCreditCard = (id: number) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/finance/cc/change/${id}`
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const addBillingAddress = (data: { address: string, address2: string, country: string, postal_code: string }) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/finance/cc/add/address`,
        data
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const addBillingAddressForStripe = (data: { customer_id: string, card_id: string, address_line1: string,postal_code: string, address_country: string}) => {
return axios({
     method: "POST",
     url: `${process.env.REACT_APP_API_URL}/finance/cc/add/customer/billing/address`,
     data
    }).then((response: AxiosResponse) => response && response.data)
    .catch((error) =>
        error &&
        error.response &&
        error.response.message
            ? {error: error.response.message}
            : {error: 'unknown error'},
    );
}
export const getBillingAddress = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/finance/cc/address/get`
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const removeBillingAddress = (id: string) => {
    return axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/finance/cc/delete/address/${id}`
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const setBillingAddress = (data: {billing_address_id:number, card_id: number}) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/finance/cc/set/address`,
        data
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
