import React from 'react'
import {useHistory} from 'react-router-dom'
import chusplogo from '../../assets/img/chusp.svg'
import mandarine from '../../assets/img/chinese-char.png'
import { ROUTES } from '../../enum/routes';


const HeaderWithMandarine = () => {
    const history = useHistory()
        return (
            <div className='header-with-mandarine-container' onClick={() => history.push(ROUTES.LOGIN)}>
                <img className='logo' src={chusplogo} alt='Chusp'/>
                <img className='logo-chinese' src={mandarine} alt='chinese'/>
            </div>
        )
    }

export default HeaderWithMandarine;
