import axios from 'axios'
import {IGetUserByIdRequestBody, ISearchUsers, IUpdateLoginSercurity, IUser} from "../interfaces/user"

export const searchUsers = (data: ISearchUsers) => {
    console.log(data, 'data from search users')
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/user/search`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const getUserById = (id:number, data: IGetUserByIdRequestBody) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/user/get/${id}`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const updateUser = (user: IUser) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/user`,
        data: {
            first_name: user.first_name?.trim(),
            last_name: user.last_name,
            gender: user.gender,
            dob: user.dob,
            short_bio: user.short_bio,
            hrf: user.hrf,
            custom_hosting_rate: user?.custom_hosting_rate,
            public_avatar_url: user.public_avatar_url,
            metadata: user.metadata
        }
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const updateLoginSercurity = (data: IUpdateLoginSercurity) => {
    return axios({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/user/system`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
