import axios, {AxiosResponse} from "axios";
import {IRateMeetingRequest} from "../interfaces/score";


export const getSuccessRateByUser = (userId: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/score/success/${userId}`,
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data);
}

export const getPopScoreByUser = (userId: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/score/pop/${userId}`,
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data);
}


export const rateUserWithStars = (data: IRateMeetingRequest) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/score/meeting`,
        data
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data);
}