import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PlayCircleIcon from '@material-ui/icons/PlayCircleFilled';
// import InfoIcon from '@material-ui/icons/Info';
import ForumIcon from '@material-ui/icons/Forum';
import SmsIcon from '@material-ui/icons/Sms';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import DescriptionIcon from '@material-ui/icons/Description';
import GavelIcon from '@material-ui/icons/Gavel';
import {ROUTES} from "./routes";
import { SEARCH_STEPS } from './steps';


type OPTIONS = {
    label: string,
    icon: any,
    route: string,
    search?: string
}

type SIDEBAR_DATA = {
    section: string,
    menuItems: OPTIONS[]
}

const LEFT_SIDEBAR_DATA: SIDEBAR_DATA[] = [
    {
        section: "Account",
        menuItems: [
            {
                label: "Login and Security",
                icon: <VpnKeyOutlinedIcon/>,
                route: ROUTES.MY_PAGE.SECURITY,
            },
            {
                label: "Verification Badge",
                icon: <CheckCircleOutlineIcon/>,
                route: ROUTES.MY_PAGE.VERIFICATION,
                search: SEARCH_STEPS.FIRST_STEP,
            },
            {
                label: "My Chusp Store",
                icon: <PlayCircleIcon/>,
                route: ROUTES.MY_PAGE.MY_CONTENT,
                search: SEARCH_STEPS.FIRST_STEP,
            }
        ]
    },
    {
        section: "Contact",
        menuItems: [
            // {
            //     label: "About",
            //     icon: <InfoIcon/>,
            //     route: ROUTES.ABOUT_US,
            // },
            {
                label: "Feedback",
                icon: <ForumIcon/>,
                route: ROUTES.FEEDBACK,
            },
            {
                label: "Support",
                icon: <SmsIcon/>,
                route: ROUTES.HELP_CENTER,
            },
            {
                label: "Complaint",
                icon: <SmsIcon/>,
                route: ROUTES.MY_PAGE.COMPLAINT_FORM,
                search: SEARCH_STEPS.FIRST_STEP,
            }
        ]
    },
    {
        section: "FAQ",
        menuItems: [
            {
                label: "Frequently Asked Questions",
                icon: <LiveHelpIcon />,
                route: ROUTES.FAQ,
            }
        ]
    },
    {
        section: "Terms",
        menuItems: [
            {
                label: "Terms",
                icon: <DescriptionIcon/>,
                route: ROUTES.TERMS,
            },
            {
                label: "Privacy Policy",
                icon: <GavelIcon/>,
                route: ROUTES.POLICY,
            }
        ]
    }
]


export default LEFT_SIDEBAR_DATA;