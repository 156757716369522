import React from "react"
import Btn from "../../../components/buttons/MainButton/Button"
import logoC from "../../../assets/img/logo-c.png"
import {THEME} from "../../../ui/theme";
import {useHistory} from "react-router"
import {ROUTES} from "../../../enum/routes";
import {useTitle} from "react-use";


const OopsPage = () => {
    const history: any = useHistory()
    const message = history?.location.state?.message

    useTitle("Oops")

    return (
        <div className={"oops-container"}>
            <div className={"absolute-container"}>
                <div className={"main-message"}>
                    <div className={"status-code"}>4</div>
                    <img className={"oops-logo"} src={logoC} alt={'Chusp oops'}/>
                    <div className={"status-code"}>4</div>
                </div>
                <h2 className={"sub-message"}>
                    {message ? message : "Oops, something went wrong..."}
                </h2>
                <Btn
                    className={"try-again-btn"}
                    clr={THEME.colors.white}
                    onClick={history.push(ROUTES.HOME)}
                    content={"Try again"}
                />
            </div>
        </div>
    )
}

export default OopsPage