import axios, {AxiosResponse} from 'axios'

export const uploadVideoFile = (data: FormData) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/video/upload`,
        data
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
};