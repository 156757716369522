import {useEffect, useState} from "react";
import {getAllPost} from "../../api/post";
import {IGetPostsRequestBody} from "../../interfaces/post";
import {useHistory} from "react-router";
import {ROUTES} from "../../enum/routes";
import { CONTAINERS } from "../../enum/containers";

export const useGetAllPosts = (body: IGetPostsRequestBody) => {
    const [data, setData] = useState<any>()
    const [loadData, setLoadData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response = body && await getAllPost(body)
            if (response) {
                setData(response)
                setLoading(false)
            }
        })()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response = await getAllPost(body)
            if (response) {
                setLoadData(response)
                setLoading(false)
            }
        })()
        // eslint-disable-next-line
    }, [body.pagination.page])

    return {
        data,
        loading,
        loadData
    }
}


export const useOpenPosts = () => {
    const history = useHistory()

    const openSinglePost = (userId: string | number, postId: string | number, userfullName: string, userProfile: string) => {
        // TODO remove userid to encrypted id (base64)
        history.push({
            pathname: `${ROUTES.USER_PAGE}/${userId}${ROUTES.USER_POST}`,
            state: {postId, userId, userfullName, userProfile}
        })
    }


    const openAllUsersPost = (userId: string | number) => {
        // TODO remove userid to encrypted id (base64)
        history.push({
            pathname: `${ROUTES.USER_PAGE}/${userId}${ROUTES.USER_POST}`,search: CONTAINERS.POST_SHOW_MORE.CONTAINER_SEARCH
        })
    }

    return {
        openAllUsersPost,
        openSinglePost
    }
}