import React, {CSSProperties, useEffect} from 'react'
import {useHistory} from 'react-router'
import {ROUTES} from "../../enum/routes";
import {useSearchParam} from "react-use";
import {THEME} from "../../ui/theme";

// QUICK INLINE PURE CSS STYLES (STYLED-COMPONENT NO NEED)
const STYLES_CONTAINER: CSSProperties = {
    position: "relative",
    width: "100%",
    height: "100vh",
    backgroundColor: `${THEME.colors.main} !important`,
    overflow: "hidden",
    zIndex: 100,
}

const WeChatRedirectToAuth = () => {
    const history = useHistory();
    const code = useSearchParam('code');

    useEffect(() => {
        code ? history.push({pathname: ROUTES.LOGIN, state: {code}}) : history.push(ROUTES.LOGIN)
        // eslint-disable-next-line
    }, [code]);

    return (
        <div style={STYLES_CONTAINER}/>
    )
};
export default WeChatRedirectToAuth
