import React, {useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {ROUTES} from "../../../../enum/routes";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Btn from "../../../../components/buttons/MainButton/Button";
import TouchableOpacity from "../../../../components/touchable-opacity";
import TextPost from './text-post';
import TextWithDecoration from '../../../../components/text-with-decoration/TextWithDecoration';
import {useOpenPosts} from "../../../../service/user/post";

interface IProps {
    userId: string | number,
    postsWithImages: any,
    textPosts: any,
    imagePostsLoading: boolean,
    textPostsLoading: boolean,
    userfullName: string,
    userProfile:string
}

const PostsContainer: React.FC<IProps> = ({userId, textPosts, postsWithImages, textPostsLoading, imagePostsLoading, userfullName, userProfile}) => {
    const {openSinglePost, openAllUsersPost} = useOpenPosts()
    const {pathname} = useLocation();
    const isMyProfilePage = pathname.includes(ROUTES.MY_PAGE.PROFILE)
    const [loadedImg, setLoadedImg] = useState<boolean>(false)
    const renderShowPostsTitle = () => {
        if (!isMyProfilePage) {
            return !!postsWithImages?.items?.length || !!textPosts?.items?.length
        } else {
            return true
        }
    }

    return (
        <div className={"image-posts-container"}>
            {!imagePostsLoading && !textPostsLoading && renderShowPostsTitle() && <TextWithDecoration top={15} bottom={15} content={'Posts'} />}
            <div className={"image-posts"}>
                {!!postsWithImages?.items?.length && postsWithImages?.items?.length > 0 ? postsWithImages?.items?.map((item: any, index: number) => (
                    <div className={"post"} key={index}>
                            {item.post_assets.map((img: any, index: number) => (
                                <TouchableOpacity id={img.id} onClick={() => {
                                    openSinglePost(userId, item?.id, userfullName, userProfile)
                                }}>
                                   <img
                                        className={`${loadedImg ? "" : "blurred"}`}
                                        key={index}
                                        src={img.public_url}
                                        alt={'Chusp post'}
                                        onLoad={() => setLoadedImg(true)}
                                        />
                                </TouchableOpacity>
                            ))}
                        </div>
                    ))
                    :""
                            }
                    {(isMyProfilePage &&
                        <Link to={ROUTES.MY_PAGE.CREATE_POST}>
                            <Btn
                                className={"add-posts-button"}
                                content={
                                    <div>
                                        <AddCircleOutlineIcon/> <p>Add post</p>
                                    </div>
                                }
                            />
                        </Link>)}
                {/* // } */}
            </div>
            {!!textPosts?.items?.length && (
                <>
                    {textPosts?.items?.map((text: any) => (
                        <TouchableOpacity id={text?.id}>
                            <TextPost text={text} openSinglePost={openSinglePost}/>
                        </TouchableOpacity>

                    ))}
                </>
            )}
            {postsWithImages?.items?.length < postsWithImages?.totalCount &&
            <div className={"more-posts"} onClick={() => openAllUsersPost(userId)}>More</div>}
        </div>
    )
}

export default PostsContainer;
