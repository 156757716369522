import { useState } from "react";
import { useDispatch } from "react-redux";
import { getVariables } from "../../api/variable";
import { setVariablesAction } from "../../redux/reducers/variable";
export const useVariable = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  return {
    error,
    loading,
    getVariables: async () => {
      setLoading(true);
      const response = await getVariables();
      if (response && response.message) {
        setError(response.message);
      } else {
        if (response && response.items) {
          const items = response.items;
          dispatch(setVariablesAction(items));
        } else {
          setError("User doesn't exist.");
        }
      }
      setLoading(false);
    },
  };
};
