import React from 'react'
import Modal from "../main-modal/modal";
import {SUCCESS_OR_ERROR_MODAL} from "../../../enum/modal";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import Btn from "../../buttons/MainButton/Button";
import {useHistory} from "react-router";
import {ROUTES} from "../../../enum/routes";
import {CALENDAR_ERRORS, COMMON_ERRORS} from "../../../enum/errors";

interface IProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>
    type: typeof SUCCESS_OR_ERROR_MODAL.SUCCESS | typeof SUCCESS_OR_ERROR_MODAL.ERROR,
    textSucess?: string,
    textError?: any,
    actionButton?: string,
    onActionClicked?: () => void,
    disableClose?: boolean
}

const SuccessOrErrorModal: React.FC<IProps> = (
    {
        open,
        setOpen,
        type,
        textSucess,
        textError,
        actionButton,
        onActionClicked,
        disableClose
    }) => {
    const history: any = useHistory();

    const actionClicked = () => {
        onActionClicked ? onActionClicked() : history.push(ROUTES.USER_LIST)
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            disableClose={disableClose}
            children={
                <div className='success-or-error-container '>
                    {type === SUCCESS_OR_ERROR_MODAL.SUCCESS ?
                        <div className='modal-window'>
                            <div className='modal-flex-container'>
                                <div className='icon-container'>
                                    <CheckCircleIcon className='styled-success-icon'/>
                                </div>
                                <div className='info-container'>Success!</div>
                                {textSucess && <div className='text'>{textSucess}</div>}
                                <Btn className='success-btn' onClick={actionClicked} content={'OK'} />
                            </div>
                        </div>
                        :
                        <div className='modal-window'>
                            <div className={textError === CALENDAR_ERRORS.CREATE_SLOT_NOT_ALLOWED ? 'modal-flex-container-create-host' : 'modal-flex-container'}>
                                {textError !== CALENDAR_ERRORS.CREATE_SLOT_NOT_ALLOWED &&
                                    <div className='icon-container'>
                                        <CancelIcon className='styled-error-icon'/>
                                    </div>}
                                {textError !== CALENDAR_ERRORS.CREATE_SLOT_NOT_ALLOWED && <div className='info-container'></div>}
                                <div className={textError === CALENDAR_ERRORS.CREATE_SLOT_NOT_ALLOWED ? 'create-host-popup-error-text' : 'text'}>{textError === CALENDAR_ERRORS.CREATE_SLOT_NOT_ALLOWED ?
                                <>To create hosting slots, you need to have a Chusp Host profile.<br/><br/> Click <span className='create-host-profile-hyperlink' onClick={actionClicked}>here</span> to create your Chusp Host profile to attract lucrative bookings. <br/><br/></>
                                : textError || COMMON_ERRORS.SERVER_ERROR}</div>
                                {textError !== CALENDAR_ERRORS.CREATE_SLOT_NOT_ALLOWED &&
                                    <Btn
                                    className='error-btn'
                                    onClick={actionClicked}
                                    background={'transparent'}
                                    content={actionButton ? actionButton : "Try again"}
                                    />
                                }
                            </div>
                        </div>}
                    {textError === CALENDAR_ERRORS.CREATE_SLOT_NOT_ALLOWED &&
                        <div className='close-modal-back-button-container'>
                            <Btn
                                className='close-modal-back-button'
                                onClick={() => setOpen(false)}
                                background={'transparent'}
                                content={"Back"}
                            />
                        </div>
                    }
                </div>
            }
        />)
}

export default SuccessOrErrorModal;
