export const ROUTES = {

    OOPS: '/404',

    //COMMON
    HOME: '/',

    //AUTH ROUTES

    ROOT_AUTH: '/auth',
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    AUTH_EMAIL_ADD: '/auth/email/confirm',
    PASSWORD_RECOVERY: '/auth/password/recovery',

    //PUBLIC ROUTES
    ROOT_PUBLIC: '/p',
    ABOUT_US: '/p/about',
    USER_LIST: '/p/users',
    USER_PAGE: '/p/users/user',
    POLICY: '/p/policy',
    TERMS: '/p/terms',
    USER_POST: '/posts',
    BE_CLIENT: '/p/be-client',
    BE_HOST: '/p/be-host',
    BE_CREATOR: '/p/be-creator',
    FAQ: '/p/faq',

    //PRIVATE ROUTES
    USER_BOOKING: '/app/user/booking',
    ROOT_PRIVATE: '/app',
    MY_PAGE: {
        PROFILE: '/app/me',
        EDIT: '/app/me/edit',
        CREATE_POST: '/app/me/create_post',
        EDIT_POST: '/app/me/edit_post',
        MEETING_MODE: '/app/me/meeting_mode',
        SYSTEM_MEETING_MODE: '/app/me/system_meeting_mode',
        REFER_EARN: '/app/me/refer_earn',
        SCHEDULER: '/app/me/schedule',
        HOSTING: '/app/me/hosting',
        VERIFICATION: '/app/me/hosting/verification',
        BOOKING: '/app/me/booking',
        ADD_CREDITS: '/app/me/credits',
        CARDS: '/app/me/cards',
        SECURITY: '/app/me/security',
        PAYOUT_SETTINGS: '/app/me/payout_settings',
        PAYOUT_HISTORY: '/app/me/payout_history',
        EARNINGS_ACCOUNT: '/app/me/earnings',
        CREDIT_ACCOUNT: '/app/me/credit',
        COMPLAINT_FORM: '/app/me/complaint_form',
        COMPLAINT_RESPOND: '/app/me/complaint_respond',
        RATES: '/app/me/rates',
        NOTIFICATIONS: '/app/me/notifications',
        PAID_CONTENT: '/app/me/paid-content',
        CREATE_PAID_CONTENT: '/app/me/paid-content/create',
        EDIT_PAID_CONTENT: '/app/me/paid-content/edit',
        BUY_PAID_CONTENT: '/app/me/paid-content/:id/buy',
        PAID_CONTENT_PAYMENT_COMPLETION: '/app/me/paid-content/completion',
        MY_CONTENT: '/app/me/my-content',
        VIDEO_PLAYLIST: '/app/me/content/:id/preview',
        ENTER_CARD_DETAILS: '/app/me/cards/details',
        ADD_BILLING_ADDRESS: '/app/me/cards/billings',
        SELECT_BILLING_ADDRESS: '/app/me/cards/select-billings'
    },
    FEEDBACK: '/app/me/feedback',
    HELP_CENTER: '/app/me/help_center',
}


// Paid content preview route
export const VIDEO_CONTENT_PREVIEW = {
    PREFIX : '/app/me/content/',
    SUFFIX : '/preview'
}
