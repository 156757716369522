export const step_search = '?step='

export const STEPS = {
    FIRST_STEP: '1',
    SECOND_STEP: '2',
    THIRD_STEP: '3',
    FOURTH_STEP: '4',
    FIFTH_STEP: '5',
}

export const SEARCH_STEPS = {
    FIRST_STEP: `${step_search}1`,
    SECOND_STEP: `${step_search}2`,
    THIRD_STEP: `${step_search}3`,
    FOURTH_STEP: `${step_search}4`,
    FIFTH_STEP: `${step_search}5`,
}