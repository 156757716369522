import React, {useState} from 'react'
import chuspLogo from "../../../assets/img/chusp.svg";
import Btn from "../../../components/buttons/MainButton/Button";
import {THEME} from "../../../ui/theme";
import {ROUTES} from "../../../enum/routes";
import {Link} from "react-router-dom";
import {InputLabel} from "@material-ui/core";
import {useHistory} from "react-router";
import {patternPassword} from "../../../config/form-validation/validation";
import {passwordReset} from "../../../api/auth";
import SuccessOrErrorModal from "../../../components/modal/success-or-error/success-or-error-modal";
import {SUCCESS_OR_ERROR_MODAL} from "../../../enum/modal";

const PasswordRecoveryPage: React.FC = () => {
    const [passwordInput, setPasswordInput] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [errorMessage, setErrorMessage] = useState<string>("")
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const history: any = useHistory()
    const recoveryToken = history?.location?.state?.token

    const validPass = patternPassword.test(passwordInput)

    const resetPassword = async () => {
        if (recoveryToken && confirmPassword === passwordInput) {
            setShowError(false)
            const response = await passwordReset({
                token: recoveryToken,
                password: passwordInput
            })
            if (response && response.message) {
                setErrorMessage(response.message)
                setOpenErrorModal(true)
            }
            if (response && response?.data) {
                const updated = response?.data?.isExpected
                updated && history.push({pathname: ROUTES.LOGIN, state: {updatedPassword: updated}})
            }
        } else {
            setShowError(true)
        }
    }


    return (
        <div className='password-container'>
            {openErrorModal && (
                <SuccessOrErrorModal
                    open={openErrorModal}
                    setOpen={setOpenErrorModal}
                    type={SUCCESS_OR_ERROR_MODAL.ERROR}
                    textError={errorMessage}
                    onActionClicked={() => setOpenErrorModal(false)}
                />
            )}
            <img className='logo' src={chuspLogo} alt='Chusp logo' onClick={() => history.push(ROUTES.LOGIN)}/>
            <div className='form-container'>
                <InputLabel color='secondary'>Please enter your new password</InputLabel>
                <input
                    onClick={() => setShowError(false)}
                    className='styled-input'
                    value={passwordInput}
                    onChange={(e) => setPasswordInput(e.target.value)}
                    type="password"
                    placeholder="New Password"
                />
                <InputLabel style={{marginTop: 15}} color='secondary'>Confirm password</InputLabel>
                <input
                    onClick={() => setShowError(false)}
                    className='styled-input'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                    placeholder="Confirm password"
                />
                {showError && <span className={"not-match-err"}>Password doesn't match</span>}
                <Btn
                    disabled={!validPass || confirmPassword?.length === 0}
                    background={!validPass || confirmPassword?.length === 0 ? THEME.colors.menugrey : THEME.colors.main}
                    style={{border: !validPass || confirmPassword?.length === 0 ? "2px solid transparent" : "2px solid #ffffff"}}
                    clr={THEME.colors.white}
                    onClick={resetPassword}
                    className="auth-button"
                    content={'Reset Password'}
                />
                <p className='text-with-link'>Already have an account?<Link to={ROUTES.LOGIN}> Sign
                    in</Link></p>
            </div>
        </div>
    )
}

export default PasswordRecoveryPage
