/* eslint-disable */
import React, {useEffect} from "react";
import {PROFILE_MODES} from "../../../../enum/profile-mods";
import Btn from "../../../../components/buttons/MainButton/Button";
import {useLocation} from "react-router";
import {ROUTES} from "../../../../enum/routes";
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import {useWindowSize} from "../../../../service/common/window";
import {IUser} from "../../../../interfaces/user";
import {RootStateOrAny, useSelector} from "react-redux";
import {useFollowUser} from "../../../../service/user/follow";

const FOLLOW_ACTION_TYPE = {
    FOLLOW: "FOLLOW",
    UNFOLLOW: "UNFOLLOW"
}

type IFollowAction = typeof FOLLOW_ACTION_TYPE.FOLLOW | typeof FOLLOW_ACTION_TYPE.UNFOLLOW

interface IFollowMark {
    user: IUser;
    mode?: typeof PROFILE_MODES.VIEW | typeof PROFILE_MODES.EDIT;
    openLoginModalCallback: (boolean: boolean) => void;
    openErrorModalCallback: (boolean: boolean) => void;
    setErrorModalTextCallback: (error: string) => void;
    setModifiedUser?: React.Dispatch<React.SetStateAction<IUser>>
}

const FollowMark: React.FC<IFollowMark> = (
    {
        user,
        mode,
        openLoginModalCallback,
        openErrorModalCallback,
        setErrorModalTextCallback,
        setModifiedUser
    }) => {
    const {isDesktop} = useWindowSize()
    const myData: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)

    const {followUser, stopFollowingUser, followingStatus, followLoader, unfollowError, followError} = useFollowUser(+user?.id, mode)

    const followAction = async (e: React.MouseEvent<HTMLButtonElement | SVGSVGElement>, type: IFollowAction) => {
        const error = type === FOLLOW_ACTION_TYPE.FOLLOW ? followError : unfollowError
        const admirorsCountChange = type === FOLLOW_ACTION_TYPE.FOLLOW ? 1 : -1
        e.stopPropagation();
        type === FOLLOW_ACTION_TYPE.FOLLOW ? await followUser() : await stopFollowingUser()
        if (!error) {
            setModifiedUser && setModifiedUser(((prevState: IUser) => {
                const copy: IUser = JSON.parse(JSON.stringify(prevState))
                copy.admirorsCount = copy.admirorsCount + admirorsCountChange
                return copy;
            }))
        }
    }

    useEffect(() => {
        if (followError && !myData) {
            openLoginModalCallback(true)
        } else if (followError && myData) {
            openErrorModalCallback(true)
            setErrorModalTextCallback(followError)
        } else if (unfollowError && myData) {
            openErrorModalCallback(true)
            setErrorModalTextCallback(unfollowError)
        }
        // eslint-disable-next-line
    }, [followError, myData, openLoginModalCallback])

    const {pathname} = useLocation()
    const isUserList = pathname === ROUTES.USER_LIST

    return (
        <div className={"follow-mark-container"}>
            <div className={"follow-btns"}>
                {mode !== PROFILE_MODES.EDIT && (
                    <div
                        className={`follow-icon ${isUserList ? "is-userlist-page" : ""}`}
                        style={{opacity: followLoader ? 0 : 1}}
                    >
                        {followingStatus ?
                            <>
                                {isUserList ? <BookmarkIcon onClick={(e) => followAction(e, FOLLOW_ACTION_TYPE.UNFOLLOW)}/> :
                                    <Btn className={"follow-btn"} onClick={(e: any) => followAction(e, FOLLOW_ACTION_TYPE.UNFOLLOW)} content={'Unfollow'}/>}

                            </> :
                            <>
                                {isUserList ? <BookmarkBorderIcon onClick={(e) => followAction(e, FOLLOW_ACTION_TYPE.FOLLOW)}/> :
                                    <Btn className={"follow-btn"} onClick={(e: any) => followAction(e, FOLLOW_ACTION_TYPE.FOLLOW)} content={'Follow'}/>}
                            </>
                        }
                    </div>
                )}
            </div>
        </div>
    )
}

export default FollowMark;