import axios from "axios";
import { IGetNotifications, IRemoveNotifications } from "../interfaces/notifications";

export const getNotifications = (data: IGetNotifications) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/notification/get`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error && error.response && error.response.message
                ? { error: error.response.message }
                : { error: "unknown error" }
        );
};

export const removeNotifications = (data: IRemoveNotifications) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/notification/read`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error && error.response && error.response.message
                ? { error: error.response.message }
                : { error: "unknown error" }
        );
};
