/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
// import visa from '../../../../../../assets/img/Visa.jpg'
// import mastercard from '../../../../../../assets/img/Mastercard.jpg'
import { useHistory } from "react-router";
import creditCardType from "credit-card-type";
import moment from "moment";
import {
    deleteCreditCard,
    getBillingAddress,
    getCreditCardById,
    setBillingAddress
} from "../../../../../../api/payment";
import { RootStateOrAny, useSelector } from "react-redux";
import { ROUTES } from "../../../../../../enum/routes";
import { LIST_OF_COUNTRIES } from "../../../../../../enum/list-of-countries";
import Modal from "../../../../../../components/modal/main-modal/modal";
// import { CARDS } from "../../../../../../enum/cards";
import PaymentForm from '../../../StripePayment';
import axios from 'axios';
import SuccessOrErrorModal from '../../../../../../components/modal/success-or-error/success-or-error-modal';
import { SUCCESS_OR_ERROR_MODAL } from '../../../../../../enum/modal';


interface IProps {
    cards: any
    setCards: React.Dispatch<React.SetStateAction<any>>,
}

export const findCountryName = (countryCode: string) => {
    const foundCountry = LIST_OF_COUNTRIES.find((country: any) => country.code === countryCode)
    return foundCountry?.name
}

// const renderCardType = (type: string) => {
//     switch (type) {
//         case CARDS.VISA:
//             return visa
//         case CARDS.MASTERCARD:
//             return mastercard
//         default:
//             return
//     }
// }

const EnterCardDetails: React.FC<IProps> = () => {
    // const My2c2p = window?.My2c2p
    const history: any = useHistory()
    // const { isDesktop } = useWindowSize()
    const user = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const userName = `${user?.first_name ? user?.first_name : ""} ${user?.last_name ? user?.last_name : ""}`;
    // eslint-disable-next-line
    const [loading, setLoading] = useState(false)
    const selectedAddress = history.location.state?.address
    const cardId = history.location.state?.cardId
    const creditCardsFromLS = localStorage.getItem('credit_cards')
    const creditCardIdFromLocalStorage = localStorage.getItem('creditCardId')
    const creditCard = localStorage.getItem('creditCard')
    const creditCardFromLocalStorage = creditCard && JSON.parse(creditCard)
    const cards = creditCardsFromLS && JSON.parse(creditCardsFromLS)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    // CARD DETAILS
    const [cardNumber, setCardNumber] = useState<string>('')
    const [expireMonth, setExpireMonth] = useState<string>('')
    const [expireYear, setExpireYear] = useState<string>('')
    const [cvv, setCvv] = useState<string>('')
    const [cardIdFromLS, setCardIdFromLS] = useState<string>(cardId)

    const [billingAddressId, setBillingAddressId] = useState<any>()
    const [billingAddresses, setBillingAddresses] = useState<any>()
    // CARD ERRORS
    const [cardNumberTextError, setCardNumberTextError] = useState<string>('')
    const [monthTextError, setMonthTextError] = useState<string>('')
    const [yearTextError, setYearTextError] = useState<string>('')
    const [cvvTextError, setCvvTextError] = useState<string>('')
    // REFS
    const expireMonthRef = useRef<HTMLInputElement>(null)
    const expireYearRef = useRef<HTMLInputElement>(null)
    const cvvRef = useRef<HTMLInputElement>(null)
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);

    // if (data?.loading) {
    //     return <ChuspLoader/>
    // }
    // CREDIT CARD VALIDATIONS
    const cardMatch = creditCardType(cardNumber)
    const cardType = cardMatch[0]?.type?.toUpperCase()
    const codeName = cardMatch[0]?.code?.name
    const codeSize = cardMatch[0]?.code?.size || 3
    const isCardNumberValid = cardNumber?.length === 16 && !!cardMatch?.length
    const dateFormat = moment(moment().format('YYYY-MM'))
    const isDateValid = (expireMonth?.length && expireYear?.length) ? dateFormat.isSameOrBefore(`${expireYear}-${expireMonth}`) : true
    const isYearValid = moment().isSameOrBefore(expireYear, 'year')
    const isMonthValid = (+expireMonth >= 1 && +expireMonth <= 12)
    const isCvvValid = cvv.length === codeSize
    const isInputsError =
        !isCardNumberValid ||
        !isMonthValid ||
        !isYearValid ||
        !isCvvValid
    // SAVE CREDIT CARD
    const [token, setToken] = useState('');
    const onSubmit = () => {
        const card = {
            card_number: cardNumber
        }
        const isExistingCreditCard = cards?.some((element: any) => {
            const newCardFirstSixDigits = card.card_number.slice(0, 6)
            const newCardLastFourDigits = card.card_number.slice(-4)

            const firstSixDigits = element.card_number.slice(0, 6)
            const lastFourDigits = element.card_number.slice(-4)

            return newCardFirstSixDigits === firstSixDigits && newCardLastFourDigits === lastFourDigits
        });

        // if (!isInputsError && (!isExistingCreditCard || cardIdFromLS)) {
        //     // My2c2p.onSubmitForm("2c2p-payment-form", (errCode: any, errDesc: any) => {
        //     //     if (errCode != 0) {
        //     //         setCardNumberTextError(errDesc.charAt(0).toUpperCase() + errDesc.slice(1));
        //     //     } else {
        //     //         setLoading(true)
        //     //         setTimeout(async () => {
        //     //             const cards = await getCreditCard()
        //     //             if (cards) {
        //     //                 const mappedCards = cards.items.map((savedCard: any) => {
        //     //                     return {
        //     //                         id: savedCard?.id,
        //     //                         type: savedCard?.type,
        //     //                         card_number: savedCard?.card_number,
        //     //                         default_card: savedCard.default_card
        //     //                     }
        //     //                 })
        //     //                 localStorage.setItem("credit_cards", JSON.stringify([...mappedCards]))
        //     //                 localStorage.setItem("creditCardId", mappedCards[mappedCards?.length - 1]?.id)
        //     //                 setCardNumber('')
        //     //                 setExpireMonth('')
        //     //                 setExpireYear('')
        //     //                 setCvv('')
        //     //                 localStorage.removeItem('creditCard')
        //     //                     if (!billingAddressId && billingAddresses?.length === 0) {
        //     //                         history.push(ROUTES.MY_PAGE.ADD_BILLING_ADDRESS)
        //     //                         localStorage.setItem('creditCard', JSON.stringify({
        //     //                             card_number: cardNumber,
        //     //                             expire_date: `${expireMonth}/${expireYear}`
        //     //                         }))
        //     //                     } else if (!billingAddressId && billingAddresses?.length > 0) {
        //     //                         history.push(ROUTES.MY_PAGE.SELECT_BILLING_ADDRESS)
        //     //                         localStorage.setItem('creditCard', JSON.stringify({
        //     //                             card_number: cardNumber,
        //     //                             expire_date: `${expireMonth}/${expireYear}`
        //     //                         }))
        //     //                     } else {
        //     //                         history.push(ROUTES.MY_PAGE.CARDS)
        //     //                     }
        //     //             }
        //     //             setLoading(false)
        //     //         }, 3000)
        //     //     }
        //     // })
        // } else if (isExistingCreditCard && !cardIdFromLS) {
        //     setCardNumberTextError('This card already exist')
        // } else {
        //     !isCardNumberValid && setCardNumberTextError('Please enter a valid credit card number')
        //     !isMonthValid && setMonthTextError('Month not valid')
        //     !isYearValid && setYearTextError('Year not valid')
        //     !isCvvValid && setCvvTextError(`${codeName} not valid`)
        // }
    }
    useEffect(() => {
        (!isMonthValid && expireMonth?.length !== 0) ? setMonthTextError('Month not valid') : setMonthTextError('')
    }, [expireMonth])
    useEffect(() => {
        (!isCardNumberValid && cardNumber?.length !== 0) ? setCardNumberTextError('Please enter a valid credit card number') : setCardNumberTextError('')
    }, [cardNumber])

    useEffect(() => {
        (!isYearValid && expireYear?.length !== 0) ? setYearTextError('Year not valid') : setYearTextError('')
    }, [expireYear])

    // REMOVE CREDIT CARD
    const deleteCard = async (id: number) => {
        if (id) {
            await deleteCreditCard(id)
            await history.push(ROUTES.MY_PAGE.CARDS)
        }
    }
    // GETTING DATA FROM LS && BILLING ADDRESS FROM API

    useEffect(() => {
        (async () => {
            const response = await getBillingAddress()
            // @ts-ignore
            if (creditCardFromLocalStorage || creditCardIdFromLocalStorage) {
                creditCardIdFromLocalStorage && setCardIdFromLS(creditCardIdFromLocalStorage)
                setCardNumber(creditCardFromLocalStorage?.card_number)
                const expireDateSpliced = creditCardFromLocalStorage?.expire_date?.split('/')
                if (expireDateSpliced) {
                    setExpireMonth(expireDateSpliced[0])
                    setExpireYear(expireDateSpliced[1])
                }
            }
            if (response) {
                setBillingAddresses(response?.items)
            }
        })()
    }, [])
    // AFTER SELECTING BILLING ADDRESS
    useEffect(() => {
        (async () => {
            if (selectedAddress && creditCardIdFromLocalStorage) {
                setBillingAddressId(selectedAddress?.id)
                await setBillingAddress({
                    billing_address_id: selectedAddress?.id,
                    card_id: creditCardIdFromLocalStorage ? +creditCardIdFromLocalStorage : 0
                })
            }
        })()
    }, [selectedAddress])
    // FIND ADDRESS CONNECTED TO CREDIT CARD
    const matchedBillingAddress = billingAddresses?.find((address: any) => address?.id === billingAddressId)
    // GET CREDIT CARD DATA FROM API
    useEffect(() => {
        (async () => {
            setLoading(true)
            if (cardIdFromLS) {
                const creditCard = await getCreditCardById(+cardIdFromLS)

                if (creditCard) {
                    setCardNumber(creditCard?.card_number)
                    !selectedAddress && setBillingAddressId(creditCard?.billing_address_id)
                    localStorage.setItem('creditCard', JSON.stringify(creditCard))
                    const expireDateSpliced = creditCard?.expire_date?.split('/')
                    if (expireDateSpliced) {
                        setExpireMonth(expireDateSpliced[0])
                        setExpireYear(expireDateSpliced[1])
                    }
                }
            }
            setLoading(false)
        })()
    }, [cardIdFromLS])
    //SELECT BILLING ADDRESS
    // const onSelect = (type: string) => {
    //     type === ROUTES.MY_PAGE.SELECT_BILLING_ADDRESS
    //         ? history.push({ pathname: ROUTES.MY_PAGE.SELECT_BILLING_ADDRESS, state: { billingAddressId } })
    //         : history.push(ROUTES.MY_PAGE.ADD_BILLING_ADDRESS)

    //     localStorage.setItem('creditCard', JSON.stringify({
    //         card_number: cardNumber,
    //         expire_date: `${expireMonth}/${expireYear}`
    //     }))
    // }
    const setTokenData = async (token: string) => {
        setToken(token)
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/finance/cc/add/customer`,
            data: {
                token: token
            }
        }).then((res: any) => {
            const CustomerRes: any = JSON.parse(res.config.data);
            setOpenSuccessModal(true);
            history.push({
                pathname: `${!!billingAddresses?.length ? ROUTES.MY_PAGE.SELECT_BILLING_ADDRESS : ROUTES.MY_PAGE.ADD_BILLING_ADDRESS}`,
                // pathname: `${ROUTES.MY_PAGE.ADD_BILLING_ADDRESS}`,
                state: {
                    cardId: res.data.cardData.id,
                    customerId: res.data.customer,
                    stripeCardId: res.data.cardData.stripe_card_id
                }
            })
        }).catch((error) => {
            error.message && setOpenErrorModal(true)
        })
    }
    return (
        <><div className={`enter-card-details-container`}>
            <div className='content'>
                <iframe name="hideReddirect" id="hideReddirect" style={{ display: "none" }}></iframe>
                <PaymentForm getToken={setTokenData} />
                {openSuccessModal && <SuccessOrErrorModal
                    type={SUCCESS_OR_ERROR_MODAL.SUCCESS}
                    textSucess={"Your card has been saved successfully."}
                    open={openSuccessModal}
                    setOpen={setOpenSuccessModal}
                    onActionClicked={() => { setOpenSuccessModal(false); history.goBack(); }} />
                }
                {/* <form id="2c2p-payment-form"
                    //   action={cardIdFromLS ? `${process.env.REACT_APP_API_URL}/finance/cc/edit` : `${process.env.REACT_APP_API_URL}/finance/cc/add`}
                      method="POST"
                      target="hideReddirect"
                >
                    {loading && <ChuspSpinner isGlobal={true}/>}
                    <div className={`card-wrapper ${cardNumberTextError && 'error'}`}>
                    <div className={`card-input`}>
                        {cardMatch && cardType && !!cardNumber && cardIdFromLS &&
                        <img src={renderCardType(cardType)} alt={cardType}/>}
                        <InputMask
                            mask="9999999999999999"
                            // @ts-ignore:next-line
                            maskChar={cardId ? 'X' : ''}
                            alwaysShowMask={false}
                            data-encrypt="cardnumber"
                            value={cardNumber}
                            placeholder={'1234 5678 9101 1213'}
                            onChange={(e) => {
                                setCardNumber(e.target.value)
                                cardNumberTextError && setCardNumberTextError('')
                            }}>
                            {(inputProps: any) => <input {...inputProps} type="text" id="user" autoFocus required/>}
                        </InputMask>
                        <label htmlFor="user">Card number</label>
                    </div>
                    <span className='error'>{cardNumberTextError}</span>
                    </div>
                    <div className='additional-info'>
                        <div className='expiry-date'>
                            <div className={`input-wrapper`}>
                                <InputMask
                                    mask="99"
                                    // @ts-ignore:next-line
                                    maskChar={''}
                                    value={expireMonth}
                                    data-encrypt="month"
                                    placeholder={'MM'}
                                    onBlur={() => {
                                        (+expireMonth < 10) && expireMonth.length === 1 && expireMonth && setExpireMonth('0' + expireMonth)
                                        expireMonth === '0' && setExpireMonth('')
                                    }}
                                    onChange={(e) => {
                                        setExpireMonth(e.target.value)
                                        monthTextError && setMonthTextError('')
                                    }}>
                                    {(inputProps: any) => <input {...inputProps} type="text" id="user"
                                                                 ref={expireMonthRef}
                                                                 required/>}
                                </InputMask>
                                <label htmlFor="user">Expiry Month</label>
                                <span
                                    className='error'>{!isMonthValid ? monthTextError : !isDateValid ? 'Expiry date not valid' : ''}</span>
                            </div>
                            <div className={`input-wrapper`}>
                                <InputMask
                                    mask="9999"
                                    // @ts-ignore:next-line
                                    maskChar={''}
                                    value={expireYear}
                                    data-encrypt="year"
                                    placeholder={'YYYY'}
                                    onChange={(e) => {
                                        setExpireYear(e.target.value)
                                        yearTextError && setYearTextError('')
                                    }}>
                                    {(inputProps: any) => <input {...inputProps} type="text" id="user"
                                                                 ref={expireYearRef}
                                                                 required/>}
                                </InputMask>
                                <label htmlFor="user">Expiry Year</label>
                                <span className='error'>{!isYearValid && yearTextError}</span>
                            </div>
                        </div>
                        <div className='CVV-input'>
                            <InputMask
                                mask={"9".repeat(codeSize ? codeSize : 3)}
                                // @ts-ignore:next-line
                                maskChar={''}
                                value={cvv}
                                data-encrypt={codeName ? codeName.toLowerCase() : 'cvv'}
                                placeholder={codeName ? codeName : "CVV"}
                                onChange={(e) => {
                                    setCvv(e.target.value)
                                    cvvTextError && setCvvTextError('')
                                }}>
                                {(inputProps: any) => <input {...inputProps} type="text" id="user" ref={cvvRef}
                                                             required/>}
                            </InputMask>
                            <InfoIcon/>
                            <HoveredPopup
                                style={{
                                    left: isDesktop ? 290 : 180,
                                    top: !isDesktop && 50,
                                }}
                                triangleStyle={
                                    isDesktop ?
                                        {
                                            top: 60,
                                            left: -15,
                                            transform: 'rotate(360deg)'
                                        } :
                                        {
                                            right: 22
                                        }
                                }
                                children={(
                                    <>
                                        <h3>The 3 digits on the back of your card.</h3>
                                        <div className='card-popup'>
                                            <div className='grey-stripe'/>
                                            <div className='CVV-backside'>
                                                <div className='white-stripe'>
                                                    <span>{"1".repeat(codeSize ? codeSize : 3)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}/>
                            <label htmlFor="user">Security code</label>
                            <span className='error'>{cvvTextError}</span>
                        </div>
                    </div>
                    {!cardIdFromLS && <div className='footer'>
                        <p>Chusp accepts the following credit cards:</p>
                        <div>
                            <img src={visa} alt={'visa'}/>
                            <img src={mastercard} alt={'visa'}/>
                        </div>
                    </div>}
                    <input type="hidden" name="user" value={JSON.stringify(user)}/>
                    <input type="hidden" name="cardType" value={cardType}/>
                    <input type="hidden" name="billingAddressId"
                           value={matchedBillingAddress?.id ? matchedBillingAddress?.id : null}/>
                    {cardIdFromLS && <input type="hidden" name="creditCardId" value={cardIdFromLS}/>}
                    {matchedBillingAddress && <div className='change-billing'>
                                <div className="billing-details-wrapper">
                                    <div className="billing-details">{matchedBillingAddress?.address}</div>
                                    <div
                                        className="billing-details">{matchedBillingAddress?.address2 && matchedBillingAddress.address2}</div>
                                    <div className="billing-details">Postal/Zip
                                        Code: {matchedBillingAddress?.postalCode}</div>
                                    <div
                                        className="billing-details">{findCountryName(matchedBillingAddress?.country)}</div>
                                </div>
                                <div className="changeBtn-wrapper"
                                     onClick={() => onSelect(ROUTES.MY_PAGE.SELECT_BILLING_ADDRESS)}>
                                    <button>Change billing address</button>
                                </div>
                            </div>}
                    {cardIdFromLS && <div className='add-billing'
                                          onClick={() => setOpenDeleteModal(true)}>Remove from Wallet</div>}
                    <Btn
                        type="submit"
                        className={`saveButton ${cardIdFromLS && 'edit'}`}
                        clr={THEME.colors.white}
                        background={THEME.colors.secondaryblue}
                        content={'Save Card'}
                        disabled={isInputsError}
                        onClick={onSubmit}
                    />
                </form> */}
                <Modal
                    disableClose={true}
                    open={openDeleteModal}
                    setOpen={setOpenDeleteModal}
                    rightButton={"Yes"}
                    leftButton={"No"}
                    onComplete={() => deleteCard(+cardIdFromLS)}
                    title={'Confirm Removal'}
                    children={
                        <>
                            <div className='userName'>{userName}</div>
                            <div className='billingAddress'>{creditCardFromLocalStorage?.card_number}</div>
                            <div className='billingAddress'>Expiry date: {creditCardFromLocalStorage?.expire_date}</div>
                            <div className='billingAddress'>Type: {creditCardFromLocalStorage?.type}</div>
                        </>}
                />
            </div>
        </div>
            <SuccessOrErrorModal
                open={openErrorModal}
                setOpen={setOpenErrorModal}
                type={SUCCESS_OR_ERROR_MODAL.ERROR}
                textError={'Failed to save the card. Card already Exist.'}
                onActionClicked={() => setOpenErrorModal(false)}
            // disableClose={true}
            />
        </>
    )
}

export default EnterCardDetails
