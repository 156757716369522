import React, { useEffect, useState } from "react";
import SignInForm from "../../components/form/desktop/signin";
import { useGoogleLogin } from "@react-oauth/google";
// import FacebookLogin from "react-facebook-login";
import { useAuth } from "../../../../service/authentication/auth";
import { useHistory } from "react-router";
import chuspLogo from "../../../../assets/img/chusp.svg";
import mandarine from "../../../../assets/img/chinese-char.png";
import googleIcon from "../../../../assets/img/icons/gmail.svg";
import wechatIcon from "../../../../assets/img/icons/wechat-logo.svg";
// import fbIcon from "../../../../assets/img/icons/facebookLogo.png";
import { ROUTES } from "../../../../enum/routes";
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';
import {
  // facebookCheckIfAccountExist,
  // facebookLoginRequest,
  // facebookSignUpWithEmailRequest,
  googleLoginRequest,
  weChatCheck,
  weChatLogin,
} from "../../../../api/auth";
import ChuspLoader from "../../../../components/loader/ChuspLoader";
import { Link } from "react-router-dom";
import { useUsersIPLocation } from "../../../../service/IP";
import SuccessOrErrorModal from "../../../../components/modal/success-or-error/success-or-error-modal";
import { SUCCESS_OR_ERROR_MODAL } from "../../../../enum/modal";
import Modal from "../../../../components/modal/main-modal/modal";
import YoutubeSlideInButton from "../../../../components/youtube-slide-in-button";

const SignInContainer: React.FC = () => {
  const history: any = useHistory();
  const { doAuthWithSocials, socialLoading, error } = useAuth();
  const { countryIp } = useUsersIPLocation();
  // eslint-disable-next-line 
  const [facebookCheckLoading, setFacebookCheckLoading] =
    useState<boolean>(false);
  const [weChatCheckLoading, setWeChatCheckLoading] = useState<boolean>(false);
  const [openUserBlockedModalWindow, setOpenUserBlockedModalWindow] =
    useState<boolean>(false);
  const [authCheckErrorMessage, setAuthCheckErrorMessage] =
    useState<string>("");
  const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  const code = history?.location?.state?.code;

  useEffect(() => {
    if (error) {
      setOpenUserBlockedModalWindow(true);
    }
  }, [error]);

  useEffect(() => {
    (async () => {
      if (code) {
        setWeChatCheckLoading(true);
        const response = await weChatCheck(code);
        if (response && response?.message) {
          setOpenUserBlockedModalWindow(true);
          setAuthCheckErrorMessage(response?.message);
        }
        response && setWeChatCheckLoading(false);
        if (response && response.data) {
          const { access_token, openid, emailExist } = response.data;
          !emailExist
            ? history.push({
                pathname: ROUTES.AUTH_EMAIL_ADD,
                state: { access_token, openid },
              })
            : await doAuthWithSocials(weChatLogin, { access_token, openid });
        }
      }
    })();
    // eslint-disable-next-line
  }, [code]);

  const googleRequest = useGoogleLogin({
    onSuccess: async (codeResponse) =>
      codeResponse &&
      codeResponse.code &&
      (await doAuthWithSocials(googleLoginRequest, {
        code: codeResponse.code,
        residence_country: countryIp,
      })),
    flow: "auth-code",
  });

  // const loginWithFacebook = async (response: any) => {
  //   if (response.accessToken) {
  //     setFacebookCheckLoading(true);
  //     const data = await facebookCheckIfAccountExist(response?.accessToken);
  //     data && setFacebookCheckLoading(false);
  //     if (data && data?.message) {
  //       setOpenUserBlockedModalWindow(true);
  //       setAuthCheckErrorMessage(data?.message);
  //     }
  //     const alreadyExist = data?.data?.existInDB;
  //     const facebookWithEmail = data?.data?.hasEmail;
  //     alreadyExist
  //       ? await doAuthWithSocials(facebookLoginRequest, {
  //           token: response?.accessToken,
  //         })
  //       : facebookWithEmail
  //       ? await doAuthWithSocials(facebookSignUpWithEmailRequest, {
  //           token: response?.accessToken,
  //           residence_country: countryIp,
  //         })
  //       : history.push({
  //           pathname: ROUTES.AUTH_EMAIL_ADD,
  //           state: { token: response.accessToken },
  //         });
  //   }
  // };
  const weChatUrl = process.env.REACT_APP_WE_CHAT_LOGIN_URL || "";
  const loginWechatRes = () => {
    window.open(weChatUrl, "_self");
  };

  return (
    <div className="signin-container">
      {(facebookCheckLoading || socialLoading || weChatCheckLoading) && (
        <ChuspLoader />
      )}
      <img className="signin-logo" src={chuspLogo} alt="Chusp" />
      {/* <div className='chusp-text-container'>
                <span className='chusp-text'>CHUSP</span>
            </div> */}
            <div className="mandarine-logo">
            <img
        className={'h-100 w-100'}
        // style={{ height: 80, zIndex: 10 }}
        src={mandarine}
        alt="Chusp logo"
      />
            </div>
     
      <div className="form-container">
        <SignInForm />
        <div className="socials">
          {/* <FacebookLogin
            appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
            autoLoad={false}
            textButton={""}
            fields="name,email,picture"
            callback={loginWithFacebook}
            icon={
              <img
                className="face-book-icon"
                src={fbIcon}
                alt={"Chusp Facebook"}
              />
            }
          /> */}
          <img
            className="google-icon"
            src={googleIcon}
            onClick={googleRequest}
            alt="Chusp Gmail"
          />
          <button className="weChat-login-button" onClick={loginWechatRes}>
            <img alt="Chusp WeChat" className="weChat-logo" src={wechatIcon} />
          </button>
        </div>
        <div className="privacy-container">
          <div className="privacy">
          By proceeding, you accept our{" "}
            <a
              href={`${process.env.REACT_APP_UI_URL}/p/terms`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms
            </a>{" "}
            and{" "}
            <a
              href={`${process.env.REACT_APP_UI_URL}/p/policy`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </div>
        </div>

      </div>
      <div className="footer-menu">        <p className="learn-more">
          <Link to={ROUTES.BE_HOST} >
            About
          </Link>
          <FiberManualRecordSharpIcon/>
          <Link to={ROUTES.FAQ} >
            FAQs
          </Link>
          <FiberManualRecordSharpIcon/>
          <a href="mailto:support@chusp.com" >
            Contact
          </a>
        </p></div>
      <YoutubeSlideInButton onClick={() => setOpenVideoModal(true)} />
      {openVideoModal && (
        <Modal
          open={openVideoModal}
          width={900}
          setOpen={setOpenVideoModal}
          leftButton={"Close"}
          children={
            <iframe
              style={{
                width: "100%",
                filter: iframeLoaded ? "none" : "blur(20px)",
                transition: iframeLoaded ? "filter 0.3s ease-out" : "none",
              }}
              onLoad={() => setIframeLoaded(true)}
              className="youtube-video"
              src="https://s3.ap-southeast-1.amazonaws.com/assets.prod-chusp.com/public/CHUSP+Hear+We+Come.mp4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          }
        />
      )}
      {openUserBlockedModalWindow && (
        <SuccessOrErrorModal
          open={openUserBlockedModalWindow}
          setOpen={setOpenUserBlockedModalWindow}
          type={SUCCESS_OR_ERROR_MODAL.ERROR}
          textError={error ? error : authCheckErrorMessage}
          onActionClicked={() => setOpenUserBlockedModalWindow(false)}
        />
      )}
    </div>
  );
};

export default SignInContainer;
