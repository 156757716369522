import React, {useContext, useMemo, useState} from "react";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {Navigation} from "swiper/core";
import Btn from "../../../../components/buttons/MainButton/Button";
import {IUser} from "../../../../interfaces/user";
import {IconButton} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {ROUTES} from "../../../../enum/routes";
import {useHistory, useLocation} from "react-router";
import {SEARCH_STEPS} from "../../../../enum/steps";
import {BookingUser} from "../../../../pages/App";

import {useUserAvailableDates} from "../../../../service/user/user-available-dates";
import TextWithDecoration from "../../../../components/text-with-decoration/TextWithDecoration";
import LogInModal from "../../../../components/modal/login-modal/login-modal";
import { RootStateOrAny, useSelector } from "react-redux";

SwiperCore.use([Navigation]);

interface IProps {
    user: IUser;
}

let slidesToShow = 4;

const AvailableDates: React.FC<IProps> = ({user}) => {
    const history = useHistory();
    const location = useLocation()
    const {pathname} = useLocation();
    const myData: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const [styledPrev, setStyledPrev] = useState<boolean>(false);
    const [dateCounter, setDateCounter] = useState(8);
    const isMyProfilePage = pathname.includes(ROUTES.MY_PAGE.PROFILE)
    const [hideNextArrow, setHideNextArrow] = useState<boolean>(false)
    const [openLoginModal, setOpenLoginModal] = useState<boolean>(false)

    const {availableDates, disableRequest} = useUserAvailableDates(dateCounter, user?.id)
    // localStorage.setItem("availableSlotsArrForProfile", availableDates?.length)
    const renderAvailableDates = useMemo(() => {
        // const defaultTimezone = moment.tz.guess();
        let datesArr: any[] = [];
        // let datesStrings: string[] = [];
        availableDates?.forEach((date: any) => {
            // console.log('defaultTimezone', date?.date?.FormattedTimeStart.split('T')[0])
            // const momentToCorrectTimeZone = getDateInCorrectTimeZoneByConvertingUtcDate(date?.date?.timeStart, defaultTimezone)
            // const formattedDate = moment(momentToCorrectTimeZone).tz(defaultTimezone).format('ddd DD MMM')
            // if (!datesStrings.includes(formattedDate)) {
            //     datesStrings.push(formattedDate);
            //     datesArr.push({formattedDate, dateInTimezone: momentToCorrectTimeZone})
            // }
            datesArr.push({timeStart: date?.date?.timeStart, FormattedTimeStart: date?.date?.FormattedTimeStart.split('T')[0]})
        });
        return datesArr;
    }, [availableDates])

    const {setBookingUser} = useContext(BookingUser);
    return (
        <div className={`available-dates-container ${user && !!user?.metadata?.length ? "has-user-data" : ""}`}
            style={{padding: !!availableDates?.length ? "14px 0" : "0"}}
        >
            {openLoginModal && <LogInModal open={openLoginModal} setOpen={setOpenLoginModal}/>}
            {
                availableDates.length > 0 &&
                <TextWithDecoration className={"available-dates"} top={15} bottom={15} content={'Next Available Date'} />
            }
            <div style={{display: styledPrev ? "block" : "none"}} className={"available-icon-prev"}>
                <IconButton>
                    <ArrowBackIosIcon/>
                </IconButton>
            </div>
            <div className={"slider-container"}>
                <Swiper slidesPerView={slidesToShow}
                        slidesPerGroup={slidesToShow}
                        navigation={{
                            nextEl: ".available-icon-next",
                            prevEl: ".available-icon-prev"
                        }}
                        freeMode={true}
                        spaceBetween={0}
                        onSlideChange={(e) => {
                            e.realIndex ? setStyledPrev(true) : setStyledPrev(false)
                            setHideNextArrow(e.realIndex === availableDates?.length - slidesToShow && disableRequest)
                        }}
                        className="Swiper">
                    {renderAvailableDates.map((date: any, index: number) => {
                            return (
                                <SwiperSlide key={index} className="SwiperSlide">                                    
                                <Btn
                                        className={"date-btn"}
                                        onClick={() => {
                                            if (!isMyProfilePage && myData) {
                                                sessionStorage.setItem("bookingStartPath",location.pathname)
                                                history.push({
                                                    pathname: ROUTES.USER_BOOKING,
                                                    search: SEARCH_STEPS.FIRST_STEP,
                                                    state: {
                                                        // date: date.dateInTimezone,
                                                        date: date.FormattedTimeStart,
                                                        // date: moment(date.formattedDate).format('YYYY-MM-DD'),
                                                        user: user,
                                                        origin: location.pathname
                                                    }
                                                });
                                                setBookingUser(user)
                                            } else {
                                                setOpenLoginModal(true)
                                            }
                                        }}
                                        content={date.timeStart}
                                    />
                                </SwiperSlide>
                            )
                        }
                    )}
                </Swiper>
            </div>
            <div
                style={{display: availableDates.length > slidesToShow && !hideNextArrow ? 'block' : 'none'}}
                className="available-icon-next">
                <IconButton onClick={() => !disableRequest && setDateCounter(dateCounter + slidesToShow)}>
                    <ArrowForwardIosIcon/>
                </IconButton>
            </div>
        </div>
    );
};

export default AvailableDates;


