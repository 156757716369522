// ScrollContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

interface ScrollContextProps {
    cardId: number | null;
    setCardId: (id: number) => void;
}

const ScrollContext = createContext<ScrollContextProps | undefined>(undefined);

export const ScrollProvider = ({ children }: { children: ReactNode }) => {
    const [cardId, setCardId] = useState<number | null>(null);
  return (
    <ScrollContext.Provider value={{ cardId, setCardId }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = (): ScrollContextProps => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};
