import axios, {AxiosResponse} from 'axios'
import {
    IAuthSocialsRequestBody,
    IGoogleAuthRequestBody,
    ILoginWithSocialsRequestBody,
    ISigninRequest,
    ISignupRequest, IUniqueNameCheck, IWeChatAuthRequestBody
} from '../interfaces/auth';

export const signinRequest = (credentials: ISigninRequest) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/auth/signin`,
        data: credentials
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data);
}

export const signupRequest = (credentials: ISignupRequest) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/auth/signup`,
        data: credentials
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data);
}

export const uniqueNameCheck = (first_name: IUniqueNameCheck) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/auth/signup/name_check`,
        data: first_name
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data);
}


export const googleLoginRequest = (body: IGoogleAuthRequestBody) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/auth/google`,
        data: body
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data)
}

export const facebookLoginRequest = (body: ILoginWithSocialsRequestBody) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/auth/facebook`,
        data: body
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data)
}

export const facebookSignUpWithEmailRequest = (body: IAuthSocialsRequestBody) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/auth/signup/facebook`,
        data: body
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data)
}

export const facebookCheckIfAccountExist = (token: string) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/auth/facebook/check`,
        data: {token}
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data)
}

export const authRequest = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/auth`
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data)
}

export const passwordRecovery = (email: string) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/user/password/recovery`,
        data: {email}
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) => error.response?.data)
}

export const passwordReset = (body: {token: string, password: string}) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/user/password/change`,
        data: body
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) => error.response?.data)
}

export const confirmRegistration = (body: ILoginWithSocialsRequestBody) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/auth/email/confirm`,
        data: body
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) => error.response?.data)
}

export const weChatCheck = (code: string) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/auth/wechat/check`,
        data: {code}
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) => error.response?.data)
}

export const weChatLogin = (body: IWeChatAuthRequestBody) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/auth/wechat`,
        data: body
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) => error.response?.data)
}

export const weChatSignUp = (body: IWeChatAuthRequestBody) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/auth/signup/wechat`,
        data: body
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) => error.response?.data)
}
