import {useEffect, useRef} from "react";
import {io, Socket} from "socket.io-client";
import {IUser} from "../../interfaces/user";
import {RootStateOrAny, useSelector} from "react-redux";
import {AUTH_KEY} from "../../enum/auth";

export const useSocketInstance = () => {
    const user: IUser = useSelector((state: RootStateOrAny) => state.auth.user?.user)
    const cookieToken = document.cookie.split("; ").reduce((r, v) => {
        const parts = v.split("=");
        return parts[0] === AUTH_KEY ? decodeURIComponent(parts[1]) : r;
    }, "");
    const socket = useRef<Socket | null>(null)

    useEffect(() => {
        if (user && cookieToken) {
            socket.current = io(`${process.env.REACT_APP_SOCKET_CONNECTION_URL}`, {
                transports: ["websocket"],
                query: {token: cookieToken?.split(" ")[1]},
                rejectUnauthorized: true,
                secure: true,
                reconnection: true,
                upgrade: false
            });
        }
    }, [user, cookieToken])


    return {
        socket: socket.current
    }
}