import React from 'react'
import {THEME} from "../../../ui/theme";
import Btn from '../MainButton/Button';

interface IProps {
    goBack: () => void
}

const BackButton: React.FC<IProps> = ({goBack}) => {
    return (
        <div className={`back-button-container`}>
            <Btn  onClick={goBack} clr={THEME.colors.secondaryblue} background={'transparent'} content={'Back'} />
        </div>
    )
}

export default BackButton