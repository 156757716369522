import Button from '@material-ui/core/Button';
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../enum/routes';
import HeaderWithMandarine from '../header/header-with-mandarine';
import star from '../../assets/img/star.svg'

interface IProps {
  header: string,
  options: any,
  buttonName: { first: string, second: string },
  buttonHref: { first: string, second: string },
  img: string
}

const DetaisPageMobile: React.FC<IProps> = ({header, options, buttonName, buttonHref, img}) => {
    const history = useHistory()

    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

        return (
            <div className='details-page-mobile-container'>
                <HeaderWithMandarine />
                <div className='picture'>
                  <img src={img} alt={'Chusp model'}/>
                </div>
                <div className='description-container'>
                    <h1>{header}</h1>
                      {options.map((el: any, index: number) => (
                        <div key={index}>
                            {el.description}
                            <ul>
                              {el?.options?.map((option: any, optionIndex: number) => (
                                <li key={optionIndex}><img alt='Chusp bullet points' src={star}/>{option}</li>
                              ))}
                            </ul>
                        </div>
                      ))}
                </div>
                <div className='button-container'>
                <Button className='styled-button' variant="outlined" onClick={() => history.push(buttonHref.first)}>
                    {buttonName.first}
                </Button>
                <Button className='styled-button' variant="outlined" onClick={() => history.push(buttonHref.second)}>
                    {buttonName.second}
                </Button>
                </div>
                <div className='go-back' onClick={() => history.push(ROUTES.HOME)}>
                    Sign In
                </div>
                <div className='privacy'>
                  By continuing, you agree with our <a href={`${process.env.REACT_APP_UI_URL}/p/terms`} target="_blank" rel="noopener noreferrer">Terms</a> and <a href={`${process.env.REACT_APP_UI_URL}/p/policy`} target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
                </div>
            </div>
        )
    }

export default DetaisPageMobile;