import React from 'react'

interface IProps {
    text: string,
    backgroundColor?: string,
    onClick?: () => void
}

const ReactQuillPreviewCard: React.FC<IProps> =
    ({
         text,
         onClick
     }) => {

        return (
            <div onClick={onClick} className="quillwrapper react-quill-preview-card" dangerouslySetInnerHTML={{__html: text}}/>
        )
    }

export default ReactQuillPreviewCard;
