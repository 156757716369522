export const VIEWS = {
    ONE_DAY: 'timeGridDay',
    THREE_DAYS: 'timeGridThreeDay',
    SEVEN_DAYS: 'timeGridWeek',
    MONTH: 'dayGridMonth',
}

export const THREE_DAYS_CONFIGURATION = {
    timeGridThreeDay: {
        type: 'timeGrid',
        duration: {days: 3},
        buttonText: '3 day'
    },
}

