import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { getUserById, searchUsers } from "../../api/user";
import { userMetaTypes } from "../../enum/user";
import { IGetUserByIdRequestBody, ISearchUsers } from "../../interfaces/user";
import { getMetaGlobal } from "../../utils/getMetaGlobal";
import { ROUTES } from "../../enum/routes";
import { useHistory } from "react-router-dom";
import { useBase64 } from "../common/base64";
import { useLocation } from "react-router-dom";

export const useOpenUserProfile = () => {
    const history = useHistory()
    const { encryptToBase64 } = useBase64()

    const openUserProfile = (userId: string | number) => {
        const encryptedUserId = encryptToBase64(userId);
        history.push({ pathname: `${ROUTES.USER_PAGE}/${encryptedUserId}` })
    }

    return {
        openUserProfile
    }
}

export const useUserProfileInfo = (userId: number, body: IGetUserByIdRequestBody, shouldFetch: boolean = true) => {
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            if (!!userId && shouldFetch) {
                setLoading(true)
                const response = await getUserById(userId, body)
                if (response) {
                    setData(response?.data)
                    setLoading(false)
                }
            }
        })()

        return () => setData(undefined)
        // eslint-disable-next-line
    }, [userId, shouldFetch])


    return {
        data,
        loading
    }
}

export const useSearchUser = (body: ISearchUsers) => {
    // eslint-disable-next-line
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const getData = async () => {
        setLoading(true)
        const response = await searchUsers(body)
        if (response) {
            setData(response)
            setLoading(false)
        }
    }
    useEffect(() => {
        getData();
        return () => setData(undefined)
        // eslint-disable-next-line 
    }, [body?.search]);

    return {
        data,
        getData,
        loading
    }
}

const usePreviousPath = () => {
    const location = useLocation();
    const prevPath = useRef(location.pathname);

    useEffect(() => {
        const currentPath = location.pathname;
        prevPath.current = currentPath;
    }, [location]);

    return prevPath.current;
};

export default usePreviousPath;

export const useUserBio = (user: any) => {
    const [languageInfo, setLanguageInfo] = useState<{ items: string[] }>()
    const [locationInfo, setLocationInfo] = useState<string>('')
    const [workInfo, setWorkInfo] = useState<any>()
    const [educationInfo, setEducationInfo] = useState<any[]>()
    const [websiteInfo, setWebsiteInfo] = useState<{ items: any[] }>()

    useEffect(() => {
        setLanguageInfo(getMetaGlobal(user?.metadata, userMetaTypes.LANGUAGE))
        setLocationInfo(getMetaGlobal(user?.metadata, userMetaTypes.LOCATION))
        setWorkInfo(getMetaGlobal(user?.metadata, userMetaTypes.WORK)?.items?.sort((a: any, b: any) => moment.utc(b.startDate).diff(moment.utc(a.startDate))))
        setEducationInfo(getMetaGlobal(user?.metadata, userMetaTypes.COLLEGE)?.items?.sort((a: any, b: any) => b.graduationDate - a.graduationDate))
        setWebsiteInfo(getMetaGlobal(user?.metadata, userMetaTypes.WEBSITES))
        // eslint-disable-next-line
    }, [user?.metadata])

    return {
        languageInfo,
        locationInfo,
        workInfo,
        educationInfo,
        websiteInfo
    }
}
