import {MAX_SIZE_IN_MB} from "./asset-size";

export const COMMON_ERRORS = {
    SERVER_ERROR: "Something went wrong. Try again later"
}

export const ERRORS = {
    NAME: {
      REQUIRED: 'This field is required',
      MIN_CHAR: 'This name is too short',
      MAX_CHAR: 'Too long',
      TIP: 'Use your common name will make your profile more searchable and credible'
    },
    EMAIL: {
      REQUIRED: 'This field is required',
      INVALID: 'Invalid email',
    },
    RESIDENCE: {
      REQUIRED: 'This field is required'
    },
    PASSWORD: {
        REQUIRED: 'This field is required',
        FORMAT: 'Password must contain at least 6 characters'
    },
    CONFIRM_PASSWORD: {
        REQUIRED: 'This field is required',
        MISMATCH: 'Password mismatch'
    },
    ACCEPT_TERMS: {
        REQUIRED: 'Accepting Terms & Conditions is required'
    }
}


export const CALENDAR_ERRORS = {
    SLOT_EXIST: "Slot already exists",
    CREATE_SLOT_NOT_ALLOWED: "CREATE_SLOT_NOT_ALLOWED"
}

export const BOOKING_ERRORS = {
    SERVER_ERROR: "Cannot read properties of undefined (reading 'time_start')",
    SERVER_ERROR_2:"Cannot read property 'time_start' of undefined",
    NOT_AVAILABLE: 'Meeting was`t booked.'
}


export const FILE_ERRORS = {
    MAX_IMAGE_SIZE_ERROR_MESSAGE: `Your image file exceeds our size limit of ${MAX_SIZE_IN_MB.IMAGE} MB. Please upload an image with smaller file size`,
    MAX_VIDEO_SIZE_ERROR_MESSAGE: `Your video file exceeds our size limit of ${MAX_SIZE_IN_MB.VIDEO} MB. Please upload an video with smaller file size`,
    WRONG_FORMAT_ERROR_MESSAGE: "Oops... Wrong format"
}

