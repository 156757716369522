import React, {useRef, useState} from 'react'
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from 'react-share'
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import HoveredPopup from "../../../../components/hovered-popup";
import TwitterIcon from "@material-ui/icons/Twitter";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TelegramIcon from "@material-ui/icons/Telegram";
import FacebookIcon from "@material-ui/icons/Facebook";
import MailIcon from "@material-ui/icons/Mail";
import {useClickAway} from "react-use";
import {IconButton} from "@material-ui/core";
import {RootStateOrAny, useSelector} from "react-redux";
import {IUser} from "../../../../interfaces/user";

interface IProps {
    user?: IUser;
}

const ShareIconsMobile: React.FC<IProps> = ({user}) => {
    const ref = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState<boolean>(false)
    const myData: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const user_name = myData?.user_name?.replace(/\s/g, '_');
    const share_link = `${process.env.REACT_APP_NEXT_PUBLIC_ENV_BASE_CLIENT_UI}/u/${user_name}`;

    useClickAway(ref, () => setOpen(false))
    return (
        <div className={"share-socials-mobile-container"} ref={ref}>
            <IconButton onClick={() => setOpen(!open)}>
                <ShareOutlinedIcon/>
            </IconButton>
            <HoveredPopup
                open={open}
                style={{top: '50px', right: '10px'}}
                triangleStyle={{left: '89%'}}
                children={
                    <div className='share-socials-popup-container'>
                        <TwitterShareButton title={'Twitter'} url={share_link}>
                            <div className={"icon-circle twitter"}>
                                <TwitterIcon/>
                            </div>
                        </TwitterShareButton>
                        <WhatsappShareButton title={'WhatsApp'} url={share_link}>
                            <div className={"icon-circle whatsapp"}>
                                <WhatsAppIcon/>
                            </div>
                        </WhatsappShareButton>
                        <TelegramShareButton title={'Telegram'} url={share_link}>
                            <div className={"icon-circle telegram"}>
                                <TelegramIcon/>
                            </div>
                        </TelegramShareButton>
                        <FacebookShareButton title={'Facebook'} url={share_link}>
                            <div className={"icon-circle facebook"}>
                                <FacebookIcon/>
                            </div>
                        </FacebookShareButton>
                        <EmailShareButton title={'Mail'} url={share_link}>
                            <div className={"icon-circle mail"}>
                                <MailIcon/>
                            </div>
                        </EmailShareButton>
                    </div>
                }/>
        </div>
    )
}

export default ShareIconsMobile