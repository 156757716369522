export const HRF = {
    CUSTOM: 'CUSTOM',
    HRF1: 'HRF1',
    HRF2: 'HRF2',
    HRF3: 'HRF3',
    HRF4: 'HRF4',
}

export const MEETING_TYPE = {
    VIDEO: 'VIDEO',
    VOICE: 'VOICE'
}

export const PAYMENT_METHOD = {
    CHUSP_CREDIT: 'CHUSP_CREDIT',
    CREDIT_CARD: 'CREDIT_CARD',
    ALIPAY: "ALIPAY",
    WECHAT: "WECHAT"
}

export const EVENT_STATUS = {
    CANCELLED: 'CANCELLED',
    PAID: "PAID",
    UNPAID: "UNPAID", 
    REJECTED: "REJECTED",
    PENDING: "PENDING"
}

export const PAYMENT_STATUS = {
    REJECTED: 'REJECTED',
    PAID: "PAID",
    UNPAID: "UNPAID",
    PENDING: "PENDING"
}

export const EWALLET_PAYMENT_STATUS = {
    OPEN: 'Payment processing has not started.',
    COMPLETED: 'The checkout session is complete.',
    EXPIRED: "Payment processing is failed.",
}
export const BREADCRUMPS_BOOKING_STEPS = ['Duration', 'Date & Time', 'Payment', 'Booking Details', 'Meeting Message']