import React, { useEffect, useState } from "react";
import ChuspSpinner from "../loader/ChuspSpinner";
import { useIOSChecker } from "../../service/ios-service/ios-service";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const { isIOS } = useIOSChecker();

  useEffect(() => {
    const handler = (e: any) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt: any) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    // @ts-ignore
    promptInstall.prompt();
  };

  if (!supportsPWA && !isIOS) {
    return (
      <button
        className="link-button"
        id="setup_button"
        aria-label="Install app"
        title="Install app"
        onClick={onClick}
        style={{ color: "white" }}
      >
        <div className="spinner-center">
          {" "}
          <ChuspSpinner isGlobal={false} />
        </div>
        {/* <div className="install-text">
          <p className="instal">Install</p>
          <p>Shortcut</p>
        </div> */}
      </button>
    );
  }
  if (isIOS) {
    return null;
  }
  return (
    <button
      className="link-button"
      id="setup_button"
      aria-label="Install app"
      title="Install app"
      onClick={onClick}
      style={{ color: "white" }}
    >
      <div className="install-text">
        <p className="instal">Install</p>
        {/* <p>Shortcut</p> */}
      </div>
    </button>
  );
};

export default InstallPWA;
