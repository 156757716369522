import React, {useEffect, useState} from 'react'
import {Form, Formik} from "formik";
import {Link} from 'react-router-dom'
import InputField from "../../../../../components/field/Field";
import {IFormikValues} from "../../../../../interfaces";
import Btn from '../../../../../components/buttons/MainButton/Button'
import {useWindowSize} from "../../../../../service/common/window";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {ROUTES} from "../../../../../enum/routes";
import {THEME} from "../../../../../ui/theme";
import {useAuth} from "../../../../../service/authentication/auth";
import {SigninSchema} from "../../../../../config/form-validation/validation-schema";
import Modal from "../../../../../components/modal/main-modal/modal";
import {useLazyRequest} from "../../../../../service/common/request";
import {passwordRecovery} from "../../../../../api/auth";
import {patternEmail} from "../../../../../config/form-validation/validation";
import SuccessOrErrorModal from "../../../../../components/modal/success-or-error/success-or-error-modal";
import {SUCCESS_OR_ERROR_MODAL} from "../../../../../enum/modal";

interface InitialValues extends IFormikValues {
    remember: boolean
}

const initialValues: InitialValues = {
    email: '',
    password: '',
    remember: true
};

const MobileLoginForm: React.FC = () => {
    const {isDesktop} = useWindowSize();
    const {login, error, setError} = useAuth();
    const [openEmailModal, setOpenEmailModal] = useState<boolean>(false)
    const [openMessageModal, setOpenMessageModal] = useState<boolean>(false)
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
    const [emailInput, setEmailInput] = useState<string>("")
    const [showError, setShowError] = useState<boolean>(false)

    const [emailRecoverySentTo, setEmailRecoverySentTo] = useState<string>("")

    const [recoveryPassword, data] = useLazyRequest(passwordRecovery, {variables: emailInput});
    const errorMessage = data?.data?.message
    const isValidEmail = patternEmail.test(emailInput)

    useEffect(() => {
        errorMessage && setOpenErrorModal(true)
        // eslint-disable-next-line
    }, [data?.data])

    const handleRecovery = async () => {
        if (isValidEmail) {
            await recoveryPassword();
            await setOpenEmailModal(false);
            await setEmailRecoverySentTo(emailInput)
            await setOpenMessageModal(true);
        } else {
            setShowError(true)
        }
    }

    useEffect(() => {
        if (!openEmailModal) {
            setEmailInput("")
            setShowError(false)
        }
    }, [openEmailModal])

    return (
        <Formik initialValues={initialValues}
                validationSchema={SigninSchema}
                onSubmit={async (values, {resetForm}) => {
                    const {email, password, remember} = values
                    await login({email, password}, remember);
                    error === '' && resetForm()
                }}
        >
            {({values: {email, password, remember}, handleSubmit, handleChange, errors, touched}) => {
                // eslint-disable-next-line
                const isSubmitDisabled = (!email || email === '')
                || (!password || password === '')
                || (errors && (errors.email || errors.password)) ? true : false;
                return (
                    <Form onSubmit={handleSubmit}>
                        <div className='input-wrapper' onClick={()=>setError(null)}>
                            <InputField
                                name={'email'}
                                placeholder={isDesktop ? 'Email' : ''}
                                title={isDesktop ? '' : 'Email'}
                                type={'email'}
                                value={email}
                                onChange={handleChange}
                                error={errors.email && touched.email}
                            />
                            {errors && !!errors.email &&
                            <div className='tip-text'>
                                {errors.email && touched.email ? errors.email : ''}
                            </div>
                            }
                        </div>
                        <span className='forgot-pass link'
                              onClick={() => setOpenEmailModal(true)}>Forgot password?</span>
                        {openEmailModal && <Modal
                            title='Enter your email'
                            leftButton={"Cancel"}
                            onComplete={handleRecovery}
                            disabled={emailInput?.length === 0}
                            disableCloseOnComplete={true}
                            rightButton={"Send"}
                            open={openEmailModal}
                            setOpen={setOpenEmailModal}
                            children={<div onClick={() => setShowError(false)}>
                                <InputField
                                    name={'emailInput'}
                                    title={'Email'}
                                    type={'email'}
                                    error={showError}
                                    value={emailInput}
                                    onChange={(e) => setEmailInput(e.target.value)}
                                />
                                {showError && emailInput.length > 0 &&
                                <div className='tip-text'>
                                    Invalid Email
                                </div>
                                }
                            </div>}
                        />}
                        {openMessageModal && <Modal
                            rightButton={"Done"}
                            open={openMessageModal}
                            setOpen={setOpenMessageModal}
                            children={<div className='modal-text'>We’ve sent an email to <b>{emailRecoverySentTo}</b> with
                                instructions to reset
                                your
                                password.
                                Please check your spam/folder as well.</div>}
                        />}
                        <div className='input-wrapper' onClick={()=>setError(null)}>
                            <InputField
                                name={'password'}
                                type={'password'}
                                placeholder={isDesktop ? 'Password' : ''}
                                title={isDesktop ? '' : 'Password'}
                                value={password}
                                onChange={handleChange}
                                error={errors.password && touched.password}
                            />
                            {errors && !!errors.password &&
                            <div className='tip-text'>
                                {errors.password && touched.password ? errors.password : ''}
                            </div>
                            }
                            {!!error &&
                            <div className='tip-text'>
                                {error}
                            </div>
                            }
                        </div>
                        <div className='remember-me'>
                            <FormControlLabel
                                control={<Checkbox
                                    name={'remember'}
                                    onChange={handleChange}
                                    value={remember}
                                    checked={remember}
                                    color='primary'
                                />}
                                label={""}
                            />
                            Keep me signed in
                        </div>
                        <Btn
                            style={{marginTop: 10}}
                            type={'submit'}
                            disabled={isSubmitDisabled}
                            clr={THEME.colors.white}
                            background={!isDesktop ? THEME.colors.secondaryblue : THEME.colors.main}
                            content={'Sign in'}
                        />
                        <p className='text-with-link'>Don’t have an account yet? <Link className='link'
                                                                                            to={ROUTES.REGISTER}>Sign
                            up</Link></p>
                        <p className='signin-footer'> By continuing, you represent that you are at least  18 years
                            old and you agree with our  <a href={ROUTES.TERMS}
                                                           target={'_blank'} rel={'noreferrer'}> Terms </a>
                            and <a href={ROUTES.POLICY} target={'_blank'} rel={'noreferrer'}
                            >Privacy Policy</a>
                        </p>
                        {openErrorModal && (
                            <SuccessOrErrorModal
                                open={openErrorModal}
                                setOpen={setOpenErrorModal}
                                type={SUCCESS_OR_ERROR_MODAL.ERROR}
                                textError={errorMessage}
                                onActionClicked={() => {
                                    setOpenErrorModal(false)
                                    setOpenEmailModal(true)
                                }}
                            />
                        )}
                    </Form>
                )
            }}
        </Formik>
    )
}

export default MobileLoginForm;
