import axios from 'axios'
import { IGetFollowers } from '../interfaces/follow';

export const getFollowers = (data: IGetFollowers) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/follow/get`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const follow = (target_user_id: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/follow/${target_user_id}`
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.data
                ? {error: error.response.data.message}
                : {error: 'unknown error'},
        );
}

export const unfollow = (target_user_id: number) => {
    return axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/follow/unfollow/${target_user_id}`
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.data.message}
                : {error: 'unknown error'},
        );
}
export const countFollowers = (type: string) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/follow/${type}/count`
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
export const checkFollow = (id: number) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/follow/check_follow/${id}`
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}
