/* eslint-disable */
import React from "react";
import Modal from "../../../../components/modal/main-modal/modal";
import { useWindowSize } from "../../../../service/common/window";
import { ICommonModalProps } from "../../../../interfaces/modal";
import Btn from "../../../../components/buttons/MainButton/Button";
import { THEME } from "../../../../ui/theme";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../enum/routes";

interface IProps extends ICommonModalProps {
  title?: string;
}

const CalendarExitWarningModal: React.FC<IProps> = ({ open, setOpen }) => {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const onComplete = () => {
    setOpen(false);
    localStorage.setItem("isFirstTime", JSON.stringify({ isFirstTime: false }));
  };
  const routeBack = () => {
    localStorage.removeItem("availableSlotsArr");
    setOpen(false);
    history.push(`${ROUTES.MY_PAGE.PROFILE}?container=hosting`);
  };
  const closeModal = () => {
    setOpen(false);
  };
  return (
    <Modal
      setOpen={setOpen}
      open={open}
      onCancel={onComplete}
      onComplete={onComplete}
      //   rightButton={"Yes, exit"}
      //   leftButton={"No, take me back"}
      modalName="calendarExit"
      children={
        <div className="calendar-exit-modal">
          <p>You haven't entered any availability slots.</p>
          <p>
            Without any availability slots, your followers won't be able to book
            lucrative Chusp meetings with you.
          </p>
          <p>
            <i>Are you sure you want to exit your Chusp calendar?</i>
          </p>
          <div className="footer-btn">
            <Btn
              className="modal-button"
              clr={THEME.colors.lightblue}
              background={"transparent"}
              onClick={closeModal}
              content={"No, take me back"}
            />
            <Btn
              className="modal-button"
              clr={THEME.colors.lightblue}
              background={"transparent"}
              onClick={routeBack}
              content={"Yes, exit"}
            />
          </div>
        </div>
      }
    />
  );
};

export default CalendarExitWarningModal;
