// @flow
import * as React from 'react';
import { Radio } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { useEffect, useState } from "react";
import Btn from "../../../../../../components/buttons/MainButton/Button";
import { THEME } from "../../../../../../ui/theme";
import Modal from "../../../../../../components/modal/main-modal/modal";
import { addBillingAddressForStripe, getBillingAddress, removeBillingAddress, setBillingAddress } from "../../../../../../api/payment";
import { findCountryName } from "../EnterCCDetails";
import { ROUTES } from "../../../../../../enum/routes";
import { useHistory } from "react-router";
import ChuspLoader from "../../../../../../components/loader/ChuspLoader";
import { RootStateOrAny, useSelector } from "react-redux";
import SuccessOrErrorModal from '../../../../../../components/modal/success-or-error/success-or-error-modal';
import { SUCCESS_OR_ERROR_MODAL } from '../../../../../../enum/modal';

export const SelectBillingAddress = () => {
    const history: any = useHistory()
    const usedAddress = history.location.state?.billingAddressId
    const [activeId, setActiveId] = useState(usedAddress)
    const [loading, setLoading] = useState(false)
    const [billingAddresses, setBillingAddresses] = useState<any>([])
    const user = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const cardIdFromBooking = history.location?.state?.cardId
    const stripeCardId = history.location.state?.stripeCardId
    const cardIdFromLS = localStorage.getItem('creditCardId')
    const customerId = history.location.state?.customerId
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

    const userName = `${user?.first_name ? user?.first_name : ""} ${user?.last_name ? user?.last_name : ""}`
    const bookingRoute = localStorage.getItem('isRedirectedAfterBooking')
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    useEffect(() => {
        setLoading(true)
        const timer1 = setTimeout(async () => {
            const response = await getBillingAddress()
            if (response?.items) setBillingAddresses(response?.items)
            setLoading(false)
        }, 1000)
        return () => {
            clearTimeout(timer1);
        };
    }, [])

    const removeAddress = async (id: string) => {
        setLoading(true)
        await removeBillingAddress(id)
        const response = await getBillingAddress()
        if (response?.items) setBillingAddresses(response?.items)
        setLoading(false)
    }
    const activeAddress = billingAddresses?.find((address: any) => address?.id === activeId)

    const setBilling = async () => {
        const data = {
            "customer_id": customerId,
            "card_id": stripeCardId,
            "address_line1": activeAddress.address,
            "postal_code": activeAddress.postalCode,
            "address_country": activeAddress.country
        }
        const res: any = await addBillingAddressForStripe(data)
        if (res?.isExpected) {
            setOpenSuccessModal(true)
        } else {
            setOpenErrorModal(true)
        }
        if (cardIdFromBooking || bookingRoute) {
            await setBillingAddress({ billing_address_id: +activeAddress?.id, card_id: cardIdFromBooking || cardIdFromLS })
            // await history.go(bookingRoute)
            setOpenSuccessModal(true)
            await history.go(-2)
        } else {
            setOpenSuccessModal(true)
            await setBillingAddress({ billing_address_id: activeAddress?.id, card_id: cardIdFromLS ? +cardIdFromLS : 0 })
            await history.push(ROUTES.MY_PAGE.CARDS)
        }
    }

    return (
        <><div className='select-billing-container'>
            <div className='content'>
                {loading && <ChuspLoader />}
                {billingAddresses?.map((address: any) =>
                    <div onClick={() => setActiveId(address?.id)}
                        className={`billing-address-container ${address?.id === activeId && 'active'}`}>
                        <div>
                            <div className="billing-details-container">
                                <div className='radio-wrapper'>
                                    <Radio checked={address?.id === activeId} color={'primary'} />
                                    <div className="billing-details-wrapper">
                                        <div className="billing-detail-text">{address?.address}</div>
                                        <div
                                            className="billing-detail-text">{address?.address2 && address?.address2}</div>
                                        <div className="billing-detail-text">Postal/Zip
                                            Code: {address?.postalCode}</div>
                                        <div className="billing-detail-text">{findCountryName(address?.country)}</div>
                                    </div>
                                </div>
                                {!address?.id !== activeId && <div className="delete-icon-container">
                                    <DeleteIcon onClick={() => setOpenDeleteModal(true)} color="action" />
                                </div>}
                            </div>
                            {address?.id === activeId && <Btn
                                className={'use-btn'}
                                clr={THEME.colors.white}
                                background={THEME.colors.secondaryblue}
                                onClick={usedAddress === activeId ? () => { } : setBilling}
                                content={usedAddress === activeId ? 'Used address' : 'Use this address'}
                            />}
                        </div>
                    </div>
                )}
                <div className='add-billing' onClick={() => history.push({
                    pathname: ROUTES.MY_PAGE.ADD_BILLING_ADDRESS, state: {
                        cardId: cardIdFromBooking,
                        customerId: customerId
                    }
                })}>Add
                    Billing Address
                </div>
                <Modal
                    disableClose={true}
                    open={openDeleteModal}
                    setOpen={setOpenDeleteModal}
                    rightButton={"Yes"}
                    leftButton={"No"}
                    onComplete={() => removeAddress(activeId || ' ')}
                    title={'Confirm Removal'}
                    children={
                        <>
                            <div className='userName'>{userName}</div>
                            <div className='billingAddress'>{activeAddress?.address}</div>
                            {activeAddress?.address2 && <div className='billingAddress'>{activeAddress?.address2}</div>}
                            <div className='billingAddress'>Postal/Zip Code: {activeAddress?.postalCode}</div>
                            <div className='billingAddress'>{findCountryName(activeAddress?.country)}</div>
                        </>}
                />
            </div>
        </div>  {openErrorModal && <SuccessOrErrorModal
            open={openErrorModal}
            setOpen={setOpenErrorModal}
            type={SUCCESS_OR_ERROR_MODAL.ERROR}
            textError={'Failed to save the adress.'}
            disableClose={true}
            onActionClicked={() => setOpenErrorModal(false)}
        />}
            {openSuccessModal && <SuccessOrErrorModal
                type={SUCCESS_OR_ERROR_MODAL.SUCCESS}
                textSucess={"Your address is saved sucessfully."}
                open={openSuccessModal}
                setOpen={setOpenSuccessModal}
                onActionClicked={() => setOpenSuccessModal(false)}
            />}</>
    );
};