export const options = [
    {
        description: 'Whatever it is that makes you stand out - Chusp lets you reap the rewards of your unique abilities and qualities.'
    },
    {
        description: 'Whether it’s your skills, your story, your expertise or your looks – unleash your earnings potential by inspiring others though hosting 1:1 Chusp video meetings on your terms.'
    },
    {
        description: 'Simply choose your availability and your meeting rate, and Chusp will handle all the taxing logistics for you such as:',
        options: [
            'Scheduling your Chusp meetings at your chosen dates and times',
            'Collecting your earnings and depositing them safely into your bank account',
            'Providing a private and secure video meeting environment',
            'Giving you access to a global audience'
        ]
    },
    {
        description: 'Chusp offers all these functionalities in one, integrated technology platform, to ensure a seamless and enjoyable experience for you.'
    },
    {
        description: 'Best of all: hosting meetings doesn’t limit you from booking meetings with other hosts: on Chusp, you can play any role you want, at any time - as host, as fan, or both. Use Chusp the way you choose. '
    },
]

export const header = 'Be a Chusp Host'

export const buttonName ={first:'Be a Chusp Creator', second: 'Be a Chusp Fan'}
