import {
  CardElement,
} from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import Btn from "../../../../components/buttons/MainButton/Button";
import { THEME } from "../../../../ui/theme";
import visa from '../../../../assets/img/Visa.jpg';
import mastercard from '../../../../assets/img/Mastercard.jpg';

export default function CheckoutForm(props) {
  const stripe = useStripe();
  const [error, setError] = useState('');
  const elements = useElements({
    theme: 'stripe',
    rules: {
      '.Tab': {
        border: '1px solid #E0E6EB',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
      },
      '.Tab--selected': {
        borderColor: '#E0E6EB',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
      },
    }
  });
      // eslint-disable-next-line
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const cardElement = elements.getElement(CardElement);
    const { token, error } = await stripe.createToken(cardElement);
    if (error) {
      // console.error(error);
      setError(error.message)
    } else {
      props.getToken(token)
      return token;
    }
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
  }

  const handleCardDetailsChange = (event) => {
    if (event.error) {
      setError(event.error.message)
    } else {
      setError('')
    }
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement onChange={handleCardDetailsChange} />
        <span className='error mt-2'>{error}</span>
        <div className='footer'>
          <p>Chusp accepts the following credit cards:</p>
          <div>
            <img src={visa} alt={'visa'} />
            <img src={mastercard} alt={'visa'} />
          </div>
        </div>
        <Btn
          id="submit"
          type="submit"
          className={`saveButton stripe`}
          clr={THEME.colors.white}
          background={THEME.colors.secondaryblue}
          content={'Save Card'}
          disabled={isLoading || !stripe || !elements || error}
        />
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  )
}