/* eslint-disable */
import React, {useEffect} from 'react';
import "../src/ui/scss/app.scss";
import favicon from "./assets/img/logo-c.png";
import {Switch, Route, Redirect} from 'react-router-dom'
import Auth from "./pages/Auth/index"
import {ROUTES} from "./enum/routes";
import ChuspLoader from "./components/loader/ChuspLoader";
import {useFavicon} from "react-use";
import {useDetectUserLocation} from "./service/IP/ip";
import {useHasToken} from "./service/authentication/has-token";
import {useFirstTimeVisit} from "./service/is-first-time-visit";
import {useWrapperHeight} from './service/wrapper-height/wrapper-height';
import WeChatRedirectToAuth from "./pages/Welcome"
import InternalApp from "./pages/App"
import {useVariable} from "./service/system/variable";
import { ScrollProvider } from './service/ScrollContext';


function App() {
    useDetectUserLocation()
    useFavicon(favicon)
    useFirstTimeVisit();
    useWrapperHeight()

    const {getVariables} = useVariable()
    const {jwtLoading, hasJwt} = useHasToken()
    const cacheName = ['workbox-precache-v2-https://chusp.com/', 'images']



    useEffect(()=>{
        (async()=> {
            if ("caches" in window) {
              cacheName.map((name:string)=>{
                    caches.delete(name).then(function (res) {
                        return res;
                    });
                })
            }
            await getVariables();
        } )()
    },[])

return (
    <ScrollProvider>
    <Switch>
        {jwtLoading && <ChuspLoader/>}
        <Route path={ROUTES.ROOT_AUTH} component={Auth}/>
        {/*TODO: This component is mediator for WeChat auth*/}
        {!hasJwt && <Route exact path={ROUTES.HOME} component={WeChatRedirectToAuth}/>}
        <InternalApp/>
        {hasJwt ? <Redirect to={ROUTES.USER_LIST}/> : <Redirect to={ROUTES.ROOT_AUTH}/>}
    </Switch></ScrollProvider>
)
}


export default App;
