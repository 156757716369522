import React, {useRef} from 'react'
import {useClickAway} from "react-use";


interface IProps {
    children: JSX.Element | JSX.Element[] | null,
    open?: boolean | any,
    setOpen?: React.Dispatch<React.SetStateAction<boolean>>,
    style?: any,
    triangleStyle?: any,
}


const HoveredPopup: React.FC<IProps> = ({children, open, setOpen, style, triangleStyle}) => {
    const ref = useRef<HTMLDivElement>(null)
    useClickAway(ref, () => setOpen && setOpen(false))
    return (
        <div 
          className={`hovered-popup-container ${open && 'hovered-popup-container--open'}`} 
          style={style} 
          ref={ref}>
            <div className='hover-container'>
                {children}
                <div className='triangle' style={triangleStyle}/>
            </div>
        </div>
    )
}

export default HoveredPopup
