import {useEffect} from "react";
import {ROUTES} from "../../enum/routes";
import {AUTH_KEY} from "../../enum/auth";
import {useHistory, useLocation} from "react-router";
import { matchPath } from "react-router-dom";

const ALLOWED_ROUTES_TO_ACCESS_WITHOUT_TOKEN = [
    ROUTES.BE_CLIENT,
    ROUTES.BE_HOST,
    ROUTES.POLICY,
    ROUTES.TERMS,
    ROUTES.FAQ,
    ROUTES.OOPS,
    ROUTES.USER_LIST,
    ROUTES.USER_PAGE
]

export const useAuthGuard = () => {
    const history = useHistory()
    const {pathname} = useLocation()
    const cookieToken = document.cookie.split("; ").reduce((r, v) => {
        const parts = v.split("=");
        return parts[0] === AUTH_KEY ? decodeURIComponent(parts[1]) : r;
    }, "");


    useEffect(() => {
            const match = ALLOWED_ROUTES_TO_ACCESS_WITHOUT_TOKEN.find(route => {
                return matchPath(pathname, {
                    path: route
                });
            })
            
            // NEED TO DISABLE FOR VISITOR MODE AND WELCOME PAGE
            if (!cookieToken && !match) {
                return history.push(ROUTES.ROOT_AUTH)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [cookieToken]
    )
}