import React from 'react';
import logoC from '../../assets/img/logo-c.png';

interface IProps {
    isGlobal?: boolean;
    isModal?: boolean
}

const ChuspSpinner: React.FC<IProps> = ({isGlobal, isModal}) => {
    return (
        <div className={`chusp-spinner-container ${isGlobal ? 'chusp-spinner-global-container': isModal ? 'chusp-spinner-modal-container' : ""}`}>
            <img className='spinner' src={logoC} alt={'Chusp'}/>
        </div>
    );
};

export default React.memo(ChuspSpinner);
