import {useEffect, useState} from "react";
import { getUserAvailableDateNew} from "../../api/booking";

export const useUserAvailableDates = (date_counter: number, id: number) => {

    const [availableDates, setAvailableDates] = useState<any>([])
    const [disableRequest, setDisableRequest] = useState<boolean>(false)

    useEffect(() => {
        (async () => {
            if (!disableRequest) {
                // const response = id && await getUserAvailableDate({date_counter, id})
                const response = id && await getUserAvailableDateNew({date_counter, id});
                if (response && response?.items) {
                    setAvailableDates(response.items)
                    if (date_counter !== response?.items?.length) {
                        setDisableRequest(true)
                    }
                }
            }
        })()
        // eslint-disable-next-line
    }, [date_counter, id])


    return {
        availableDates,
        disableRequest,
        setDisableRequest
    }
}
