import React, {useEffect, useState} from 'react'
import StarsIcon from '@material-ui/icons/Stars';
import {useWindowSize} from "../../../../service/common/window";
import {THEME} from "../../../../ui/theme";
import {PROFILE_MODES} from "../../../../enum/profile-mods";
import {IUser} from "../../../../interfaces/user";
import {getEducationInfoString, getWorkplaceInfoString} from "../../../../utils/formatters";
import {CONTAINERS} from "../../../../enum/containers";
import HoveredPopup from "../../../../components/hovered-popup";
import StarsStatistics from "../../../../components/stars-statistics";
import {useUserBio} from '../../../../service/user/user';
import {useHistory} from 'react-router-dom';
import TextWithDecoration from '../../../../components/text-with-decoration/TextWithDecoration';

interface IProps {
    user: IUser,
    mode: typeof PROFILE_MODES.EDIT | typeof PROFILE_MODES.VIEW,
    earnings: number,
    totalScore: number,
    successRate: number,
    getStarsCounterByEnumValue: (amountOfStars: number) => number,
    loadingOfAdmirors: boolean,
    loadingOfFollowers: boolean,
    hasCountOfFollowers: any,
    hasCountOfAdmirors: any
}

const BioContainer: React.FC<IProps> = (
    {
        user,
        mode,
        getStarsCounterByEnumValue,
        successRate,
        totalScore,
        earnings,
        loadingOfAdmirors,
        loadingOfFollowers,
        hasCountOfFollowers,
        hasCountOfAdmirors
    }) => {
    const isMyProfile = mode === PROFILE_MODES.EDIT
    const history: any = useHistory()
    const {isDesktop, isMobile} = useWindowSize()
    const {languageInfo, locationInfo, workInfo, educationInfo, websiteInfo} = useUserBio(user)

    const [open, setOpen] = useState<boolean>(false)
    const [followerCount, setFollowerCount] = useState<number>(0);
    const [admirorsCount, setAdmirorsCount] = useState<number>(0);


    useEffect(() => {
        if (!loadingOfFollowers) {
            Number.isNaN(hasCountOfFollowers?.data?.count) ? setFollowerCount(0) : setFollowerCount(hasCountOfFollowers?.data?.count)
        }
        // eslint-disable-next-line
    }, [loadingOfFollowers])

    useEffect(() => {
        if (!loadingOfAdmirors) {
            Number.isNaN(hasCountOfAdmirors?.data?.count) ? setAdmirorsCount(0) : setAdmirorsCount(hasCountOfAdmirors?.data?.count)
        }
        // eslint-disable-next-line
    }, [loadingOfAdmirors])

    const dataExist = (languageInfo && languageInfo?.items?.length > 0) || (locationInfo && locationInfo?.length > 0)
        || (workInfo && workInfo?.length > 0) || (educationInfo && educationInfo?.length > 0)

    const showMoreButton = (workInfo && workInfo?.length > 1)
        || (educationInfo && educationInfo?.length > 1)
        || (websiteInfo && websiteInfo?.items?.length > 0)

    const openAdmirors = () => {
        !!admirorsCount && history.push({search: CONTAINERS.ADMIRORS.CONTAINER_SEARCH})
    }

    const openFollowers = () => {
        !!followerCount && history.push({search: CONTAINERS.FOLLOWERS.CONTAINER_SEARCH})
    }

    const showMore = () => {
        history.push({search: CONTAINERS.SHOW_MORE.CONTAINER_SEARCH})
    }

    return (
        <div className={"bio-container"} style={{margin: dataExist ? '5px auto' : '0'}}>
            {!isDesktop && isMyProfile && (
                <>
                    <div className={"stats"}>
                        <div className={"stats-info"}>
                            <span>${earnings}</span>
                            <span>Earnings</span>
                        </div>
                        <div className={"stats-info"} onClick={() => setOpen(!open)}>
                            <StarsIcon style={{color: THEME.colors.grey}}/>
                            <span>{totalScore}</span>
                            <HoveredPopup
                                open={open}
                                style={{top: '60px'}}
                                children={
                                    <StarsStatistics
                                        getStarsCounterByEnumValue={getStarsCounterByEnumValue}
                                        totalScore={totalScore}
                                    />}
                                setOpen={setOpen}/>
                        </div>
                        <div className={"stats-info"}>
                            <span>{successRate}%</span>
                            <span>Success</span>
                        </div>
                    </div>
                    <div className={"followers-container"}>
                        <span onClick={openAdmirors}>
                             {admirorsCount} Admirers
                        </span>
                        <span onClick={openFollowers}>
                             {followerCount} Favourites
                        </span>
                    </div>
                </>
            )}
            {dataExist && (
                <>
                    <div className={"bio-info"}>
                        <TextWithDecoration className={"decoration-text"} top={15} bottom={15} content={'About'}/>
                        <div>
                            {locationInfo && <p>Lives in {locationInfo?.replace(/"/g, '')}</p>}
                            {workInfo && <p>{getWorkplaceInfoString(workInfo[0], isMobile)}</p>}
                            {educationInfo && <p>{getEducationInfoString(educationInfo[0], isMobile)}</p>}
                            {!!languageInfo?.items?.length &&
                            <p>Knows {languageInfo?.items?.map((item, index) => index < 5 && ((index ? ', ' : '') + item))} </p>}
                        </div>
                    </div>
                    {showMoreButton && <span className={"show-more"} onClick={showMore}>More</span>}
                </>
            )}

        </div>
    )
}

export default BioContainer
