import React, {useEffect, useState} from 'react'
import {Avatar, IconButton} from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import badge from '../../../assets/img/check_circle_outline_24px.png'
import chuspSkeleton from "../../../assets/img/chusp-skeleton.jpg"
import defaultChuspAvatat from "../../../assets/img/chusp-avatar.png"
import cameraUploadIcon from "../../../assets/icons/camera-icon.svg"
import StatsContainer from '../Stats';
import {PROFILE_MODES} from "../../../enum/profile-mods";
import "cropperjs/dist/cropper.css";
import {IUser} from "../../../interfaces/user";
import {useAuth} from "../../../service/authentication/auth";
import InlineLoader from "../../../components/loader/InlineLoader";
import Btn from "../../../components/buttons/MainButton/Button";
import {ROUTES} from "../../../enum/routes";
import {Link} from "react-router-dom";
import {useHistory} from "react-router"
import {useWindowSize} from "../../../service/common/window";
import ChuspLoader from "../../../components/loader/ChuspLoader";
import FollowMark from "../Followers/followMark";
import {RootStateOrAny, useSelector} from "react-redux";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Badge from "@material-ui/core/Badge";
import ShareOrReport from '../Modals/ShareOrReportModal';
import {ASSETS_SIZE} from "../../../enum/asset-size";
import {useGetNotifications} from "../../../service/notifications/notifications";

interface IProps {
    mode: typeof PROFILE_MODES.VIEW | typeof PROFILE_MODES.EDIT,
    user: IUser,
    loading: boolean,
    inputRef: any,
    tempImageWhileS3Upload: string | null,
    image: string | ArrayBuffer | null,
    setErrorModalTextCallback: (error: string) => void,
    openLoginModalCallback: (boolean: boolean) => void,
    openErrorModalCallback: (boolean: boolean) => void,
    setOpenChoosePhotoModal: React.Dispatch<React.SetStateAction<boolean>>,
    onChange: any
}

const ProfileHeader: React.FC<IProps> = (
    {
        mode,
        user,
        loading,
        inputRef,
        tempImageWhileS3Upload,
        image,
        setErrorModalTextCallback,
        openLoginModalCallback,
        openErrorModalCallback,
        setOpenChoosePhotoModal,
        onChange
    }) => {
    const history = useHistory()
    const isMyProfile = mode === PROFILE_MODES.EDIT

    const [avatarLoaded, setAvatarLoaded] = useState<boolean>(false)

    const myData = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const otherUserName = `${!!user?.first_name ? user?.first_name : ''} ${!!user?.last_name ? user?.last_name : ''}`
    const myName = `${!!myData?.first_name ? myData?.first_name : ''} ${!!myData?.last_name ? myData?.last_name : ''}`
    const userName = isMyProfile ? myName : otherUserName
    const userAvatar = user?.public_avatar_url ? user?.public_avatar_url : defaultChuspAvatat

    const {isDesktop, isTable, isMobile} = useWindowSize()
    const {refreshLoader} = useAuth()

    const [modifiedUserState, setModifiedUserState] = useState<IUser>(user)

    const navigateToNotificationPage = () => {
        const goToPageOrAuth = user ? ROUTES.MY_PAGE.NOTIFICATIONS : ROUTES.ROOT_AUTH
        return notifications?.totalCount !== 0 && history.push(goToPageOrAuth)
    }

    useEffect(() => setModifiedUserState(user), [user])

    useEffect(() => {
        if (!image) {
            if (inputRef.current) {
                inputRef.current.value = ''
            }
        }
    }, [image, inputRef])

    useEffect(() => {
        if (image) {
            setOpenChoosePhotoModal(false)
        }
        // eslint-disable-next-line
    }, [image])

    // eslint-disable-next-line
    const {data: notifications} = useGetNotifications({
            pagination: {
                page: 0,
                pageSize: 1
            },
            asset_size: ASSETS_SIZE.SMALL
        },
        false,
        // FETCH ONLY WHEN ITS MY PROFILE
        isMyProfile
    )

    return (
        <div className={`profile-header-container ${isMyProfile && 'my-profile-header-container'}`}>
            {loading && <ChuspLoader/>}
            <div className='header-wrapper'>
                <div
                    className='picture'
                    onClick={() => isMyProfile && isTable && setOpenChoosePhotoModal(true)}
                >
                    {user?.id_verified && <div className='badge-icon'><img src={badge} alt='Chusp verification badge'/></div>}
                    {isMyProfile && tempImageWhileS3Upload ?
                        <img className='temp-image-while-S3-upload' alt='Chusp avatar' src={tempImageWhileS3Upload}/> :
                        <Avatar
                            className='styled-avatar'
                            onLoad={() => setAvatarLoaded(true)}
                            src={avatarLoaded ? userAvatar : chuspSkeleton}
                        />
                    }
                    {isMyProfile && !myData?.public_avatar_url && !tempImageWhileS3Upload && <img
                        className='upload-icon'
                        onClick={() => isDesktop ? inputRef?.current?.click() : setOpenChoosePhotoModal(true)}
                        src={cameraUploadIcon}
                        alt={"Chusp upload icon"}
                    />}

                    {isMyProfile && myData?.public_avatar_url && isDesktop && !tempImageWhileS3Upload && (
                        <div className='hover'>
                            <label htmlFor='file'>
                                <EditOutlinedIcon/>
                                <span>Change <br/> Profile Picture</span>
                            </label>
                        </div>
                    )}
                    <input
                        style={{display: "none"}}
                        ref={inputRef}
                        id='file'
                        type='file'
                        accept="image/png, image/jpg, image/jpeg, image/svg"
                        onChange={onChange}
                    />
                </div>
                <div className='info'>
                    <div className={`name ${isMyProfile && 'my-name'}`}>
                        <div className={`styled-flex ${isMyProfile && 'my-styled-flex'}`}>
                            <h2>{userName}</h2>
                            {isMobile && isMyProfile &&
                            <div className='styled-button'>
                                <Link className='styled-link' to={ROUTES.MY_PAGE.EDIT}> <Btn className='edit-btn'
                                                                                             content={'Edit profile'}/></Link>
                                <div style={{display: 'block', paddingRight: 10, top: 10, right: 10}} className='notification-wrapper'
                                     onClick={navigateToNotificationPage}>
                                    <Badge className='styled-badge' badgeContent={notifications?.totalCount}
                                           color="error">
                                        <IconButton className='styled-icon-btn'>
                                            <NotificationsIcon/>
                                        </IconButton>
                                    </Badge>
                                </div>
                            </div>}
                        </div>
                        <div className='profile-header-bio-container'>
                            {refreshLoader ? <InlineLoader/> : (
                                <div className='bio'>
                                    {isMyProfile ? (
                                            <p>{user?.short_bio?.replace(/"/g, '')}</p>) :
                                        (<p>{user?.short_bio?.replace(/"/g, '')}</p>)
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                    <StatsContainer user={modifiedUserState} isMyProfile={isMyProfile}/>
                </div>
                <div className='buttons'>
                    {isMyProfile ?
                        <Link className='styled-link' to={ROUTES.MY_PAGE.EDIT}><Btn className='edit-btn'
                                                                                    content={'Edit Profile'}/></Link>
                        : <FollowMark
                            user={user}
                            mode={mode}
                            openLoginModalCallback={openLoginModalCallback}
                            openErrorModalCallback={openErrorModalCallback}
                            setErrorModalTextCallback={setErrorModalTextCallback}
                            setModifiedUser={setModifiedUserState}
                        />
                    }
                    {isMyProfile ?
                        <div className='notification-wrapper' onClick={navigateToNotificationPage}>
                            <Badge className='styled-badge' badgeContent={notifications?.totalCount} color="error">
                                <IconButton className='styled-icon-btn'>
                                    <NotificationsIcon/>
                                </IconButton>
                            </Badge>
                        </div>
                        :
                        <IconButton className='user-share-icon'>
                            <ShareOrReport user={user}/>
                        </IconButton>
                    }
                </div>
            </div>
        </div>
    )
}

export default React.memo(ProfileHeader);
