import {useCallback, useMemo, useState} from "react";
import {SOCKET_LISTENERS} from "../../enum/meeting";
import {IMeetingNotification} from "../../interfaces/notifications";
import {Socket} from "socket.io-client";

export const useMeetingNotification = (socket: Socket | null) => {
    const [meetingNotification, setMeetingNotification] = useState<IMeetingNotification>()

    socket &&
    socket?.on(
        SOCKET_LISTENERS.START_MEETING_NOTIFICATION,
        (data: any) => {
            setMeetingNotification(data)
        }
    )

    const resetMeetingNotification = useCallback(() => {
        setMeetingNotification(undefined)
    }, [setMeetingNotification])

    return {
        meetingNotification: useMemo(() => meetingNotification, [meetingNotification]),
        resetMeetingNotification
    }

}
