import {useNetworkState} from "react-use";
import {useEffect, useState} from "react";

export const useInternetConnectionState = () => {
    const state = useNetworkState()
    const [userLostInternetConnection, setUserLostInternetConnection] = useState<boolean>(false)
    const [userHasBadInternetConnection, setUserHasBadInternetConnection] = useState<boolean>(false)

    useEffect(() => {
        if (state && !state.online) {
            setUserLostInternetConnection(true)
        }
        // eslint-disable-next-line
    }, [state])

    useEffect(() => {
        if (!!state.downlink && state.downlink < 0.5) {
            setUserHasBadInternetConnection(true)
        }
    }, [state])

    return {
        userHasBadInternetConnection,
        userLostInternetConnection
    }

}