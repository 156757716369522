import React from "react";
import {Avatar} from "@material-ui/core";
import {NOTIFICATION_TEXT, NOTIFICATION_TYPE} from "../../enum/notifications";
import {getDateInCorrectTimeZoneByConvertingUtcDate} from "../../utils/getDateInCorrectTimeZoneByConvertingUtcDate";
import moment from "moment-timezone";
import {GET_TIME} from "../../enum/date-formats";
import logoC from "../../assets/img/logo-c.png"

interface IProps {
    notification: any,
    onClick: () => void,
    order: number
}

const CommonNotification: React.FC<IProps> = ({notification, onClick, order}) => {
    const defaultTimezone = moment.tz.guess();
    const toTimeZone = (time_start: string) => {
        const dateConvertedToTimezone = getDateInCorrectTimeZoneByConvertingUtcDate(
            time_start,
            defaultTimezone
        );
        return moment(dateConvertedToTimezone).format(GET_TIME);
    };
    const timeStart = toTimeZone(notification?.value?.timeRange?.timeStart);
    const timeEnd = toTimeZone(notification?.value?.timeRange?.timeEnd);
    const date = moment(notification?.value?.timeRange?.timeStart).format(
        "ddd DD MMMM"
    );

    return (
        notification && (
            <div className="common-notifications-container" onClick={onClick}>
                <div>
                    <Avatar className={`user-avatar ${!notification?.value?.user?.public_avatar_url ? "default" : ""}`}
                            src={
                                notification?.value?.user?.public_avatar_url ?
                                    notification?.value?.user?.public_avatar_url :
                                    logoC
                            }
                    />
                    <div>
                        <p>
                            {notification?.value?.user?.first_name}{" "}
                            {notification?.value?.user?.last_name}{" "}
                            {
                                NOTIFICATION_TEXT[notification?.type as keyof typeof NOTIFICATION_TEXT] ?
                                    NOTIFICATION_TEXT[notification?.type as keyof typeof NOTIFICATION_TEXT] :
                                    notification?.value?.text
                            }
                        </p>
                        {notification?.type === NOTIFICATION_TYPE.SYSTEM_MEETING_BOOKED && (
                            <h6>
                                Date: {date}, {timeStart}
                                {"-"}
                                {timeEnd} {defaultTimezone}
                            </h6>
                        )}
                        {notification?.type === NOTIFICATION_TYPE.SYSTEM_MEETING_BOOKED && (
                            <h6>Your earnings: ${notification?.value?.price}</h6>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export default CommonNotification;
