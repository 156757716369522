import React from 'react'

interface IProps {
    top?: number,
    right?: number,
    bottom?: number,
    left?: number,
    content: any,
    className?: any
}

const TextWithDecoration: React.FC<IProps> = ({...props}) => {
    return <h3
        className={`text-with-decoration-component ${props.className ? props.className : ''}`}
        style={{
            marginTop: props.top ? `${props.top}px` : 'unset',
            marginRight: props.right ? `${props.right}px` : 'unset',
            marginBottom: props.bottom ? `${props.bottom}px` : 'unset',
            marginLeft: props.left ? `${props.left}px` : 'unset',
        }}>
        {props.content}
    </h3>
}

export default TextWithDecoration;
