export const ASSETS_SIZE = {
    SMALL: "SMALL",
    MEDIUM: "MEDIUM",
    LARGE: "LARGE",
    ORIGINAL: "ORIGINAL"
}

export const ONE_MB_IN_BYTES = 1000000

export const MAX_SIZE_IN_MB = {
    CONTENT: 200,
    VIDEO: 100,
    IMAGE: 10
}

export const MAX_IMAGE_SIZE_BYTES = MAX_SIZE_IN_MB.IMAGE * ONE_MB_IN_BYTES
export const MAX_VIDEO_SIZE_BYTES = MAX_SIZE_IN_MB.VIDEO * ONE_MB_IN_BYTES


