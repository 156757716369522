import {useEffect, useState} from "react";
import {getCreditBalance} from "../../api/finance";

export const useCreditBalance = (shouldFetch: boolean = true) => {
    const [creditBalance, setCreditBalance] = useState<number>(0)

    useEffect(() => {
        (async () => {
            if (shouldFetch) {
                const response = await getCreditBalance()
                if (response) {
                    const balance: number = response?.data?.balance
                    Number.isNaN(+balance) ? setCreditBalance(0) : setCreditBalance(+balance)
                }
            }

        })()
    }, [shouldFetch])

    const roundValue = (num: number, decimals = 2) => {
        let scaling = 10 ** decimals;
        return Math.round((num + Number.EPSILON) * scaling) / scaling;
    }

    return {
        creditBalance: roundValue(creditBalance)
    }
}
