/* eslint-disable */
import React, { useState, useRef, useContext } from "react";
import Btn from "../../../components/buttons/MainButton/Button";
import { THEME } from "../../../ui/theme";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { VIEWS } from "../../../config/calendar/views";
import { useClickAway } from "react-use";
import TimezoneModal from "../Modals/TimezoneModal";
import { useWindowSize } from "../../../service/common/window";
import BackButton from "../../../components/buttons/back-button";
import { useHistory } from "react-router-dom";
import { PrivateContainerStateContext } from "../../../pages/App/Private";
import { ROUTES } from "../../../enum/routes";
import CalendarExitWarningModal from "../Modals/CalendarExitWarning";
import { useUserRole } from "../../../service/authentication/auth";

interface IProps {
  changeCalendarView: (view: string) => void;
  selectIsMonthDaySelected: (boolean: boolean) => void;
  currentMonth: string;
  currentYear: string;
  selectCustomDayGridView: (boolean: boolean) => void;
  availableCheckbox: boolean;
  showAvailableDates: (boolean: boolean) => void;
  meetingsCheckbox: boolean;
  showBookedMeetings: (boolean: boolean) => void;
  timezoneLabel: string;
}

const BigCalendarHeader: React.FC<IProps> = ({
  changeCalendarView,
  selectIsMonthDaySelected,
  selectCustomDayGridView,
  availableCheckbox,
  showAvailableDates,
  meetingsCheckbox,
  showBookedMeetings,
  timezoneLabel,
  currentMonth,
  currentYear,
}) => {
  const history = useHistory();

  const { isDesktop } = useWindowSize();
  const { view } = useContext(PrivateContainerStateContext);
  const popupRef = useRef<HTMLDivElement>(null);
  const mobilePopupRef = useRef<HTMLDivElement>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  // const [availableSlotsArr, setAvailableSlotsArr] = useState<any>([]);
  const [openMobilePopup, setOpenMobilePopup] = useState<boolean>(false);
  const [openTimeZone, setOpenTimeZone] = useState<boolean>(false);

  const handleSelect = (option: string) => {
    if (isDesktop) {
      setOpenPopup(false);
    } else {
      setOpenMobilePopup(false);
    }
    changeCalendarView(option);
    selectIsMonthDaySelected(false);
    selectCustomDayGridView(false);
  };
  const openScheduler = () => {
    selectCustomDayGridView(true);
    changeCalendarView(VIEWS.SEVEN_DAYS);
    if (isDesktop) {
      setOpenPopup(false);
    } else {
      setOpenMobilePopup(false);
    }
  };

  const openPopupSidebar = () => {
    if (isDesktop) {
      setOpenPopup(true);
    } else {
      setOpenMobilePopup(true);
    }
  };

  // useEffect(() => {
  //   const availableSlotsItem: string | null =
  //     localStorage.getItem("availableSlotsArr");
  //   let availableSlotsArr1: any;

  //   if (availableSlotsItem !== null) {
  //     availableSlotsArr1 = JSON.parse(availableSlotsItem);
  //     setAvailableSlotsArr(availableSlotsArr1);
  //   } else {
  //     availableSlotsArr1 = []; // Setting an empty array as default value
  //     setAvailableSlotsArr(availableSlotsArr1);
  //   }
  // }, []);
  
  const goBackHandle = () => {
    const exitsingSlotLength = localStorage.getItem("availableSlotsArr");
    if ((exitsingSlotLength === null || parseInt(exitsingSlotLength) === 0)) {
        setOpen(true)
    } else {
      localStorage.removeItem("availableSlotsArr");
      history.push(`${ROUTES.MY_PAGE.PROFILE}?container=hosting`);
    }
  };

  useClickAway(popupRef, () => setOpenPopup(false));
  useClickAway(mobilePopupRef, () => setOpenMobilePopup(false));
  return (
    <div className={"calendar-header-container"}>
      <BackButton goBack={goBackHandle} />
      {open && <CalendarExitWarningModal open={true} setOpen={setOpen} />}
      <div className={"timezone"}>
        <span onClick={() => setOpenTimeZone(true)}>{timezoneLabel}</span>
      </div>
      {openTimeZone && (
        <TimezoneModal open={openTimeZone} setOpen={setOpenTimeZone} />
      )}
      <div>
        <Btn
          className={"choose-view-btn"}
          onClick={openPopupSidebar}
          clr={THEME.colors.secondaryblue}
          content={"Choose View"}
        />
        {isDesktop ? (
          <div
            className={`choose-view-popup ${openPopup ? "opened" : ""}`}
            ref={popupRef}
          >
            <div className={"view-options"}>
              <Btn onClick={openScheduler} content={"Schedule"} />
              <Btn
                onClick={() => handleSelect(VIEWS.ONE_DAY)}
                content={"1 Day"}
              />
              <Btn
                onClick={() => handleSelect(VIEWS.THREE_DAYS)}
                content={"3 Days"}
              />
              <Btn
                onClick={() => handleSelect(VIEWS.SEVEN_DAYS)}
                content={"7 Days"}
              />
              <Btn
                onClick={() => handleSelect(VIEWS.MONTH)}
                content={"Month"}
              />
            </div>
            <div className="view-checkboxes">
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={meetingsCheckbox}
                      onChange={(e) => showBookedMeetings(e.target.checked)}
                      checked={meetingsCheckbox}
                    />
                  }
                  label={"Show Meetings"}
                />
              </div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={availableCheckbox}
                      onChange={(e) => showAvailableDates(e.target.checked)}
                      checked={availableCheckbox}
                    />
                  }
                  label={"Show Availability"}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`choose-view-mobile-popup ${
              openMobilePopup ? "mobile-opened" : ""
            }`}
          >
            <div
              className={`menu ${openMobilePopup ? "menu-opened" : ""}`}
              ref={mobilePopupRef}
            >
              <div className={"menu-options-wrapper"}>
                <Btn onClick={openScheduler} content={"Schedule"} />
                <Btn
                  onClick={() => handleSelect(VIEWS.ONE_DAY)}
                  content={"1 Day"}
                />
                <Btn
                  onClick={() => handleSelect(VIEWS.THREE_DAYS)}
                  content={"3 Days"}
                />
                <Btn
                  onClick={() => handleSelect(VIEWS.SEVEN_DAYS)}
                  content={"7 Days"}
                />
                <Btn
                  onClick={() => handleSelect(VIEWS.MONTH)}
                  content={"Month"}
                />
              </div>
              <div
                className="menu-footer"
                style={{
                  paddingBottom:
                    window.innerHeight - (window.innerHeight - 300),
                }}
              >
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={meetingsCheckbox}
                        onChange={(e) => showBookedMeetings(e.target.checked)}
                        checked={meetingsCheckbox}
                        size={"small"}
                      />
                    }
                    label={"Show Meetings"}
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={availableCheckbox}
                        onChange={(e) => showAvailableDates(e.target.checked)}
                        checked={availableCheckbox}
                        size={"small"}
                      />
                    }
                    label={"Show Availability"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {!isDesktop && view === VIEWS.MONTH && (
        <div className={"current-month"}>
          <span>{currentMonth}</span>
          <span className="year">{currentYear}</span>
        </div>
      )}
    </div>
  );
};

export default React.memo(BigCalendarHeader, (prev, next) => {
  return (
    prev.currentMonth === next.currentMonth &&
    prev.currentYear === next.currentYear &&
    prev.availableCheckbox === next.availableCheckbox &&
    prev.meetingsCheckbox === next.meetingsCheckbox &&
    prev.timezoneLabel === next.timezoneLabel
  );
});
