import axios from "axios";

export const createVerificationRequest = (data: FormData) => {
    return axios({
        method: 'POST',
        url: `${process.env.REACT_APP_API_URL}/verification/create`,
        data
    })
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}

export const getStatusVerification = () => {
    return axios({
        method: 'GET',
        url: `${process.env.REACT_APP_API_URL}/verification/get/verification/request`
    })
        .then((response) => response && response.data )
        .catch((error) =>
            error &&
            error.response &&
            error.response.message
                ? {error: error.response.message}
                : {error: 'unknown error'},
        );
}


