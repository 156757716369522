import {useEffect, useState} from "react";
import { getFaq } from "../../api/faq";

export const useFaq = (shouldFetch: boolean = true) => {
    const [faq, setFaq] = useState<any>()
    const [loading, setLoading] = useState<any>()

    useEffect(() => {
        (async () => {
            if (shouldFetch) {
                setLoading(true)
                const response = await getFaq()
                if (response) {
                    setFaq(response?.items)
                    setLoading(false)
                }
            }

        })()
    }, [shouldFetch])

    return {
        faq,
        loading
    }
}
