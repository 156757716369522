import {useEffect, useState} from "react";
import {getStatusVerification} from "../../api/face-recoginition";

export const useVerificationStatus = (shouldFetch: boolean = true) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [verificationInfo, setVerificationInfo] = useState<any[]>([])

    useEffect(() => {
        (async () => {
            if (shouldFetch) {
                setLoading(true)
                const response = await getStatusVerification()
                if (response) {
                    setVerificationInfo(response?.items)
                    setLoading(false)
                }
            }
        })()
    }, [shouldFetch])

    return {
        verificationInfo,
        loading
    }
}