import {useEffect, useState, useRef} from 'react'
import {IPostBody} from "../../interfaces/request";

export const usePagination = (request: (...args: any) => any, args: IPostBody): any[] => {
    const [newData, setNewData] = useState<any>([]);
    // eslint-disable-next-line
    const [scrollLoad, setScrollLoad] = useState<boolean>(false)
    const [pageCount, setPageCount] = useState<boolean>(false)
    const {variables} = {...args};
    const isMounted = useRef<boolean>(false);

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
        return () => document.removeEventListener('scroll', handleScroll);
    }, [scrollLoad])

    const handleScroll = async () => {
        if (document?.scrollingElement && document?.scrollingElement?.scrollHeight - Math.round(window.innerHeight + document.documentElement.scrollTop) < 300) {
            // dispatch(saveToMemory({ id:0, ...saveToMemory}));
            setPageCount(true)
        } else {
            setPageCount(false)
        }
    }
    useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (pageCount) {
                const response = await request(variables)
                if (response) {
                    if (isMounted.current) {
                        setNewData(response)
                        // setScrollLoad(true)
                    }
                }
            }
        })()
        // eslint-disable-next-line
    }, [pageCount])

    return [newData, scrollLoad]
}
