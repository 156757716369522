export const SOCKET_LISTENERS = {
    START_MEETING_NOTIFICATION: 'START_MEETING_NOTIFICATION',
    CAN_JOIN_MEETING_CONFIRMATION: 'CAN_JOIN_MEETING_CONFIRMATION',

    USER_JOINED_ROOM: 'USER_JOIN',
    USER_CONNECTED: 'USER_CONNECTED',
    USER_DISCONNECTED: 'USER_DISCONNECTED',

    NOTIFICATIONS: 'NOTIFICATIONS',

    VIDEO_UPLOADING_STATUS: "VIDEO_UPLOADING_STATUS",

    ADD_PEER: "ADD_PEER",
    SESSION_DESCRIPTION: "SESSION_DESCRIPTION",
    ICE_CANDIDATE: "ICE_CANDIDATE",
    REMOVE_PEER: "REMOVE_PEER",

    //Test Mode
    ADD_PEER_TEST: "ADD_PEER_TEST",
    SESSION_DESCRIPTION_TEST: "SESSION_DESCRIPTION_TEST",
    ICE_CANDIDATE_TEST: "ICE_CANDIDATE_TEST",
    REMOVE_PEER_TEST: "REMOVE_PEER_TEST",
    USER_DISCONNECTED_TEST: 'USER_DISCONNECTED_TEST',
    SOCKET_LISTENER_TEST: 'SOCKET_LISTENER_TEST',

    MEDIA_SETTING: "MEDIA_SETTING",
    APPLY_RESOLUTION: "APPLY_RESOLUTION"
}

export const SOCKET_EMITTERS = {
    CAN_JOIN_MEETING: 'CAN_JOIN_MEETING',
    JOIN_ROOM: 'JOIN_ROOM',
    LEAVE_CALL: 'LEAVE_CALL',
    USER_ONLINE_STATUS: 'USER_ONLINE_STATUS',
    MEETING_COMPLETED: 'MEETING_COMPLETED',
    USER_CONNECTED: "USER_CONNECTED",

    VIDEO_UPLOADING_STATUS: "VIDEO_UPLOADING_STATUS",

    RELAY_ICE: "RELAY_ICE",
    RELAY_SDP: "RELAY_SDP",
    JOIN: "JOIN",
    LEAVE: "LEAVE",

    //Test Mode

    RELAY_ICE_TEST: "RELAY_ICE_TEST",
    RELAY_SDP_TEST: "RELAY_SDP_TEST",
    JOIN_TEST: "JOIN_TEST",
    LEAVE_TEST: "LEAVE_TEST",
    SOCKET_EMITTER_TEST: 'SOCKET_EMITTER_TEST',

    GET_MEDIA_SETTINGS: "GET_USER_SETTING",
    DESIRED_RESOLUTION: "DESIRED_RESOLUTION"
}

export const SCREEN_MODE = {
    FULL_SCREEN: 'FULL_SCREEN',
    SPLIT_SCREEN: 'SPLIT_SCREEN'
}

export const QUALITY_RESOLUTION = [
    {
        label: "240p",
        value: {
            width: 352,
            height: 240
        }
    },
     {
        label: "360p",
        value: {
            width: 480,
            height: 360
        }
    },
     {
        label: "480p",
        value: {
            width: 858,
            height: 480
        }
    },
    {
        label: "720p",
        value: {
            width: 1280,
            height: 720
        }
    },
     {
        label: "1080p",
        value: {
            width: 1920,
            height: 1080
        }
    },
     {
        label: "2160p",
        value: {
            width: 3860,
            height: 2160
        }
    }
];

export const QUALITY_RESOLUTION_TEST = [
    {
        aspectRatio: "1.777",
        label: "16x9",
        resolution: [
            {
                label: "240p",
                value: {
                    width: 352,
                    height: 240
                }
            },
             {
                label: "360p",
                value: {
                    width: 480,
                    height: 360
                }
            },
             {
                label: "480p",
                value: {
                    width: 858,
                    height: 480
                }
            },
            {
                label: "720p",
                value: {
                    width: 1280,
                    height: 720
                }
            },
             {
                label: "1080p",
                value: {
                    width: 1920,
                    height: 1080
                }
            },
             {
                label: "2160p",
                value: {
                    width: 3860,
                    height: 2160
                }
            }
        ]
    },
    {
        aspectRatio: "1.333",
        label: "4x3",
        resolution: [
            {
                label: "240p",
                value: {
                    width: 320,
                    height: 240
                }
            },
            {
                label: "360p",
                value: {
                    width: 480,
                    height: 360
                }
            },
            {
                label: "480p",
                value: {
                    width: 640,
                    height: 480
                }
            },
            {
                label: "720p",
                value: {
                    width: 960,
                    height: 720
                }
            },
            {
                label: "1080p",
                value: {
                    width: 1440,
                    height: 1080
                }
            }
        ]  
    }
];
export const SHOW_TIME_LEFT_AFTER = 180;
