/* eslint-disable */
import React, {useCallback, useContext, useEffect, useState} from "react";
import Btn from "../../../components/buttons/MainButton/Button";
import {THEME} from "../../../ui/theme";
import VideocamIcon from "@material-ui/icons/Videocam";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import {PROFILE_MODES} from "../../../enum/profile-mods";
import {CONTAINERS} from "../../../enum/containers";
import {RootStateOrAny, useSelector} from "react-redux";
import {SERVICE_VARIABLE} from "../../../enum/service_variable";
import {IUser} from "../../../interfaces/user";
import {useHistory, useLocation} from "react-router";
import {ROUTES} from "../../../enum/routes";
import {BookingUser} from "../../../pages/App";
import VideoPreviewModal from "../../../components/modal/preview-video/preview-video-modal";
import SuccessOrErrorModal from "../../../components/modal/success-or-error/success-or-error-modal";
import {SUCCESS_OR_ERROR_MODAL} from "../../../enum/modal";
import {useVideoUpload} from "../../../service/video";
import {VIDEO_UPLOAD_STATUS} from "../../../enum/video";
import { HRF } from "../../../enum/booking";
import { useUserAvailableDates } from "../../../service/user/user-available-dates";

interface IProps {
    mode: typeof PROFILE_MODES.VIEW | typeof PROFILE_MODES.EDIT;
    user: IUser;
    openLoginModal?: boolean | null;
    setOpenLoginModal: React.Dispatch<React.SetStateAction<boolean>>;
    contentLength: any
}

const Navigation: React.FC<IProps> = ({mode, user, openLoginModal, setOpenLoginModal, contentLength}) => {
    const {setBookingUser} = useContext(BookingUser);
    const history = useHistory()
    const location = useLocation()
    const myData: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const hourlyBaseFactor = useSelector((state: RootStateOrAny) => state?.variable?.variables?.find((v: any) => v.key === SERVICE_VARIABLE.HBR)?.value)
    const userHourRate = useSelector((state: RootStateOrAny) => state?.variable?.variables?.find((v: any) => v.key === user?.hrf)?.value)
    // const vatRate = useSelector((state: RootStateOrAny) => state?.vat?.rate)

    const [clickMeet, setClickMeet] = useState<boolean>(false)

    const userMeetingPrice = user?.hrf === HRF.CUSTOM ? user?.custom_hosting_rate : +hourlyBaseFactor * +userHourRate
    // const priceWithTax = !!vatRate ? userMeetingPrice + (userMeetingPrice * +vatRate) : userMeetingPrice
    const isHosting = location.search === CONTAINERS.HOSTING.CONTAINER_SEARCH
    const isWallet = location.search === CONTAINERS.WALLET.CONTAINER_SEARCH
    const isReferPage = location.pathname === ROUTES.MY_PAGE.REFER_EARN
    const isProfile = location.pathname === ROUTES.MY_PAGE.PROFILE && !location.search
    const [openVideoPreview, setOpenVideoPreview] = useState<boolean>(false)

    const {availableDates} = useUserAvailableDates(4, user?.id)


    const goToContentPage = () => history.push({pathname:ROUTES.MY_PAGE.PAID_CONTENT, state:{user, buyMode: true}})

    const {
        loading,
        successModal,
        errorModal,
        fileInput,
        sizeLimitErrorModal,
        wrongFormatErrorModal,
        uploadingStatus
    } = useVideoUpload()

    useEffect(() => {
        if (!myData && clickMeet) {
            setOpenLoginModal(true)
        }
        if (!openLoginModal) {
            setClickMeet(false)
        }
    }, [clickMeet, setClickMeet, myData, openLoginModal, setOpenLoginModal])

    const bookEventWithUser = useCallback(() => {
        setClickMeet(true)
        if (myData) {
            setBookingUser(user);
            sessionStorage.setItem("bookingStartPath",location.pathname)
            history.push({pathname:ROUTES.USER_BOOKING, state:{user, origin: location.pathname, date: availableDates[0]?.date?.FormattedTimeStart.split('T')[0]}});
        }
    }, [history, myData, setBookingUser, user, availableDates])
    const renderVideoIntroText = () => {
        if (loading) {
            return <div className="video-intro-text">
                Uploading...
            </div>
        } else {
            if (uploadingStatus === VIDEO_UPLOAD_STATUS.PROCESSING) {
                return <div className="video-intro-text">
                    Processing...
                </div>
            } else {
                if (myData?.public_video_url) {
                    return <div className="video-intro-text">
                        Video Intro
                        <CreateOutlinedIcon/>
                    </div>
                } else {
                    return <label htmlFor={"video-file"}>
                        <div className="video-intro-text">
                            Video Intro <span style={{fontSize: 20, marginLeft: 10}}>+</span>
                        </div>
                    </label>
                }
            }
        }
    }

    const openPreviewIfVideoExist = () => {
        if (mode === PROFILE_MODES.EDIT) {
            if (uploadingStatus !== VIDEO_UPLOAD_STATUS.PROCESSING && myData?.public_video_url) {
                setOpenVideoPreview(true)
            }
        } else {
            user?.public_video_url && setOpenVideoPreview(true)
        }
    }

    return (
        <div className={"navigation-container"}>
            {mode === PROFILE_MODES.VIEW ? (
                <div className={"user-tab"}>
                    {availableDates?.length > 0 ?  (<Btn
                        className={`price ${(contentLength && contentLength !== 0 ) && 'half-width'}`}
                        clr={THEME.colors.white}
                        background={THEME.colors.secondaryblue}
                        onClick={bookEventWithUser}
                        content={`$${userMeetingPrice || 0}/Meet`}
                    />):
                    (<Btn
                        className={`noHover ${(contentLength && contentLength !== 0 ) && 'half-width'}`}
                        clr={THEME.colors.white}
                        background={"#9FAEF2"}
                        onClick={()=>''}
                        content={`$${userMeetingPrice || 0}/Meet`}
                    />)
                    }
                    {(!!contentLength && contentLength > 0) && <Btn
                        className={"content"}
                        clr={THEME.colors.white}
                        background={THEME.colors.secondaryblue}
                        onClick={goToContentPage}
                        content={`Click & View`}
                    />}
                    <br/>
                    {user?.public_video_url && <Btn
                        className={"video"}
                        background={THEME.colors.darkgrey}
                        clr={THEME.colors.gainsboro}
                        onClick={(e: any) => {
                            e.stopPropagation()
                            setOpenVideoPreview(true)
                        }}
                        content={
                            <>
                                Video Intro
                                <VideocamIcon style={{color: THEME.colors.lightblue}}/>
                            </>
                        }
                    />}
                </div>
            ) : (
                <div className={"my-tabs"}>
                    <Btn
                        onClick={() => history.push(ROUTES.MY_PAGE.PROFILE)}
                        background={
                            isProfile ? THEME.colors.white : THEME.colors.secondaryblue
                        }
                        clr={isProfile ? THEME.colors.secondaryblue : THEME.colors.white}
                        border={isProfile ? THEME.colors.secondaryblue : "none"}
                        content={'Profile'}
                    />
                    <Btn
                        onClick={() =>
                            history.push({
                                search: CONTAINERS.HOSTING.CONTAINER_SEARCH
                            })
                        }
                        background={
                            isHosting ? THEME.colors.white : THEME.colors.secondaryblue
                        }
                        clr={isHosting ? THEME.colors.secondaryblue : THEME.colors.white}
                        border={isHosting ? THEME.colors.secondaryblue : "none"}
                        content={'Hosting'}
                    />
                    <Btn
                        onClick={() =>
                            history.push({
                                search: CONTAINERS.WALLET.CONTAINER_SEARCH
                            })
                        }
                        background={
                            isWallet ? THEME.colors.white : THEME.colors.secondaryblue
                        }
                        clr={isWallet ? THEME.colors.secondaryblue : THEME.colors.white}
                        border={isWallet ? THEME.colors.secondaryblue : "none"}
                        content={'Wallet'}
                    />
                    <Btn
                        onClick={() => history.push(ROUTES.MY_PAGE.REFER_EARN)}
                        background={
                            isReferPage ? THEME.colors.white : THEME.colors.secondaryblue
                        }
                        clr={isReferPage ? THEME.colors.secondaryblue : THEME.colors.white}
                        border={isReferPage ? THEME.colors.secondaryblue : "none"}
                        style={{borderRight: "none"}}
                        content={'Referral'}
                    />
                    <Btn
                        background={THEME.colors.darkgrey}
                        clr={THEME.colors.white}
                        className={"video"}
                        onClick={openPreviewIfVideoExist}
                        content={renderVideoIntroText()}
                    />
                    <input
                        ref={fileInput.ref}
                        style={{display: "none"}}
                        id='video-file'
                        type='file'
                        accept="video/*"
                        onChange={fileInput.onChange}
                    />
                </div>
            )}
            {openVideoPreview && <VideoPreviewModal
                open={openVideoPreview}
                setOpen={setOpenVideoPreview}
                onComplete={mode === PROFILE_MODES.EDIT ?
                    () => fileInput?.ref?.current?.click() :
                    undefined
                }
                videoUrl={mode === PROFILE_MODES.VIEW ? user?.public_video_url : myData?.public_video_url}
            />}
            {successModal.open && (
                <SuccessOrErrorModal
                    open={successModal.open}
                    setOpen={successModal.setOpen}
                    type={SUCCESS_OR_ERROR_MODAL.SUCCESS}
                    onActionClicked={() => successModal.setOpen(false)}
                    textSucess={successModal.message}
                />
            )}
            {errorModal.open && (
                <SuccessOrErrorModal
                    open={errorModal.open}
                    setOpen={errorModal.setOpen}
                    type={SUCCESS_OR_ERROR_MODAL.ERROR}
                    onActionClicked={() => errorModal.setOpen(false)}
                />
            )}
            {sizeLimitErrorModal.open && (
                <SuccessOrErrorModal
                    open={sizeLimitErrorModal.open}
                    setOpen={sizeLimitErrorModal.setOpen}
                    type={SUCCESS_OR_ERROR_MODAL.ERROR}
                    onActionClicked={() => sizeLimitErrorModal.setOpen(false)}
                    textError={sizeLimitErrorModal.message}
                />
            )}
            {wrongFormatErrorModal.open && (
                <SuccessOrErrorModal
                    open={wrongFormatErrorModal.open}
                    setOpen={wrongFormatErrorModal.setOpen}
                    type={SUCCESS_OR_ERROR_MODAL.ERROR}
                    onActionClicked={() => wrongFormatErrorModal.setOpen(false)}
                    textError={wrongFormatErrorModal.message}
                />
            )}
        </div>
    );
};

export default Navigation;
