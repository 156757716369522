import React, { useEffect, useState } from 'react';
import axios from "axios";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'

interface PaymentProps {
    getToken: (e: string) => void
}
const PaymentForm: React.FC<PaymentProps> = (props) => {

    const [clientSecret, setClientSecret] = useState('');
    const stripePromise = loadStripe('' + process.env.REACT_APP_STRIPE_PK);

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        getClientSecretKey();
    }, []);

    const getClientSecretKey = async () => {
        const amountToCharge = 500;
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/content/paid/stripeAccount`,
            data: {
                amount: amountToCharge
            }
        })
            .then((res) => {
                setClientSecret(res?.data?.clientSecret)
            })
    }
    return (
        <>
            {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret, }}>
                    <CheckoutForm clientSecret={clientSecret} getToken={props.getToken} />
                </Elements>
            )}
        </>
    );
}

export default PaymentForm;