import React, {useMemo, useRef} from "react";
import {Avatar, IconButton} from "@material-ui/core";
import {useHistory} from "react-router";
import {ROUTES} from "../../enum/routes";
import MeetingPlayIcon from '../../assets/icons/play-circle.svg'
import {useVariableValues} from "../../service/system/variable-values";
import {SERVICE_VARIABLE} from "../../enum/service_variable";
import {useRealTime} from "../../service/time/current-time";
import {IUser} from "../../interfaces/user";
import {RootStateOrAny, useSelector} from "react-redux";

interface IProps {
    meetingNotification: any,
}

const MeetingNotification: React.FC<IProps> = ({meetingNotification}) => {
    const myData: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const interlocator = myData?.id !== meetingNotification?.event?.initiator_user?.id ?
        meetingNotification?.event?.initiator_user :
        meetingNotification?.event?.host_user
    const {variableValue: hostingGracePeriod} = useVariableValues(SERVICE_VARIABLE.HOSTING_USER_GRACE_PERIOD)
    const {variableValue: fanUserGracePeriod} = useVariableValues(SERVICE_VARIABLE.FAN_USER_GRACE_PERIOD)
    const {currentTimeInUnix} = useRealTime()
    const history = useHistory();
    const ref = useRef<HTMLDivElement>(null)
    const initiatorUserAvatar = interlocator?.public_avatar_url
    const initiator_first_name = !!interlocator?.first_name
        ? interlocator?.first_name
        : "";
    const initiator_last_name = !!interlocator?.last_name
        ? interlocator?.last_name
        : "";
    const event_public_identifier = meetingNotification?.event?.public_identifier

    const imHostingUser = myData?.id === meetingNotification?.event?.host_user?.id
    const meeting_time_start = meetingNotification?.event?.slots[0]?.time_start

    const notificationAction = () => {
        history.push(`${ROUTES.MY_PAGE.MEETING_MODE}/${event_public_identifier}`);
    };

    const hideMeetingNotification = useMemo(() => {
        if (imHostingUser) {
            return currentTimeInUnix >= (+hostingGracePeriod + +meeting_time_start)
        } else {
            return currentTimeInUnix >= (+fanUserGracePeriod + +meeting_time_start)
        }
    }, [currentTimeInUnix, fanUserGracePeriod, hostingGracePeriod, imHostingUser, meeting_time_start])

    return (
        <div
            className="meeting-notification-container"
            style={{display: hideMeetingNotification ? 'none' : 'flex'}}
            ref={ref}
        >
            <div className="meeting-notification">
                <div>
                    <Avatar className="user-avatar" src={initiatorUserAvatar}/>
                    <p>{`You are having a meeting with ${initiator_first_name} ${initiator_last_name} in a minute...`}</p>
                </div>
                <div>
                    <IconButton className="styled-button" onClick={notificationAction}>
                        <img src={MeetingPlayIcon} alt={'Join webrtc'}/>
                    </IconButton>
                </div>
            </div>
            <div className="warning-message">
                <span>If you have any other video sessions opened, please close them now; else you may not be able to join.</span>
            </div>
        </div>
    );
};

export default MeetingNotification
