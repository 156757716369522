import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react"
import {uploadVideoFile} from "../../api/video";
import {SocketsContext} from "../../pages/App";
import {SOCKET_EMITTERS, SOCKET_LISTENERS} from "../../enum/meeting";
import {VIDEO_UPLOAD_STATUS} from "../../enum/video";
import {IUser} from "../../interfaces/user";
import {RootStateOrAny, useSelector} from "react-redux";
import {MAX_VIDEO_SIZE_BYTES} from "../../enum/asset-size";
import {FILE_ERRORS} from "../../enum/errors";

const SUCCESSFULLY_UPLOADED = "Your video was uploaded successfully. It will be added to your Chusp profile in the next 24 hours"

export const useVideoUpload = () => {
    const myData: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const {socket} = useContext(SocketsContext)
    const inputRef = useRef<HTMLInputElement>(null)
    const [videoFile, setVideoFile] = useState<File | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [uploadingStatus, setUploadingStatus] = useState<string>("")

    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)
    const [openSizeLimitModal, setOpenSizeLimitModal] = useState<boolean>(false)
    const [openWrongFormatModal, setOpenWrongFormatModal] = useState<boolean>(false)

    const [countsWaitedForStatus, setCountWaitedForStatus] = useState<number>(0)
    const videoStatusFromSessionStorage = sessionStorage.getItem("video_processing")

    useEffect(() => {
        if (videoStatusFromSessionStorage) {
            setUploadingStatus(videoStatusFromSessionStorage)
        }
    }, [videoStatusFromSessionStorage])

    useEffect(() => {
        const currentStatus = videoStatusFromSessionStorage ? videoStatusFromSessionStorage : uploadingStatus
        if (currentStatus === VIDEO_UPLOAD_STATUS.PROCESSING) {
            setTimeout(() => {
                setCountWaitedForStatus(prev => prev + 1)
                socket && socket.emit(SOCKET_EMITTERS.VIDEO_UPLOADING_STATUS, {userId: myData?.id})
            }, 60000)
        }
    }, [videoStatusFromSessionStorage, myData, socket, uploadingStatus, countsWaitedForStatus])

    useEffect(() => {
        socket && socket.on(SOCKET_LISTENERS.VIDEO_UPLOADING_STATUS, async (data) => {
            setUploadingStatus(data?.status)
            if (data?.status === VIDEO_UPLOAD_STATUS.COMPLETED) {
                sessionStorage.removeItem("video_processing")
            }
        })
    }, [socket])

    useEffect(() => {
        (async () => {
            if (videoFile) {
                setLoading(true)
                sessionStorage.setItem("video_loading", "loading")
                const formData = new FormData()
                formData.append("file", videoFile)
                const response = await uploadVideoFile(formData)
                if (response) {
                    setLoading(false)
                    sessionStorage.removeItem("video_loading")
                    setVideoFile(null)
                }
                if (response && response?.data) {
                    setOpenSuccessModal(true)
                    socket && socket.emit(SOCKET_EMITTERS.VIDEO_UPLOADING_STATUS, {userId: myData?.id})
                    sessionStorage.setItem("video_processing", VIDEO_UPLOAD_STATUS.PROCESSING)
                }
                if (response && response?.error) {
                    setOpenErrorModal(true)
                }
            }
        })()
        // eslint-disable-next-line
    }, [videoFile])

    useEffect(() => {
        if (!videoFile && inputRef.current) {
            inputRef.current.value = ""
        }
    }, [videoFile])

    const onChange = useCallback((e: any) => {
        let file = e.target?.files[0];
        if (file) {
            if (file?.type?.includes("video")) {
                if (file?.size <= MAX_VIDEO_SIZE_BYTES) {
                    setVideoFile(file)
                } else {
                    setOpenSizeLimitModal(true)
                }
            } else {
                setOpenWrongFormatModal(true)
            }
        }
    }, [])

    return {
        loading: sessionStorage.getItem("video_loading") ? sessionStorage.getItem("video_loading") : loading,
        successModal: {
            open: openSuccessModal,
            setOpen: setOpenSuccessModal,
            message: SUCCESSFULLY_UPLOADED
        },
        errorModal: {
            open: openErrorModal,
            setOpen: setOpenErrorModal
        },
        wrongFormatErrorModal: {
            open: openWrongFormatModal,
            setOpen: setOpenWrongFormatModal,
            message: FILE_ERRORS.WRONG_FORMAT_ERROR_MESSAGE
        },
        sizeLimitErrorModal: {
            open: openSizeLimitModal,
            setOpen: setOpenSizeLimitModal,
            message: FILE_ERRORS.MAX_VIDEO_SIZE_ERROR_MESSAGE
        },
        fileInput: {
            ref: inputRef,
            onChange
        },
        uploadingStatus: useMemo(() => uploadingStatus, [uploadingStatus])
    }
}