import {useCallback, useEffect, useState} from "react";
import {IPopScoreResponse} from "../../interfaces/score";
import {getPopScoreByUser} from "../../api/score";


export const useUsersPopScore = (userId: number | undefined, shouldFetch: boolean = true) => {
    const [popScoreResponse, setPopScoreResponse] = useState<IPopScoreResponse[]>([])

    const getStarsCounterByEnumValue = useCallback((amountOfStars: number) => {
        if (!!popScoreResponse?.length) {
            const starsArr = popScoreResponse?.filter((score) => score.count === amountOfStars)
            return starsArr?.length
        } else {
            return 0
        }
    }, [popScoreResponse])

    const totalScore = !!popScoreResponse?.length ? popScoreResponse?.length : 0
    useEffect(() => {
        (async () => {
            if (userId && shouldFetch) {
                const response = await getPopScoreByUser(userId)
                if (response) {
                    setPopScoreResponse(response?.data)
                }
            }
        })()
        // eslint-disable-next-line
    }, [userId, shouldFetch])

    return {
        getStarsCounterByEnumValue,
        totalScore
    }
}
