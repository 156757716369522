export const USER_ROLE = {
    ANONYMOUS: "ANONYMOUS",
    VU: "VU",
    HU: "HU",
    ADU: "ADU"
};
export const  userMetaTypes = {
    LANGUAGE: "LANGUAGE",
    LOCATION: "LOCATION",
    GENDER: "GENDER",
    BASIC_INFO: "BASIC_INFO",
    ABOUT_USER: "ABOUT_USER",
    ACCOMPLISHMENTS: "ACCOMPLISHMENTS",
    WORK: "WORK",
    COLLEGE: "COLLEGE",
    WEBSITES: 'WEBSITES',
    EXTERNAL_IG_REF: "EXTERNAL_IG_REF",
    EXTERNAL_AWS_REF: "EXTERNAL_AWS_REF",
}

export const transactionTargetMerch = {
    EVENT: "EVENT",
    CHUSP_CREDIT: "CHUSP_CREDIT",
    PAID_CONTENT: "PAID_CONTENT",
    EXTERNAL_EARNINGS: 'EXTERNAL_EARNINGS'
};
