import React, {CSSProperties} from 'react'
import SignUpForm from "../../components/form/signup";

const STYLES_CONTAINER: CSSProperties = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flex: 0.25,
    backgroundColor: "#ffffff",
    padding: "30px 15px"
}


const SignUpContainer: React.FC = () => {
    return (
        <div style={STYLES_CONTAINER}>
            <SignUpForm/>
        </div>
    )
}

export default SignUpContainer
