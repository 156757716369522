import React, {useState} from 'react'
import Checkbox from '@material-ui/core/Checkbox';
import {IUser} from "../../../../interfaces/user";
import Modal from '../../../../components/modal/main-modal/modal';
import {createTicket} from '../../../../api/create-ticket';
import {TICKET_TYPE} from '../../../../enum/tickets';
import {TICKET_POST_REASON, TICKET_PROFILE_REASON, TICKET_PAID_CONTENT_REASON} from '../../../../enum/ticket-reasons';
import SuccessOrErrorModal from '../../../../components/modal/success-or-error/success-or-error-modal';
import {SUCCESS_OR_ERROR_MODAL} from '../../../../enum/modal';
import {FormControlLabel} from "@material-ui/core";

interface IProps {
    user?: IUser;
    postId?: number;
    paidContentId?: number;
    openReportModal: boolean,
    setOpenReportModal: React.Dispatch<React.SetStateAction<boolean>>,
    isRedirectedFromPaidContent: boolean
}

const ReportModal: React.FC<IProps> = ({user, postId, paidContentId, openReportModal, setOpenReportModal, isRedirectedFromPaidContent}) => {
    const [reasonForReport, setReasonForReport] = useState<any>([])
    const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false)
    const [openErrorModal, setOpenErrorModal] = useState<boolean>(false)

    const setTheReasonOfTheReport = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setReasonForReport([...reasonForReport, event.target.value])
        } else {
            let reasons = [...reasonForReport];
            reasons = reasons.filter((el) => {
                return el !== event.target.value
            });
            setReasonForReport(reasons)
        }
    }

    const submitProfileForm = async () => {
        const response = await createTicket({
            type: TICKET_TYPE.INDIVIDUAL_USER_REPORT,
            reportee_id: user?.id,
            reasons: reasonForReport
        })
        if (response && response.error) {
            setOpenErrorModal(true)
        } else {
            setOpenSuccessModal(true)
        }
    }

    const submitPostForm = async () => {
        const response = await createTicket({
            type: TICKET_TYPE.INDIVIDUAL_POST_REPORT,
            reportee_id: user?.id,
            reasons: reasonForReport,
            post_id: postId
        })
        if (response && response.error) {
            setOpenErrorModal(true)
        } else {
            setOpenSuccessModal(true)
        }
    }
    
    const submitPaidContentForm = async () => {
        const response = await createTicket({
            type: TICKET_TYPE.INDIVIDUAL_PAID_CONTENT_REPORT,
            reportee_id: user?.id,
            reasons: reasonForReport,
            paid_content_id: paidContentId
        })
        if (response && response.error) {
            setOpenErrorModal(true)
        } else {
            setOpenSuccessModal(true)
        }
    }

    const closeResponseModal = () =>{
        openSuccessModal && setOpenSuccessModal(false)
        openErrorModal && setOpenErrorModal(false)
        setOpenReportModal(false)
    }

    return (
        <>
            <Modal
                open={openReportModal}
                setOpen={() =>{}}
                title={postId 
                    ? 'Why are you reporting this post?' 
                    : isRedirectedFromPaidContent
                    ? 'Why are you reporting this content?'
                    : 'Why are you reporting this profile?'}
                leftButton={<span onClick={()=>setOpenReportModal(false)}>Cancel</span>}
                rightButton={'Report'}
                disabled={!reasonForReport.length}
                onComplete={postId 
                    ? submitPostForm 
                    : isRedirectedFromPaidContent
                    ? submitPaidContentForm
                    : submitProfileForm}
                children={
                    <div className={"report-modal-container"}>
                        {postId
                            ? Object.values(TICKET_POST_REASON).map((item, index) => (
                                <div className={"report-option"} key={index}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={(e) => setTheReasonOfTheReport(e)}
                                            value={item.type}
                                        />}
                                        label={item.text}
                                    />
                                </div>
                            ))
                            : isRedirectedFromPaidContent 
                            ? Object.values(TICKET_PAID_CONTENT_REASON).map((item, index) => (
                                <div className={"report-option"} key={index}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={(e) => setTheReasonOfTheReport(e)}
                                            value={item.type}
                                        />}
                                        label={item.text}
                                    />
                                </div>
                            ))
                            : Object.values(TICKET_PROFILE_REASON).map((item, index) => (
                                <div className={"report-option"} key={index}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={(e) => setTheReasonOfTheReport(e)}
                                            value={item.type}
                                        />}
                                        label={item.text}
                                    />
                                </div>
                            ))}
                    </div>
                }
            />
            {openSuccessModal && <SuccessOrErrorModal
                open={openSuccessModal}
                setOpen={closeResponseModal}
                type={SUCCESS_OR_ERROR_MODAL.SUCCESS}
            />}
            {openErrorModal && <SuccessOrErrorModal
                open={openErrorModal}
                setOpen={closeResponseModal}
                type={SUCCESS_OR_ERROR_MODAL.ERROR}
            />}
        </>
    )
}

export default ReportModal;
