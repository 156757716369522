import axios from 'axios'

export const getIP = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_FETCH_IP_URL}`
    })
        .then((response) => response && response?.data)
        .catch((err) => console.log(err))
}

export const sendIP = (data: {ip: string, timezone: string}) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/auth/location`,
        data: data
    })
}


export const detectVPN = (ip: string) => {
    const data = {
        api: `${process.env.REACT_APP_DETECT_VPN_URL}${ip}`
    }
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/public/api/ipquality`,
        data: data
    })
        .then((response) => response && response?.data)
        .catch((err) => console.log({ err }))
}