import React, {useEffect} from "react";
import OptionWithIconAndText from "../../../components/option-with-icon-and-text";
import PaymentOutlinedIcon from "@material-ui/icons/PaymentOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import HistoryOutlinedIcon from "@material-ui/icons/HistoryOutlined";
import QueueOutlinedIcon from "@material-ui/icons/QueueOutlined";
import EqualizerOutlinedIcon from "@material-ui/icons/EqualizerOutlined";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {useWindowSize} from "../../../service/common/window";
import {useHistory} from 'react-router'
import {ROUTES} from "../../../enum/routes";
import {useCreditBalance} from "../../../service/credit-balance/credit-balance";
import {SEARCH_STEPS} from "../../../enum/steps";
import TextWithDecoration from "../../../components/text-with-decoration/TextWithDecoration";


interface IProps {
    earnings: number
}

const Wallet: React.FC<IProps> = ({earnings}) => {
    const history = useHistory()
    const {isDesktop, isMobile, isTable} = useWindowSize();
    const {creditBalance} = useCreditBalance(!!isMobile || !!isTable)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={"wallet-container"}>
            {!isDesktop && (
                <div className={"stats"}>
                    <div className={"option-wrapper"}>
                        <span>${earnings}</span>
                        <span>Earnings</span>
                    </div>
                    <div className={"option-wrapper"}>
                        <span>{creditBalance}</span>
                        <span>Credits</span>
                    </div>
                </div>
            )}
            <TextWithDecoration top={5} bottom={10} content={'Payments'} />
            <div className={"grid"}>
                <OptionWithIconAndText
                    icon={<PaymentOutlinedIcon/>}
                    text={"Payment Cards"}
                    isAvailable={true}
                    onClick={() => history.push(ROUTES.MY_PAGE.CARDS)}
                />
                <OptionWithIconAndText
                    icon={<MonetizationOnOutlinedIcon/>}
                    text={"Payout Settings"}
                    isAvailable={true}
                    onClick={() => history.push({
                        pathname: ROUTES.MY_PAGE.PAYOUT_SETTINGS,
                        search: SEARCH_STEPS.FIRST_STEP
                    })}
                />
                <OptionWithIconAndText
                    icon={<QueueOutlinedIcon/>}
                    text={"Buy Credits"}
                    isAvailable={true}
                    onClick={() => history.push({
                        pathname: ROUTES.MY_PAGE.ADD_CREDITS,
                        search: SEARCH_STEPS.FIRST_STEP
                    })}
                />
                <OptionWithIconAndText
                    icon={<AccountBalanceWalletIcon/>}
                    text={"Credit Account"}
                    isAvailable={true}
                    onClick={() => history.push(ROUTES.MY_PAGE.CREDIT_ACCOUNT)}
                />
            </div>
            <TextWithDecoration top={5} bottom={10} content={'Earnings'} />
            <div className={"grid"}>
                <OptionWithIconAndText
                    icon={<EqualizerOutlinedIcon/>}
                    text={"Earnings Account"}
                    isAvailable={true}
                    onClick={() => history.push(ROUTES.MY_PAGE.EARNINGS_ACCOUNT)}
                />
                <OptionWithIconAndText
                    icon={<HistoryOutlinedIcon/>}
                    text={"Payout History"}
                    isAvailable={true}
                    onClick={() => history.push(ROUTES.MY_PAGE.PAYOUT_HISTORY)}
                />
            </div>
        </div>
    );
};

export default Wallet;
