import React, {createContext, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {RootStateOrAny, useSelector} from "react-redux";
import {Switch, Route, Redirect} from "react-router-dom";
import {ROUTES} from "../../enum/routes";
import {useLocation} from "react-router";
import {useCookie} from "react-use";
import {AUTH_KEY} from "../../enum/auth";
import {Socket} from "socket.io-client";
import MeetingNotification from "../../components/notifications/meeting-notification.";
import {IUser} from "../../interfaces/user";
import CommonNotification from "../../components/notifications/common-notifications";
import {useWindowSize} from "../../service/common/window";
import BadInternetConnectionModal from "../../components/modal/bad-internet/bad-internet-connection";
import {BAD_OR_LOST_CONNECTION_MODAL} from "../../enum/modal";
import ChuspLoader from "../../components/loader/ChuspLoader";
import {useInternetConnectionState} from "../../service/connection/internet-connection";
import {useRedirectToTheSameRoute} from "../../service/redirection-same-page/redirect-same-page";
import {useSocketInstance} from "../../service/socket";
import {useMeetingNotification} from "../../service/notifications/meeting-notification";
import {useCommonNotifications} from "../../service/notifications/common-notifications";
import BeClient from "./Details/be-client";
import BeHost from "./Details/be-host";
import OopsPage from "./OopsPage";
import PublicContainer from "./../../pages/App/Public/index";
import PrivateContainer from "./../../pages/App/Private/index";
import {useAuthGuard} from "../../service/authentication/auth-guard";
import BeCreator from "./Details/be-creator";

type ContextValues = {
    socket: Socket | null;
};

export const SocketsContext = createContext<ContextValues>({
    socket: null,
});
export const BookingUser = createContext<any>({
    bookingUser: undefined,
    setBookingUser: () => {
    },
});
export const OpenUser = createContext<any>({
    openUser: undefined,
    setOpenUser: () => {
    },
});
export const PostsUser = createContext<any>({
    postsUser: undefined,
    setPostsUser: () => {
    },
});

const InternalApp: React.FC = () => {
    const {socket} = useSocketInstance()
    const {refetchAfterreloadLoading} = useRedirectToTheSameRoute()
    useAuthGuard()
    const {userHasBadInternetConnection, userLostInternetConnection} = useInternetConnectionState()
    const {pathname} = useLocation()
    const {isDesktop} = useWindowSize()
    const [values] = useCookie(AUTH_KEY)

    const [bookingUser, setBookingUser] = useState<IUser>()
    const [openUser, setOpenUser] = useState<IUser>()
    const [postsUser, setPostsUser] = useState()

    const [lostInternetConnection, setLostInternetConnection] = useState<boolean>(false)
    const [badInternetConnection, setBadInternetConnection] = useState<boolean>(false)

    const user: IUser = useSelector((state: RootStateOrAny) => state.auth.user?.user)
    const isMeetingMode = pathname.includes(ROUTES.MY_PAGE.MEETING_MODE)


    const {meetingNotification, resetMeetingNotification} = useMeetingNotification(socket)
    const {closeNotification, commonNotifications} = useCommonNotifications(socket)
    const resetMeetingNotificationInterval = useRef<any>()

    useEffect(() => {
        if (meetingNotification) {
            resetMeetingNotificationInterval.current = setTimeout(() => {
                resetMeetingNotification()
            }, 360000)
        }

        return () => {
            clearTimeout(resetMeetingNotificationInterval.current)
        }
        // eslint-disable-next-line
    }, [meetingNotification])

    useEffect(() => {
        userHasBadInternetConnection ? setBadInternetConnection(true) : setBadInternetConnection(false)
    }, [userHasBadInternetConnection])

    useEffect(() => {
        userLostInternetConnection ? setLostInternetConnection(true) : setLostInternetConnection(false)
    }, [userLostInternetConnection])

    const contextInitialValue = {
        socket: useMemo(() => socket, [socket]),
    };

    const bookingValues = {
        bookingUser: useMemo(() => bookingUser, [bookingUser]),
        setBookingUser: useCallback((user: IUser) => setBookingUser(user), [setBookingUser]),
    };

    const postsValues = {
        postsUser: useMemo(() => postsUser, [postsUser]),
        setPostsUser: useCallback((post: any) => setPostsUser(post), [setPostsUser]),
    };

    const openUserValues = {
        openUser: useMemo(() => openUser, [openUser]),
        setOpenUser: useCallback((user: IUser) => setOpenUser(user), [setOpenUser]),
    };

    return (
        <SocketsContext.Provider value={contextInitialValue}>
            {refetchAfterreloadLoading && <ChuspLoader/>}
            <OpenUser.Provider value={openUserValues}>
                <BookingUser.Provider value={bookingValues}>
                    <PostsUser.Provider value={postsValues}>
                        <Switch>
                            <Route exact path={ROUTES.BE_CLIENT} render={() => <BeClient/>}/>
                            <Route exact path={ROUTES.BE_HOST} render={() => <BeHost/>}/>
                            <Route exact path={ROUTES.BE_CREATOR} render={() => <BeCreator/>}/>
                            <Route component={OopsPage} path={ROUTES.OOPS}/>
                            <Route path={ROUTES.ROOT_PUBLIC} component={PublicContainer}/>
                            <Route path={ROUTES.ROOT_PRIVATE} component={PrivateContainer}/>
                            {user && values ? (
                                <Redirect to={ROUTES.USER_LIST}/>
                            ) : (
                                <Redirect to={ROUTES.ROOT_AUTH}/>
                            )}
                        </Switch>
                    </PostsUser.Provider>
                </BookingUser.Provider>
            </OpenUser.Provider>
            <div className="common-notification-container">
                {!isMeetingMode && meetingNotification &&
                <MeetingNotification meetingNotification={meetingNotification}/>}
                {isDesktop && !isMeetingMode &&
                <div className={`notification-container ${!!meetingNotification && 'active-notification-container'}`}>
                    {!!commonNotifications &&
                    commonNotifications?.slice(0, 3)?.map((notification, index) => (
                        <CommonNotification
                            order={index}
                            notification={notification}
                            onClick={() => closeNotification(notification.id)}
                        />
                    ))}
                </div>}
                {lostInternetConnection && <BadInternetConnectionModal
                    open={lostInternetConnection}
                    setOpen={setLostInternetConnection}
                    type={BAD_OR_LOST_CONNECTION_MODAL.BAD_CONNECTION}
                />}
                {badInternetConnection && <BadInternetConnectionModal
                    open={badInternetConnection}
                    setOpen={setBadInternetConnection}
                    type={BAD_OR_LOST_CONNECTION_MODAL.LOST_CONNECTION}
                />}
            </div>
        </SocketsContext.Provider>
    );
};

export default InternalApp;
