import axios from "axios";
import { IRequestContent } from "../interfaces/paid-content";
import { paginationInterface } from "../interfaces/paid-content";
// import { IBuyChuspCredits } from "../interfaces/booking";

export const createContent = (data: IRequestContent) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/content/paid/create`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
}
export const uploadContent = (data: FormData) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/content/paid/upload`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
}

export const getPaidContentList = async (data: paginationInterface) => {
    const resData = await axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/content/paid/list`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
    return resData;
};

export const getPaidContentListById = async (id: string) => {
    const resData = await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/content/paid/${id}`
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
    return resData;
};

export const removeContentFile = async (contentId: string, fileId: string) => {
    await axios({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/content/paid/delete/${contentId}/item/${fileId}`
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
};
export const updateStatus = async (data: { status: string }, id: string) => {
    await axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_API_URL}/content/paid/update/${id}`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
};
export const purchaseContent = (data: any) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/content/paid/purchase`,
        data
    })
        .then((response) =>   {
        if (response && response.data) {
            localStorage.setItem("checkoutSessionId", response.data.data?.checkoutSessionId)
            return response.data;
        }
    })
        .catch((error) =>
            error &&
                //    error.response &&
                error.message
                ? { error: error.response }
                : { error: 'unknown error' },
        );
};
export const getContentDetails = (externalIdParam: any) => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/content/paid/details/${externalIdParam}`
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
};

export const updateContentDuration = async (data: { duration: number }, id: number) => {
    await axios({
        method: "PATCH",
        url: `${process.env.REACT_APP_API_URL}/content/paid/update-content-duration/${id}`,
        data
    })
        .then((response) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
};