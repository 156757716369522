import axios, { AxiosResponse } from 'axios'
import { IGetEarningHistory, IGetRemittanceHistory } from '../interfaces/finance';
import { IBuyChuspCredits } from "../interfaces/booking";

export const paypalAuth = (code: string) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/finance/auth/paypal`,
        data: { code }
    })
        .then((response: AxiosResponse) => response && response?.data)
        .catch((error) => error.response?.data);
}


export const getCreditBalance = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/finance/get/credits/balance`
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
}

export const buyCredits = (data: IBuyChuspCredits) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/finance/buy/credits`,
        data
    })
        .then((response: AxiosResponse) => {
            if (response && response.data) {
                localStorage.setItem("checkoutSessionId", response.data.data?.checkoutSessionId)
                return response.data;
            }
        })
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
}

export const getPurchaseCreditsDetails = (external_id: string) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/finance/credit/details/get/${external_id}`,
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
}

export const getEarningHistory = (data: IGetEarningHistory) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/finance/earning/history`,
        data
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
}

export const getRemittanceHistory = (data: IGetRemittanceHistory) => {
    return axios({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/finance/remitted/history`,
        data
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
}
export const getCurrentBalance = () => {
    return axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/finance/balance/my`
    })
        .then((response: AxiosResponse) => response && response.data)
        .catch((error) =>
            error &&
                error.response &&
                error.response.message
                ? { error: error.response.message }
                : { error: 'unknown error' },
        );
}





