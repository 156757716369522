export const options = [
    {
        description: 'Connect personally and in real-time with people you look up to. Just browse our host user community and book 1-to-1 video meetings with those you admire and who deserve your time and money.'
    },
    {
        description: 'Role models whom till now you could only follow and admire from afar: on Chusp, you can now meet them real-time, in the privacy and exclusivity of a Chusp video session.'
    },
    {
        description: 'Whether it’s meaningful connections with mentors or life-changing encounters with artists, experts and social media hotshots: Chusp gives you direct access to a global host user community with the ease of a few clicks.'
    },
    {
        description: 'You can even invite people you fancy to join Chusp as Host Users so that you finally have the opportunity to meet them personally. '
    },
    {
        description: 'Last but not least, being a fan user doesn’t limit you from hosting meetings yourself: on Chusp you can play any role you want, at any time - as host, as fan, or both. Use Chusp the way you choose. '
    },
]

export const header = 'Be a Chusp Fan'

export const buttonName = {first:'Be a Chusp Host', second: 'Be a Chusp Creator'}

