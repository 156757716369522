import React, { useEffect, useRef, useState } from "react";
import { Form, Formik, FieldArray } from "formik";
import { IFormikValues } from "../../../../interfaces";
import InputField from "../../../../components/field/Field";
import { THEME } from "../../../../ui/theme";
import { SignupSchema } from "../../../../config/form-validation/validation-schema";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import Btn from "../../../../components/buttons/MainButton/Button";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../enum/routes";
import { useWindowSize } from "../../../../service/common/window";
import { useAuth } from "../../../../service/authentication/auth";
import MailOptions from "../mailoptions";
import Modal from "../../../../components/modal/main-modal/modal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { LIST_OF_COUNTRIES } from "../../../../enum/list-of-countries";
import { useSearchParam } from "react-use";
import ChuspLoader from "../../../../components/loader/ChuspLoader";
import StyledButtonComponent from "../../../../components/buttons/AnimatedButton/animated-button";
import { useUsersIPLocation } from "../../../../service/IP";
import SuccessOrErrorModal from "../../../../components/modal/success-or-error/success-or-error-modal";
import { SUCCESS_OR_ERROR_MODAL } from "../../../../enum/modal";
import { useHistory } from "react-router";
// import FacebookLogin from "react-facebook-login";
// import fbIcon from "../../../../assets/img/icons/facebookLogo.png";
import { useGoogleLogin } from "@react-oauth/google";
import googleIcon from "../../../../assets/img/icons/gmail.svg";
import wechatIcon from "../../../../assets/img/icons/wechat-logo.svg";
import {
  // facebookCheckIfAccountExist,
  // facebookLoginRequest,
  // facebookSignUpWithEmailRequest,
  googleLoginRequest,
  uniqueNameCheck,
} from "../../../../api/auth";
import { popUpWindow } from "../../../../utils/window-popup";

interface InitialValues extends IFormikValues {
  name: string;
  residence: string;
  acceptTerms: boolean;
}

const SignUpForm: React.FC = () => {
  const { register, confirmSignUp, error, confirmEmailLoading } = useAuth();
  const history = useHistory();
  const { isDesktop } = useWindowSize();
  const formikRef = useRef<any>();
  const token = useSearchParam("token");
  const [open, setOpen] = useState<boolean>(false);
  const { countryIp } = useUsersIPLocation();

  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [isUniqueName, setIsUniqueName] = useState<boolean>(true);
  const [nameErrorMessage, setNameErrorMessage] = useState<string>(
    "User name already taken. Please choose a different one"
  );

  const initialValues: InitialValues = {
    name: "",
    email: "",
    residence: "",
    password: "",
    acceptTerms: false,
  };

  useEffect(() => {
    if (formikRef?.current) {
      formikRef?.current?.setFieldValue("residence", countryIp);
    }
  }, [countryIp]);

  useEffect(() => {
    (async () => {
      if (token) {
        await confirmSignUp({ token, residence_country: countryIp });
      }
    })();
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (
      error &&
      error !== "User name already taken. Please choose a different one"
    ) {
      setOpenErrorModal(true);
    } else {
      setOpenErrorModal(false);
    }
  }, [error]);

  const { doAuthWithSocials } = useAuth();

  // const [openUserBlockedModalWindow, setOpenUserBlockedModalWindow] = useState<boolean>(false)
  // const [authCheckErrorMessage, setAuthCheckErrorMessage] = useState<string>("")

  const googleRequest = useGoogleLogin({
    onSuccess: async (codeResponse) =>
      codeResponse &&
      codeResponse.code &&
      (await doAuthWithSocials(googleLoginRequest, {
        code: codeResponse.code,
        residence_country: countryIp,
      })),
    flow: "auth-code",
  });

  // const loginWithFacebook = async (response: any) => {
  //   if (response.accessToken) {
  //     const data = await facebookCheckIfAccountExist(response?.accessToken);
  //     if (data && data?.message) {
  //       // setOpenUserBlockedModalWindow(true)
  //       // setAuthCheckErrorMessage(data?.message)
  //     }
  //     const alreadyExist = data?.data?.existInDB;
  //     const facebookWithEmail = data?.data?.hasEmail;
  //     alreadyExist
  //       ? await doAuthWithSocials(facebookLoginRequest, {
  //           token: response?.accessToken,
  //         })
  //       : facebookWithEmail
  //       ? await doAuthWithSocials(facebookSignUpWithEmailRequest, {
  //           token: response?.accessToken,
  //           residence_country: countryIp,
  //         })
  //       : history.push({
  //           pathname: ROUTES.AUTH_EMAIL_ADD,
  //           state: { token: response.accessToken },
  //         });
  //   }
  // };

  const weChatUrl = process.env.REACT_APP_WE_CHAT_LOGIN_URL || "";
  const loginWechatRes = () => {
    popUpWindow(weChatUrl, "WeChat", 500, 500);
  };

  const handleBlurToCheckUniqueName = async (e: any) => {
    const response = await uniqueNameCheck({
      first_name: e.target.value.trim(),
    });
    if (response?.data === "success") {
      setNameErrorMessage(response.data);
      setIsUniqueName(true);
    } else {
      setIsUniqueName(false);
      setNameErrorMessage(response?.message);
    }
  };

  return (
    <Formik
      innerRef={(element) => (formikRef.current = element)}
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={async (values) => {
        const { name, email, password, residence } = values;
        const response = await register({
          first_name: name,
          email,
          password,
          residence,
        });
        // @ts-ignore
        if (response && response?.data) {
          await setOpen(true);
        }
      }}
      validateOnBlur={true}
    >
      {({
        values: { name, email, password, acceptTerms, residence },
        handleSubmit,
        setFieldValue,
        handleChange,
        touched,
        handleBlur,
        errors,
      }) => {
        return (
          <Form className="form-container signup" onSubmit={handleSubmit}>
            {confirmEmailLoading && <ChuspLoader />}
            <div className="signup-name-input-wrapper">
              <InputField
                name={"name"}
                placeholder={isDesktop ? "Your name" : ""}
                title={isDesktop ? "" : "Your name"}
                type={"text"}
                value={name}
                onChange={handleChange}
                onBlur={(e) => {
                  handleBlur(e);
                  handleBlurToCheckUniqueName(e);
                }}
                error={errors.name && touched.name}
                classname={`${isDesktop ? "sign-in-form-field" : ""}`}
              />
              {!!errors.name && !!touched.name ? (
                <div className="tip-text">{errors.name}</div>
              ) : isUniqueName ? (
                <div className={`tip-text-info`}>
                  Use your common name to make your profile searchable &
                  credible
                </div>
              ) : error ===
                "User name already taken. Please choose a different one" ? (
                <div className="tip-text same-name-tip-text">
                  {nameErrorMessage}
                </div>
              ) : (
                <div className="tip-text same-name-tip-text">
                  {nameErrorMessage}
                </div>
              )}
            </div>

            <div className="signup-input-wrapper">
              <FieldArray
                name={"email"}
                render={(arrayHelpers) => {
                  return (
                    <>
                      <InputField
                        name={"email"}
                        placeholder={isDesktop ? "Email" : ""}
                        title={isDesktop ? "" : "Email"}
                        type={"email"}
                        value={arrayHelpers.form.values.email}
                        onChange={arrayHelpers.form.handleChange}
                        onBlur={handleBlur}
                        error={
                          arrayHelpers.form.errors.email &&
                          arrayHelpers.form.touched.email
                        }
                        classname={`${isDesktop ? "sign-in-form-field" : ""}`}
                      />
                      {!isDesktop && (
                        <MailOptions
                          setFieldValue={setFieldValue}
                          arrayHelpers={arrayHelpers}
                        />
                      )}
                    </>
                  );
                }}
              />
              {((!!errors.email && !!touched.email) || !!error) && (
                <div className="tip-text" style={{ color: "#FC0101" }}>
                  {errors.email && touched.email ? errors.email : ""}
                </div>
              )}
            </div>
            <div className="signup-input-wrapper ">
              <Autocomplete
                className={`residence-auto-complete ${isDesktop ? "sign-in-form-field" : "mobile-residence"
                  }`}
                options={LIST_OF_COUNTRIES.map((info) => info.name)}
                value={residence}
                onChange={(_, value) =>
                  value && setFieldValue("residence", value)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={`residence-input-field ${isDesktop ? "sign-in-form-field" : ""
                      }`}
                    placeholder={isDesktop ? "Residence" : ""}
                    name={"residence"}
                    label={isDesktop ? "" : "Residence"}
                    variant={"outlined"}
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </div>
            <div className="signup-pass-input-wrapper">
              <InputField
                name={"password"}
                placeholder={isDesktop ? "Password" : ""}
                title={isDesktop ? "" : "Password"}
                type={"password"}
                value={password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password && touched.password}
                classname={`${isDesktop ? "sign-in-form-field" : ""}`}
              />
              {!!errors.password && !!touched.password && (
                <div className="tip-text">
                  {errors.password && touched.password && errors.password}
                </div>
              )}
            </div>
            <div className="assept-terms" style={{ width: "100%" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={"acceptTerms"}
                    onChange={handleChange}
                    value={acceptTerms}
                    checked={acceptTerms}
                    color="primary"
                    onBlur={handleBlur}
                  />
                }
                label={
                  <p style={{ fontSize: !isDesktop ? "14px" : "" }}>
                    I agree with Chusp's{" "}
                    <a href={ROUTES.POLICY}>Privacy Policy</a> and
                    <a href={ROUTES.TERMS}> Terms.</a>
                  </p>
                }
              />
            </div>
            {isDesktop ? (
              <StyledButtonComponent
                className="signUpBtn"
                disabled={
                  !(Object.keys(errors).length === 0) ||
                  nameErrorMessage !== "success"
                }
                text={"Sign Up"}
                width={"100%"}
                type={"submit"}
                onClickHandler={() => handleSubmit}
              ></StyledButtonComponent>
            ) : (
              <Btn
                className={"sign-up-btn"}
                onClick={() => handleSubmit}
                type={"submit"}
                disabled={!(Object.keys(errors).length === 0)}
                clr={THEME.colors.white}
                background={
                  !isDesktop ? THEME.colors.secondaryblue : THEME.colors.main
                }
                content={"Sign Up"}
              />
            )}
            {open && error?.length === 0 && (
              <Modal
                rightButton={"Done"}
                onComplete={() => history.push(ROUTES.LOGIN)}
                title={<span>Welcome to Chusp</span>}
                open={open}
                disableClose={true}
                setOpen={setOpen}
                children={
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <span className="text-in-modal">
                      We have sent a verification request to <b>{email}</b>
                      <p>Check your spam folder if you haven’t received</p>
                    </span>
                  </div>
                }
              />
            )}
            <p
              className="text-with-link text-with-link-signup "
              style={{ textAlign: "left" }}
            >
              Have an account?
              <Link className="link" to={ROUTES.LOGIN}>
                {" "}
                Sign in{" "}
              </Link>
              or continue with
            </p>
            {!isDesktop && (
              <div className="socials">
                {/* <FacebookLogin
                  appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
                  autoLoad={false}
                  textButton={""}
                  fields="name,email,picture"
                  callback={loginWithFacebook}
                  isMobile={true}
                  redirectUri={`${process.env.REACT_APP_REDIRECT_FACEBOOK_URI}`}
                  disableMobileRedirect={true}
                  icon={<img src={fbIcon} alt={"Chusp Facebook"} />}
                /> */}
                <img
                  className="google-icon"
                  src={googleIcon}
                  onClick={googleRequest}
                  alt="Chusp Gmail"
                />
                <button
                  className="weChat-login-button"
                  onClick={loginWechatRes}
                >
                  <img
                    className="weChat-logo"
                    src={wechatIcon}
                    alt="Chusp WeChat"
                  />
                </button>
              </div>
            )}
            {/*<span className='text-with-decoration'>Or</span>*/}
            {openErrorModal && (
              <SuccessOrErrorModal
                open={openErrorModal}
                setOpen={setOpenErrorModal}
                type={SUCCESS_OR_ERROR_MODAL.ERROR}
                textError={error ? error : "Something went wrong"}
                onActionClicked={() => setOpenErrorModal(false)}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignUpForm;
