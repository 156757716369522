import React from "react";
import chuspLogo from "../../../../assets/img/chusp.svg";
import male from "../../../../assets/img/male-without-background(1).png";
import maleWebp from "../../../../assets/img/male-without-background.webp";
import female from "../../../../assets/img/woman-without-background(1).png";
import femaleWebp from "../../../../assets/img/woman-without-background.webp";
import mandarine from "../../../../assets/img/chinese-char.png";
import { useLocation, useHistory } from "react-router-dom";
import { useWindowSize } from "../../../../service/common/window";
import { devices } from "../../../../ui/queries/devices";
import { ROUTES } from "../../../../enum/routes";

interface IProps {
  toggleForm: boolean;
  setToggleForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileBanner: React.FC<IProps> = ({ toggleForm, setToggleForm }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { width } = useWindowSize();

  // const TEXT_ANIMATION: CSSProperties = {
  //   display: toggleForm ? "none" : "flex",
  //   height: toggleForm ? 0 : 100,
  //   overflow: toggleForm ? "hidden" : "unset",
  //   transition: "0.3s all linear",
  // };

  const isPCSize = width && width >= devices.laptop;

  React.useEffect(() => {
    isPCSize && setToggleForm(false);
  }, [isPCSize, setToggleForm]);

  const goBack = () => {
    pathname === ROUTES.LOGIN
      ? setToggleForm(false)
      : history.push(ROUTES.HOME);
  };

  return (
    <div
      className={`${
        toggleForm ? "picture-container-toggle" : "picture-container"
      }`}
    >
      <div
        className="animated-logo"
        onClick={() => {history.push("/")}}
        style={{
          width: toggleForm || pathname === ROUTES.REGISTER ? 85 : 127,
          transition: "0.3s all linear",
        }}
      >
        <img src={chuspLogo} alt="chusp" />
        <img className="mandarine" src={mandarine} alt="chinese" />
      </div>
      {/* {pathname === ROUTES.LOGIN && (
                <div className='banner-text' style={TEXT_ANIMATION}>
                    <h2>{BANNER_TEXT.EARN.TITLE}</h2>
                    <p>{BANNER_TEXT.EARN.DESCRIPTION.MOBILE}</p>
                </div>
            )} */}
      <div
        className="pictures"
        onClick={goBack}
        style={pathname === ROUTES.REGISTER ? { marginTop: "25px" } : {}}
      >
        <div>
          <span className="male-img">
            <picture>
              <source srcSet={maleWebp} type="image/webp" />
              <source srcSet={male} type="image/png" />
              <img src={male} alt="Chusp male model" />
            </picture>
          </span>
          <span>
            <picture>
              <source srcSet={femaleWebp} type="image/webp" />
              <source srcSet={female} type="image/png" />
              <img src={female} alt="Chusp female model" />
            </picture>
          </span>
        </div>
        <div className="bannerText-container-mobile">
          <span className="bannerText-mobile">
            Earn by hosting exclusive video meetings and content
          </span>
        </div>
      </div>
      {/* {pathname === ROUTES.LOGIN && <div className='banner-text padding-top'>
                    {pathname === ROUTES.LOGIN && <h2 className='meet'>{BANNER_TEXT.MEET.TITLE}</h2>}
                    <p>{BANNER_TEXT.MEET.DESCRIPTION.MOBILE}</p>
                </div>} */}
    </div>
  );
};

export default MobileBanner;
