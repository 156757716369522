import {useEffect} from "react";
import {AUTH_KEY} from "../../enum/auth";
import {useHistory, useLocation} from "react-router";
import {useAuth} from "../authentication/auth";
import {useVariable} from "../system/variable";
import {useCookie} from "react-use";

export const useRedirectToTheSameRoute = () => {
    const [values] = useCookie(AUTH_KEY)
    const history = useHistory()
    const {pathname, search} = useLocation()
    const {authCheck, loading} = useAuth()
    const {getVariables} = useVariable()

    useEffect(() => {
        history.push(`${pathname}${search}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

// REFETCH WHEN REFRESH PAGE
    useEffect(() => {
        (async () => {
            if (values) {
                await authCheck(() => {})
                await getVariables();
            }
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    return {
        refetchAfterreloadLoading: loading
    }
}