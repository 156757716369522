// import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import authReducer from "./reducers/auth";
// import variableReducer from "./reducers/variable";
// import searchReducer from "./reducers/searchUsers";
// import vatReducer from "./reducers/vat";
// import visitorModeReducer from "./reducers/visitorMode"
// import { persistReducer, persistStore } from "redux-persist";
// import storage from "redux-persist/es/storage";

// // export default configureStore({
  
// //   reducer: {
// //     auth: authReducer,
// //     visitorMode: visitorModeReducer,
// //     variable: variableReducer,
// //     searchMemory: searchReducer,
// //     vat: vatReducer
// //   },
// //   middleware: [...getDefaultMiddleware({ immutableCheck: false })],
// // });


// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const persistedReducer = persistReducer(persistConfig, searchReducer);

// const store = configureStore({
//   reducer: {
//     auth: authReducer,
//     visitorMode: visitorModeReducer,
//     variable: variableReducer,
//     searchMemory: persistedReducer,
//     vat: vatReducer
//   },
//   // middleware: [...getDefaultMiddleware({ immutableCheck: false })],
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
//         // Optionally ignore specific paths in the state
//         // ignoredPaths: ['register'],
//       },
//     }),
// });

// const persistor = persistStore(store);

// export { store, persistor };
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import variableReducer from "./reducers/variable";
import searchReducer from "./reducers/searchUsers";
import vatReducer from "./reducers/vat";
import visitorModeReducer from "./reducers/visitorMode";

const store = configureStore({
  reducer: {
    auth: authReducer,
    visitorMode: visitorModeReducer,
    variable: variableReducer,
    searchMemory: searchReducer,
    vat: vatReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      // Optionally ignore specific paths in the state
      // ignoredPaths: ['register'],
    },
  }),
});

export { store };
