import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import InputField from "../../../../../components/field/Field";
import { IFormikValues } from "../../../../../interfaces";
import { ROUTES } from "../../../../../enum/routes";
import { useAuth } from "../../../../../service/authentication/auth";
import { SigninSchema } from "../../../../../config/form-validation/validation-schema";
import Modal from "../../../../../components/modal/main-modal/modal";
import { useLazyRequest } from "../../../../../service/common/request";
import { passwordRecovery } from "../../../../../api/auth";
import { patternEmail } from "../../../../../config/form-validation/validation";
import { useSearchParam } from "react-use";
import SuccessOrErrorModal from "../../../../../components/modal/success-or-error/success-or-error-modal";
import { SUCCESS_OR_ERROR_MODAL } from "../../../../../enum/modal";
import StyledButtonComponent from "../../../../../components/buttons/AnimatedButton/animated-button";
import { useCurrentBrowser } from "../../../../../service/browser-support/browser";
import FiberManualRecordSharpIcon from '@material-ui/icons/FiberManualRecordSharp';

const initialValues: IFormikValues = {
  email: "",
  password: "",
  remember: true,
};

interface IProps {
  isFromModal?: boolean;
}

const SignInForm: React.FC<IProps> = ({ isFromModal }) => {
  const history: any = useHistory();
  const { login, error, setError } = useAuth();
  const token = useSearchParam("token");
  const [openEmailModal, setOpenEmailModal] = useState<boolean>(false);
  const [openMessageModal, setOpenMessageModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [
    openSuccessfullyUpdatedPasswordModal,
    setOpenSuccessfullyUpdatedPasswordModal,
  ] = useState<boolean>(false);
  const [emailInput, setEmailInput] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [showBrowserErrorModal, setShowBrowserErrorModal] =
    useState<boolean>(false);

  const [emailRecoverySentTo, setEmailRecoverySentTo] = useState<string>("");

  const { currentBrowser } = useCurrentBrowser();

  const successfullyUpdatedPassword = history.location?.state?.updatedPassword;

  const [recoveryPassword, data] = useLazyRequest(passwordRecovery, {
    variables: emailInput,
  });
  const errorMessage = data?.data?.message;
  const isValidEmail = patternEmail.test(emailInput);

  const handleRecovery = async () => {
    if (isValidEmail) {
      await recoveryPassword();
      await setOpenEmailModal(false);
      await setEmailRecoverySentTo(emailInput);
      await setOpenMessageModal(true);
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    if (
      currentBrowser === "Samsung Internet" ||
      currentBrowser === "Microsoft Internet Explorer" ||
      currentBrowser === "Samsung Internet" ||
      currentBrowser === "unknown"
    ) {
      setShowBrowserErrorModal(true);
    }

    // eslint-disable-next-line
  }, [currentBrowser]);

  useEffect(() => {
    token &&
      history.push({ pathname: ROUTES.PASSWORD_RECOVERY, state: { token } });
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    errorMessage && setOpenErrorModal(true);
  }, [errorMessage]);

  useEffect(() => {
    successfullyUpdatedPassword &&
      setOpenSuccessfullyUpdatedPasswordModal(true);
  }, [successfullyUpdatedPassword]);

  useEffect(() => {
    if (!openEmailModal) {
      setEmailInput("");
      setShowError(false);
    }
  }, [openEmailModal]);

  // useEffect(()=>{
  //     if(error?.length){
  //         setTimeout(()=>{
  //             setError('test')
  //         },5000)
  //     }
  // },[error])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SigninSchema}
      onSubmit={async (values, { resetForm }) => {
        if (
          currentBrowser === "Samsung Internet" ||
          currentBrowser === "Microsoft Internet Explorer" ||
          currentBrowser === "Samsung Internet" ||
          currentBrowser === "unknown"
        ) {
          setShowBrowserErrorModal(true);
        } else {
          const { email, password, remember } = values;
          await login({ email, password }, remember);
          error === "" && resetForm();
        }
      }}
    >
      {({
        values: { email, password, remember },
        handleSubmit,
        handleChange,
        handleBlur,
        errors,
        touched,
      }) => {
        const isSubmitDisabled = !!(
          !email ||
          email === "" ||
          !password ||
          password === "" ||
          (errors && (errors.email || errors.password))
        );
        return (
          <Form className="form-container form-fields-container" onSubmit={handleSubmit}>
            <div className="input-wrapper" onClick={() => setError(null)}>
              <InputField
                name={"email"}
                placeholder={"Email"}
                title={""}
                type={"email"}
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email}
                classname="sign-in-form-field"
              />
              {errors && !!errors.email && (
                <div className="tip-text-signin">
                  {errors.email && touched.email ? errors.email : ""}
                </div>
              )}
            </div>
            {isFromModal && <br />}
            {openEmailModal && (
              <Modal
                title="Enter your email"
                leftButton={"Cancel"}
                onComplete={handleRecovery}
                disabled={emailInput?.length === 0}
                disableCloseOnComplete={true}
                rightButton={"Send"}
                open={openEmailModal}
                setOpen={setOpenEmailModal}
                children={
                  <div onClick={() => setShowError(false)}>
                    <InputField
                      name={"emailInput"}
                      title={"Email"}
                      type={"email"}
                      error={showError}
                      value={emailInput}
                      onChange={(e) => setEmailInput(e.target.value)}
                      // classname="sign-in-form-field"
                    />
                    {showError && emailInput.length > 0 && (
                      <div className="tip-text-signin">Invalid Email</div>
                    )}
                  </div>
                }
              />
            )}
            {showBrowserErrorModal && (
              <SuccessOrErrorModal
                open={showBrowserErrorModal}
                setOpen={setShowBrowserErrorModal}
                type={SUCCESS_OR_ERROR_MODAL.ERROR}
                textError={
                  "Your browser is not supported by Chusp. Please switch to any of our supported browsers (Chrome, Safari, Edge, Opera or Firefox)."
                }
                disableClose={true}
              />
            )}
            {openMessageModal && (
              <Modal
                rightButton={"Done"}
                open={openMessageModal}
                setOpen={setOpenMessageModal}
                children={
                  <div className="modal-text">
                    We’ve sent an email to <b>{emailRecoverySentTo}</b> with
                    instructions to reset your password. Please check your
                    spam/folder as well.
                  </div>
                }
              />
            )}
            {openErrorModal && (
              <SuccessOrErrorModal
                open={openErrorModal}
                setOpen={setOpenErrorModal}
                type={SUCCESS_OR_ERROR_MODAL.ERROR}
                textError={errorMessage}
                onActionClicked={() => {
                  setOpenErrorModal(false);
                  setOpenEmailModal(true);
                }}
              />
            )}
            {openSuccessfullyUpdatedPasswordModal && (
              <SuccessOrErrorModal
                open={openSuccessfullyUpdatedPasswordModal}
                setOpen={setOpenSuccessfullyUpdatedPasswordModal}
                type={SUCCESS_OR_ERROR_MODAL.SUCCESS}
                textSucess={
                  "You successfully changed your password!. You may now login with your new credentials"
                }
                onActionClicked={() =>
                  setOpenSuccessfullyUpdatedPasswordModal(false)
                }
              />
            )}
            <div className="input-wrapper" onClick={() => setError(null)}>
              <InputField
                name={"password"}
                placeholder={"Password"}
                title={""}
                type={"password"}
                value={password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.password && touched.password}
                classname="sign-in-form-field"
              />
              {errors && !!errors.password && (
                <div className="tip-text-signin">
                  {errors.password && touched.password ? errors.password : ""}
                </div>
              )}
              {!!error && !errors.password && (
                <div className="tip-text-signin">{error}</div>
              )}
            </div>
            {/* <div className='remember-me'>
                            <FormControlLabel
                                control={<Checkbox
                                    name={'remember'}
                                    onChange={handleChange}
                                    value={remember}
                                    checked={remember}
                                    color='primary'
                                />}
                                label={<Typography className='keep-me-signed-in' >Keep me signed in</Typography>}
                            />
                        </div> */}
            <StyledButtonComponent
              className="mt-4 signInBtn"
              text={"Sign In"}
              width={"100%"}
              type={"submit"}
              disabled={isSubmitDisabled}
            ></StyledButtonComponent>
            <div className="forgot-pass-continue-container">
              {!isFromModal && (
                <span
                  style={{ marginTop: errors && !!errors.email ? "0" : "" }}
                //   className="forgot-pass link"
                  className="link forgot-pass-span"
                  onClick={() => setOpenEmailModal(true)}
                >
                  Forgot password?
                </span>
              )}
              {/* <p className="text-with-link"> */}
              <FiberManualRecordSharpIcon/>
              <p>
                <Link className="link" to={ROUTES.REGISTER}>
                  {" "}
                  Sign up
                </Link>{" "}
                or continue with{" "}
              </p>
              {/*<span className='text-with-decoration'>Or</span>*/}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SignInForm;
