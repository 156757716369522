import React from 'react'

const HostingModal = () => {
    return (
        <div className='start-hosting-modal-container'>
            <div className='content'>
                <p>If you stop now, all the progress you’ve made will be lost.</p>
                <p>Do you really want to stop creating your profile? </p>
            </div>
        </div>
    )
}

export default HostingModal