import React, {CSSProperties} from 'react'
import {RootStateOrAny, useSelector} from "react-redux"
import CloseIcon from '@material-ui/icons/Close';
import { useGoogleLogin } from '@react-oauth/google';
import {useAuth} from "../../../service/authentication/auth";
import googleIcon from "../../../assets/img/icons/gmail.svg";
import fbIcon from "../../../assets/img/icons/facebookLogo.png";
import wechatIcon from "../../../assets/img/icons/wechat-logo.svg";
import FacebookLogin from "react-facebook-login";
import Modal from '../main-modal/modal';
import SignInForm from '../../../pages/Auth/components/form/desktop/signin';
import {
    facebookCheckIfAccountExist,
    facebookLoginRequest,
    facebookSignUpWithEmailRequest,
    googleLoginRequest
} from "../../../api/auth";
import {ROUTES} from "../../../enum/routes";
import {useHistory} from "react-router";
import {useUsersIPLocation} from "../../../service/IP";
import {ICommonModalProps} from "../../../interfaces/modal";

// QUICK INLINE STYLES WHERE THERE IS NO LOGIC NEEDED AND NO MEDIA
const STYLES_WE_CHAT_BUTTON: CSSProperties = {
    border: "none",
    backgroundColor: "transparent",
    height: "54px",
    width: "54px",
}

const LogInModal: React.FC<ICommonModalProps> = ({open, setOpen}) => {
    const history = useHistory();
    const {countryIp} = useUsersIPLocation()
    const {doAuthWithSocials} = useAuth();
    const googleRequest = useGoogleLogin({
        onSuccess: async (codeResponse) => codeResponse && codeResponse.code && await doAuthWithSocials(googleLoginRequest, {
            code: codeResponse.code,
            residence_country: countryIp
        }),
        flow: 'auth-code',
    });
    const myData = useSelector((state: RootStateOrAny) => state.auth?.user?.user)

    const loginWithFacebook = async (response: any) => {
        if (response.accessToken) {
            const data = await facebookCheckIfAccountExist(response?.accessToken);
            const alreadyExist = data?.data?.existInDB
            const facebookWithEmail = data?.data?.hasEmail
            alreadyExist ?
                await doAuthWithSocials(facebookLoginRequest, {
                    token: response?.accessToken,
                }) :
                facebookWithEmail ?
                    await doAuthWithSocials(facebookSignUpWithEmailRequest, {
                        token: response?.accessToken,
                        residence_country: countryIp
                    }) :
                    history.push({pathname: ROUTES.AUTH_EMAIL_ADD, state: {token: response.accessToken}})
        }
    }

    const weChatUrl = process.env.REACT_APP_WE_CHAT_LOGIN_URL || ""
    const loginWechatRes = () => {
        window.open(weChatUrl, "_self")
    }

    return (
        <Modal
            open={myData ? false : open}
            setOpen={setOpen}
            children={
                <div className='login-modal-container'>
                    <div className='form-container'>
                        <div className='close-btn' onClick={() => setOpen(false)}><CloseIcon/></div>
                        <h2 className='heading'>Sign in to access all features</h2>
                        <SignInForm isFromModal={true}/>
                        <div className='socials'>
                            <FacebookLogin
                                appId={`${process.env.REACT_APP_FACEBOOK_APP_ID}`}
                                autoLoad={false}
                                textButton={''}
                                fields="name,email,picture"
                                callback={loginWithFacebook}
                                icon={<img className='facebook-icon' src={fbIcon} alt={'Chusp Facebook'}/>}
                            />
                            <img className='google-icon' src={googleIcon} onClick={googleRequest} alt='Chusp Gmail'/>
                            <button style={STYLES_WE_CHAT_BUTTON} onClick={loginWechatRes}>
                                <img className='we-chat-logo' alt='Chusp WeChat' src={wechatIcon}/>
                            </button>
                        </div>
                    </div>
                </div>
            }/>

    )
}

export default LogInModal;

