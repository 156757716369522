import React from 'react'
import Modal from "../main-modal/modal";
import {BAD_OR_LOST_CONNECTION_MODAL} from "../../../enum/modal";
import NetworkWifiIcon from '@material-ui/icons/SignalWifiOff';
import {useHistory} from "react-router";
import {ROUTES} from "../../../enum/routes";
import {ICommonModalProps} from "../../../interfaces/modal";
import Btn from '../../buttons/MainButton/Button';

interface IProps extends ICommonModalProps{
    type: typeof BAD_OR_LOST_CONNECTION_MODAL.BAD_CONNECTION | typeof BAD_OR_LOST_CONNECTION_MODAL.LOST_CONNECTION
}

const BadInternetConnectionModal: React.FC<IProps> = ({open, setOpen, type}) => {
    const history: any = useHistory();

    const modalAction = () => {
        history.push(ROUTES.USER_LIST)
        setOpen(false)
    }
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            children={
                <div className='bad-internet-connection-container'>
                        <div className='modal-window'>
                            <div>
                                <div className='icon-container'>
                                    <NetworkWifiIcon className='styled-error-icon'/>
                                </div>
                                <div className='info-cotainer'>Oooops...</div>
                                <div className='text'>
                                    {type === BAD_OR_LOST_CONNECTION_MODAL.BAD_CONNECTION ? 
                                    'Poor network connection detected. Please check your connectivity.'
                                    : 'Looks like you have lost connection with WiFi or other Internet connection.'}
                                </div>
                                <Btn className='error-btn' onClick={modalAction} background={'transparent'} content={'Try again'} />
                            </div>
                        </div>
                </div>
            }
        />)
}

export default BadInternetConnectionModal;