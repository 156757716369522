export const options = [
    {
        description: 'If you’re good at something, never do it for free. '
    },
    {
        description: 'Whether its video, audio, images or writings – monetizing your digital content on your terms has never been easier or more secure. '
    },
    {
        description: 'On Chusp, you can now set up your own global sales portal for your content in a few simple steps:',
        options: [
            'Create your Chusp profile',
            'Upload, describe & promote your digital content on Chusp ',
            'Choose the price tag for access to your content'
        ]
    },
    {
        description: 'With a few clicks, fans worldwide can now purchase access to your digital content on Chusp, thanks to Chusp’s global accessibility & end-to-end payment processing capabilities, powered by technology from DBS bank and Wise. '
    },
    {
        description: 'Wish Chusp, you can now unlock the full value of your creativity.'
    },
    {
        description: 'Best of all: on Chusp, you can generate additional earnings by hosting private, paid video meetings to your fans, powered by Chusp’s secure, proprietary video technology. '
    },
]

export const header = 'Be a Chusp Creator'

export const buttonName = {first:'Be a Chusp Fan', second: 'Be a Chusp Host'}

