import moment from "moment-timezone";
import ct from "countries-and-timezones"


export const useUsersIPLocation = () => {
    const location = sessionStorage.getItem("location")
    const timezone = moment.tz.guess()

    const country = ct.getCountryForTimezone(timezone)

    const countryName = country ? country.name : "Singapore"

    return {
        countryIp: location ? location : countryName
    }
}