import React, {useContext, useState} from 'react'
import {ROUTES} from "../../enum/routes";
import chusplogo from '../../assets/img/chusp.svg'
import {IconButton} from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import HomeIcon from "@material-ui/icons/Home";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import PersonIcon from "@material-ui/icons/Person";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined';
import {RootStateOrAny, useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router'
import {IUser} from "../../interfaces/user";
import {useLocation} from "react-use";
import Tooltip from "@material-ui/core/Tooltip";
import {saveToMemory} from "../../redux/reducers/searchUsers";
import Badge from "@material-ui/core/Badge";
import LogInModal from '../modal/login-modal/login-modal';
import {useCommonNotifications} from "../../service/notifications/common-notifications";
import {SocketsContext} from "../../pages/App";
import Btn from '../buttons/MainButton/Button';
import { THEME } from '../../ui/theme';
import { CONTAINERS } from '../../enum/containers';
import { useHasToken } from '../../service/authentication/has-token';

interface IHeaderProps {
    children?: JSX.Element | null,
    setOpenLeftBar?: React.Dispatch<React.SetStateAction<boolean>>,
    setToggleSearch?: React.Dispatch<React.SetStateAction<boolean>>,
    leftIcon?: JSX.Element | null,
    rightIcon?: JSX.Element | null,
    openLeftBar?: boolean
}

const Header: React.FC<IHeaderProps> =
    ({
         leftIcon,
         rightIcon,
         setOpenLeftBar,
         openLeftBar,
     }) => {
        const {socket} = useContext(SocketsContext)
        const {commonNotifications, resetNotifications} = useCommonNotifications(socket, false)
        const history = useHistory();
        const { jwtLoading, hasJwt } = useHasToken();
        const user: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
        const {pathname} = useLocation()
        const isAuth = pathname?.includes(ROUTES.ROOT_AUTH)
        const isWelcomePage = pathname === ROUTES.ROOT_PRIVATE
        const isMeetingPage = pathname?.includes(ROUTES.MY_PAGE.MEETING_MODE)
        const dispatch = useDispatch()
        const [openLoginModal, setOpenLoginModal] = useState<boolean>(false);
        const location = useLocation();
        const isPostShowMore = location.search === CONTAINERS.POST_SHOW_MORE.CONTAINER_SEARCH;

        const searchMemory = useSelector(
            (state: RootStateOrAny) => state?.searchMemory?.memory
          );

        const chuspLogoRedirect = () => {
            console.log(user, 'user chuspLogoRedirect',jwtLoading,  hasJwt)
            if (user) {
            window.scroll({top: 0, behavior: 'smooth'})
            dispatch(saveToMemory({id: searchMemory?.id, page: searchMemory?.page, usersInfo: searchMemory?.usersInfo}))
                if (!isAuth && !isMeetingPage) {
                    return history.push(ROUTES.USER_LIST)
                }
            } else {
                setOpenLoginModal(true)
            }
        }

        const goToPage = (historyParams: { pathname: string, state?: any }, setStateAction?: () => void) => {
            if (user) {
                // dispatch(saveToMemory({id: 0, page: 0, usersInfo:[]}))
                // dispatch(saveToMemory({id: searchMemory?.id, page: searchMemory?.page, usersInfo: searchMemory?.usersInfo}))
                // setStateAction && setStateAction()
                return history.push(historyParams)
            } else {
                setOpenLoginModal(true)
                // history.push(ROUTES.ROOT_AUTH)
            }
        }

        return (
            <div className='header-container'>
                {openLoginModal && <LogInModal open={openLoginModal} setOpen={setOpenLoginModal}/>}
                <div className='left-icon'>
              {
                hasJwt && (isPostShowMore ? <div className='profile-container position-relative'>
                <Btn
                        className={"go-back-button"}
                        clr={THEME.colors.secondaryblue}
                        onClick={() => history.goBack()}
                        content={"Back"}
                    /></div> :   <IconButton onClick={() => setOpenLeftBar && setOpenLeftBar(!openLeftBar)}>
                    {leftIcon}
                </IconButton>)
              }  
                  
                </div>
              { !isAuth && <img className='logo' onClick={chuspLogoRedirect} src={chusplogo} alt='Chusp'/> }
                { hasJwt && !isWelcomePage && (
                    <div className='right-icon'>
                        <div className={`main-header-icons ${isMeetingPage && 'main-header-icons--hide'}`}>
                            <Tooltip title={'Home'}>
                                <IconButton
                                    className={`styled-icon-btn ${pathname === ROUTES.USER_LIST && 'styled-icon-btn--active'}`}
                                    onClick={() => history.push(ROUTES.USER_LIST)}>
                                    {pathname === ROUTES.USER_LIST ? <HomeIcon/> : <HomeOutlinedIcon/>}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Schedule'}>
                                <IconButton
                                    className={`styled-icon-btn ${pathname === ROUTES.MY_PAGE.SCHEDULER && 'styled-icon-btn--active'}`}
                                    onClick={() => goToPage({
                                        pathname: user && ROUTES.MY_PAGE.SCHEDULER,
                                        state: {isRedirectedFromHeader: true}
                                    })}>
                                    {pathname === ROUTES.MY_PAGE.SCHEDULER ? <PermContactCalendarIcon/> :
                                        <PermContactCalendarOutlinedIcon/>}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Create post'}>
                                <IconButton
                                    className={`styled-icon-btn ${pathname === ROUTES.MY_PAGE.CREATE_POST && 'styled-icon-btn--active'}`}
                                    onClick={() => goToPage({
                                        pathname: ROUTES.MY_PAGE.CREATE_POST,
                                    })}>
                                    {pathname === ROUTES.MY_PAGE.CREATE_POST ? <AddBoxIcon/> :
                                        <AddBoxOutlinedIcon/>}
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'My Profile'}>
                                <IconButton
                                    className={`styled-icon-btn ${pathname === ROUTES.MY_PAGE.PROFILE && 'styled-icon-btn--active'}`}
                                    onClick={() => goToPage({
                                            pathname: ROUTES.MY_PAGE.PROFILE
                                        },
                                        resetNotifications
                                    )}>
                                    <Badge className='styled-badge'
                                           badgeContent={commonNotifications ? commonNotifications?.length : 0}
                                           color="error">
                                        {pathname === ROUTES.MY_PAGE.PROFILE ? <PersonIcon/> :
                                            <PersonOutlineOutlinedIcon/>}
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <span className='styled-icon'> {rightIcon} </span>
                    </div>
                )}
            </div>
        )
    }

export default React.memo(Header);
