import React, {useEffect, useState} from "react";
import Btn from "../../../components/buttons/MainButton/Button";
import {THEME} from "../../../ui/theme";
import {useWindowSize} from "../../../service/common/window";
import QueryBuilderOutlinedIcon from "@material-ui/icons/QueryBuilderOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import SupervisorAccountOutlinedIcon from "@material-ui/icons/SupervisorAccountOutlined";
import StarsIcon from "@material-ui/icons/Stars";
import Tv from '@material-ui/icons/Tv';
import OptionWithIconAndText from '../../../components/option-with-icon-and-text'
import {ROUTES} from "../../../enum/routes";
import {useHistory} from 'react-router'
import {CONTAINERS} from "../../../enum/containers";
import {useInitialRequest} from "../../../service/common/request";
import {getBlockedUser} from "../../../api/blacklist";
import {REQUEST_TYPES} from "../../../enum/request";
import {useUserRole} from "../../../service/authentication/auth";
import {STATUS_VERIFICATION} from "../../../enum/face-recoginition";
import ToastNotification from "../../../components/toast-notification";
import {SUCCESS_OR_ERROR_MODAL} from "../../../enum/modal";
import {RootStateOrAny, useSelector} from "react-redux";
import {IUser} from "../../../interfaces/user";
import HoveredPopup from "../../../components/hovered-popup";
import StarsStatistics from "../../../components/stars-statistics";
import {Tooltip} from "@material-ui/core";
import {SEARCH_STEPS} from "../../../enum/steps";
import TextWithDecoration from "../../../components/text-with-decoration/TextWithDecoration";
import {useVerificationStatus} from "../../../service/verification";
import CannotCreateSlotErrorModal from "./../../Calendar/Modals/CannotCreateSlotErrorModal"
import { USER_ROLE } from "../../../enum/user";
import { CALENDAR_ERRORS } from "../../../enum/errors";
import Modal from "../../../components/modal/main-modal/modal";

interface IProps {
    earnings: number,
    totalScore: number,
    successRate: number,
    getStarsCounterByEnumValue: (amountOfStars: number) => number
}

const Hosting: React.FC<IProps> = ({earnings, totalScore, successRate, getStarsCounterByEnumValue}) => {
    const myData: IUser = useSelector((state: RootStateOrAny) => state.auth?.user?.user)
    const {isDesktop} = useWindowSize()
    const history = useHistory()
    const {isHostingUser} = useUserRole()
    const [open, setOpen] = useState<boolean>(false)
    const userRole = useSelector((state: RootStateOrAny) => state.auth?.user?.user?.role)

    const [openCannotCreateSlotModal, setOpenCannotCreateSlotModal] = useState<boolean>(false)
    const [cannotCreateModalErrorMessage, setCannotCreateModalMessage] = useState<string>()
    
    const [openRedirectToUserAuthenticationModal, setOpenRedirectToUserAuthenticationModal] = useState<boolean>(false)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // eslint-disable-next-line
    const [blackList, data] = useInitialRequest(getBlockedUser, {
        variables: {
            type: REQUEST_TYPES.BLOCKED
        },
        pagination: {page: 0, pageSize: 10}
    });

    const {verificationInfo, loading} = useVerificationStatus(!myData?.id_verified)

    const noBlacklistedUsers = data?.data?.items.length === 0
    const isUserFirstlyVerified = verificationInfo?.length <= 0;
    const userVerificationStatus = verificationInfo && verificationInfo[0]?.status

    const changeContainers = () => {
        if (noBlacklistedUsers) {
            history.push({
                search: CONTAINERS.HOSTING.CONTAINER_SEARCH
            })
        } else {
            history.push({
                search: CONTAINERS.BLACKLIST.CONTAINER_SEARCH
            })
        }
    }

    const renderToastColorDependingOnStatus = () => {
        switch (userVerificationStatus) {
            case STATUS_VERIFICATION.PENDING:
                return THEME.colors.orange;
            case STATUS_VERIFICATION.REJECTED_PHOTO_ID_MISSING:
                return THEME.colors.deletered
            case STATUS_VERIFICATION.REJECTED_NOT_MATCHING_AVATAR:
                return THEME.colors.deletered
            case STATUS_VERIFICATION.REJECTED_RETAKE_PICTURE:
                return THEME.colors.deletered
        }
    }

    // TODO Need to rewrite this shit
    const renderVerificationStatus = () => {
        if (isUserFirstlyVerified || userVerificationStatus === STATUS_VERIFICATION.INITIAL) {
            return <><Btn
                className={"hosting-btn"}
                clr={THEME.colors.white}
                background={THEME.colors.secondaryblue}
                onClick={() => history.push(ROUTES.MY_PAGE.VERIFICATION)}
                content={'Verification'}
            />
                <ToastNotification
                    type={SUCCESS_OR_ERROR_MODAL.SUCCESS}
                    text={'Earning the authentication badge will lift the credibility of your Host profile and can boost bookings and earnings '}
                />
            </>
        } else if (userVerificationStatus === STATUS_VERIFICATION.PENDING) {
            return <ToastNotification
                color={renderToastColorDependingOnStatus()}
                type={SUCCESS_OR_ERROR_MODAL.SUCCESS}
                text={'Verification in progress..\n' +
                'You will get notified about your verification status within the next few business days'}
            />
        } else if (userVerificationStatus === STATUS_VERIFICATION.REJECTED) {
            return <>
                <Btn
                    className={"hosting-btn"}
                    clr={THEME.colors.white}
                    background={THEME.colors.secondaryblue}
                    onClick={() => history.push(ROUTES.MY_PAGE.VERIFICATION)}
                    content={'Verification'}
                />
                <ToastNotification
                    color={renderToastColorDependingOnStatus()}
                    type={SUCCESS_OR_ERROR_MODAL.ERROR}
                    text={'Unfortunately, we were not able to endorse your verification request. We’ve sent you an email with the reason why.'}
                />
            </>
        } else if (userVerificationStatus === STATUS_VERIFICATION.REJECTED_RETAKE_PICTURE) {
            return <>
                <Btn
                    className={"hosting-btn"}
                    clr={THEME.colors.white}
                    background={THEME.colors.secondaryblue}
                    onClick={() => history.push(ROUTES.MY_PAGE.VERIFICATION)}
                    content={'Verification'}
                />
                <ToastNotification
                    color={renderToastColorDependingOnStatus()}
                    type={SUCCESS_OR_ERROR_MODAL.ERROR}
                    text={'Unfortunately, we were not able to endorse your verification request. We’ve sent you an email with the reason why.'}
                />
            </>
        } else if (userVerificationStatus === STATUS_VERIFICATION.REJECTED_NOT_MATCHING_AVATAR) {
            return <>
                <Btn
                    className={"hosting-btn"}
                    clr={THEME.colors.white}
                    background={THEME.colors.secondaryblue}
                    onClick={() => history.push(ROUTES.MY_PAGE.VERIFICATION)}
                    content={'Verification'}
                />
                <ToastNotification
                    color={renderToastColorDependingOnStatus()}
                    type={SUCCESS_OR_ERROR_MODAL.ERROR}
                    text={'Unfortunately, we were not able to endorse your verification request. We’ve sent you an email with the reason why.'}
                />
            </>
        } else if (userVerificationStatus === STATUS_VERIFICATION.REJECTED_PHOTO_ID_MISSING) {
            return <>
                <Btn
                    className={"hosting-btn"}
                    clr={THEME.colors.white}
                    background={THEME.colors.secondaryblue}
                    onClick={() => history.push(ROUTES.MY_PAGE.VERIFICATION)}
                    content={'Verification'}
                />
                <ToastNotification
                    color={renderToastColorDependingOnStatus()}
                    type={SUCCESS_OR_ERROR_MODAL.ERROR}
                    text={'Unfortunately, we were not able to endorse your verification request. We’ve sent you an email with the reason why.'}
                />
            </>
        } else {
            return <></>
        }
    }

    const accessToHostingPages = (object: { pathname: string, state?: any }) => {
        if (isHostingUser) {
            history.push(object)
        }
    }

    const removeTempSlot = () => {}

    const userCantCreateSlot = () => {
        setCannotCreateModalMessage(CALENDAR_ERRORS.CREATE_SLOT_NOT_ALLOWED)
        setOpenCannotCreateSlotModal(true)
    }
    return (
        <div className={"hosting-container"}>
            {openCannotCreateSlotModal && <CannotCreateSlotErrorModal
                removeTempSlot={removeTempSlot}
                open={openCannotCreateSlotModal}
                setOpen={setOpenCannotCreateSlotModal}
                errorMessage={cannotCreateModalErrorMessage}
                setErrorMessage={setCannotCreateModalMessage}
            />}
            {!isDesktop && (
                <>
                    <div className={"hosting-stats"}>
                        <div className="hosting-stats-flex">
                            <span>${earnings}</span>
                            <span>Earnings</span>
                        </div>
                        <div className="hosting-stats-flex" onClick={() => setOpen(!open)}>
                            <StarsIcon style={{color: THEME.colors.grey}}/>
                            <span>{totalScore}</span>
                            <HoveredPopup
                                open={open}
                                style={{top: '60px'}}
                                children={
                                    <StarsStatistics
                                        getStarsCounterByEnumValue={getStarsCounterByEnumValue}
                                        totalScore={totalScore}
                                    />}
                                setOpen={setOpen}/>
                        </div>
                        <div className="hosting-stats-flex">
                            <span>{successRate}%</span>
                            <span>Success</span>
                        </div>
                    </div>
                    <TextWithDecoration top={5} bottom={10} content={'Hosting'} />
                </>
            )}
            <div className={"options"}>
                <OptionWithIconAndText
                    onClick={() => userRole === USER_ROLE.VU ?
                        userCantCreateSlot()
                        :
                        history.push({
                        pathname: ROUTES.MY_PAGE.SCHEDULER,
                        state: {isRedirectedFromHeader: false}
                    })}
                    text={'Hosting Schedule'}
                    icon={<QueryBuilderOutlinedIcon/>}
                    isAvailable={true}
                />
                <Tooltip title={!isHostingUser ? "Available only for hosting users" : ""} placement="top">
                    <div>
                        <OptionWithIconAndText
                            onClick={() => accessToHostingPages({pathname: ROUTES.MY_PAGE.RATES})}
                            text={'Hosting Rates'}
                            icon={<MonetizationOnOutlinedIcon/>}
                            isAvailable={isHostingUser}
                        />
                    </div>
                </Tooltip>
                <Tooltip title={noBlacklistedUsers ? "You don't have blacklisted users" : ""} placement="top">
                    <div>
                        <OptionWithIconAndText
                            text={'User Management'}
                            onClick={() => changeContainers()}
                            icon={<SupervisorAccountOutlinedIcon/>}
                            isAvailable={!noBlacklistedUsers}
                        />
                    </div>
                </Tooltip>
                <Tooltip title="Paid Content"  placement="top">
                    <div>
                        <OptionWithIconAndText
                            text={'Paid Content'}
                            onClick={() =>{
                                !!myData.id_verified 
                                ?  history.push({
                                    pathname: ROUTES.MY_PAGE.PAID_CONTENT,
                                    state: {buyMode: false}
                                })
                                : setOpenRedirectToUserAuthenticationModal(true)
                            }}
                            icon={<Tv/>}
                            isAvailable={true}
                        />
                    </div>
                </Tooltip>
            </div>
            {!isHostingUser && <Btn
                className={"hosting-btn"}
                clr={THEME.colors.white}
                background={THEME.colors.secondaryblue}
                onClick={() => history.push({
                    pathname: ROUTES.MY_PAGE.HOSTING,
                    search: SEARCH_STEPS.FIRST_STEP
                })}
                content={'Start hosting'}
            />}
            {!myData?.id_verified && !loading && renderVerificationStatus()}
            {openRedirectToUserAuthenticationModal && <Modal 
                open={openRedirectToUserAuthenticationModal}
                setOpen={setOpenRedirectToUserAuthenticationModal}
                customClass="redirect-to-user-authentication"
                children={myData.role === 'HU' 
                ? `To offer and market your digital content on Chusp, you'll need to authenticate your user profile.`
                : `To offer and market your digital content on Chusp, please create an authenticated user profile.`}
                leftButton={`No thanks`}
                rightButton={myData.role === 'HU' 
                    ? <div onClick={() => history.push(ROUTES.MY_PAGE.VERIFICATION)}>
                        Authenticate now
                    </div> 
                    : <div onClick={() =>history.push({
                        pathname: ROUTES.MY_PAGE.HOSTING,
                        search: SEARCH_STEPS.FIRST_STEP
                    })}>Create profile now</div>}
            />}
        </div>
    );
};

export default Hosting;
