import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {SUCCESS_OR_ERROR_MODAL} from "../../enum/modal";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

interface IProps {
    type: string,
    text?: string,
    color?: string
}

const ToastNotification: React.FC<IProps> = (
    {
        type,
        text,
        color
    }) => {
    return (
        <div className='toast-container'>
            <div className='icon-container' style={{backgroundColor: color}}>
                {
                    type === SUCCESS_OR_ERROR_MODAL.SUCCESS
                        ?
                        <CheckCircleIcon className='styled-icon'/>
                        :
                        <RemoveCircleIcon className='styled-icon'/>
                }
            </div>
            <div className='text-container'>
                {text}
            </div>
        </div>
    )
}

export default ToastNotification;

