import React, {useEffect, useState} from 'react'
import {Route, Switch, Redirect} from "react-router";
import {useWindowSize} from "../../service/common/window";
import MobileSignInContainer from "./mobile/container/signin";
import MobileSignUpContainer from "./mobile/container/signup";

import DesktopSignInContainer from "./desktop/container/signin";
import DesktopSignUpContainer from "./desktop/container/signup";

import DesktopBanner from "./desktop/banner";
import MobileBanner from "./mobile/banner";
import {ROUTES} from "../../enum/routes";
import {useCookie, useSearchParam, useTitle} from "react-use";
import {AUTH_KEY} from "../../enum/auth";
import {useHistory, useLocation} from "react-router"
import {useAuth} from "../../service/authentication/auth";
import PasswordRecoveryPage from "./password-recovery-page";
import AddEmailContainer from "./add-email-page";

const Auth = () => {
    const history = useHistory()
    const {pathname} = useLocation()
    const [values] = useCookie(AUTH_KEY)
    const [toggleForm, setToggleForm] = useState<boolean>(false)
    const {isDesktop} = useWindowSize();
    const {authCheck} = useAuth()
    const meetingId = useSearchParam('meetingId');

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    useEffect(() => {
        (async () => {
            meetingId && sessionStorage.setItem('meetingId', meetingId)
            values && (await authCheck(() => {
               meetingId ?  history.push(ROUTES.MY_PAGE.MEETING_MODE + '/' + meetingId) : history.push(ROUTES.USER_LIST)
            }));
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values]);

    const renderTitle = () => {
        switch (pathname) {
            case ROUTES.LOGIN:
                return "Chusp • Login";
            case ROUTES.REGISTER:
                return "Chusp • Register";
            case ROUTES.PASSWORD_RECOVERY:
                return "Chusp • Password Recovery";
            case ROUTES.AUTH_EMAIL_ADD:
                return "Chusp • Add Email";
            default:
                return "Chusp"
        }
    }

    useTitle(renderTitle())

    const mobileSignInFormProps = {
        toggleForm,
        setToggleForm,
    }

    if (values) {
        return <Redirect to={ROUTES.USER_LIST}/>
    }


    return (
        <div className={`auth-container ${pathname === '/auth/login' ? 'login-auth-container' : ''}`}>
            {(isDesktop || isDesktop === null) ? <DesktopBanner/> :
                <MobileBanner toggleForm={toggleForm} setToggleForm={setToggleForm}/>}
            <Switch>
                <Route exact path={ROUTES.AUTH_EMAIL_ADD} component={AddEmailContainer}/>
                <Route exact path={ROUTES.PASSWORD_RECOVERY} component={PasswordRecoveryPage}/>
                <Route exact path={ROUTES.REGISTER}
                       render={() => (isDesktop || isDesktop === null) ? <DesktopSignUpContainer/> :
                           <MobileSignUpContainer/>}/>
                <Route exact path={ROUTES.LOGIN}
                       render={() => (isDesktop || isDesktop === null) ? <DesktopSignInContainer/> :
                           <MobileSignInContainer {...mobileSignInFormProps}/>}/>
                <Redirect to={ROUTES.LOGIN}/>
            </Switch>
        </div>
    )
}

export default Auth
